import logo from "images/ATAF-Logo-Colour.png";

export default function PageHeaderExternal({ pageHeaderName }) {
    return (
        <>
            <div>
                <img className="mx-auto h-24 w-auto" src={logo} />
                <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">
                    ATAF TA Management System
                </h2>
                <p className="mt-2 text-center text-lg text-gray-600">
                    {pageHeaderName}
                </p>
            </div>
        </>
    );
}
