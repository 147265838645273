import { Fragment, useEffect, useState, useContext } from "react";
import {
    primaryNavigation,
    reportingNavigation,
    adminNavigation,
} from "routes";
import { PORTAL } from "portal";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon, GlobeAltIcon } from "@heroicons/react/outline";
import { ApiConsumer } from "api-consumer";
import { API_ROUTES } from "api";
import { ActiveCountryContext } from "hooks/ActiveCountryContext";
import { AuthenticationContext } from "hooks/AuthenticationContext";
import { usePermissionsHook } from "hooks/PermissionsHook";
import { getCache, deleteCache } from "hooks/cacheStorage";
import { NavLink } from "react-router-dom";
import { useAuthentication } from "hooks/AuthenticationHook";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";
import logo from "images/ATAF-Logo-Colour.png";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function Navigation({ sidebarOpen, setSidebarOpen, filtered, searchParam, pageName="" }) {
    let { currentUser } = useContext(AuthenticationContext);
    const [countries, setCountries] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loaded, setLoaded] = useState(false);
    let { activeCountry, setActiveCountry } = useContext(ActiveCountryContext);
    let { userCountriesWithProgrammes } = usePermissionsHook();
    let { hasPermission } = useAuthentication();

    const getCountryAndProgrammes = (countryApi, programmeApi) => {
        ApiConsumer.get(countryApi)
            .then((res) => {
                res.data.sort((a, b) => a.name.localeCompare(b.name));
                let memberCountries = res.data.sort(
                    (a, b) => b.total_programmes - a.total_programmes
                );

                ApiConsumer.get(programmeApi)
                    .then((res) => {
                        res.data = res.data.filter(
                            (v, i, a) =>
                                a.findIndex((v2) => v2.id === v.id) === i
                        );
                        setCountries(
                            userCountriesWithProgrammes(
                                memberCountries,
                                res.data
                            )
                        );
                    })
                    .finally(() => {
                        setLoaded(true);
                    });
            })
            .catch((err) => {})
            .finally(() => {});
    };

    const loadData = () => {
        const countryApi = filtered ? API_ROUTES.CONTENT.COUNTRY + searchParam : API_ROUTES.CONTENT.COUNTRY;
        const programmeApi = filtered ? API_ROUTES.PROGRAMME.PROGRAMMES + searchParam : API_ROUTES.PROGRAMME.PROGRAMMES;
        const cacheData = getCache("dateFilter");
        if (pageName !== "programmes") {
            getCountryAndProgrammes(countryApi, programmeApi);
        } else {
            if ((cacheData && filtered) || (!cacheData)) {
                getCountryAndProgrammes(countryApi, programmeApi);
            }
        }
    };

    useEffect(() => {
        loadData();
        return () => {
            setCountries({});
            setCategories({});
            setLoaded(false);
        };
    }, [filtered, searchParam]);

    useEffect(() => {
        ApiConsumer.get(API_ROUTES.PROGRAMME.CATEGORIES)
            .then((res) => {
                setCategories(res.data);
            })
            .catch((err) => {});
    }, []);

    const clearStorage = () => {
        setActiveCountry({});
        deleteCache('dateFilter');
    };

    return (
        <>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 flex z-40 lg:hidden"
                    onClose={setSidebarOpen}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-green-700">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute top-0 right-0 -mr-12 pt-2">
                                    <button
                                        type="button"
                                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                        onClick={() => setSidebarOpen(false)}
                                    >
                                        <span className="sr-only">
                                            Close sidebar
                                        </span>
                                        <XIcon
                                            className="h-6 w-6 text-white"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </div>
                            </Transition.Child>
                            <div className="flex-shrink-0 flex items-center px-4">
                                <img className="h-auto w-3/4" src={logo} />
                            </div>
                            <nav
                                className="mt-5 flex-shrink-0 h-full divide-y divide-green-800"
                                aria-label="Sidebar"
                            >
                                <div className="px-2 space-y-1">
                                    {primaryNavigation.map((item, i) => (
                                        <NavLink
                                            key={i}
                                            to={item.href}
                                            exact={true}
                                            activeClassName={"bg-green-800"}
                                            className={classNames(
                                                item.current
                                                    ? "bg-green-800 "
                                                    : "text-green-100 hover:bg-green-600",
                                                "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                                            )}
                                            aria-current={
                                                item.current
                                                    ? "page"
                                                    : undefined
                                            }
                                        >
                                            <item.icon
                                                className="mr-2 flex-shrink-0 h-6 w-6 text-green-200"
                                                aria-hidden="true"
                                            />
                                            {item.name}
                                        </NavLink>
                                    ))}
                                </div>
                                <div className="mt-6 pt-6">
                                    <div className="px-2 space-y-1">
                                        {adminNavigation.map((item, i) =>
                                            hasPermission(item.permission) ? (
                                                <NavLink
                                                    key={i}
                                                    to={item.href}
                                                    activeClassName={
                                                        "bg-green-800"
                                                    }
                                                    exact={true}
                                                    className="group flex items-center px-2 py-2 text-base font-medium rounded-md text-green-100 hover:bg-green-600"
                                                >
                                                    <item.icon
                                                        className="mr-2 h-6 w-6 text-green-200"
                                                        aria-hidden="true"
                                                    />
                                                    {item.name}
                                                </NavLink>
                                            ) : null
                                        )}
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </Transition.Child>
                    <div className="flex-shrink-0 w-14" aria-hidden="true">
                        {/* Dummy element to force sidebar to shrink to fit close icon */}
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden lg:flex lg:w-64 lg:flex-col lg:fixed lg:inset-y-0">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex flex-col flex-grow bg-green-700 pb-5 overflow-y-auto">
                    <div className="px-4 py-4 bg-gray-100 leading-5">
                        <NavLink to="/">
                            <img className="h-auto w-3/4 mb-2" src={logo} />
                        </NavLink>
                    </div>
                    <nav
                        className="mt-5 flex-1 flex flex-col divide-y divide-green-800 "
                        aria-label="Sidebar"
                    >
                        <div className="px-2 space-y-1">
                            {primaryNavigation.map((item, i) => (
                                <NavLink
                                    key={i}
                                    to={item.href}
                                    activeClassName={"bg-green-800"}
                                    onClick={() => clearStorage()}
                                    exact={true}
                                    className={classNames(
                                        item.current
                                            ? "bg-green-800 text-green-100"
                                            : "text-green-100 hover:bg-green-600",
                                        "group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md"
                                    )}
                                    aria-current={
                                        item.current ? "page" : undefined
                                    }
                                >
                                    <item.icon
                                        className="mr-2 flex-shrink-0 h-6 w-6 text-green-200"
                                        aria-hidden="true"
                                    />
                                    {item.name}
                                </NavLink>
                            ))}
                        </div>
                        <div className="mt-6 pt-4">
                            {loaded && (
                                <div className="px-2 space-y-1">
                                    <span className="text-green-500 font-medium text-xs ml-2">
                                        PROGRAMMES BY COUNTRY
                                    </span>
                                    <Disclosure>
                                        {({ open }) => (
                                            <>
                                                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-green-800 text-green-100 px-4 py-2 text-left text-sm font-medium">
                                                    <span>View Countries</span>
                                                    <ChevronUpIcon
                                                        className={`${
                                                            open
                                                                ? "rotate-180 transform"
                                                                : ""
                                                        } h-5 w-5 text-green-100`}
                                                    />
                                                </Disclosure.Button>
                                                <Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500">
                                                    {countries.map(
                                                        (country, i) => {
                                                            return (
                                                                <Fragment
                                                                    key={i}
                                                                >
                                                                    <NavLink
                                                                        className={`group flex items-center px-2 py-2 text-sm font-medium leading-6 rounded-md text-green-100  hover:bg-green-600 ${
                                                                            activeCountry.id ===
                                                                            country.id
                                                                                ? "bg-green-800 "
                                                                                : null
                                                                        }`}
                                                                        activeClassName={
                                                                            "bg-green-800"
                                                                        }
                                                                        key={
                                                                            country.id
                                                                        }
                                                                        to={`${PORTAL.PROGRAMMES_BY_COUNTRY}/${country.id}`}
                                                                        onClick={() =>
                                                                            setActiveCountry(
                                                                                country
                                                                            )
                                                                        }
                                                                    >
                                                                        <GlobeAltIcon
                                                                            className="mr-2 flex-shrink-0 h-6 w-6 text-green-200"
                                                                            aria-hidden="true"
                                                                        />{" "}
                                                                        {country.name +
                                                                            " (" +
                                                                            country.total_programmes +
                                                                            ")"}
                                                                    </NavLink>
                                                                    <div className="bg-green-800 rounded-md">
                                                                        {activeCountry.id ===
                                                                            country.id &&
                                                                            categories.length > 0 &&
                                                                            categories.map(
                                                                                (
                                                                                    category
                                                                                ) => {
                                                                                    return (
                                                                                        <NavLink
                                                                                            className="group flex items-center px-10 py-2 text-sm leading-6 font-small rounded-md text-green-100  hover:bg-green-600"
                                                                                            key={
                                                                                                category.id
                                                                                            }
                                                                                            to={`${PORTAL.PROGRAMMES_BY_COUNTRY}/${country.id}/category/${category.id}`}
                                                                                        >
                                                                                            {
                                                                                                category.name
                                                                                            }
                                                                                        </NavLink>
                                                                                    );
                                                                                }
                                                                            )}
                                                                    </div>
                                                                </Fragment>
                                                            );
                                                        }
                                                    )}
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                </div>
                            )}
                        </div>
                        <div className="mt-6 pt-6">
                            <div className="px-2 space-y-1">
                                <span className="text-green-500 font-medium text-xs ml-2">
                                    REPORTING
                                </span>
                                {reportingNavigation.map((item, i) =>
                                    hasPermission(item.permission) ? (
                                        <NavLink
                                            key={i}
                                            to={item.href}
                                            exact={true}
                                            activeClassName={"bg-green-800"}
                                            onClick={() => clearStorage()}
                                            className="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-green-100 hover:bg-green-600"
                                        >
                                            <item.icon
                                                className="mr-2 h-6 w-6 text-green-200"
                                                aria-hidden="true"
                                            />
                                            {item.name}
                                        </NavLink>
                                    ) : null
                                )}
                            </div>
                        </div>

                        {currentUser.user_type.name === "Administrator" && (
                            <div className="mt-6 pt-6">
                                <div className="px-2 space-y-1">
                                    <span className="text-green-500 font-medium text-xs ml-2">
                                        ADMINISTRATION
                                    </span>
                                    {adminNavigation.map((item, i) =>
                                        hasPermission(item.permission) ? (
                                            <NavLink
                                                key={i}
                                                to={item.href}
                                                exact={true}
                                                activeClassName={"bg-green-800"}
                                                onClick={() => clearStorage()}
                                                className="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-green-100 hover:bg-green-600"
                                            >
                                                <item.icon
                                                    className="mr-2 h-6 w-6 text-green-200"
                                                    aria-hidden="true"
                                                />
                                                {item.name}
                                            </NavLink>
                                        ) : null
                                    )}
                                </div>
                            </div>
                        )}
                    </nav>
                </div>
            </div>
        </>
    );
}
