import { useState, useEffect, useContext } from "react";
import { format } from "date-fns";
import { useFormValidation } from "hooks/FormValidationHook";
import { API_ROUTES } from "api";
import { ApiConsumer } from "api-consumer";
import "react-datepicker/dist/react-datepicker.css";
import Alert from "components/alert";
import { AppLoaderContext } from "hooks/AppLoaderContext";

export default function AddEditUser({
    userTypeParam,
    setOpen,
    editableRow,
    setRowUpdated,
    loading
}) {
    let { setLoading } = useContext(AppLoaderContext);
    let { validFormDetails } = useFormValidation();
    let selectedPermissions = [];
    const [userTypes, setUserTypes] = useState([]);
    const [userCountries, setUserCountries] = useState([]);
    const [expertInstitution, setExpertInstitution] = useState([]);
    const [programmePermissions, setProgrammePermissions] = useState([]);
    const [missionPermissions, setMissionPermissions] = useState([]);
    const [taskPermissions, setTaskPermissions] = useState([]);
    const [invalidFormDetails, setInvalidFormDetails] = useState({});
    const [formDetails, setFormDetails] = useState({});
    const [formError, setFormError] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [groupPermissions, setGroupPermissions] = useState([]);
    const [userPermissions, setUserPermissions] = useState([]);
    const [region, setRegion] = useState('');

    const formFields = [
        { name: "first_name", display: "first name" },
        { name: "last_name", display: "last name" },
        { name: "designation", display: "designation" },
        { name: "email", display: "email address" },
        /*{ name: 'contact_number', display: 'contact number' },*/
        { name: "user_type", display: "user type" },
        { name: "expert_institution", display: "institution of expert" },
    ];

    const validateAddEditUser = (isEdit) => {
        if (
            validFormDetails(
                formFields,
                formDetails,
                setInvalidFormDetails,
                setFormError
            )
        ) {
            setLoading(true);

            if (isEdit) {
                ApiConsumer.put(
                    API_ROUTES.USERS.USER_DETAIL(editableRow.id),
                    formDetails
                )
                    .then((res) => {
                        setRowUpdated(true);
                        setFormSubmitted(true);
                    })
                    .catch((err) => {
                        setFormError(err.toString());
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                ApiConsumer.post(API_ROUTES.USERS.USERS, formDetails)
                    .then((res) => {
                        setRowUpdated(true);
                        setFormSubmitted(true);
                    })
                    .catch((err) => {
                        setFormError(err.toString());
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    };

    const loadDropdown = (link, setMethod) => {
        ApiConsumer.get(link)
            .then((res) => {
                res.data.sort((a, b) => a.name.localeCompare(b.name));
                setMethod(res.data);
            })
            .catch((err) => {});
    };

    useEffect(() => {
        if (loading) setLoading(true);
        else setLoading(false);
    }, [loading]);

    useEffect(() => {
        loadDropdown(
            API_ROUTES.MISSION.EXPERT_INSTITUTION,
            setExpertInstitution
        );
        ApiConsumer.get(API_ROUTES.USERS.TYPES)
            .then((res) => {
                res.data.sort((a, b) => a.name.localeCompare(b.name));
                setUserTypes(
                    Object.keys(editableRow).length === 0
                        ? userTypeParam === "administrators"
                            ? res.data.filter(
                                  (type) => type.name === "Administrator"
                              )
                            : res.data.filter(
                                  (type) => type.name !== "Administrator"
                              )
                        : res.data
                );
            })
            .catch((err) => {});
    }, [userTypeParam, editableRow]);

    useEffect(() => {
        ApiConsumer.get(API_ROUTES.CONTENT.USER_COUNTRIES)
            .then((res) => {
                res.data.sort((a, b) => a.name.localeCompare(b.name));
                setUserCountries(res.data);
            })
            .catch((err) => {});
    }, []);

    useEffect(() => {
        ApiConsumer.get(API_ROUTES.USERS.PERMISSIONS_BY_GROUP)
            .then((res) => {
                setGroupPermissions(res.data);
                //Permission segments for disply
                setProgrammePermissions(
                    res.data.filter(
                        (permission) =>
                            permission.name === "Programme Permissions"
                    )[0].permissions
                );
                setMissionPermissions(
                    res.data.filter(
                        (permission) =>
                            permission.name === "Mission Permissions"
                    )[0].permissions
                );
                setTaskPermissions(
                    res.data.filter(
                        (permission) => permission.name === "Task Permissions"
                    )[0].permissions
                );
            })
            .catch((err) => {});
    }, []);

    useEffect(() => {
        //Load existing user permissions
        if (Object.keys(editableRow).length !== 0) {
            ApiConsumer.get(
                API_ROUTES.USERS.PERMISSIONS_BY_USER(editableRow.id)
            )
                .then((res) => {
                    //Get IDs of permissions
                    selectedPermissions = res.data.individual_permissions.map(
                        (obj) => obj.id
                    );
                    setUserPermissions(selectedPermissions);
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`permissions`]: selectedPermissions,
                    }));
                })
                .catch((err) => {});
        }
    }, [editableRow]);

    const loadUserTypePermissions = (userTypeId) => {
        let userType = userTypes.filter(
            (userType) => userType.id === userTypeId
        )[0]?.name;

        selectedPermissions = groupPermissions
            .filter((permission) => permission.name === userType)[0]
            ?.permissions.map((obj) => obj.id);

        setUserPermissions(selectedPermissions);
        setFormDetails((prevState) => ({
            ...prevState,
            [`permissions`]: selectedPermissions,
        }));
    };

    const changeCountry = (event) => {
        const selectedCountry = userCountries.filter((item) => item.id === event.target.value);
        const value = selectedCountry[0].region ? selectedCountry[0].region.name : '';
        setRegion(value);
        setFormDetails((prevState) => ({
            ...prevState,
            [`country`]: event.target.value,
        }));
    };

    /* useEffect(() => {
      //Load permissions based on user type
    let userType = userTypes.filter(
      (userType) => userType.id === formDetails.user_type,
    )[0]?.name

    let userTypePermissions = groupPermissions.filter(
      (permission) => permission.name === userType,
    )[0]?.permissions

    if (userTypePermissions !== undefined && userType !== 'Administrator') {
      //Reload selected permissions on user type change
      if (selectedPermissions.length) {
        
      }
    } else {
      setProgrammePermissions([])
      setMissionPermissions([])
      setTaskPermissions([])

      delete formDetails.permissions
    }
  }, [userTypes, groupPermissions, formDetails.user_type])
*/
    useEffect(() => {
        /* Defaults */
        setFormDetails((prevState) => ({
            ...prevState,
            [`first_name`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`last_name`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`designation`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`email`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`contact_number`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`alternative_contact_number`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`institution_name`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`user_type`]: "selected",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`country`]: "selected",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`expert_institution`]: "selected",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`permissions`]: [],
        }));

        /* If editing */
        if (Object.keys(editableRow).length !== 0) {
            setFormDetails((prevState) => ({
                ...prevState,
                [`user`]: editableRow.id,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`first_name`]: editableRow.first_name,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`last_name`]: editableRow.last_name,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`designation`]: editableRow.designation,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`email`]: editableRow.email,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`contact_number`]: editableRow.contact_number,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`alternative_contact_number`]:
                    editableRow.alternative_contact_number,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`institution_name`]:
                    editableRow.institution_name,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`user_type`]: editableRow.user_type.id,
            }));
            if (
                editableRow?.country_id !== undefined &&
                editableRow?.country_id !== null
            ) {
                setFormDetails((prevState) => ({
                    ...prevState,
                    [`country`]: editableRow?.country_id,
                }));
            }
            if (editableRow?.region_name) {
                setRegion(editableRow.region_name);
            }
            if (
                editableRow?.expert_institution !== undefined &&
                editableRow?.expert_institution !== null
            ) {
                setFormDetails((prevState) => ({
                    ...prevState,
                    [`expert_institution`]: editableRow.expert_institution.id,
                }));
            }
        }

        return () => {
            setUserTypes([]);
            setExpertInstitution([]);
            setGroupPermissions([]);
            setProgrammePermissions([]);
            setMissionPermissions([]);
            setTaskPermissions([]);
            setUserPermissions([]);
            setInvalidFormDetails({});
            setFormDetails({});
            setFormError("");
            setFormSubmitted(false);
        };
    }, [editableRow]);

    return (
        <>
            {!formSubmitted ? (
                <form
                    className="space-y-6"
                    onSubmit={(event) => {
                        event.preventDefault();
                        validateAddEditUser(Object.keys(editableRow).length);
                    }}
                    method="POST"
                >
                    {Object.keys(editableRow).length !== 0 && (
                        <div className="text-sm border-gray-200 p-4 rounded-lg bg-gray-100">
                            <span className="font-bold">Created:</span>{" "}
                            {format(
                                new Date(editableRow.created),
                                "dd MMM yyyy"
                            )}{" "}
                            <span className="font-bold">Last updated:</span>{" "}
                            {format(
                                new Date(editableRow.updated),
                                "dd MMM yyyy"
                            )}{" "}
                        </div>
                    )}
                    <div className="space-y-2">
                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="first_name">
                                    First name:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="first_name"
                                    name="first_name"
                                    type="text"
                                    autoComplete="first_name"
                                    className={`form-field ${
                                        invalidFormDetails.first_name
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`first_name`]: event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.first_name
                                            ? formDetails.first_name
                                            : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="last_name">
                                    Last name:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="last_name"
                                    name="last_name"
                                    type="text"
                                    autoComplete="last_name"
                                    className={`form-field ${
                                        invalidFormDetails.last_name
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`last_name`]: event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.last_name
                                            ? formDetails.last_name
                                            : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="designation"
                                >
                                    Designation:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="designation"
                                    name="designation"
                                    type="text"
                                    autoComplete="designation"
                                    className={`form-field ${
                                        invalidFormDetails.designation
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`designation`]: event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.designation
                                            ? formDetails.designation
                                            : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="email">
                                    Email address:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="email"
                                    name="email"
                                    type="text"
                                    autoComplete="email"
                                    className={`form-field ${
                                        invalidFormDetails.email
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`email`]:
                                                event.target.value.trim(),
                                        }));
                                    }}
                                    value={
                                        formDetails.email
                                            ? formDetails.email
                                            : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="contact_number"
                                >
                                    Contact number:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="contact_number"
                                    name="contact_number"
                                    type="text"
                                    autoComplete="contact_number"
                                    className={`form-field ${
                                        invalidFormDetails.contact_number
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`contact_number`]:
                                                event.target.value.trim(),
                                        }));
                                    }}
                                    value={
                                        formDetails.contact_number
                                            ? formDetails.contact_number
                                            : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="alternative_contact_number"
                                >
                                    Alternative contact number:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="alternative_contact_number"
                                    name="alternative_contact_number"
                                    type="text"
                                    autoComplete="alternative_contact_number"
                                    className={`form-field ${
                                        invalidFormDetails.alternative_contact_number
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`alternative_contact_number`]:
                                                event.target.value.trim(),
                                        }));
                                    }}
                                    value={
                                        formDetails.alternative_contact_number
                                            ? formDetails.alternative_contact_number
                                            : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="institution_name"
                                >
                                    Name of institution:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="institution_name"
                                    name="institution_name"
                                    type="text"
                                    autoComplete="institution_name"
                                    className={`form-field ${
                                        invalidFormDetails.institution_name
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`institution_name`]:
                                                event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.institution_name
                                            ? formDetails.institution_name
                                            : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="name">
                                    User type:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <select
                                    id="user_type"
                                    name="user_type"
                                    autoComplete="user_type"
                                    className={`${
                                        invalidFormDetails.user_type
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`user_type`]: event.target.value,
                                        }));
                                        loadUserTypePermissions(
                                            event.target.value
                                        );
                                    }}
                                    value={
                                        formDetails.user_type
                                            ? formDetails.user_type
                                            : "selected"
                                    }
                                >
                                    <option value="selected">
                                        Select user type
                                    </option>
                                    {userTypes.map((userType) => {
                                        return (
                                            <option
                                                key={userType.id}
                                                value={userType.id}
                                            >
                                                {userType.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="name">
                                    Country of citizenship (or origin):{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <select
                                    id="country"
                                    name="country"
                                    autoComplete="country"
                                    className={`${
                                        invalidFormDetails.country
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => changeCountry(event)}
                                    value={
                                        formDetails.country
                                            ? formDetails.country
                                            : "selected"
                                    }
                                >
                                    <option value="selected">
                                        Select country of citizenship (or
                                        origin)
                                    </option>
                                    {userCountries.map((userCountry) => {
                                        return (
                                            <option
                                                key={userCountry.id}
                                                value={userCountry.id}
                                            >
                                                {userCountry.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="region"
                                >
                                    Region:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    disabled={true}
                                    id="region"
                                    name="region"
                                    type="text"
                                    autoComplete="region"
                                    className={`form-field border-gray-300`}
                                    value={region}
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="expert_institution"
                                >
                                    Institution of expert:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <select
                                    id="expert_institution"
                                    name="expert_institution"
                                    autoComplete="expert_institution"
                                    className={`${
                                        invalidFormDetails.expert_institution
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`expert_institution`]:
                                                event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.expert_institution
                                            ? formDetails.expert_institution
                                            : "selected"
                                    }
                                >
                                    <option value="selected">
                                        Select institution of expert
                                    </option>
                                    {expertInstitution.map((institution) => {
                                        return (
                                            <option
                                                key={institution.id}
                                                value={institution.id}
                                            >
                                                {institution.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>

                        {formDetails.user_type !== "selected" && (
                            <>
                                <h3 className="font-bold text-gray-900 pt-4">
                                    Programme Permissions
                                </h3>

                                <div className="grid grid-cols-12">
                                    {programmePermissions.length ? (
                                        programmePermissions.map(
                                            (permission) => (
                                                <div
                                                    key={permission.id}
                                                    className="col-span-6"
                                                >
                                                    <input
                                                        className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                        type="checkbox"
                                                        id={permission.id}
                                                        onChange={(event) => {
                                                            selectedPermissions =
                                                                event.target
                                                                    .checked
                                                                    ? [
                                                                          ...formDetails.permissions,
                                                                          permission.id,
                                                                      ]
                                                                    : [
                                                                          ...formDetails.permissions,
                                                                      ].filter(
                                                                          (
                                                                              id
                                                                          ) =>
                                                                              id !==
                                                                              permission.id
                                                                      );
                                                            setUserPermissions(
                                                                selectedPermissions
                                                            );
                                                            setFormDetails(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    [`permissions`]:
                                                                        selectedPermissions,
                                                                })
                                                            );
                                                        }}
                                                        checked={
                                                            userPermissions.indexOf(
                                                                permission.id
                                                            ) !== -1
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    <label
                                                        className="form-check-label inline-block text-gray-800 hover:cursor-pointer"
                                                        htmlFor={permission.id}
                                                    >
                                                        {permission.codename
                                                            .replace("_", " ")
                                                            .toUpperCase()}
                                                    </label>
                                                </div>
                                            )
                                        )
                                    ) : (
                                        <div className="col-span-12">
                                            <em>
                                                No permissions available for
                                                programmes.
                                            </em>
                                        </div>
                                    )}
                                </div>

                                <h3 className="font-bold text-gray-900 pt-4">
                                    Mission Permissions
                                </h3>

                                <div className="grid grid-cols-12">
                                    {missionPermissions.length ? (
                                        missionPermissions.map((permission) => (
                                            <div
                                                key={permission.id}
                                                className="col-span-6"
                                            >
                                                <input
                                                    className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                    type="checkbox"
                                                    id={permission.id}
                                                    onChange={(event) => {
                                                        selectedPermissions =
                                                            event.target.checked
                                                                ? [
                                                                      ...formDetails.permissions,
                                                                      permission.id,
                                                                  ]
                                                                : [
                                                                      ...formDetails.permissions,
                                                                  ].filter(
                                                                      (id) =>
                                                                          id !==
                                                                          permission.id
                                                                  );
                                                        setUserPermissions(
                                                            selectedPermissions
                                                        );
                                                        setFormDetails(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                [`permissions`]:
                                                                    selectedPermissions,
                                                            })
                                                        );
                                                    }}
                                                    checked={
                                                        userPermissions.indexOf(
                                                            permission.id
                                                        ) !== -1
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                <label
                                                    className="form-check-label inline-block text-gray-800 cursor-pointer"
                                                    htmlFor={permission.id}
                                                >
                                                    {permission.codename
                                                        .replace("_", " ")
                                                        .toUpperCase()}
                                                </label>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="col-span-12">
                                            <em>
                                                No permissions available for
                                                missions.
                                            </em>
                                        </div>
                                    )}
                                </div>

                                <h3 className="font-bold text-gray-900 pt-4">
                                    Task Permissions
                                </h3>

                                <div className="grid grid-cols-12">
                                    {taskPermissions.length ? (
                                        taskPermissions.map((permission) => (
                                            <div
                                                key={permission.id}
                                                className="col-span-6"
                                            >
                                                <input
                                                    className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                                    type="checkbox"
                                                    id={permission.id}
                                                    onChange={(event) => {
                                                        selectedPermissions =
                                                            event.target.checked
                                                                ? [
                                                                      ...formDetails.permissions,
                                                                      permission.id,
                                                                  ]
                                                                : [
                                                                      ...formDetails.permissions,
                                                                  ].filter(
                                                                      (id) =>
                                                                          id !==
                                                                          permission.id
                                                                  );
                                                        setUserPermissions(
                                                            selectedPermissions
                                                        );
                                                        setFormDetails(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                [`permissions`]:
                                                                    selectedPermissions,
                                                            })
                                                        );
                                                    }}
                                                    checked={
                                                        userPermissions.indexOf(
                                                            permission.id
                                                        ) !== -1
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                <label
                                                    className="form-check-label inline-block text-gray-800 cursor-pointer"
                                                    htmlFor={permission.id}
                                                >
                                                    {permission.codename
                                                        .replace("_", " ")
                                                        .toUpperCase()}
                                                </label>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="col-span-12">
                                            <em>
                                                No permissions available for
                                                tasks.
                                            </em>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                        {formError.length !== 0 && (
                            <div className="col-span-12 form-error text-center">
                                {formError}
                            </div>
                        )}

                        <div className="flex justify-center">
                            <button
                                type="button"
                                className="button-default mx-1 mt-4"
                                onClick={() => setOpen(false)}
                            >
                                Close Popup
                            </button>
                            <button type="submit" className="button mx-1 mt-4">
                                {Object.keys(editableRow).length === 0
                                    ? `Add ${
                                          userTypeParam === "administrators"
                                              ? `Administrator`
                                              : `User`
                                      }`
                                    : `Update`}
                            </button>
                        </div>
                    </div>
                </form>
            ) : (
                <>
                    <Alert
                        type={`success`}
                        message={`Successfully ${
                            Object.keys(editableRow).length === 0
                                ? `added`
                                : `updated`
                        } ${
                            userTypeParam === "administrators"
                                ? `administrator`
                                : `user`
                        }:<br /><strong>${
                            formDetails.first_name + " " + formDetails.last_name
                        }</strong>`}
                    />

                    <div className="flex justify-center">
                        <button
                            type="button"
                            className="button-default mx-1 mt-4"
                            onClick={() => setOpen(false)}
                        >
                            Close
                        </button>
                    </div>
                </>
            )}
        </>
    );
}
