import { useState, useEffect, useContext } from "react";
import { PORTAL } from "portal";
import { PencilIcon } from "@heroicons/react/outline";
import { isAfter } from "date-fns";
import { NavLink } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/outline";
import { useAuthentication } from "hooks/AuthenticationHook";
import { PERMISSIONS } from "hooks/permissions";

import { API_ROUTES } from "api";
import { format } from "date-fns";
import ViewProgrammeOutcome from "components/view-programme-outcome";
import CloseProgramme from "components/close-programme";
import Status from "components/status";
import Progress from "components/progress";
import ProgressBar from "components/progress-bar";
import ModalUserDetails from "components/modal-user-details";
import Modal from "components/modal";
import ViewUserDetails from "components/view-user-details";
import DownloadAsPdf from "components/download-pdf";
import Extension from "components/extension";
import Delete from "components/delete";

export default function CardWide({
    row,
    editable,
    setEditableRow,
    reloadProgrammeData,
    tableHeaders,
}) {
    let { hasPermission } = useAuthentication();
    const [showMoreText, setShowMoreText] = useState(false);
    const [userDetailsRow, setUserDetailsRow] = useState({});
    const [openUserDetails, setOpenUserDetails] = useState(false);

    useEffect(() => {
        return () => {
            setShowMoreText(false);
        };
    }, []);

    return (
        <>
            <Modal
                modalHeaderTag={""}
                modalHeader={`User Details: ${row.created_by.first_name} ${row.created_by.last_name}`}
                modalContent={
                    <ModalUserDetails
                        setOpen={setOpenUserDetails}
                        userDetailsRow={userDetailsRow}
                    />
                }
                open={openUserDetails}
                setOpen={setOpenUserDetails}
            />
            <div key={row.id} className="mx-full px-4 sm:px-6 lg:px-8 my-5">
                <div className="bg-white rounded-md shadow-md">
                    <div className="w-full p-5">
                        <div className="flex space-x-3">
                            <div className="min-w-0 flex-1">
                                <span className="header-tag-green">
                                    {row.country.name}
                                </span>
                                <span className="header-tag-green">
                                    {row.normalised.type}
                                </span>
                                <span className="header-tag-green">
                                    {row.normalised.category}
                                </span>
                                {!row.is_approved ? (
                                    <span className="inline-flex uppercase px-2 py-0.5 ml-1 rounded text-xs font-medium bg-red-800 text-white tag">
                                        Not Approved
                                    </span>
                                ) : (
                                    <span className="inline-flex uppercase px-2 py-0.5 ml-1 rounded text-xs font-medium bg-green-800 text-white tag">
                                        Approved
                                    </span>
                                )}
                            </div>
                            <div className="flex-shrink-0 self-center flex">
                                {row.closed ? (
                                    <ViewProgrammeOutcome programme={row} />
                                ) : (
                                    <CloseProgramme
                                        programme={row}
                                        reloadProgrammeData={
                                            reloadProgrammeData
                                        }
                                    />
                                )}
                                {row.workplan && (
                                    <a
                                        href={row.workplan}
                                        target="_blank"
                                        className={`button-default ml-2`}
                                        rel="noreferrer"
                                    >
                                        View Workplan
                                    </a>
                                )}
                                <NavLink
                                    to={`${PORTAL.MISSIONS_BY_PROGRAMME}/${row.id}`}
                                    className={`button-default ml-2`}
                                >
                                    Missions ({row.total_missions})
                                    <span className="button-icon m-0">
                                        <ChevronRightIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </span>
                                </NavLink>
                            </div>
                        </div>
                        <div className="flex space-x-3 mt-1">
                            <h2 className="h2 whitespace-normal text-xl">
                                {row.normalised.name}
                                {editable &&
                                    !row.closed &&
                                    hasPermission(
                                        PERMISSIONS.PROGRAMMES
                                            .CAN_EDIT_PROGRAMME
                                    ) && (
                                        <button
                                            type="button"
                                            className="bg-white p-1 mx-1 rounded-full text-gray-400 hover:text-gray-500"
                                        >
                                            <PencilIcon
                                                className="h-4 w-4 mr-1"
                                                aria-hidden="true"
                                                onClick={() =>
                                                    setEditableRow(row)
                                                }
                                            />
                                        </button>
                                    )}
                            </h2>
                        </div>

                        <div>
                            <p className="text-gray-400">
                                {row.overview.length > 250 ? (
                                    <>
                                        {showMoreText
                                            ? row.overview + ` `
                                            : row.overview.substr(0, 250) +
                                              ` ... `}
                                        <span
                                            className="brand-link"
                                            onClick={() => {
                                                setShowMoreText(!showMoreText);
                                            }}
                                        >
                                            show{" "}
                                            {showMoreText ? `less` : `more`}
                                        </span>
                                    </>
                                ) : (
                                    row.overview
                                )}
                            </p>
                        </div>

                        <div className="pt-1 text-md">
                            <strong>Date:</strong>{" "}
                            {row.normalised.start_date +
                                " - " +
                                row.normalised.end_date}
                            &nbsp;|&nbsp;
                            <strong>Duration:</strong> {row.normalised.duration}
                        </div>

                        <div className="pt-1 text-sm">
                            <strong>Tax types:</strong>{" "}
                            {row.tax_types.map((type, i) => (
                                <span key={i}>
                                    {type.name}
                                    {i + 1 !==
                                        row?.normalised?.tax_types?.length &&
                                        ", "}
                                </span>
                            ))}
                        </div>

                        <div className="pt-1 text-sm">
                            <strong>Specific subject matter:</strong>{" "}
                            {row.departments.map((department, i) => (
                                <span key={i}>
                                    {department.name}
                                    {i + 1 !==
                                        row?.normalised?.departments?.length &&
                                        ", "}
                                </span>
                            ))}
                        </div>

                        <div className="pt-1 text-sm">
                            <strong>Internal unit synergy:</strong>{" "}
                            {row.internal_units.map((unit, i) => (
                                <span key={i}>
                                    {unit.name}
                                    {i + 1 !==
                                        row?.normalised?.internal_units
                                            ?.length && ", "}
                                </span>
                            ))}
                            {row.internal_units.length === 0 && "None"}
                        </div>

                        <div className="py-1 text-sm">
                            <ViewUserDetails row={row} type={`programme`} />
                        </div>

                        {/*isAfter(new Date(), new Date(row.end_date)) && (
              <Extension programmeId={row.id} />
            )*/}

                        <div className="pt-2 text-sm">
                            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                                <ProgressBar
                                    progressValue={row.overall_progress}
                                />
                            </div>
                        </div>

                        <div className="pt-1 text-sm">
                            <div className="flex justify-between mb-1">
                                <Status progressValue={row.overall_progress} />
                                <Progress
                                    progressValue={row.overall_progress}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="border-t border-gray-200 p-5 rounded-bl-lg rounded-br-lg bg-gray-100 text-sm">
                        <div className="float-left">
                            <span className="font-bold">Created:</span>{" "}
                            {format(new Date(row.created), "dd MMM yyyy")} by{" "}
                            <span
                                className="hover:underline cursor-pointer"
                                onClick={() => {
                                    setUserDetailsRow(row.created_by);
                                    setOpenUserDetails(true);
                                }}
                            >
                                {row.created_by.first_name +
                                    " " +
                                    row.created_by.last_name}
                            </span>{" "}
                            <span className="font-bold">Last updated:</span>{" "}
                            {row.normalised.updated}
                        </div>
                        <div className="float-right">
                            <DownloadAsPdf
                                tableTitle={`Programme:`}
                                tableHeaders={tableHeaders}
                                tableData={[row]}
                                tableDataResult={row.name}
                                printType={`page`}
                                filename={`Programme: ${row.name}`}
                            />
                            {hasPermission(
                                PERMISSIONS.PROGRAMMES.CAN_DELETE_PROGRAMME
                            ) && (
                                <Delete
                                    apiRoute={
                                        API_ROUTES.PROGRAMME.PROGRAMME_DETAIL
                                    }
                                    rowData={row}
                                    loadData={reloadProgrammeData}
                                    titleValue={"Programme"}
                                />
                            )}
                        </div>
                        <div className="clear-both"></div>
                    </div>
                </div>
            </div>
        </>
    );
}
