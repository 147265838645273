import { useContext, useEffect } from "react";
import { notificationService } from "notification";
import { NotificationContext } from "hooks/NotificationContext";

export default function NotificationsWrapper() {
    let { setNotification } = useContext(NotificationContext);

    useEffect(() => {
        const mySubscription = notificationService
            .from("*")
            .on("*", (payload) => {
                setNotification((prevState) => ({
                    ...prevState,
                    [`popup`]: true,
                }));
            })
            .subscribe();

        return () => {
            mySubscription.unsubscribe();
        };
    }, []);

    return <></>;
}
