import { useEffect, useState, Fragment, useContext } from "react";
import { PORTAL } from "portal";
import { NavLink, useRouteMatch, useLocation } from "react-router-dom";
import { isAfter } from "date-fns";
import {
    PencilIcon,
    PlusCircleIcon,
    ChevronLeftIcon,
} from "@heroicons/react/outline";
import { compareDesc } from "date-fns";
import { useTextFormatHook } from "hooks/TextFormatHook";
import { usePermissionsHook } from "hooks/PermissionsHook";
import { AuthenticationContext } from "hooks/AuthenticationContext";
import Navigation from "components/navigation";
import Header from "components/header";
import PageHeader from "components/page-header";
import TableHeader from "components/table-header";
import Card from "components/card";
import AddEditMission from "components/add-edit-mission";
import Modal from "components/modal";
import Alert from "components/alert";
import AddEditProgramme from "components/add-edit-programme";
import { ApiConsumer } from "api-consumer";
import { API_ROUTES } from "api";
import DownloadAsPdf from "components/download-pdf";

import { useAuthentication } from "hooks/AuthenticationHook";
import { PERMISSIONS } from "hooks/permissions";
import Extension from "components/extension";
import { AppLoader } from "components/app-loader";
import { AppLoaderContext } from "hooks/AppLoaderContext";

export default function Missions() {
    const search = useLocation().search;
    const searchParams = new URLSearchParams(search);
    const searchTerm = searchParams.get("search");
    let { setLoading } = useContext(AppLoaderContext);

    /* Missions by programme */
    const programmesByCountry = useRouteMatch(
        PORTAL.MISSIONS_BY_PROGRAMME + "/:programme_id"
    );
    let programmeId = programmesByCountry?.params?.programme_id;

    /* Missions by user */
    const missionsByUser = useRouteMatch(PORTAL.MISSIONS_BY_USER + "/:user_id");
    let userId = missionsByUser?.params?.user_id;

    /* Set programme API url */
    let missionAPIURL = API_ROUTES.MISSION.MISSIONS;
    if (programmeId)
        missionAPIURL = API_ROUTES.MISSION.MISSIONS_BY_PROGRAMME(programmeId);
    if (userId) missionAPIURL = API_ROUTES.MISSION.MISSIONS_BY_USER(userId);

    let { normaliseTableData } = useTextFormatHook();
    let { missionsAssignedToUser } = usePermissionsHook();
    let { hasPermission } = useAuthentication();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [programme, setProgramme] = useState({});
    const [missions, setMissions] = useState([]);
    const [user, setUser] = useState({});
    const [openAddEditMission, setOpenAddEditMission] = useState(false);
    const [openAddEditProgramme, setOpenAddEditProgramme] = useState(false);
    const [editableRow, setEditableRow] = useState({});
    const [editableProgramme, setEditableProgramme] = useState({});
    const [rowUpdated, setRowUpdated] = useState(false);
    const [filterString, setFilterString] = useState("");
    const [filteredData, setFilteredData] = useState([]);

    const PROGRAMME_HEADERS = [
        {
            key: "name",
            value: "Programme",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "country",
            value: "Country",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "overview",
            value: "Brief overview",
            showOnTable: false,
            showOnPrint: false,
        },
        {
            key: "workplan",
            value: "Workplan",
            showOnTable: false,
            showOnPrint: false,
        },
        { key: "type", value: "Type", showOnTable: true, showOnPrint: true },
        {
            key: "category",
            value: "Category",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "departments",
            value: "Specific Subject Matter",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "tax_types",
            value: "Tax Types",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "internal_units",
            value: "Internal Units",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "duration",
            value: "Duration",
            showOnTable: true,
            showOnPrint: false,
        },
        {
            key: "start_date",
            value: "Start Date",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "end_date",
            value: "End Date",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "overall_progress",
            value: "Progress",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "status",
            value: "Status",
            showOnTable: true,
            showOnPrint: false,
        },
        {
            key: "ta_managers",
            value: "Country Programmes Manager",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "ta_officers",
            value: "CP Programme Coordinator",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "internal_experts",
            value: "Internal expert(s) able to manage missions",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "external_experts",
            value: "External expert(s) able to manage missions",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "report_users",
            value: "View programme reports",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "created",
            value: "Created",
            showOnTable: false,
            showOnPrint: false,
        },
        {
            key: "updated",
            value: "Updated",
            showOnTable: false,
            showOnPrint: false,
        },
    ];

    const MISSION_HEADERS = [
        { key: "name", value: "Mission", showOnTable: true, showOnPrint: true },
        {
            key: "country",
            value: "Country",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "overview",
            value: "Brief overview",
            showOnTable: false,
            showOnPrint: false,
        },
        {
            key: "type",
            value: "Mission Type",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "activity",
            value: "Mission Activity",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "areas_of_support",
            value: "Area of Support",
            showOnTable: true,
            showOnPrint: true,
        },
        ,
        {
            key: "duration",
            value: "Duration",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "start_date",
            value: "Start Date",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "end_date",
            value: "End Date",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "overall_progress",
            value: "Progress",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "status",
            value: "Status",
            showOnTable: true,
            showOnPrint: false,
        },
        {
            key: "internal_experts",
            value: "Internal Experts",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "external_experts",
            value: "External Experts",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "report_users",
            value: "View mission reports",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "partners",
            value: "Partners",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "created",
            value: "Created",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "updated",
            value: "Updated",
            showOnTable: false,
            showOnPrint: true,
        },
    ];

    const pageHeaderButtons = [
        <NavLink
            to={`${PORTAL.PROGRAMMES_BY_COUNTRY}/${programme?.country?.id}`}
            className={`button-default`}
        >
            <span className="button-icon">
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </span>
            Programmes
        </NavLink>,
    ];
    const pageHeaderIcons = [
        !programme.closed &&
            hasPermission(PERMISSIONS.PROGRAMMES.CAN_EDIT_PROGRAMME) && (
                <button
                    type="button"
                    className="icon-transparent bg-gray-100 ml-3"
                >
                    <PencilIcon
                        className="h-6 w-6"
                        aria-hidden="true"
                        onClick={() => {
                            setEditableProgramme(programme);
                            setOpenAddEditProgramme(true);
                        }}
                    />
                </button>
            ),
        <DownloadAsPdf
            tableTitle={`Programme:`}
            tableHeaders={PROGRAMME_HEADERS}
            tableData={[programme]}
            tableDataResult={programme?.name}
            printType={`page`}
            filename={`Programme: ${programme?.name}`}
        />,
    ];
    const subHeaderButtons = [
        programme?.workplan ? (
            <a
                href={programme?.workplan}
                target="_blank"
                className={`button-default ml-2`}
                rel="noreferrer"
            >
                View Workplan
            </a>
        ) : null,
        programme.is_approved &&
        !programme.closed &&
        !userId &&
        /*!isAfter(new Date(), new Date(programme.end_date)) &&*/
        hasPermission(PERMISSIONS.MISSIONS.CAN_ADD_MISSION) ? (
            <button
                type="button"
                onClick={() => setOpenAddEditMission(true)}
                className={`button`}
            >
                <span className="button-icon">
                    <PlusCircleIcon className="h-5 w-5" aria-hidden="true" />
                </span>
                Add Mission
            </button>
        ) : !programme.is_approved &&
          (searchTerm === null || searchTerm === "") ? (
            <div className="rounded-md px-4 py-2 bg-red-100">
                <p className="text-sm font-medium text-red-600 ">
                    Awaiting approval from Administrator or Country Programmes
                    Manager(s)
                </p>
            </div>
        ) : /* isAfter(new Date(), new Date(programme.end_date)) ? (
      <Extension programmeId={programme.id} />) */
        null,
    ];

    const addLinksToTableData = (tableData) => {
        tableData.forEach((row) => {
            row.normalised.name = (
                <NavLink
                    to={`${PORTAL.MISSION}/${row.id}`}
                    className={`brand-link underline`}
                >
                    {row.normalised.name}
                </NavLink>
            );
        });
        return tableData;
    };

    const loadData = () => {
        setLoading(true);
        ApiConsumer.get(missionAPIURL)
            .then((res) => {
                res.data.sort((a, b) =>
                    compareDesc(new Date(a.created), new Date(b.created))
                );
                if (
                    missionAPIURL === API_ROUTES.MISSION.MISSIONS &&
                    searchTerm !== null &&
                    searchTerm !== ""
                ) {
                    let missions = res.data;
                    res.data = missions.filter((p) =>
                        p.name.toLowerCase().includes(searchTerm.toLowerCase())
                    );
                }

                let userMissions = missionsAssignedToUser(res.data);

                let normalisedData = normaliseTableData(
                    MISSION_HEADERS,
                    userMissions.length !== 0 ? userMissions : res.data,
                    userMissions.length !== 0 ? userMissions : res.data
                );

                setMissions(addLinksToTableData(normalisedData));
            })
            .catch((err) => {})
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        loadData();
        return () => {
            setSidebarOpen(false);
            setProgramme({});
            setMissions([]);
            setUser({});
            setOpenAddEditMission(false);
            setOpenAddEditProgramme(false);
            setEditableRow({});
            setEditableProgramme({});
            setRowUpdated(false);
            setFilterString("");
            setFilteredData([]);
        };
    }, [missionAPIURL, programmeId, userId]);

    useEffect(() => {
        if (userId) {
            ApiConsumer.get(API_ROUTES.USERS.USER_DETAIL(userId))
                .then((res) => {
                    setUser(res.data);
                })
                .catch((err) => {})
                .finally(() => {});
        } else if (programmeId) {
            ApiConsumer.get(API_ROUTES.PROGRAMME.PROGRAMME_DETAIL(programmeId))
                .then((res) => {
                    setProgramme(
                        normaliseTableData(PROGRAMME_HEADERS, [res.data])[0]
                    );
                })
                .catch((err) => {})
                .finally(() => {});
        }
    }, [userId, programmeId, missionAPIURL]);

    const onCloseAddEditMission = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditableRow({});
            if (rowUpdated) loadData();
        }
        setOpenAddEditMission(modalStatus);
    };

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableRow).length === 0
            ? setOpenAddEditMission(false)
            : setOpenAddEditMission(true);
    }, [editableRow]);

    const onCloseAddEditProgramme = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditableProgramme({});
            if (rowUpdated) loadData();
        }
        setOpenAddEditProgramme(modalStatus);
    };

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableProgramme).length === 0
            ? setOpenAddEditProgramme(false)
            : setOpenAddEditProgramme(true);
    }, [editableProgramme]);

    return (
        <>
            <Modal
                modalHeaderTag={
                    programme?.country?.name !== undefined
                        ? programme.country.name
                        : ""
                }
                modalHeader={
                    Object.keys(editableProgramme).length === 0
                        ? `Add New Programme`
                        : `Edit Programme: ${editableProgramme.name}`
                }
                modalContent={
                    <AddEditProgramme
                        countryId={
                            programme?.country?.id !== undefined
                                ? programme.country.id
                                : ""
                        }
                        setOpen={onCloseAddEditProgramme}
                        editableRow={editableProgramme}
                        setRowUpdated={setRowUpdated}
                    />
                }
                open={openAddEditProgramme}
                setOpen={onCloseAddEditProgramme}
            />

            <Modal
                modalHeaderTag={
                    programme?.country?.name !== undefined
                        ? programme.country.name
                        : ""
                }
                modalHeader={
                    Object.keys(editableRow).length === 0
                        ? `Add New Mission`
                        : `Edit Mission: ${editableRow.name}`
                }
                modalContent={
                    <AddEditMission
                        programme={programme}
                        setOpen={onCloseAddEditMission}
                        editableRow={editableRow}
                        setRowUpdated={setRowUpdated}
                    />
                }
                open={openAddEditMission}
                setOpen={onCloseAddEditMission}
            />
            <div className="min-h-full bg-gray-100">
                <Navigation
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                />

                <div className="lg:pl-64 flex flex-col flex-1">
                    <Header />

                    <main className="flex-1 pb-8">
                        {!userId &&
                            (searchTerm === null || searchTerm === "") && (
                                <PageHeader
                                    pageHeaderName={`${
                                        programme?.name !== undefined
                                            ? programme.name
                                            : ""
                                    } <span class="header-tag-green">
                ${
                    programme?.country?.name !== undefined
                        ? programme.country.name
                        : ""
                }
              </span>
              <span class="header-tag-green">
                ${
                    programme?.type?.name !== undefined
                        ? programme.type.name
                        : ""
                }
              </span>
              <span class="header-tag-green">
                ${
                    programme?.category?.name !== undefined
                        ? programme.category.name
                        : ""
                }
              </span>`}
                                    pageHeaderButtons={pageHeaderButtons}
                                    pageHeaderIcons={pageHeaderIcons}
                                />
                            )}
                        <PageHeader
                            pageHeaderName={`${
                                user?.first_name !== undefined &&
                                user?.last_name !== undefined
                                    ? user.first_name +
                                      " " +
                                      user.last_name +
                                      " "
                                    : ""
                            }Missions (${missions?.length}) ${
                                searchTerm !== null && searchTerm !== ""
                                    ? ' Search results for "' + searchTerm + '"'
                                    : ""
                            }`}
                            pageHeaderButtons={subHeaderButtons}
                        />
                        {hasPermission(
                            PERMISSIONS.MISSIONS.CAN_VIEW_MISSION
                        ) ? (
                            <div className="relative">
                                <AppLoader
                                    pageContent={
                                        missions.length ? (
                                            <>
                                                <TableHeader
                                                    tableTitle={`${
                                                        programme?.name !==
                                                        undefined
                                                            ? programme.name +
                                                              " - "
                                                            : ""
                                                    }${
                                                        user?.first_name !==
                                                            undefined &&
                                                        user?.last_name !==
                                                            undefined
                                                            ? user.first_name +
                                                              " " +
                                                              user.last_name +
                                                              " "
                                                            : ""
                                                    }Missions`}
                                                    tableHeaders={
                                                        MISSION_HEADERS
                                                    }
                                                    tableData={missions}
                                                    filterString={filterString}
                                                    setFilterString={
                                                        setFilterString
                                                    }
                                                    filteredData={filteredData}
                                                    setFilteredData={
                                                        setFilteredData
                                                    }
                                                />

                                                <div className="mx-full px-4 sm:px-6 lg:px-8 my-5">
                                                    <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                                                        {(filterString.length ||
                                                        filteredData.length
                                                            ? filteredData
                                                            : missions
                                                        ).map((mission, i) => (
                                                            <Fragment key={i}>
                                                                <Card
                                                                    row={
                                                                        mission
                                                                    }
                                                                    editable={
                                                                        true
                                                                    }
                                                                    setEditableRow={
                                                                        setEditableRow
                                                                    }
                                                                    reloadMissionData={
                                                                        loadData
                                                                    }
                                                                    tableHeaders={
                                                                        MISSION_HEADERS
                                                                    }
                                                                />
                                                            </Fragment>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="mx-full px-8 my-4 text-center">
                                                <Alert
                                                    type={`danger`}
                                                    message={`There are no missions added under ${
                                                        programme?.name !==
                                                        undefined
                                                            ? programme.name
                                                            : ""
                                                    } ${
                                                        user?.first_name !==
                                                            undefined &&
                                                        user?.last_name !==
                                                            undefined
                                                            ? user.first_name +
                                                              " " +
                                                              user.last_name
                                                            : ""
                                                    }
                          ${
                              searchTerm !== null && searchTerm !== ""
                                  ? searchTerm
                                  : ""
                          }`}
                                                />
                                            </div>
                                        )
                                    }
                                />
                            </div>
                        ) : (
                            <div className="mx-full px-8 my-4 text-center">
                                <Alert
                                    type={`danger`}
                                    message={`You do not have permission to view missions. Please contact the system administrator.`}
                                />
                            </div>
                        )}
                    </main>
                </div>
            </div>
        </>
    );
}
