import { useContext } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { useAuthentication } from "hooks/AuthenticationHook";
import { AuthenticationContext } from "hooks/AuthenticationContext";
import { PORTAL } from "portal";

const PrivateRoute = ({ component: Component, ...rest }) => {
    let { isAuthenticated } = useAuthentication();
    let { currentUser } = useContext(AuthenticationContext);
    const location = useLocation();

    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated() && Object.keys(currentUser).length !== 0 ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: PORTAL.LOGIN,
                            state: { referrer: location.pathname },
                        }}
                        from={location.pathname}
                    />
                )
            }
        />
    );
};
export default PrivateRoute;
