import { useState, useEffect, useContext } from "react";
import { format } from "date-fns";
import { useFormValidation } from "hooks/FormValidationHook";
import { API_ROUTES } from "api";
import { ApiConsumer } from "api-consumer";
import "react-datepicker/dist/react-datepicker.css";
import Alert from "components/alert";
import { AppLoaderContext } from "hooks/AppLoaderContext";

export default function AddEditDepartment({
    departmentId,
    setOpen,
    editableRow,
    setRowUpdated,
}) {
    let { setLoading } = useContext(AppLoaderContext);
    let { validFormDetails } = useFormValidation();
    const [users, setUsers] = useState([]);
    const [invalidFormDetails, setInvalidFormDetails] = useState({});
    const [formDetails, setFormDetails] = useState({});
    const [formError, setFormError] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);

    const formFields = [
        { name: "name", display: "department name" },
        { name: "main_correspondent", display: "main correspondent" },
    ];

    const validateAddEditDepartment = (isEdit) => {
        if (
            validFormDetails(
                formFields,
                formDetails,
                setInvalidFormDetails,
                setFormError
            )
        ) {
            setLoading(true);
            if (isEdit) {
                ApiConsumer.put(
                    API_ROUTES.METADATA.PROGRAMME.DEPARTMENT_DETAIL(
                        editableRow.id
                    ),
                    formDetails
                )
                    .then((res) => {
                        setRowUpdated(true);
                        setFormSubmitted(true);
                    })
                    .catch((err) => {
                        setFormError(err.toString());
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                ApiConsumer.post(
                    API_ROUTES.METADATA.PROGRAMME.DEPARTMENTS,
                    formDetails
                )
                    .then((res) => {
                        setRowUpdated(true);
                        setFormSubmitted(true);
                    })
                    .catch((err) => {
                        setFormError(err.toString());
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    };

    useEffect(() => {
        ApiConsumer.get(API_ROUTES.USERS.USERS)
            .then((res) => {
                res.data.sort((a, b) =>
                    a.first_name.localeCompare(b.first_name)
                );
                setUsers(res.data);
            })
            .catch((err) => {});
    }, []);

    useEffect(() => {
        /* Defaults */
        setFormDetails((prevState) => ({
            ...prevState,
            [`name`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`main_correspondent`]: "selected",
        }));

        /* If editing */
        if (Object.keys(editableRow).length !== 0) {
            setFormDetails((prevState) => ({
                ...prevState,
                [`department`]: editableRow.id,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`name`]: editableRow.name,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`main_correspondent`]: editableRow.main_correspondent.id,
            }));
        }

        return () => {
            setUsers([]);
            setInvalidFormDetails({});
            setFormDetails({});
            setFormError("");
            setFormSubmitted(false);
        };
    }, [editableRow]);

    return (
        <>
            {!formSubmitted ? (
                <form
                    className="space-y-6"
                    onSubmit={(event) => {
                        event.preventDefault();
                        validateAddEditDepartment(
                            Object.keys(editableRow).length
                        );
                    }}
                    method="POST"
                >
                    {Object.keys(editableRow).length !== 0 && (
                        <div className="text-sm border-gray-200 p-4 rounded-lg bg-gray-100">
                            <span className="font-bold">Created:</span>{" "}
                            {format(
                                new Date(editableRow.created),
                                "dd MMM yyyy"
                            )}{" "}
                            <span className="font-bold">Last updated:</span>{" "}
                            {format(
                                new Date(editableRow.updated),
                                "dd MMM yyyy"
                            )}{" "}
                        </div>
                    )}
                    <div className="space-y-2">
                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="name">
                                    Specific subject matter name:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="name"
                                    name="name"
                                    type="text"
                                    autoComplete="name"
                                    className={`form-field ${
                                        invalidFormDetails.name
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`name`]: event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.name ? formDetails.name : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="name">
                                    Main correspondent:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <select
                                    id="main_correspondent"
                                    name="main_correspondent"
                                    autoComplete="main_correspondent"
                                    className={`${
                                        invalidFormDetails.main_correspondent
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`main_correspondent`]:
                                                event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.main_correspondent
                                            ? formDetails.main_correspondent
                                            : "selected"
                                    }
                                >
                                    <option value="selected">
                                        Select contact
                                    </option>
                                    {users.map((user) => {
                                        return (
                                            <option
                                                key={user.id}
                                                value={user.id}
                                            >
                                                {user.first_name +
                                                    " " +
                                                    user.last_name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>

                        {formError.length !== 0 && (
                            <div className="col-span-12 form-error text-center">
                                {formError}
                            </div>
                        )}

                        <div className="flex justify-center">
                            <button
                                type="button"
                                className="button-default mx-1 mt-4"
                                onClick={() => setOpen(false)}
                            >
                                Close Popup
                            </button>
                            <button type="submit" className="button mx-1 mt-4">
                                {Object.keys(editableRow).length === 0
                                    ? `Add Specific Subject Matter`
                                    : `Update`}
                            </button>
                        </div>
                    </div>
                </form>
            ) : (
                <>
                    <Alert
                        type={`success`}
                        message={`Successfully ${
                            Object.keys(editableRow).length === 0
                                ? `added`
                                : `updated`
                        } department:<br /><strong>${
                            formDetails.name
                        }</strong>`}
                    />

                    <div className="flex justify-center">
                        <button
                            type="button"
                            className="button-default mx-1 mt-4"
                            onClick={() => setOpen(false)}
                        >
                            Close
                        </button>
                    </div>
                </>
            )}
        </>
    );
}
