import { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { ApiConsumer } from "api-consumer";

export function StackedLine({
  url = "",
  height = "400",
  name = "",
  title = "",
}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState("loading ..");

  const options = {
    title: "",
    legend: { position: "bottom" },
    chartArea: { width: "75%" },
    isStacked: true,
    hAxis: {
      title: "",
      minValue: 0,
    },
    vAxis: {
      title: "",
    },
    series: {
      0: { color: "#0F9017" },
      1: { color: "#3366CC" },
    },
  };

  const sortMission = (data) => {
    const header = data.shift();
    data.forEach((item, index) => data[index].push(item[1] + item[2]));
    data.sort();
    data.sort((a, b) => {
      return b[3] - a[3];
    });
    data.map((_, index) => data[index].pop());
    data.unshift(header);
    data = data.slice(0, 11);

    return data;
  };

  useEffect(() => {
    setLoading(true);
    ApiConsumer.get(url)
      .then((res) => {
        let types = [];
        res.data.types.map((x) => types.push(x.name));
        let countries = [];
        res.data.results.map((x) =>
          countries.indexOf(x.country) === -1 ? countries.push(x.country) : null
        );

        let final = [];
        countries.map((c) => {
          let temp = [];
          temp.push(c);
          types.map((t) => {
            let x = res.data.results.filter(
              (x) => x.country === c && x.name === t
            );
            if (x.length === 0) {
              temp.push(0);
            } else {
              temp.push(x[0].count);
            }
          });
          final.push(temp);
        });
        const test = [["Country", ...types], ...final];
        const result = sortMission(test);
        setData(result);
        if (result.length === 1) {
          setErrorMsg("No data");
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, [url]);

  return (
    <>
      <div className="min-w-0 flex-1">
        <div className="align-middle min-w-full overflow-x-auto shadow-sm overflow-hidden sm:rounded-lg">
          <h3 className="bg-white p-2 text-center">{title}</h3>
          <div style={{ height: "400px", backgroundColor: "#fff" }}>
            {!data || data.length === 1 ? (
              <div
                style={{
                  textAlign: "center",
                  position: "relative",
                  top: "40%",
                }}
              >
                {errorMsg}
              </div>
            ) : (
              <Chart
                chartType="ColumnChart"
                width="100%"
                height={`${height}px`}
                data={data}
                options={options}
                className={"shadow-2xl rounded"}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
