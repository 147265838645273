export default function ModalUserDetails({ setOpen, userDetailsRow }) {
  return (
    <>
      <form
        className="space-y-6"
        onSubmit={(event) => {
          event.preventDefault()
        }}
        method="POST"
      >
        <div className="space-y-2 mt-3">
          <div className="grid grid-cols-12">
            <div className="col-span-6">
              <label className="text-sm" htmlFor="first_name">
                First name:
              </label>
            </div>
            <div className="col-span-6 text-sm">
              {userDetailsRow.first_name}
            </div>
          </div>

          <div className="grid grid-cols-12">
            <div className="col-span-6">
              <label className="text-sm" htmlFor="last_name">
                Last name:
              </label>
            </div>
            <div className="col-span-6 text-sm">{userDetailsRow.last_name}</div>
          </div>

          <div className="grid grid-cols-12">
            <div className="col-span-6">
              <label className="text-sm" htmlFor="designation">
                Designation:
              </label>
            </div>
            <div className="col-span-6 text-sm">
              {userDetailsRow.designation}
            </div>
          </div>

          <div className="grid grid-cols-12">
            <div className="col-span-6">
              <label className="text-sm" htmlFor="email">
                Email address:
              </label>
            </div>
            <div className="col-span-6 text-sm">{userDetailsRow.email}</div>
          </div>

          <div className="grid grid-cols-12">
            <div className="col-span-6">
              <label className="text-sm" htmlFor="contact_number">
                Contact number:
              </label>
            </div>
            <div className="col-span-6 text-sm">
              {userDetailsRow.contact_number}
            </div>
          </div>

          <div className="grid grid-cols-12">
            <div className="col-span-6">
              <label className="text-sm" htmlFor="alternative_contact_number">
                Alternative contact number:
              </label>
            </div>
            <div className="col-span-6 text-sm">
              {userDetailsRow.alternative_contact_number
                ? userDetailsRow.alternative_contact_number
                : '-'}
            </div>
          </div>

          <div className="grid grid-cols-12">
            <div className="col-span-6">
              <label className="text-sm" htmlFor="name">
                User type:
              </label>
            </div>
            <div className="col-span-6 text-sm">
              {userDetailsRow.user_type.name}
            </div>
          </div>

          <div className="flex justify-center">
            <button
              type="button"
              className="button-default mx-1 mt-4"
              onClick={() => setOpen(false)}
            >
              Close Popup
            </button>
          </div>
        </div>
      </form>
    </>
  )
}
