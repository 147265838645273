function store_value(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
}

function get_value(key) {
    let res = sessionStorage.getItem(key);
    if (res !== undefined) {
        return JSON.parse(res);
    }
    return null;
}

function clear_storage() {
    sessionStorage.clear();
}

export const isAuthenticated = () => {
    return !!sessionStorage.getItem(`ATAFTAX`);
};

export const useAuthentication = () => {
    function user() {
        return get_value(`ATAFTAX`);
    }

    function hasPermission(permission) {
        if (user() !== null) {
            if (user().user_type.name === "Administrator") return true;
            let individual_permissions = user().individual_permissions;
            let i_perm = individual_permissions.find(
                (o) => o.codename === permission
            );
            return i_perm !== undefined;
        }
        return false;
    }

    function hasCustomPermission(permission) {}

    function canViewReport(dataObject) {
        if (user().user_type.name === "Administrator") return true;
        return (
            dataObject.report_users.filter((x) => x.id === user().id).length !==
            0
        );
    }

    function setAuthentication(details) {
        store_value(`ATAFTAX`, details);
    }

    function logout() {
        clear_storage();
        window.location.reload();
    }

    return {
        user,
        setAuthentication,
        logout,
        hasPermission,
        hasCustomPermission,
        canViewReport,
        isAuthenticated,
    };
};
