import { useState, useEffect, useContext } from "react";
import { ApiConsumer } from "api-consumer";
import "react-datepicker/dist/react-datepicker.css";
import Alert from "components/alert";
import { AppLoaderContext } from "hooks/AppLoaderContext";

export default function ConfirmDelete({
    setOpen,
    rowToDelete,
    setRowDeleted,
    deleteAPILink,
    deleteDesc,
}) {
    let { setLoading } = useContext(AppLoaderContext);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const confirmDelete = () => {
        setLoading(true);
        ApiConsumer.delete(deleteAPILink)
            .then((res) => {
                setRowDeleted(true);
                setFormSubmitted(true);
            })
            .catch((err) => {})
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        return () => {
            setFormSubmitted(false);
        };
    }, [rowToDelete]);

    return (
        <>
            {!formSubmitted ? (
                <form
                    className="space-y-6"
                    onSubmit={(event) => {
                        event.preventDefault();
                        confirmDelete(rowToDelete.id);
                    }}
                    method="POST"
                >
                    <div className="space-y-2">
                        <div className="grid grid-cols-12">
                            <div className="col-span-12 text-center">
                                <label className="text-sm" htmlFor="name">
                                    Are you sure you want to delete{" "}
                                    <strong>{deleteDesc}</strong>?
                                </label>
                            </div>
                        </div>

                        <div className="flex justify-center">
                            <button
                                type="button"
                                className="button-default mx-1 mt-4"
                                onClick={() => setOpen(false)}
                            >
                                Close Popup
                            </button>
                            <button type="submit" className="button mx-1 mt-4">
                                {`Confirm Delete`}
                            </button>
                        </div>
                    </div>
                </form>
            ) : (
                <>
                    <Alert
                        type={`success`}
                        message={`Successfully deleted:<br /><strong>${deleteDesc}</strong>`}
                    />

                    <div className="flex justify-center">
                        <button
                            type="button"
                            className="button-default mx-1 mt-4"
                            onClick={() => setOpen(false)}
                        >
                            Close
                        </button>
                    </div>
                </>
            )}
        </>
    );
}
