import { useEffect, useState, useContext } from "react";
import { ApiConsumer } from "api-consumer";
import { API_ROUTES } from "api";

import Alert from "components/alert";
import { format } from "date-fns";
import ModalUserDetails from "components/modal-user-details";
import Modal from "components/modal";
import DownloadAsPdf from "components/download-pdf";
import { useTextFormatHook } from "hooks/TextFormatHook";
import { AppLoaderContext } from "hooks/AppLoaderContext";

export default function ModalMissionOutcome({ setOpen, missionDetailsRow }) {
    let loggedInUser = JSON.parse(sessionStorage.getItem(`ATAFTAX`));
    let { setLoading } = useContext(AppLoaderContext);
    let { normaliseTableData } = useTextFormatHook();
    const [userDetailsRow, setUserDetailsRow] = useState({});
    const [openUserDetails, setOpenUserDetails] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [outcome, setOutcome] = useState([]);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const tableHeaders = [
        {
            key: "closed",
            value: "Closed on",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "updated",
            value: "Last updated",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "no_of_participants",
            value: "Beneficiaries/No of participants",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "participants_by_language",
            value: "No of participants by language",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "participants_by_org",
            value: "No of participant by organization",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "participants_by_region",
            value: "No of participant by region",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "african_experts",
            value: "No of African experts utilised",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "mission_objectives",
            value: "Mission objectives",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "geographic_coverage",
            value: "Activity geographic coverage",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "gender_disaggregation",
            value: "Gender (disaggregation by female and male)",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "context_of_activity",
            value: "Context of activity",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "risks_assumptions",
            value: "Activity associated risks/assumptions",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "additional_outcomes",
            value: "Additional outcomes",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "lessons_learned",
            value: "Lessons learned",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "mission_impact",
            value: "Mission impact",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "mission_impact_details",
            value: "Mission impact details",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "mission_report",
            value: "Mission report",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "mission",
            value: "Start Date",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "mission",
            value: "End Date",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "mission",
            value: "Internal Experts",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "mission",
            value: "External Experts",
            showOnTable: false,
            showOnPrint: true,
        },
    ];

    useEffect(() => {
        ApiConsumer.get(
            API_ROUTES.OUTCOMES.MISSION_DETAIL(missionDetailsRow.id)
        )
            .then((res) => {
                let normalisedData = normaliseTableData(tableHeaders, res.data);
                setOutcome(normalisedData[0]);
            })
            .catch((err) => {})
            .finally(() => {
                setLoaded(true);
            });
    }, [missionDetailsRow.id]);

    const reOpenMission = () => {
        setLoading(true);
        ApiConsumer.put(
            API_ROUTES.MISSION.MISSION_DETAIL(missionDetailsRow.id),
            {
                closed: false,
                name: missionDetailsRow.name,
            }
        )
            .then((res) => {
                setFormSubmitted(true);
            })
            .catch((err) => {})
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {loaded && outcome.length === 0 && (
                <Alert
                    type={`danger`}
                    message={`Mission outcome not loaded. Please contact system administrator.`}
                />
            )}
            {loaded && (
                <>
                    <Modal
                        modalHeaderTag={""}
                        modalHeader={`User Details: ${outcome.creator.first_name} ${outcome.creator.last_name}`}
                        modalContent={
                            <ModalUserDetails
                                setOpen={setOpenUserDetails}
                                userDetailsRow={userDetailsRow}
                            />
                        }
                        open={openUserDetails}
                        setOpen={setOpenUserDetails}
                    />

                    {!formSubmitted ? (
                        <>
                            <div className="text-sm border-gray-200 p-4 rounded-lg bg-gray-100 mb-4 flex">
                                <div className="flex-1">
                                    <span className="font-bold">
                                        Closed on:
                                    </span>{" "}
                                    {format(
                                        new Date(outcome.created),
                                        "dd MMM yyyy"
                                    )}{" "}
                                    by{" "}
                                    <span
                                        className="hover:underline cursor-pointer"
                                        onClick={() => {
                                            setUserDetailsRow(outcome.creator);
                                            setOpenUserDetails(true);
                                        }}
                                    >
                                        {outcome.creator.first_name +
                                            " " +
                                            outcome.creator.last_name}
                                    </span>
                                    <div className="clear-both"></div>
                                    <span className="font-bold">
                                        Last updated:
                                    </span>{" "}
                                    {format(
                                        new Date(outcome.updated),
                                        "dd MMM yyyy"
                                    )}
                                </div>
                                <div>
                                    <DownloadAsPdf
                                        tableTitle={`Mission Outcome: `}
                                        tableHeaders={tableHeaders}
                                        tableData={[outcome]}
                                        tableDataResult={outcome.mission.name}
                                        printType={`page`}
                                        filename={`Mission Outcome: ${outcome.mission.name}`}
                                    />
                                </div>
                            </div>
                            <div className="space-y-2">
                                <div className="grid grid-cols-12">
                                    <div className="col-span-12">
                                        <label
                                            className="text-sm font-bold"
                                            htmlFor="no_of_participants"
                                        >
                                            Beneficiaries/No of participants:{" "}
                                            <span className="font-normal">
                                                {outcome.no_of_participants}
                                            </span>
                                        </label>
                                    </div>
                                </div>

                                <div className="grid grid-cols-12">
                                    <div className="col-span-12">
                                        <label
                                            className="text-sm font-bold"
                                            htmlFor="participants_by_language"
                                        >
                                            No of participants by language:{" "}
                                            <span className="font-normal">
                                                {
                                                    outcome.participants_by_language
                                                }
                                            </span>
                                        </label>
                                    </div>
                                </div>

                                <div className="grid grid-cols-12">
                                    <div className="col-span-12">
                                        <label
                                            className="text-sm font-bold"
                                            htmlFor="participants_by_org"
                                        >
                                            No of participant by organization:{" "}
                                            <span className="font-normal">
                                                {outcome.participants_by_org}
                                            </span>
                                        </label>
                                    </div>
                                </div>

                                <div className="grid grid-cols-12">
                                    <div className="col-span-12">
                                        <label
                                            className="text-sm font-bold"
                                            htmlFor="participants_by_region"
                                        >
                                            No of participant by region:{" "}
                                            <span className="font-normal">
                                                {outcome.participants_by_region}
                                            </span>
                                        </label>
                                    </div>
                                </div>

                                <div className="grid grid-cols-12">
                                    <div className="col-span-12">
                                        <label
                                            className="text-sm font-bold"
                                            htmlFor="african_experts"
                                        >
                                            No of African experts utilised:{" "}
                                            <span className="font-normal">
                                                {outcome.african_experts}
                                            </span>
                                        </label>
                                    </div>
                                </div>

                                <div className="grid grid-cols-12">
                                    <div className="col-span-12 font-bold">
                                        <label
                                            className="text-sm font-bold"
                                            htmlFor="mission_objectives"
                                        >
                                            Mission objectives
                                        </label>
                                    </div>
                                    <div className="col-span-12 text-sm">
                                        {outcome.mission_objectives}
                                    </div>
                                </div>

                                <div className="grid grid-cols-12">
                                    <div className="col-span-12">
                                        <label
                                            className="text-sm font-bold"
                                            htmlFor="geographic_coverage"
                                        >
                                            Activity geographic coverage:
                                        </label>
                                    </div>
                                    <div className="col-span-12 text-sm">
                                        {outcome.geographic_coverage}
                                    </div>
                                </div>

                                <div className="grid grid-cols-12">
                                    <div className="col-span-12">
                                        <label
                                            className="text-sm font-bold"
                                            htmlFor="gender_disaggregation"
                                        >
                                            Gender (disaggregation by female and
                                            male):
                                        </label>
                                    </div>
                                    <div className="col-span-12 text-sm">
                                        {outcome.gender_disaggregation}
                                    </div>
                                </div>

                                <div className="grid grid-cols-12">
                                    <div className="col-span-12">
                                        <label
                                            className="text-sm font-bold"
                                            htmlFor="context_of_activity"
                                        >
                                            Context of activity:
                                        </label>
                                    </div>
                                    <div className="col-span-12 text-sm">
                                        {outcome.context_of_activity}
                                    </div>
                                </div>

                                <div className="grid grid-cols-12">
                                    <div className="col-span-12">
                                        <label
                                            className="text-sm font-bold"
                                            htmlFor="risks_assumptions"
                                        >
                                            Activity associated
                                            risks/assumptions:
                                        </label>
                                    </div>
                                    <div className="col-span-12 text-sm">
                                        {outcome.risks_assumptions}
                                    </div>
                                </div>

                                <div className="grid grid-cols-12">
                                    <div className="col-span-12">
                                        <label
                                            className="text-sm font-bold"
                                            htmlFor="additional_outcomes"
                                        >
                                            Additional outcomes:
                                        </label>
                                    </div>
                                    <div className="col-span-12 text-sm">
                                        {outcome.additional_outcomes}
                                    </div>
                                </div>

                                <div className="grid grid-cols-12">
                                    <div className="col-span-12">
                                        <label
                                            className="text-sm font-bold"
                                            htmlFor="lessons_learned"
                                        >
                                            Lessons learned:
                                        </label>
                                    </div>
                                    <div className="col-span-12 text-sm">
                                        {outcome.lessons_learned}
                                    </div>
                                </div>

                                <div className="grid grid-cols-12">
                                    <div className="col-span-12">
                                        <label
                                            className="text-sm font-bold"
                                            htmlFor="mission_impact"
                                        >
                                            Mission impact:
                                        </label>
                                    </div>
                                    <div className="col-span-12 text-sm">
                                        {outcome.mission_impact.name}
                                    </div>
                                </div>

                                <div className="grid grid-cols-12">
                                    <div className="col-span-12">
                                        <label
                                            className="text-sm font-bold"
                                            htmlFor="mission_impact_details"
                                        >
                                            Mission impact details:
                                        </label>
                                    </div>
                                    <div className="col-span-12 text-sm">
                                        {outcome.mission_impact_details}
                                    </div>
                                </div>

                                <div className="grid grid-cols-12">
                                    <div className="col-span-12">
                                        <label
                                            className="text-sm font-bold"
                                            htmlFor="mission_report"
                                        >
                                            Mission report:
                                        </label>
                                    </div>
                                    <div className="col-span-12 text-sm">
                                        <a
                                            href={outcome.mission_report}
                                            target="_blank"
                                            className={`brand-link`}
                                            rel="noreferrer"
                                        >
                                            {outcome.mission_report}
                                        </a>
                                    </div>
                                </div>

                                <div className="flex justify-center">
                                    {loggedInUser.user_type.name ===
                                        "Country Programmes Manager" ||
                                    loggedInUser.user_type.name ===
                                        "Administrator" ? (
                                        <button
                                            type="button"
                                            className="button mx-1 mt-4"
                                            onClick={() => reOpenMission()}
                                        >
                                            Re-Open Mission
                                        </button>
                                    ) : null}

                                    <button
                                        type="button"
                                        className="button-default mx-1 mt-4"
                                        onClick={() => setOpen(false)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <Alert
                                type={`success`}
                                message={`Mission successfully re-opened.`}
                            />

                            <div className="flex justify-center">
                                <button
                                    type="button"
                                    className="button-default mx-1 mt-4"
                                    onClick={() => {
                                        setOpen(false);
                                        window.location.reload(false);
                                    }}
                                >
                                    Close
                                </button>
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    );
}
