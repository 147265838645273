export default function Status({ progressValue }) {
    const SLIDER = [
        {
            NAME: "NOT STARTED",
            MIN: 0,
            MAX: 1,
            STYLE: "bg-red-800",
        },
        {
            NAME: "POOR",
            MIN: 1,
            MAX: 33,
            STYLE: "bg-red-500",
        },
        {
            NAME: "PROGRESSIVE",
            MIN: 33,
            MAX: 66,
            STYLE: "bg-yellow-500",
        },
        {
            NAME: "GOOD",
            MIN: 66,
            MAX: 99,
            STYLE: "bg-green-500",
        },
        {
            NAME: "COMPLETE",
            MIN: 99,
            MAX: 100,
            STYLE: "bg-green-800",
        },
    ];
    return (
        <span>
            Status{" "}
            <span
                className={`inline-flex uppercase items-center px-2 py-0.5 rounded font-medium tag ${
                    SLIDER.filter(
                        (x) => x.MIN <= progressValue && x.MAX >= progressValue
                    )[0].STYLE
                } text-white text-xs`}
            >
                {
                    SLIDER.filter(
                        (x) => x.MIN <= progressValue && x.MAX >= progressValue
                    )[0].NAME
                }
            </span>{" "}
        </span>
    );
}
