import { useEffect, useState, useContext } from "react";
import { useFormValidation } from "hooks/FormValidationHook";
import Alert from "components/alert";
import { ApiConsumer } from "api-consumer";
import { API_ROUTES } from "api";
import { format, compareDesc } from "date-fns";
import { AuthenticationContext } from "hooks/AuthenticationContext";
import Delete from "components/delete";
import ModalUserDetails from "components/modal-user-details";
import Modal from "components/modal";

export default function Comments({ type, route, id, missionClosed }) {
    let { currentUser } = useContext(AuthenticationContext);
    let { validFormDetails } = useFormValidation();
    const [userDetailsRow, setUserDetailsRow] = useState({});
    const [openUserDetails, setOpenUserDetails] = useState(false);
    const [comments, setComments] = useState([]);
    const [invalidFormDetails, setInvalidFormDetails] = useState({});
    const [formDetails, setFormDetails] = useState({});
    const [formError, setFormError] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);

    const formFields = [{ name: "comment", display: "comment" }];
    const validateAddComment = () => {
        setFormSubmitted(false);
        if (
            validFormDetails(
                formFields,
                formDetails,
                setInvalidFormDetails,
                setFormError
            )
        ) {
            ApiConsumer.post(API_ROUTES.COMMENTS.COMMENTS, formDetails)
                .then((res) => {
                    loadComments();
                    setFormSubmitted(true);
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`comment`]: "",
                    }));
                })
                .catch((err) => {
                    setFormError(err.toString());
                })
                .finally(() => {});
        }
    };

    const loadComments = () => {
        ApiConsumer.get(route(id))
            .then((res) => {
                res.data.sort((a, b) =>
                    compareDesc(new Date(a.updated), new Date(b.updated))
                );
                setComments(res.data);
            })
            .catch((err) => {});
    };

    useEffect(() => {
        loadComments();
        /* Defaults */
        setFormDetails((prevState) => ({
            ...prevState,
            [`posted_by`]: currentUser.id,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [type]: id,
        }));
        return () => {
            setComments([]);
            setInvalidFormDetails({});
            setFormDetails({});
            setFormError("");
            setFormSubmitted(false);
        };
    }, [id, currentUser.id]);

    return (
        <>
            <Modal
                modalHeaderTag={""}
                modalHeader={`User Details: ${userDetailsRow?.first_name} ${userDetailsRow?.last_name}`}
                modalContent={
                    <ModalUserDetails
                        setOpen={setOpenUserDetails}
                        userDetailsRow={userDetailsRow}
                    />
                }
                open={openUserDetails}
                setOpen={setOpenUserDetails}
            />
            <div className="divide-y divide-gray-200">
                <div className="px-4 py-5 sm:px-6">
                    <h2
                        id="notes-title"
                        className="text-lg font-medium text-gray-900"
                    >
                        Comments ({comments.length})
                    </h2>
                </div>
                {!missionClosed && (
                    <div className="bg-gray-100 px-4 py-6 sm:px-6">
                        <div className="flex space-x-3">
                            <div className="flex-shrink-0">
                                <span className="icon-transparent p-2 bg-orange-500 text-orange-100 hover:text-orange-100">
                                    {currentUser.name.charAt(0).toUpperCase() +
                                        currentUser.surname
                                            .charAt(0)
                                            .toUpperCase()}
                                </span>
                            </div>
                            <div className="min-w-0 flex-1">
                                <form
                                    className="space-y-4"
                                    onSubmit={(event) => {
                                        event.preventDefault();
                                        validateAddComment();
                                    }}
                                >
                                    <div>
                                        <label
                                            htmlFor="comment"
                                            className="sr-only"
                                        >
                                            Post a comment
                                        </label>
                                        <textarea
                                            id="comment"
                                            name="comment"
                                            rows="3"
                                            placeholder="Add a comment"
                                            autoComplete="comment"
                                            className={`form-field ${
                                                invalidFormDetails.comment
                                                    ? `border-red-600`
                                                    : `border-gray-300`
                                            }`}
                                            onChange={(event) => {
                                                setFormDetails((prevState) => ({
                                                    ...prevState,
                                                    [`comment`]:
                                                        event.target.value,
                                                }));
                                            }}
                                            value={
                                                formDetails.comment
                                                    ? formDetails.comment
                                                    : ""
                                            }
                                        ></textarea>
                                    </div>
                                    {formError.length !== 0 && (
                                        <div className="col-span-12 form-error">
                                            {formError}
                                        </div>
                                    )}
                                    {formSubmitted && (
                                        <Alert
                                            type={`success`}
                                            message={`Your comment has been successfully posted.`}
                                        />
                                    )}
                                    <div className="mt-2 flex items-center justify-between">
                                        <button
                                            type="submit"
                                            className="button"
                                        >
                                            Post Comment
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                )}
                <div className="px-4 py-6 sm:px-6">
                    <ul className="space-y-8">
                        {comments.length
                            ? comments.map((comment, i) => (
                                  <li key={i}>
                                      <div className="flex space-x-3">
                                          <div className="flex-shrink-0">
                                              <span className="icon-transparent p-2 bg-orange-500 text-orange-100 hover:text-orange-100">
                                                  {comment.posted_by.first_name
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                      comment.posted_by.last_name
                                                          .charAt(0)
                                                          .toUpperCase()}
                                              </span>
                                              {comment.posted_by.id ===
                                                  currentUser.id &&
                                                  !missionClosed && (
                                                      <Delete
                                                          apiRoute={
                                                              API_ROUTES
                                                                  .COMMENTS
                                                                  .COMMENT_DETAIL
                                                          }
                                                          rowData={comment}
                                                          loadData={
                                                              loadComments
                                                          }
                                                          titleValue={"Comment"}
                                                      />
                                                  )}
                                          </div>
                                          <div>
                                              <div className="font-medium">
                                                  <span
                                                      className="hover:underline cursor-pointer"
                                                      onClick={() => {
                                                          setUserDetailsRow(
                                                              comment.posted_by
                                                          );
                                                          setOpenUserDetails(
                                                              true
                                                          );
                                                      }}
                                                  >
                                                      {comment.posted_by
                                                          .first_name +
                                                          " " +
                                                          comment.posted_by
                                                              .last_name}
                                                  </span>
                                              </div>
                                              <div className="mt-1 text-gray-700">
                                                  <p>{comment.comment}</p>
                                              </div>
                                              <div className="mt-0">
                                                  <span className="text-gray-400 text-sm">
                                                      {format(
                                                          new Date(
                                                              comment.created
                                                          ),
                                                          "dd MMM yyyy HH:mm:ss"
                                                      )}
                                                  </span>
                                              </div>
                                          </div>
                                      </div>
                                  </li>
                              ))
                            : "There are no comments posted."}
                    </ul>
                </div>
            </div>
        </>
    );
}
