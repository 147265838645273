import { useState, useEffect, useContext } from "react";
import { format, isAfter } from "date-fns";
import { useFormValidation } from "hooks/FormValidationHook";
import { API_ROUTES } from "api";
import { ApiConsumer } from "api-consumer";

import {
    DocumentReportIcon,
    UserIcon,
    UserAddIcon,
} from "@heroicons/react/outline";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Alert from "components/alert";
import Select from "react-select";
import { AppLoaderContext } from "hooks/AppLoaderContext";
import ModalUserDetails from "components/modal-user-details";
import Modal from "components/modal";
import Extension from "components/extension";

export default function AddEditProgramme({
    countryId,
    setOpen,
    editableRow,
    setRowUpdated,
}) {
    let loggedInUser = JSON.parse(sessionStorage.getItem(`ATAFTAX`));
    let { setLoading } = useContext(AppLoaderContext);
    const [categories, setCategories] = useState([]);
    const [types, setTypes] = useState([]);
    const [taxTypes, setTaxTypes] = useState([]);
    const [programmeDepartments, setProgrammeDepartments] = useState([]);
    const [internalUnits, setInternalUnits] = useState([]);
    const [programmeManagers, setProgrammeManagers] = useState([]);
    const [programmeOfficers, setProgrammeOfficers] = useState([]);
    const [internalExperts, setInternalExperts] = useState([]);
    const [externalExperts, setExternalExperts] = useState([]);
    const [reportUsers, setReportUsers] = useState([]);
    const [userDetailsRow, setUserDetailsRow] = useState({});
    const [openUserDetails, setOpenUserDetails] = useState(false);

    let { validFormDetails } = useFormValidation();
    const [invalidFormDetails, setInvalidFormDetails] = useState({});
    const [formDetails, setFormDetails] = useState({});
    const [formError, setFormError] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);

    const formFields = [
        { name: "type", display: "programme type" },
        { name: "category", display: "category" },
        { name: "tax_types", display: "tax type" },
        { name: "departments", display: "specific subject matters" },
        { name: "internal_units", display: "internal unit synergy" },
        { name: "name", display: "programme name" },
        { name: "overview", display: "overwiew" },
        { name: "start_date", display: "programme start date" },
        { name: "end_date", display: "programme end date" },
        { name: "ta_managers", display: "country programmes manager" },
    ];

    const validateAddEditProgramme = (isEdit) => {
        if (
            validFormDetails(
                formFields,
                formDetails,
                setInvalidFormDetails,
                setFormError
            )
        ) {
            setLoading(true);

            let formData = new FormData();
            for (const key in formDetails) {
                formData.append(
                    key,
                    key.includes("date")
                        ? formDetails[key].toISOString().substring(0, 10)
                        : formDetails[key]
                );
            }

            formData.append("workplan", formDetails.workplan);

            if (isEdit) {
                if (
                    formDetails.is_approved &&
                    formDetails.ta_officers.length === 0
                ) {
                    setFormError("Please enter CP programme coordinator(s).");
                    setLoading(false);
                } else if (
                    formDetails.is_approved &&
                    formDetails.report_users.length === 0
                ) {
                    setFormError(
                        "Please enter users allowed to view programme reports and outcomes."
                    );
                    setLoading(false);
                } else {
                    ApiConsumer.put(
                        API_ROUTES.PROGRAMME.PROGRAMME_DETAIL(editableRow.id),
                        formData
                    )
                        .then((res) => {
                            if (
                                formDetails.is_approved &&
                                !editableRow.is_approved
                            ) {
                                ApiConsumer.put(
                                    API_ROUTES.PROGRAMME.PROGRAMME_APPROVAL(
                                        editableRow.id
                                    ),
                                    { is_approved: 1 }
                                ).then((res) => {});
                            }

                            setRowUpdated(true);
                            setFormSubmitted(true);
                        })
                        .catch((err) => {
                            setFormError(err.toString());
                        })
                        .finally(() => {
                            setLoading(false);
                        });
                }
            } else {
                ApiConsumer.post(API_ROUTES.PROGRAMME.PROGRAMMES, formData)
                    .then((res) => {
                        setRowUpdated(true);
                        setFormSubmitted(true);
                    })
                    .catch((err) => {
                        setFormError(err.toString());
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    };

    const loadDropdown = (link, setMethod) => {
        ApiConsumer.get(link)
            .then((res) => {
                res.data.sort((a, b) => a.name.localeCompare(b.name));
                setMethod(res.data);
            })
            .catch((err) => {});
    };

    const loadMultiple = (link, setMethod) => {
        ApiConsumer.get(link)
            .then((res) => {
                res.data.sort((a, b) => a.name.localeCompare(b.name));
                let options = res.data.map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                    };
                });
                setMethod(options);
            })
            .catch((err) => {});
    };

    useEffect(() => {
        loadDropdown(API_ROUTES.PROGRAMME.CATEGORIES, setCategories);
        loadDropdown(API_ROUTES.PROGRAMME.TYPES, setTypes);
        loadMultiple(API_ROUTES.PROGRAMME.DEPARTMENTS, setProgrammeDepartments);
        loadMultiple(API_ROUTES.PROGRAMME.TAX_TYPES, setTaxTypes);
        loadMultiple(API_ROUTES.PROGRAMME.INTERNAL_UNITS, setInternalUnits);

        ApiConsumer.get(API_ROUTES.USERS.USERS)
            .then((res) => {
                res.data.sort((a, b) =>
                    a.first_name.localeCompare(b.first_name)
                );
                let users = res.data.filter(
                    (type) =>
                        type.user_type.name === "Country Programmes Manager" ||
                        type.user_type.name === "Administrator"
                );
                let userOptions = users.map((user) => {
                    return {
                        value: user.id,
                        label: user.first_name + " " + user.last_name,
                    };
                });
                setProgrammeManagers(userOptions);
                userOptions = res.data.map((user) => {
                    return {
                        value: user.id,
                        label: user.first_name + " " + user.last_name,
                    };
                });
                setProgrammeOfficers(userOptions);
                users = res.data.filter(
                    (type) => type.user_type.name === "Internal Expert"
                );
                userOptions = users.map((user) => {
                    return {
                        value: user.id,
                        label: user.first_name + " " + user.last_name,
                    };
                });
                setInternalExperts(userOptions);
                users = res.data.filter(
                    (type) => type.user_type.name === "External Expert"
                );
                userOptions = users.map((user) => {
                    return {
                        value: user.id,
                        label: user.first_name + " " + user.last_name,
                    };
                });
                setExternalExperts(userOptions);
                users = res.data; //Get all users
                userOptions = users.map((user) => {
                    return {
                        value: user.id,
                        label: user.first_name + " " + user.last_name,
                    };
                });
                setReportUsers(userOptions);
            })
            .catch((err) => {});
    }, []);

    useEffect(() => {
        /* Defaults */
        setFormDetails((prevState) => ({
            ...prevState,
            [`country`]: countryId,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`category`]: "selected",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`type`]: "selected",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`name`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`overview`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`start_date`]: new Date(),
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`end_date`]: new Date(),
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`workplan`]: null,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`departments`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`tax_types`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`internal_units`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`ta_managers`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`ta_officers`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`internal_experts`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`external_experts`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`report_users`]: [],
        }));

        setFormDetails((prevState) => ({
            ...prevState,
            [`is_approved`]: 0,
        }));

        /* If editing */
        if (Object.keys(editableRow).length !== 0) {
            setFormDetails((prevState) => ({
                ...prevState,
                [`programme_id`]: editableRow.id,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`category`]: editableRow.category.id,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`type`]: editableRow.type.id,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`name`]: editableRow.name,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`overview`]: editableRow.overview,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`start_date`]: new Date(editableRow.start_date),
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`end_date`]: new Date(editableRow.end_date),
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`workplan`]: null,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`workplan_link`]: editableRow.workplan,
            }));
            let departments = [];
            editableRow.departments.forEach((department, i) => {
                departments[i] = department.id;
            });
            setFormDetails((prevState) => ({
                ...prevState,
                [`departments`]: departments,
            }));
            let tax_types = [];
            editableRow.tax_types.forEach((type, i) => {
                tax_types[i] = type.id;
            });
            setFormDetails((prevState) => ({
                ...prevState,
                [`tax_types`]: tax_types,
            }));
            let internal_units = [];
            editableRow.internal_units.forEach((unit, i) => {
                internal_units[i] = unit.id;
            });
            setFormDetails((prevState) => ({
                ...prevState,
                [`internal_units`]: internal_units,
            }));
            let ta_managers = [];
            editableRow.ta_managers.forEach((ta_manager, i) => {
                ta_managers[i] = ta_manager.id;
            });
            setFormDetails((prevState) => ({
                ...prevState,
                [`ta_managers`]: ta_managers,
            }));
            let ta_officers = [];
            editableRow.ta_officers.forEach((ta_officer, i) => {
                ta_officers[i] = ta_officer.id;
            });
            setFormDetails((prevState) => ({
                ...prevState,
                [`ta_officers`]: ta_officers,
            }));
            let internal_experts = [];
            editableRow.internal_experts.forEach((internal_expert, i) => {
                internal_experts[i] = internal_expert.id;
            });
            setFormDetails((prevState) => ({
                ...prevState,
                [`internal_experts`]: internal_experts,
            }));
            let external_experts = [];
            editableRow.external_experts.forEach((external_expert, i) => {
                external_experts[i] = external_expert.id;
            });
            setFormDetails((prevState) => ({
                ...prevState,
                [`external_experts`]: external_experts,
            }));
            let report_users = [];
            editableRow.report_users.forEach((report_user, i) => {
                report_users[i] = report_user.id;
            });
            setFormDetails((prevState) => ({
                ...prevState,
                [`report_users`]: report_users,
            }));

            setFormDetails((prevState) => ({
                ...prevState,
                [`is_approved`]: editableRow.is_approved ? 1 : 0,
            }));
        }

        return () => {
            setCategories([]);
            setTypes([]);
            setTaxTypes([]);
            setProgrammeDepartments([]);
            setProgrammeManagers([]);
            setProgrammeOfficers([]);
            setInternalExperts([]);
            setExternalExperts([]);
            setReportUsers([]);
            setInvalidFormDetails({});
            setFormDetails({});
            setFormError("");
            setFormSubmitted(false);
        };
    }, [editableRow, countryId]);

    return (
        <>
            {!formSubmitted ? (
                <form
                    className="space-y-4 mb-28"
                    onSubmit={(event) => {
                        event.preventDefault();
                        validateAddEditProgramme(
                            Object.keys(editableRow).length
                        );
                    }}
                    method="POST"
                    encType="multipart/form-data"
                >
                    {Object.keys(editableRow).length !== 0 && (
                        <>
                            <Modal
                                modalHeaderTag={""}
                                modalHeader={`User Details: ${editableRow.created_by.first_name} ${editableRow.created_by.last_name}`}
                                modalContent={
                                    <ModalUserDetails
                                        setOpen={setOpenUserDetails}
                                        userDetailsRow={userDetailsRow}
                                    />
                                }
                                open={openUserDetails}
                                setOpen={setOpenUserDetails}
                            />
                            <div className="text-sm border-gray-200 p-4 rounded-lg bg-gray-100">
                                <span className="font-bold">Created:</span>{" "}
                                {format(
                                    new Date(editableRow.created),
                                    "dd MMM yyyy"
                                )}{" "}
                                by{" "}
                                <span
                                    className="hover:underline cursor-pointer"
                                    onClick={() => {
                                        setUserDetailsRow(
                                            editableRow.created_by
                                        );
                                        setOpenUserDetails(true);
                                    }}
                                >
                                    {editableRow.created_by.first_name +
                                        " " +
                                        editableRow.created_by.last_name}
                                </span>{" "}
                                <span className="font-bold">Last updated:</span>{" "}
                                {editableRow.normalised.updated}
                            </div>

                            {!editableRow.is_approved && (
                                <>
                                    <div class="rounded-md p-4 bg-red-100">
                                        <p className="text-sm font-medium text-red-600 w-full">
                                            Awaiting approval from Administrator
                                            or Country Programmes Manager(s)
                                        </p>
                                        {loggedInUser.user_type.name ===
                                            "Country Programmes Manager" ||
                                        loggedInUser.user_type.name ===
                                            "Administrator" ? (
                                            <div className="grid grid-cols-12  mt-2">
                                                <div className="col-span-4">
                                                    <label
                                                        className="text-sm"
                                                        htmlFor="type"
                                                    >
                                                        Approve Programme?
                                                    </label>
                                                </div>
                                                <div className="col-span-8">
                                                    <select
                                                        id="is_approved"
                                                        name="is_approved"
                                                        autoComplete="is_approved"
                                                        className={`border-gray-300`}
                                                        onChange={(event) => {
                                                            setFormDetails(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    [`is_approved`]:
                                                                        parseInt(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        ),
                                                                })
                                                            );
                                                            setFormError("");
                                                        }}
                                                        value={
                                                            formDetails.is_approved
                                                        }
                                                    >
                                                        <option
                                                            selected
                                                            value={0}
                                                        >
                                                            No
                                                        </option>
                                                        <option value={1}>
                                                            Yes
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </>
                            )}
                        </>
                    )}

                    <div className="space-y-2">
                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="type">
                                    Programme type:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <select
                                    id="type"
                                    name="type"
                                    autoComplete="type"
                                    className={`${
                                        invalidFormDetails.type
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`type`]: event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.type
                                            ? formDetails.type
                                            : "selected"
                                    }
                                >
                                    <option value="selected">
                                        Select type
                                    </option>
                                    {types.map((type) => {
                                        return (
                                            <option
                                                key={type.id}
                                                value={type.id}
                                            >
                                                {type.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="category">
                                    Category:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <select
                                    id="category"
                                    name="category"
                                    autoComplete="category"
                                    className={`${
                                        invalidFormDetails.category
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`category`]: event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.category
                                            ? formDetails.category
                                            : "selected"
                                    }
                                >
                                    <option value="selected">
                                        Select category
                                    </option>
                                    {categories.map((category) => {
                                        return (
                                            <option
                                                key={category.id}
                                                value={category.id}
                                            >
                                                {category.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="tax_types">
                                    Tax Types:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <Select
                                    id="tax_types"
                                    name="tax_types"
                                    isMulti
                                    options={taxTypes}
                                    classNamePrefix="multi-select"
                                    className={`multi-select ${
                                        invalidFormDetails.tax_types
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`tax_types`]: [...event].map(
                                                (option) => option.value
                                            ),
                                        }));
                                    }}
                                    defaultValue={0}
                                    value={
                                        Object.keys(taxTypes).length !== 0
                                            ? formDetails?.tax_types?.map(
                                                  (selectedOption) => {
                                                      return taxTypes[
                                                          taxTypes.findIndex(
                                                              (option) =>
                                                                  option.value ===
                                                                  selectedOption
                                                          )
                                                      ];
                                                  }
                                              )
                                            : 0
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="departments"
                                >
                                    Specific subject matters:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <Select
                                    id="departments"
                                    name="departments"
                                    isMulti
                                    options={programmeDepartments}
                                    classNamePrefix="multi-select"
                                    className={`multi-select ${
                                        invalidFormDetails.departments
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`departments`]: [...event].map(
                                                (option) => option.value
                                            ),
                                        }));
                                    }}
                                    defaultValue={0}
                                    value={
                                        Object.keys(programmeDepartments)
                                            .length !== 0
                                            ? formDetails?.departments?.map(
                                                  (selectedOption) => {
                                                      return programmeDepartments[
                                                          programmeDepartments.findIndex(
                                                              (option) =>
                                                                  option.value ===
                                                                  selectedOption
                                                          )
                                                      ];
                                                  }
                                              )
                                            : 0
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="internal_units"
                                >
                                    Internal unit synergy (List of MS &amp; DRM
                                    units):{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <Select
                                    id="internal_units"
                                    name="internal_units"
                                    isMulti
                                    options={internalUnits}
                                    classNamePrefix="multi-select"
                                    className={`multi-select ${
                                        invalidFormDetails.internal_units
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`internal_units`]: [...event].map(
                                                (option) => option.value
                                            ),
                                        }));
                                    }}
                                    defaultValue={0}
                                    value={
                                        Object.keys(internalUnits).length !== 0
                                            ? formDetails?.internal_units?.map(
                                                  (selectedOption) => {
                                                      return internalUnits[
                                                          internalUnits.findIndex(
                                                              (option) =>
                                                                  option.value ===
                                                                  selectedOption
                                                          )
                                                      ];
                                                  }
                                              )
                                            : 0
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="name">
                                    Programme name:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="name"
                                    name="name"
                                    type="text"
                                    autoComplete="name"
                                    className={`form-field ${
                                        invalidFormDetails.name
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`name`]: event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.name ? formDetails.name : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="overview">
                                    Brief overview:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <textarea
                                    id="overview"
                                    name="overview"
                                    autoComplete="overview"
                                    className={`form-field ${
                                        invalidFormDetails.overview
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`overview`]: event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.overview
                                            ? formDetails.overview
                                            : ""
                                    }
                                ></textarea>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="workplan">
                                    Upload workplan:
                                </label>
                            </div>
                            <div className="col-span-8">
                                {formDetails.workplan_link && (
                                    <a
                                        href={formDetails.workplan_link}
                                        target="_blank"
                                        className={`brand-link mb-2`}
                                        rel="noreferrer"
                                    >
                                        View current workplan
                                    </a>
                                )}
                                <input
                                    id="workplan"
                                    name="workplan"
                                    autoComplete="workplan"
                                    className={`form-field ${
                                        invalidFormDetails.workplan
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`workplan`]: event.target.files[0],
                                        }));
                                    }}
                                    //value={formDetails.workplan}
                                    type="file"
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="start_date">
                                    Programme start date:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <DatePicker
                                    fixedHeight
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    /*disabled={
                    Object.keys(editableRow).length !== 0 &&
                    isAfter(new Date(), new Date(editableRow.end_date)) &&
                    loggedInUser.user_type.name !==
                      'Country Programmes Manager' &&
                    loggedInUser.user_type.name !== 'Administrator'
                      ? true
                      : false
                  }*/
                                    showYearDropdown
                                    dateFormat="dd MMMM yyyy"
                                    selected={formDetails.start_date}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`start_date`]: event,
                                        }));
                                        if (
                                            isAfter(event, formDetails.end_date)
                                        )
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`end_date`]: event,
                                            }));
                                    }}
                                    className="form-field"
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="end_date">
                                    Programme end date:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <DatePicker
                                    fixedHeight
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    /*disabled={
                    Object.keys(editableRow).length !== 0 &&
                    isAfter(new Date(), new Date(editableRow.end_date)) &&
                    loggedInUser.user_type.name !==
                      'Country Programmes Manager' &&
                    loggedInUser.user_type.name !== 'Administrator'
                      ? true
                      : false
                  }*/
                                    showYearDropdown
                                    minDate={new Date(formDetails.start_date)}
                                    dateFormat="dd MMMM yyyy"
                                    selected={formDetails.end_date}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`end_date`]: event,
                                        }));
                                    }}
                                    className="form-field"
                                />
                            </div>
                        </div>

                        {/*Object.keys(editableRow).length !== 0 &&
              isAfter(new Date(), new Date(editableRow.end_date)) && (
                <Extension programmeId={editableRow.id} />
              )*/}

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="ta_managers"
                                >
                                    Country programmes manager(s):{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <Select
                                    id="ta_managers"
                                    name="ta_managers"
                                    isMulti
                                    options={programmeManagers}
                                    classNamePrefix="multi-select"
                                    className={`multi-select ${
                                        invalidFormDetails.ta_managers
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`ta_managers`]: [...event].map(
                                                (option) => option.value
                                            ),
                                        }));
                                    }}
                                    defaultValue={0}
                                    value={
                                        Object.keys(programmeManagers)
                                            .length !== 0
                                            ? formDetails?.ta_managers?.map(
                                                  (selectedOption) => {
                                                      return programmeManagers[
                                                          programmeManagers.findIndex(
                                                              (option) =>
                                                                  option.value ===
                                                                  selectedOption
                                                          )
                                                      ];
                                                  }
                                              )
                                            : 0
                                    }
                                />
                            </div>
                        </div>

                        {Object.keys(editableRow).length !== 0 &&
                        formDetails.is_approved ? (
                            <div className="space-y-2 text-sm border-gray-200 p-4 rounded-lg bg-gray-100">
                                <div className="font-bold">
                                    To be updated by the country programmes
                                    manager once the programme has been
                                    approved.
                                </div>
                                <div className="grid grid-cols-12">
                                    <div className="col-span-4">
                                        <label
                                            className="text-sm"
                                            htmlFor="ta_officers"
                                        >
                                            CP programme coordinator(s):{" "}
                                            <span className="text-red-600">
                                                *
                                            </span>
                                        </label>
                                    </div>
                                    <div className="col-span-8">
                                        <Select
                                            id="ta_officers"
                                            name="ta_officers"
                                            isMulti
                                            options={programmeOfficers}
                                            classNamePrefix="multi-select"
                                            className={`multi-select ${
                                                invalidFormDetails.ta_officers
                                                    ? `border-red-600`
                                                    : `border-gray-300`
                                            }`}
                                            onChange={(event) => {
                                                setFormDetails((prevState) => ({
                                                    ...prevState,
                                                    [`ta_officers`]: [
                                                        ...event,
                                                    ].map(
                                                        (option) => option.value
                                                    ),
                                                }));
                                            }}
                                            defaultValue={0}
                                            value={
                                                Object.keys(programmeOfficers)
                                                    .length !== 0
                                                    ? formDetails?.ta_officers?.map(
                                                          (selectedOption) => {
                                                              return programmeOfficers[
                                                                  programmeOfficers.findIndex(
                                                                      (
                                                                          option
                                                                      ) =>
                                                                          option.value ===
                                                                          selectedOption
                                                                  )
                                                              ];
                                                          }
                                                      )
                                                    : 0
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="grid grid-cols-12">
                                    <div className="col-span-4">
                                        <label
                                            className="text-sm"
                                            htmlFor="internal_experts"
                                        >
                                            <UserIcon
                                                className="h-5 w-5 mr-0.5 inline-block"
                                                aria-hidden="true"
                                            />
                                            Internal expert(s) able to manage
                                            missions:
                                        </label>
                                    </div>
                                    <div className="col-span-8">
                                        <Select
                                            id="internal_experts"
                                            name="internal_experts"
                                            isMulti
                                            options={internalExperts}
                                            classNamePrefix="multi-select"
                                            className={`multi-select ${
                                                invalidFormDetails.internal_experts
                                                    ? `border-red-600`
                                                    : `border-gray-300`
                                            }`}
                                            onChange={(event) => {
                                                setFormDetails((prevState) => ({
                                                    ...prevState,
                                                    [`internal_experts`]: [
                                                        ...event,
                                                    ].map(
                                                        (option) => option.value
                                                    ),
                                                }));
                                            }}
                                            defaultValue={0}
                                            value={
                                                Object.keys(internalExperts)
                                                    .length !== 0
                                                    ? formDetails?.internal_experts?.map(
                                                          (selectedOption) => {
                                                              return internalExperts[
                                                                  internalExperts.findIndex(
                                                                      (
                                                                          option
                                                                      ) =>
                                                                          option.value ===
                                                                          selectedOption
                                                                  )
                                                              ];
                                                          }
                                                      )
                                                    : 0
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="grid grid-cols-12">
                                    <div className="col-span-4">
                                        <label
                                            className="text-sm"
                                            htmlFor="external_experts"
                                        >
                                            <UserAddIcon
                                                className="h-5 w-5 mr-0.5 inline-block"
                                                aria-hidden="true"
                                            />
                                            External expert(s) able to manage
                                            missions:
                                        </label>
                                    </div>
                                    <div className="col-span-8">
                                        <Select
                                            id="external_experts"
                                            name="external_experts"
                                            isMulti
                                            options={externalExperts}
                                            classNamePrefix="multi-select"
                                            className={`multi-select ${
                                                invalidFormDetails.external_experts
                                                    ? `border-red-600`
                                                    : `border-gray-300`
                                            }`}
                                            onChange={(event) => {
                                                setFormDetails((prevState) => ({
                                                    ...prevState,
                                                    [`external_experts`]: [
                                                        ...event,
                                                    ].map(
                                                        (option) => option.value
                                                    ),
                                                }));
                                            }}
                                            defaultValue={0}
                                            value={
                                                Object.keys(externalExperts)
                                                    .length !== 0
                                                    ? formDetails?.external_experts?.map(
                                                          (selectedOption) => {
                                                              return externalExperts[
                                                                  externalExperts.findIndex(
                                                                      (
                                                                          option
                                                                      ) =>
                                                                          option.value ===
                                                                          selectedOption
                                                                  )
                                                              ];
                                                          }
                                                      )
                                                    : 0
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="grid grid-cols-12">
                                    <div className="col-span-4">
                                        <label
                                            className="text-sm"
                                            htmlFor="external_experts"
                                        >
                                            <DocumentReportIcon
                                                className="h-5 w-5 mr-0.5 inline-block"
                                                aria-hidden="true"
                                            />
                                            Users allowed to view programme
                                            reports and outcomes:{" "}
                                            <span className="text-red-600">
                                                *
                                            </span>
                                        </label>
                                    </div>
                                    <div className="col-span-8">
                                        <Select
                                            id="report_users"
                                            name="report_users"
                                            isMulti
                                            options={reportUsers}
                                            classNamePrefix="multi-select"
                                            className={`multi-select ${
                                                invalidFormDetails.report_users
                                                    ? `border-red-600`
                                                    : `border-gray-300`
                                            }`}
                                            onChange={(event) => {
                                                setFormDetails((prevState) => ({
                                                    ...prevState,
                                                    [`report_users`]: [
                                                        ...event,
                                                    ].map(
                                                        (option) => option.value
                                                    ),
                                                }));
                                            }}
                                            defaultValue={0}
                                            value={
                                                Object.keys(reportUsers)
                                                    .length !== 0
                                                    ? formDetails?.report_users?.map(
                                                          (selectedOption) => {
                                                              return reportUsers[
                                                                  reportUsers.findIndex(
                                                                      (
                                                                          option
                                                                      ) =>
                                                                          option.value ===
                                                                          selectedOption
                                                                  )
                                                              ];
                                                          }
                                                      )
                                                    : 0
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        {formError.length !== 0 && (
                            <div className="col-span-12 form-error text-center">
                                {formError}
                            </div>
                        )}

                        <div className="flex justify-center">
                            <button
                                type="button"
                                className="button-default mx-1 mt-4"
                                onClick={() => setOpen(false)}
                            >
                                Close Popup
                            </button>
                            <button type="submit" className="button mx-1 mt-4">
                                {Object.keys(editableRow).length === 0
                                    ? `Add Programme`
                                    : `Update`}
                            </button>
                        </div>
                    </div>
                </form>
            ) : (
                <>
                    <Alert
                        type={`success`}
                        message={`Successfully ${
                            Object.keys(editableRow).length === 0
                                ? `added`
                                : `updated`
                        } programme:<br /><strong>${formDetails.name}</strong>`}
                    />

                    <div className="flex justify-center">
                        <button
                            type="button"
                            className="button-default mx-1 mt-4"
                            onClick={() => setOpen(false)}
                        >
                            Close
                        </button>
                    </div>
                </>
            )}
        </>
    );
}
