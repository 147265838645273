import { useEffect, useState, useContext } from "react";
import { SearchIcon } from "@heroicons/react/outline";
import Navigation from "components/navigation";
import Header from "components/header";
import PageHeader from "components/page-header";
import Table from "components/table";
import { sub, add, compareDesc, isAfter } from "date-fns";
import { useTextFormatHook } from "hooks/TextFormatHook";
import { ApiConsumer } from "api-consumer";
import { NavLink } from "react-router-dom";

import { API_ROUTES } from "api";
import { PORTAL } from "portal";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AppLoader } from "components/app-loader";
import { AppLoaderContext } from "hooks/AppLoaderContext";

export default function MissionReports() {
    let { setLoading } = useContext(AppLoaderContext);
    let { normaliseTableData } = useTextFormatHook();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [formDetails, setFormDetails] = useState({});
    const [countries, setCountries] = useState([]);
    const [programmes, setProgrammes] = useState([]);

    const [missionTypes, setMissionTypes] = useState([]);
    const [missionActivities, setMissionActivities] = useState([]);
    const [missionAreasOfSupport, setMissionAreasOfSupport] = useState([]);
    const [missionExpertInstitutions, setMissionExpertInstitutions] = useState(
        []
    );
    const [missionExperts, setMissionExperts] = useState([]);
    const [missionsReport, setMissionsReport] = useState([]);
    const [programmeDepartments, setProgrammeDepartments] = useState([]);

    const MISSION_HEADERS = [
        { key: "name", value: "Mission", showOnTable: true, showOnPrint: true },
        {
            key: "country",
            value: "Country",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "overview",
            value: "Brief overview",
            showOnTable: false,
            showOnPrint: false,
        },
        {
            key: "mission_departments",
            value: "Specific Subject Matters",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "type",
            value: "Mission Type",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "activity",
            value: "Mission Activity",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "areas_of_support",
            value: "Area of Support",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "duration",
            value: "Duration",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "start_date",
            value: "Start Date",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "end_date",
            value: "End Date",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "overall_progress",
            value: "Progress",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "status",
            value: "Status",
            showOnTable: true,
            showOnPrint: false,
        },
        {
            key: "internal_experts",
            value: "Internal Experts",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "external_experts",
            value: "External Experts",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "report_users",
            value: "View mission reports",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "partners",
            value: "Partners",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "created",
            value: "Created",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "updated",
            value: "Updated",
            showOnTable: false,
            showOnPrint: true,
        },
    ];
    const STATUS = [
        {
            value: "open",
            label: "OPEN",
        },
        {
            value: "closed",
            label: "CLOSED",
        },
        {
            value: "overdue",
            label: "OVERDUE",
        },
    ];

    const SLIDER = [
        {
            NAME: "NOT STARTED",
            MIN: 0,
            MAX: 1,
            STYLE: "bg-red-800",
        },
        {
            NAME: "POOR",
            MIN: 1,
            MAX: 33,
            STYLE: "bg-red-500",
        },
        {
            NAME: "PROGRESSIVE",
            MIN: 33,
            MAX: 66,
            STYLE: "bg-yellow-500",
        },
        {
            NAME: "GOOD",
            MIN: 66,
            MAX: 99,
            STYLE: "bg-green-500",
        },
        {
            NAME: "COMPLETE",
            MIN: 99,
            MAX: 100,
            STYLE: "bg-green-800",
        },
    ];

    const addMissionLinksToTableData = (tableData) => {
        tableData.forEach((row) => {
            row.normalised.name = (
                <NavLink
                    to={`${PORTAL.MISSION}/${row.id}`}
                    className={`brand-link`}
                >
                    {row.normalised.name}
                </NavLink>
            );
        });
        return tableData;
    };

    const searchReport = () => {
        setLoading(true);
        for (const key in formDetails) {
            if (
                Array.isArray(formDetails[key]) &&
                formDetails[key].length === 0
            ) {
                delete formDetails[key];
            }
        }
        ApiConsumer.post(API_ROUTES.REPORT.MISSIONS, formDetails)
            .then((res) => {
                res.data.sort((a, b) =>
                    compareDesc(new Date(a.updated), new Date(b.updated))
                );
                //Group by "id" to avoid duplicates
                res.data = res.data.filter(
                    (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
                );
                //Filter by status
                res.data.map((row, i) => {
                    if (
                        isAfter(new Date(), new Date(row.end_date)) &&
                        row.overall_progress < 100
                    )
                        res.data[i]["status"] = "OVERDUE".toLowerCase();
                    else
                        res.data[i]["status"] = SLIDER.filter(
                            (x) =>
                                x.MIN <= row.overall_progress &&
                                x.MAX >= row.overall_progress
                        )[0].NAME.toLowerCase();
                });
                if (
                    formDetails.mission_status !== undefined &&
                    formDetails.mission_status.length !== 0
                )
                    res.data = res.data.filter(
                        (report) =>
                            formDetails.mission_status.indexOf(
                                report.status
                            ) !== -1
                    );

                let normalisedMissionData = normaliseTableData(
                    MISSION_HEADERS,
                    res.data
                );
                setMissionsReport(
                    addMissionLinksToTableData(normalisedMissionData)
                );
            })
            .catch((err) => {})
            .finally(() => {
                setLoading(false);
            });
    };

    const onMissionDateChange = (dates) => {
        const [start, end] = dates;

        setFormDetails((prevState) => ({
            ...prevState,
            [`mission_start_date`]: start,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`mission_end_date`]: end,
        }));
    };

    useEffect(() => {
        setFormDetails((prevState) => ({
            ...prevState,
            [`mission_start_date`]: sub(new Date(), { years: 1 }),
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`mission_end_date`]: add(new Date(), { years: 1 }),
        }));
    }, []);

    const loadDropdown = (link, setMethod) => {
        ApiConsumer.get(link)
            .then((res) => {
                res.data.sort((a, b) => a.name.localeCompare(b.name));
                let options = res.data.map((option) => {
                    return {
                        value: option.id,
                        label: option.name,
                    };
                });
                setMethod(options);
            })
            .catch((err) => {});
    };

    useEffect(() => {
        loadDropdown(API_ROUTES.CONTENT.COUNTRY, setCountries);
        loadDropdown(API_ROUTES.MISSION.TYPES, setMissionTypes);
        loadDropdown(API_ROUTES.MISSION.ACTIVITY, setMissionActivities);
        loadDropdown(API_ROUTES.PROGRAMME.DEPARTMENTS, setProgrammeDepartments);
        loadDropdown(
            API_ROUTES.MISSION.AREAS_OF_SUPPORT,
            setMissionAreasOfSupport
        );
        loadDropdown(
            API_ROUTES.MISSION.EXPERT_INSTITUTION,
            setMissionExpertInstitutions
        );

        loadDropdown(API_ROUTES.PROGRAMME.PROGRAMMES, setProgrammes);

        ApiConsumer.get(API_ROUTES.USERS.USERS)
            .then((res) => {
                res.data.sort((a, b) =>
                    a.first_name.localeCompare(b.first_name)
                );
                let users = res.data.filter(
                    (type) =>
                        type.user_type.name === "Internal Expert" ||
                        type.user_type.name === "External Expert"
                );
                let userOptions = users.map((user) => {
                    return {
                        value: user.id,
                        label: user.first_name + " " + user.last_name,
                    };
                });
                setMissionExperts(userOptions);
            })
            .catch((err) => {});

        return () => {
            setMissionTypes([]);
            setMissionActivities([]);
            setMissionAreasOfSupport([]);
            setMissionExpertInstitutions([]);
            setProgrammeDepartments([]);
            setMissionExperts([]);
            setSidebarOpen(false);
        };
    }, []);

    const clearSearch = () => {
        setFormDetails((prevState) => ({
            ...prevState,
            [`countries`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`programmes`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`mission_departments`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`mission_types`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`activity`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`areas_of_support`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`expert_institution`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`mission_users`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`mission_status`]: [],
        }));
        setMissionsReport([]);
    };

    return (
        <>
            <div className="min-h-full bg-gray-100">
                <Navigation
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                />

                <div className="lg:pl-64 flex flex-col flex-1">
                    <Header />

                    <main className="flex-1 pb-8">
                        <PageHeader pageHeaderName={`Mission Reports`} />

                        <div className="mx-full px-4 sm:px-6 lg:px-8 pt-2 my-2">
                            <div className="grid grid-cols-12 space-x-4">
                                <div className="col-span-3">
                                    <label
                                        className="text-sm"
                                        htmlFor="countries"
                                    >
                                        Countries:
                                    </label>
                                    <Select
                                        id="countries"
                                        name="countries"
                                        placeholder="All countries"
                                        isMulti
                                        options={countries}
                                        classNamePrefix="multi-select"
                                        className={`multi-select text-xs`}
                                        onChange={(event) => {
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`countries`]: [...event].map(
                                                    (option) => option.value
                                                ),
                                            }));
                                        }}
                                        defaultValue={0}
                                        value={
                                            Object.keys(countries).length !==
                                                0 &&
                                            formDetails.countries !== undefined
                                                ? formDetails?.countries?.map(
                                                      (selectedOption) => {
                                                          return countries[
                                                              countries.findIndex(
                                                                  (option) =>
                                                                      option.value ===
                                                                      selectedOption
                                                              )
                                                          ];
                                                      }
                                                  )
                                                : 0
                                        }
                                    />
                                </div>
                                <div className="col-span-3">
                                    <label
                                        className="text-sm"
                                        htmlFor="mission_departments"
                                    >
                                        Specific subject matters:
                                    </label>
                                    <Select
                                        id="mission_departments"
                                        name="mission_departments"
                                        placeholder="All subject matters"
                                        isMulti
                                        options={programmeDepartments}
                                        classNamePrefix="multi-select"
                                        className={`multi-select text-xs`}
                                        onChange={(event) => {
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`mission_departments`]: [...event].map(
                                                    (option) => option.value
                                                ),
                                            }));
                                        }}
                                        defaultValue={0}
                                        value={
                                            Object.keys(programmeDepartments)
                                                .length !== 0 &&
                                            formDetails.mission_departments !==
                                                undefined
                                                ? formDetails?.mission_departments?.map(
                                                      (selectedOption) => {
                                                          return programmeDepartments[
                                                              programmeDepartments.findIndex(
                                                                  (option) =>
                                                                      option.value ===
                                                                      selectedOption
                                                              )
                                                          ];
                                                      }
                                                  )
                                                : 0
                                        }
                                    />
                                </div>
                                <div className="col-span-6">
                                    <label
                                        className="text-sm"
                                        htmlFor="programmes"
                                    >
                                        Select programmes:
                                    </label>
                                    <Select
                                        id="programmes"
                                        name="programmes"
                                        placeholder="All programmes"
                                        isMulti
                                        options={programmes}
                                        classNamePrefix="multi-select"
                                        className={`multi-select text-xs`}
                                        onChange={(event) => {
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`programmes`]: [...event].map(
                                                    (option) => option.value
                                                ),
                                            }));
                                        }}
                                        defaultValue={0}
                                        value={
                                            Object.keys(programmes).length !==
                                                0 &&
                                            formDetails.programmes !== undefined
                                                ? formDetails?.programmes?.map(
                                                      (selectedOption) => {
                                                          return programmes[
                                                              programmes.findIndex(
                                                                  (option) =>
                                                                      option.value ===
                                                                      selectedOption
                                                              )
                                                          ];
                                                      }
                                                  )
                                                : 0
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mx-full px-4 sm:px-6 lg:px-8 pt-2 my-2">
                            <div className="grid grid-cols-12 space-x-4">
                                <div className="col-span-3">
                                    <label
                                        className="text-sm"
                                        htmlFor="mission_types"
                                    >
                                        Mission types:
                                    </label>
                                    <Select
                                        id="mission_types"
                                        name="mission_types"
                                        placeholder="All types"
                                        isMulti
                                        options={missionTypes}
                                        classNamePrefix="multi-select"
                                        className={`multi-select text-xs`}
                                        onChange={(event) => {
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`mission_types`]: [
                                                    ...event,
                                                ].map((option) => option.value),
                                            }));
                                        }}
                                        defaultValue={0}
                                        value={
                                            Object.keys(missionTypes).length !==
                                                0 &&
                                            formDetails.mission_types !==
                                                undefined
                                                ? formDetails?.mission_types?.map(
                                                      (selectedOption) => {
                                                          return missionTypes[
                                                              missionTypes.findIndex(
                                                                  (option) =>
                                                                      option.value ===
                                                                      selectedOption
                                                              )
                                                          ];
                                                      }
                                                  )
                                                : 0
                                        }
                                    />
                                </div>

                                <div className="col-span-3">
                                    <label
                                        className="text-sm"
                                        htmlFor="activity"
                                    >
                                        Mission activities:
                                    </label>
                                    <Select
                                        id="mission_types"
                                        name="mission_types"
                                        placeholder="All activities"
                                        isMulti
                                        options={missionActivities}
                                        classNamePrefix="multi-select"
                                        className={`multi-select text-xs`}
                                        onChange={(event) => {
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`activity`]: [...event].map(
                                                    (option) => option.value
                                                ),
                                            }));
                                        }}
                                        defaultValue={0}
                                        value={
                                            Object.keys(missionActivities)
                                                .length !== 0 &&
                                            formDetails.activity !== undefined
                                                ? formDetails?.activity?.map(
                                                      (selectedOption) => {
                                                          return missionActivities[
                                                              missionActivities.findIndex(
                                                                  (option) =>
                                                                      option.value ===
                                                                      selectedOption
                                                              )
                                                          ];
                                                      }
                                                  )
                                                : 0
                                        }
                                    />
                                </div>

                                <div className="col-span-3">
                                    <label
                                        className="text-sm"
                                        htmlFor="areas_of_support"
                                    >
                                        Mission areas of support:
                                    </label>
                                    <Select
                                        id="areas_of_support"
                                        name="areas_of_support"
                                        placeholder="All areas of support"
                                        isMulti
                                        options={missionAreasOfSupport}
                                        classNamePrefix="multi-select"
                                        className={`multi-select text-xs`}
                                        onChange={(event) => {
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`areas_of_support`]: [
                                                    ...event,
                                                ].map((option) => option.value),
                                            }));
                                        }}
                                        defaultValue={0}
                                        value={
                                            Object.keys(missionAreasOfSupport)
                                                .length !== 0 &&
                                            formDetails.areas_of_support !==
                                                undefined
                                                ? formDetails?.areas_of_support?.map(
                                                      (selectedOption) => {
                                                          return missionAreasOfSupport[
                                                              missionAreasOfSupport.findIndex(
                                                                  (option) =>
                                                                      option.value ===
                                                                      selectedOption
                                                              )
                                                          ];
                                                      }
                                                  )
                                                : 0
                                        }
                                    />
                                </div>
                                <div className="col-span-3">
                                    <label
                                        className="text-sm"
                                        htmlFor="expert_institution"
                                    >
                                        Institutions of external expert:
                                    </label>
                                    <Select
                                        id="expert_institution"
                                        name="expert_institution"
                                        placeholder="All institutions"
                                        isMulti
                                        options={missionExpertInstitutions}
                                        classNamePrefix="multi-select"
                                        className={`multi-select text-xs`}
                                        onChange={(event) => {
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`expert_institution`]: [
                                                    ...event,
                                                ].map((option) => option.value),
                                            }));
                                        }}
                                        defaultValue={0}
                                        value={
                                            Object.keys(
                                                missionExpertInstitutions
                                            ).length !== 0 &&
                                            formDetails.expert_institution !==
                                                undefined
                                                ? formDetails?.expert_institution?.map(
                                                      (selectedOption) => {
                                                          return missionExpertInstitutions[
                                                              missionExpertInstitutions.findIndex(
                                                                  (option) =>
                                                                      option.value ===
                                                                      selectedOption
                                                              )
                                                          ];
                                                      }
                                                  )
                                                : 0
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="border-b border-gray-200 mx-full px-4 sm:px-6 lg:px-8 my-3 pb-3">
                            <div className="grid grid-cols-12 space-x-4">
                                <div className="col-span-3">
                                    <label
                                        className="text-sm"
                                        htmlFor="mission_users"
                                    >
                                        Internal/external experts:
                                    </label>
                                    <Select
                                        id="mission_users"
                                        name="mission_users"
                                        placeholder="All users"
                                        isMulti
                                        options={missionExperts}
                                        classNamePrefix="multi-select"
                                        className={`multi-select text-xs`}
                                        onChange={(event) => {
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`mission_users`]: [
                                                    ...event,
                                                ].map((option) => option.value),
                                            }));
                                        }}
                                        defaultValue={0}
                                        value={
                                            Object.keys(missionExperts)
                                                .length !== 0 &&
                                            formDetails.mission_users !==
                                                undefined
                                                ? formDetails?.mission_users?.map(
                                                      (selectedOption) => {
                                                          return missionExperts[
                                                              missionExperts.findIndex(
                                                                  (option) =>
                                                                      option.value ===
                                                                      selectedOption
                                                              )
                                                          ];
                                                      }
                                                  )
                                                : 0
                                        }
                                    />
                                </div>
                                <div className="col-span-3">
                                    <label
                                        className="text-sm"
                                        htmlFor="mission_status"
                                    >
                                        Mission status:
                                    </label>
                                    <Select
                                        id="mission_status"
                                        name="mission_status"
                                        placeholder="All statuses"
                                        isMulti
                                        options={STATUS}
                                        classNamePrefix="multi-select"
                                        className={`multi-select text-xs`}
                                        onChange={(event) => {
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`mission_status`]: [
                                                    ...event,
                                                ].map((option) => option.value),
                                            }));
                                        }}
                                        defaultValue={0}
                                        value={
                                            Object.keys(STATUS).length !== 0 &&
                                            formDetails.mission_status !==
                                                undefined
                                                ? formDetails?.mission_status?.map(
                                                      (selectedOption) => {
                                                          return STATUS[
                                                              STATUS.findIndex(
                                                                  (option) =>
                                                                      option.value ===
                                                                      selectedOption
                                                              )
                                                          ];
                                                      }
                                                  )
                                                : 0
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="relative py-2">
                            <AppLoader
                                pageContent={
                                    <h2 className="flex mx-full px-4 sm:px-6 lg:px-8 text-lg leading-6 text-gray-900 text-left items-center">
                                        <span className="min-w-0 flex-1 items-center font-medium">
                                            Mission Results (
                                            {missionsReport.length})
                                            <br />
                                            <span
                                                className="brand-link text-sm font-normal"
                                                onClick={() => clearSearch()}
                                            >
                                                Clear all search parameters
                                            </span>
                                        </span>

                                        <div className="col-span-3">
                                            <label
                                                className="text-sm"
                                                htmlFor="date_range"
                                            >
                                                Date range:
                                            </label>
                                            <DatePicker
                                                fixedHeight
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }}
                                                showYearDropdown
                                                dateFormat="dd MMMM yyyy"
                                                selected={
                                                    formDetails.mission_start_date
                                                }
                                                onChange={(event) =>
                                                    onMissionDateChange(event)
                                                }
                                                className="form-field"
                                                startDate={
                                                    formDetails.mission_start_date
                                                }
                                                endDate={
                                                    formDetails.mission_end_date
                                                }
                                                selectsRange
                                            />
                                        </div>
                                        <button
                                            type="button"
                                            className="button flex-shrink-0 ml-2 mt-5"
                                            onClick={() => searchReport()}
                                        >
                                            <div
                                                className="flex items-center pointer-events-none mr-1"
                                                aria-hidden="true"
                                            >
                                                <SearchIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            Search
                                        </button>
                                    </h2>
                                }
                            />
                        </div>
                        <Table
                            tableTitle={`Mission Report Results`}
                            tableHeaders={MISSION_HEADERS}
                            tableData={missionsReport}
                            fontSize="text-xs"
                        />
                    </main>
                </div>
            </div>
        </>
    );
}
