import {
    format,
    add,
    isAfter,
    intervalToDuration,
    formatDuration,
} from "date-fns";

export const useTextFormatHook = () => {
    const SLIDER = [
        {
            NAME: "NOT STARTED",
            MIN: 0,
            MAX: 1,
            STYLE: "bg-red-800",
        },
        {
            NAME: "POOR",
            MIN: 1,
            MAX: 33,
            STYLE: "bg-red-500",
        },
        {
            NAME: "PROGRESSIVE",
            MIN: 33,
            MAX: 66,
            STYLE: "bg-yellow-500",
        },
        {
            NAME: "GOOD",
            MIN: 66,
            MAX: 99,
            STYLE: "bg-green-500",
        },
        {
            NAME: "COMPLETE",
            MIN: 99,
            MAX: 100,
            STYLE: "bg-green-800",
        },
    ];

    const convertDateToString = (date) => {
        return new Date(date).toLocaleDateString("en-ZA");
    };

    const getFullMonth = (date) => {
        return new Date(date).toLocaleString("default", { month: "long" });
    };

    const getDate = (date) => {
        const d = new Date(date);
        let month = d.getMonth() + 1;
        let day = d.getDate();
        return `${d.getFullYear()}/${
            month < 10 ? "0" + month.toString() : month
        }/${day < 10 ? "0" + day.toString() : day}`;
    };

    const formatPhoneNumber = (phoneNumberString) => {
        let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
        let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            let intlCode = match[1] ? "+1 " : "";
            return [intlCode, match[2], " ", match[3], " ", match[4]].join("");
        }
        return null;
    };

    const getDateTime = (date, time) => {
        const d = new Date(date);
        const t = new Date(time);
        let month = d.getMonth() + 1;
        let day = d.getDate();

        return `${d.getFullYear()}/${
            month < 10 ? "0" + month.toString() : month
        }/${day < 10 ? "0" + day.toString() : day} ${
            t.getHours() < 10 ? "0" + t.getHours().toString() : t.getHours()
        }:${
            t.getMinutes() < 10
                ? "0" + t.getMinutes().toString()
                : t.getMinutes()
        }`;
    };

    const getTime = (date) => {
        const t = new Date(date);
        return `${
            t.getHours() < 10 ? "0" + t.getHours().toString() : t.getHours()
        }:${
            t.getMinutes() < 10
                ? "0" + t.getMinutes().toString()
                : t.getMinutes()
        }`;
    };

    const formatDate = (date) => {
        let cleaned = ("" + date).replace(/\D/g, "");
        let match = cleaned.match(/^(1|)?(\d{4})(\d{2})(\d{2})$/);
        if (match) {
            return [match[2], "/", match[3], "/", match[4]].join("");
        }
        return null;
    };

    const formatTime = (time) => {
        if (time.length < 4) {
            time = `0${time}`;
        }
        return time.substring(0, 2) + ":" + time.substring(2, time.length);
    };

    const normaliseTableData = (tableHeaders, tableData) => {
        tableData.forEach((row, i) => {
            let normalisedData = {};
            tableHeaders.forEach((column) => {
                normalisedData[column.key] =
                    normalisedData[column.key] === undefined
                        ? row[column.key]
                        : normalisedData[column.key];

                if (column.key === "name")
                    normalisedData["name"] =
                        row.first_name !== undefined &&
                        row.last_name !== undefined
                            ? row.first_name + " " + row.last_name
                            : row.name + `${row.closed ? ` (CLOSED)` : ``}`;

                if (column.key === "duration") {
                    normalisedData[column.key] = formatDuration(
                        intervalToDuration({
                            start: new Date(row.start_date),
                            end: add(new Date(row.end_date), { days: 1 }),
                        })
                    );

                    normalisedData["start_date"] = format(
                        new Date(row.start_date),
                        "dd MMM yyyy"
                    );

                    normalisedData["end_date"] = format(
                        new Date(row.end_date),
                        "dd MMM yyyy"
                    );
                }

                if (column.key === "closed") {
                    normalisedData["closed"] = `${format(
                        new Date(row.created),
                        "dd MMM yyyy"
                    )} by ${
                        row.creator.first_name + " " + row.creator.last_name
                    }`;
                }

                if (
                    typeof normalisedData[column.key] === "object" &&
                    (column.key === "user_type" ||
                        column.key === "category" ||
                        column.key === "type" ||
                        column.key === "expert_institution" ||
                        column.key === "activity" ||
                        column.key === "region" ||
                        column.key === "country" ||
                        column.key === "mission_impact" ||
                        column.key === "programme_impact")
                )
                    normalisedData[column.key] = row[column.key]?.name;

                if (
                    column.key === "country" &&
                    row?.programme?.country?.name !== undefined
                ) {
                    normalisedData["country"] = row?.programme?.country?.name;
                }

                if (column.key === "main_correspondent") {
                    normalisedData["main_correspondent"] =
                        row.main_correspondent.first_name +
                        " " +
                        row.main_correspondent.last_name;
                    normalisedData["designation"] =
                        row.main_correspondent.designation;
                    normalisedData["email"] = row.main_correspondent.email;
                    normalisedData["contact_number"] =
                        row.main_correspondent.contact_number;
                    normalisedData["user_type"] =
                        row.main_correspondent.user_type.name;
                }

                if (
                    column.key === "ta_officers" ||
                    column.key === "ta_managers" ||
                    column.key === "internal_experts" ||
                    column.key === "external_experts" ||
                    column.key === "report_users"
                ) {
                    normalisedData[column.key] = [];
                    row[column.key].forEach((contact, x) => {
                        normalisedData[column.key][x] =
                            contact.first_name + " " + contact.last_name;
                    });
                }

                if (
                    column.key === "partners" ||
                    column.key === "internal_units" ||
                    column.key === "tax_types" ||
                    column.key === "departments" ||
                    column.key === "mission_departments" ||
                    column.key === "areas_of_support"
                ) {
                    normalisedData[column.key] = [];
                    row[column.key].forEach((meta, x) => {
                        normalisedData[column.key][x] = meta.name;
                    });
                }

                if (column.key === "status") {
                    if (
                        isAfter(new Date(), new Date(row.end_date)) &&
                        row.overall_progress < 100
                    ) {
                        normalisedData["status"] = (
                            <span
                                className={`inline-flex uppercase items-center px-2 py-0.5 rounded text-xs font-medium tag bg-red-800 text-white`}
                            >
                                {"OVERDUE"}
                            </span>
                        );
                    } else {
                        normalisedData["status"] = (
                            <span
                                className={`inline-flex uppercase items-center px-2 py-0.5 rounded text-xs font-medium tag ${
                                    SLIDER.filter(
                                        (x) =>
                                            x.MIN <= row.overall_progress &&
                                            x.MAX >= row.overall_progress
                                    )[0].STYLE
                                } text-white`}
                            >
                                {
                                    SLIDER.filter(
                                        (x) =>
                                            x.MIN <= row.overall_progress &&
                                            x.MAX >= row.overall_progress
                                    )[0].NAME
                                }
                            </span>
                        );
                    }
                }

                if (column.key === "progress") {
                    normalisedData["overall_progress"] = normalisedData[
                        "progress"
                    ] = (
                        <span
                            className={`inline-flex uppercase items-center px-2 py-0.5 rounded text-xs font-medium tag ${
                                SLIDER.filter(
                                    (x) =>
                                        x.MIN <= row.overall_progress &&
                                        x.MAX >= row.overall_progress
                                )[0].STYLE
                            } text-white`}
                        >
                            {row.overall_progress + "%"}
                        </span>
                    );
                }

                if (column.key === "created")
                    normalisedData["created"] = format(
                        new Date(row.created),
                        "dd MMM yyyy"
                    );

                if (column.key === "updated")
                    normalisedData["updated"] = format(
                        new Date(row.updated),
                        "dd MMM yyyy"
                    );
            });

            tableData[i]["normalised"] = normalisedData;
        });
        return tableData;
    };

    return {
        convertDateToString,
        formatPhoneNumber,
        getFullMonth,
        getDate,
        getDateTime,
        getTime,
        formatDate,
        formatTime,
        normaliseTableData,
    };
};
