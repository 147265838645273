import { useEffect, useState, useContext } from "react";
import Navigation from "components/navigation";
import Header from "components/header";
import PageHeader from "components/page-header";
import Table from "components/table";
import { ApiConsumer } from "api-consumer";
import { NavLink } from "react-router-dom";
import { API_ROUTES } from "api";
import { PORTAL } from "portal";
import { useTextFormatHook } from "hooks/TextFormatHook";
import { usePermissionsHook } from "hooks/PermissionsHook";
import { ActiveCountryContext } from "hooks/ActiveCountryContext";
import { createCache, deleteCache } from "hooks/cacheStorage";
import Alert from "components/alert";
import { Bar } from "components/bar";
import { Doughnut } from "components/doughnut";
import { StackedLine } from "components/stacked";
import CircularProgress from "@mui/material/CircularProgress";

export default function Dashboard() {
  let { normaliseTableData } = useTextFormatHook();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [countries, setCountries] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [search, setSearch] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  let { setActiveCountry } = useContext(ActiveCountryContext);
  let { userCountriesWithProgrammes } = usePermissionsHook();

  const searchParam = `?start_date=${startDate}&end_date=${endDate}`;
  const tableHeaders = [
    {
      key: "name",
      value: "Member Country",
      showOnTable: true,
      showOnPrint: true,
    },
    {
      key: "total_programmes",
      value: "Total Programmes",
      showOnTable: true,
      showOnPrint: true,
    },
    {
      key: "total_missions",
      value: "Total Missions",
      showOnTable: true,
      showOnPrint: true,
    },
    {
      key: "total_tasks",
      value: "Total Tasks",
      showOnTable: true,
      showOnPrint: true,
    },
  ];

  const addLinksToTableData = (tableData) => {
    tableData.forEach((row) => {
      row.normalised.name = (
        <NavLink
          to={`${PORTAL.PROGRAMMES_BY_COUNTRY}/${row.id}`}
          onClick={() => setActiveCountry(row)}
          className={`brand-link`}
        >
          {row.normalised.name}
        </NavLink>
      );
    });
    return tableData;
  };

  const loadData = () => {
    const countryApi = search
      ? API_ROUTES.CONTENT.COUNTRY + searchParam
      : API_ROUTES.CONTENT.COUNTRY;
    const programmeApi = search
      ? API_ROUTES.PROGRAMME.PROGRAMMES + searchParam
      : API_ROUTES.PROGRAMME.PROGRAMMES;
    ApiConsumer.get(countryApi)
      .then((res) => {
        res.data.sort((a, b) => a.name.localeCompare(b.name));

        let memberCountries = res.data.sort(
          (a, b) => b.total_programmes - a.total_programmes
        );

        ApiConsumer.get(programmeApi)
          .then((res) => {
            let normalisedData = normaliseTableData(
              tableHeaders,
              userCountriesWithProgrammes(memberCountries, res.data)
            );
            setCountries(addLinksToTableData(normalisedData));
          })
          .finally(() => {
            setLoaded(true);
          });
      })
      .catch((err) => {})
      .finally(() => {});
  };

  useEffect(() => {
    deleteCache('dateFilter');
  }, []);

  useEffect(() => {
    loadData();
    if (search) createCache('dateFilter', [startDate, endDate]);
    return () => {
      setCountries({});
      setLoaded(false);
      setSidebarOpen(false);
    };
  }, [startDate, endDate]);

  const getDate = (value) => {
    let day = value.getDate();
    day = String(day).length > 1 ? day : "0" + day;
    let month = value.getMonth() + 1;
    month = String(month).length > 1 ? month : "0" + month;
    const year = value.getFullYear();
    const finalDate = `${year}-${month}-${day}`;

    return finalDate;
  };

  const searchReport = (searchDate) => {
    if (searchDate.start_date && searchDate.end_date) {
      const startDate = getDate(searchDate.start_date);
      const endDate = getDate(searchDate.end_date);
      setStartDate(startDate);
      setEndDate(endDate);
      setSearch(true);
    } else if (!searchDate.end_date && searchDate.start_date) {
      //invalid case
    } else {
      setSearch(false);
      setStartDate("");
      setEndDate("");
      deleteCache('dateFilter');
    }
  };

  return (
    <>
      <div className="min-h-full bg-gray-100">
        <Navigation
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          filtered={search}
          searchParam={searchParam}
        />
        <div className="lg:pl-64 flex flex-col flex-1">
          <Header />
          <main className="flex-1 pb-8">
            <PageHeader
              pageHeaderName={`DASHBOARD - ATAF TA Management System`}
              pageName={"DASHBOARD"}
              loaded={loaded}
              searchReport={searchReport}
            />
            {!loaded && (
              <div
                style={{
                  margin: 0,
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(" - (50 % ", ") - (50 % ")"),
                }}
              >
                <CircularProgress size="3rem" color="success" />
              </div>
            )}
            {loaded &&
              (countries.length ? (
                <>
                  <div className="mx-full px-4 sm:px-6 lg:px-8 my-5 ">
                    <h3 className="mb-2">Programmes Overview</h3>
                    <div className="flex space-x-3">
                      <Doughnut
                        url={
                          search
                            ? API_ROUTES.PROGRAMME.GRAPHS.CATEGORY + searchParam
                            : API_ROUTES.PROGRAMME.GRAPHS.CATEGORY
                        }
                        title={"Programmes by Category"}
                      />

                      <Doughnut
                        url={
                          search
                            ? API_ROUTES.PROGRAMME.GRAPHS.TYPE + searchParam
                            : API_ROUTES.PROGRAMME.GRAPHS.TYPE
                        }
                        title={"Programmes by Type"}
                      />
                    </div>
                  </div>
                  <div className="mx-full px-4 sm:px-6 lg:px-8 my-5 ">
                    <div className="flex space-x-3">
                      <Doughnut
                        url={
                          search
                            ? API_ROUTES.PROGRAMME.GRAPHS.DEPARTMENT +
                              searchParam
                            : API_ROUTES.PROGRAMME.GRAPHS.DEPARTMENT
                        }
                        title={"Programmes by Specific Subject Matters"}
                      />

                      <Doughnut
                        url={
                          search
                            ? API_ROUTES.PROGRAMME.GRAPHS.MISSION_SUPPORT +
                              searchParam
                            : API_ROUTES.PROGRAMME.GRAPHS.MISSION_SUPPORT
                        }
                        title={"Missions by Area of Support"}
                        name={"type"}
                      />
                    </div>
                  </div>

                  <div className="mx-full px-4 sm:px-6 lg:px-8 my-5 ">
                    <h3 className="mb-2">Missions Overview</h3>
                    <div className="flex space-x-3">
                      <StackedLine
                        url={
                          search
                            ? API_ROUTES.PROGRAMME.GRAPHS.MISSION_TYPE +
                              searchParam
                            : API_ROUTES.PROGRAMME.GRAPHS.MISSION_TYPE
                        }
                        title={"Missions by Virtual / In Country"}
                      />
                    </div>
                  </div>

                  <div className="mx-full px-4 sm:px-6 lg:px-8 my-5 ">
                    <div className="flex space-x-3">
                      <Doughnut
                        url={
                          search
                            ? API_ROUTES.PROGRAMME.GRAPHS.MISSION_BY_PARTNER +
                              searchParam
                            : API_ROUTES.PROGRAMME.GRAPHS.MISSION_BY_PARTNER
                        }
                        title={
                          "Missions done with partner organizations (excludes ATAF only missions)"
                        }
                      />

                      <Doughnut
                        url={
                          search
                            ? API_ROUTES.PROGRAMME.GRAPHS.MISSION_BY_ACTIVITY +
                              searchParam
                            : API_ROUTES.PROGRAMME.GRAPHS.MISSION_BY_ACTIVITY
                        }
                        title={"Mission by Activities"}
                      />
                    </div>
                  </div>

                  <Bar
                    url={
                      search
                        ? API_ROUTES.CONTENT.COUNTRY + searchParam
                        : API_ROUTES.CONTENT.COUNTRY
                    }
                    title={"Programme and Missions Overview"}
                  />
                  <h2 className="flex-1 flex mx-full px-4 sm:px-6 lg:px-8 mt-8 text-lg leading-6 font-medium text-gray-900 text-left">
                    ATAF TA Programmes by Member Country
                  </h2>
                  <Table
                    tableTitle={`ATAF TA Programmes by Member Country`}
                    tableHeaders={tableHeaders}
                    tableData={countries}
                    editable={false}
                  />
                </>
              ) : (
                <div className="mx-full px-8 my-4 text-center">
                  <Alert
                    type={`danger`}
                    message={`There are no countries added.`}
                  />
                </div>
              ))}
          </main>
        </div>
      </div>
    </>
  );
}
