import { useState } from 'react'
import { XIcon } from '@heroicons/react/solid'

export default function Alert({ type, message, hasClose = false }) {
  const [showAlert, setShowAlert] = useState(true)

  const styles = {
    success: {
      container: 'bg-green-100',
      message: 'text-green-600',
      close:
        'text-green-500 bg-green-50 hover:bg-green-100 focus:ring-offset-green-50 focus:ring-green-600',
    },
    danger: {
      container: 'bg-red-100',
      message: 'text-red-600',
      close:
        'text-red-500 bg-red-50 hover:bg-red-100 focus:ring-offset-red-50 focus:ring-red-600',
    },
  }

  return (
    <>
      {showAlert && (
        <div className={`rounded-md p-4 ${styles[type].container}`}>
          <div className="flex">
            <div className="mx-2">
              <p className={`text-sm font-medium ${styles[type].message} `}>
                <span dangerouslySetInnerHTML={{ __html: message }} />
              </p>
            </div>
            {hasClose && (
              <div className="ml-auto pl-3">
                <div className="-mx-1.5 -my-1.5">
                  <button
                    type="button"
                    onClick={() => setShowAlert(false)}
                    className={`inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2 ${styles[type].close}`}
                  >
                    <span className="sr-only">Dismiss</span>
                    <XIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}
