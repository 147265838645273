const getDate = (value) => {
  let day = value.getDate();
  day = String(day).length > 1 ? day : "0" + day;
  let month = value.getMonth() + 1;
  month = String(month).length > 1 ? month : "0" + month;
  const year = value.getFullYear();
  const finalDate = `${year}-${month}-${day}`;

  return finalDate;
};

export const createCache = (name, value) => {
  const dataString = JSON.stringify(value);
  localStorage.setItem(name, dataString);
};

export const getCache = (name) => {
  const stringData = localStorage.getItem(name);
  if (stringData === null) return stringData;
  else {
    const dataObj = JSON.parse(stringData);

    return dataObj;
  }
};

export const deleteCache = (name) => {
  localStorage.removeItem(name);
};
