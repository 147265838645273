import { useState } from 'react'
import { useAuthentication } from 'hooks/AuthenticationHook'
import ModalProgrammeOutcome from 'components/modal-programme-outcome'
import Modal from 'components/modal'

export default function ViewProgrammeOutcome({ programme }) {
  const [programmeDetailsRow, setProgrammeDetailsRow] = useState({})
  const [viewProgrammeOutcome, setViewProgrammeOutcome] = useState(false)
  let { canViewReport } = useAuthentication()

  const modalHeaderIcons = []

  return canViewReport(programme) ? (
    <>
      <Modal
        modalHeaderTag={''}
        modalHeader={`Programme Outcome: ${programme?.name}`}
        modalHeaderIcons={modalHeaderIcons}
        modalContent={
          <ModalProgrammeOutcome
            setOpen={setViewProgrammeOutcome}
            programmeDetailsRow={programmeDetailsRow}
          />
        }
        open={viewProgrammeOutcome}
        setOpen={setViewProgrammeOutcome}
      />
      <div
        onClick={() => {
          setProgrammeDetailsRow(programme)
          setViewProgrammeOutcome(true)
        }}
        className={`button-default ml-2`}
        rel="noreferrer"
      >
        View Outcome
      </div>
    </>
  ) : (
    <span className="text-red-600 mt-2 text-sm">
      <em>(Report Restricted)</em>
    </span>
  )
}
