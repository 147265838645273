import { useEffect, useState, useContext } from "react";
import { PlusCircleIcon } from "@heroicons/react/outline";
import Navigation from "components/navigation";
import Header from "components/header";
import PageHeader from "components/page-header";
import Table from "components/table";
import Modal from "components/modal";
import Delete from "components/delete";
import AddEditCountry from "components/add-edit-country";
import { useTextFormatHook } from "hooks/TextFormatHook";
import { ApiConsumer } from "api-consumer";
import { Redirect, NavLink } from "react-router-dom";
import { API_ROUTES } from "api";
import { PORTAL } from "portal";
import { ActiveCountryContext } from "hooks/ActiveCountryContext";

export default function Countries() {
    let loggedInUser = JSON.parse(sessionStorage.getItem(`ATAFTAX`));
    let { normaliseTableData } = useTextFormatHook();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [countries, setCountries] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [openAddEditCountry, setOpenAddEditCountry] = useState(false);
    const [editableRow, setEditableRow] = useState({});
    const [rowUpdated, setRowUpdated] = useState(false);
    const [loadFlag, setLoadFlag] = useState(false);
    let { setActiveCountry } = useContext(ActiveCountryContext);

    const pageHeaderButtons = [
        <button
            type="button"
            onClick={() => setOpenAddEditCountry(true)}
            className={`button`}
        >
            <span className="button-icon">
                <PlusCircleIcon className="h-5 w-5" aria-hidden="true" />
            </span>
            Add Country
        </button>,
    ];

    const tableHeaders = [
        {
            key: "name",
            value: "Country",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "region",
            value: "Region",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "main_correspondent",
            value: "Main Correspondent",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "designation",
            value: "Designation",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "email",
            value: "Email Address",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "contact_number",
            value: "Contact Number",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "created",
            value: "Created",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "updated",
            value: "Updated",
            showOnTable: false,
            showOnPrint: true,
        },
    ];

    const addLinksToTableData = (tableData) => {
        tableData.forEach((row) => {
            row.normalised.name = (
                <>
                    <NavLink
                        to={`${PORTAL.PROGRAMMES_BY_COUNTRY}/${row.id}`}
                        onClick={() => setActiveCountry(row)}
                        className={`brand-link`}
                    >
                        {row.normalised.name +
                            " (" +
                            row.total_programmes +
                            ")"}
                    </NavLink>
                    <Delete
                        apiRoute={API_ROUTES.CONTENT.COUNTRY_DETAIL}
                        rowData={row}
                        loadData={loadData}
                        titleValue={"Country"}
                    />
                </>
            );
        });
        return tableData;
    };

    const loadData = () => {
        ApiConsumer.get(API_ROUTES.CONTENT.COUNTRY)
            .then((res) => {
                res.data.sort((a, b) => a.name.localeCompare(b.name));
                res.data.sort(
                    (a, b) => b.total_programmes - a.total_programmes
                );
                let normalisedData = normaliseTableData(tableHeaders, res.data);
                setCountries(addLinksToTableData(normalisedData));
            })
            .catch((err) => {})
            .finally(() => {
                setLoaded(true);
            });
    };

    useEffect(() => {
        loadData();
        return () => {
            setCountries({});
            setLoaded(false);
            setSidebarOpen(false);
            setOpenAddEditCountry(false);
            setEditableRow({});
            setRowUpdated(false);
        };
    }, []);

    const delay = (delayInms) => {
        return new Promise(resolve => setTimeout(resolve, delayInms));
      };

    const onCloseAddEditCountry = async (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            if (rowUpdated) {
                loadData();
                setLoadFlag(true);
                await delay(1500);
                setLoadFlag(false);
                setRowUpdated(false);
            }
            setEditableRow({});
        }
        setOpenAddEditCountry(modalStatus);
    };

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableRow).length === 0
            ? setOpenAddEditCountry(false)
            : setOpenAddEditCountry(true);
    }, [editableRow]);

    if (loggedInUser.user_type.name !== "Administrator")
        return <Redirect to={PORTAL.LOGIN} />;

    return (
        <>
            <Modal
                modalHeaderTag={""}
                modalHeader={
                    Object.keys(editableRow).length === 0
                        ? `Add New Country`
                        : `Edit Country: ${editableRow.name}`
                }
                modalContent={
                    <AddEditCountry
                        setOpen={onCloseAddEditCountry}
                        editableRow={editableRow}
                        setRowUpdated={setRowUpdated}
                        loading={loadFlag}
                    />
                }
                open={openAddEditCountry}
                setOpen={onCloseAddEditCountry}
            />

            <div className="min-h-full bg-gray-100">
                <Navigation
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                />

                <div className="lg:pl-64 flex flex-col flex-1">
                    <Header />

                    <main className="flex-1 pb-8">
                        <PageHeader
                            pageHeaderName={`ATAF Member Countries (${countries?.length})`}
                            pageHeaderButtons={pageHeaderButtons}
                        />

                        {loaded && (
                            <Table
                                tableTitle={`ATAF Member Countries`}
                                tableHeaders={tableHeaders}
                                tableData={countries}
                                editable={true}
                                setEditableRow={setEditableRow}
                            />
                        )}
                    </main>
                </div>
            </div>
        </>
    );
}
