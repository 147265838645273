import { useContext } from "react";
import { AuthenticationContext } from "hooks/AuthenticationContext";

export const usePermissionsHook = () => {
  let { currentUser } = useContext(AuthenticationContext);

  const userCountriesWithProgrammes = (countries, programmes) => {
    let userProgrammes = programmesAssignedToUser(programmes);
    let userProgrammesByCountry = [];

    userProgrammesByCountry = countries.filter((country) => {
      let programmesByCountry = userProgrammes.filter(
        (programme) => programme.country.id === country.id
      );
      if (currentUser.user_type.name !== "Administrator")
        country.total_programmes = programmesByCountry.length;
      return programmesByCountry.length !== 0 ? true : false;
    });

    return currentUser.user_type.name !== "Administrator" &&
      currentUser.user_type.name !== "Internal Expert" &&
      currentUser.user_type.name !== "Auditor"
      ? userProgrammesByCountry
      : countries;
  };

  const programmesAssignedToUser = (programmes) => {
    let programmesAssignedToUser = [];
    programmesAssignedToUser = programmes.filter((programme) => {
      let isManager = programme.ta_managers.filter(
        (user) => user.id === currentUser.id
      );
      let isOfficer = programme.ta_officers.filter(
        (user) => user.id === currentUser.id
      );
      let isInternalExpertsMissionManager = programme.internal_experts.filter(
        (user) => user.id === currentUser.id
      );
      let isExternalExpertsMissionManager = programme.external_experts.filter(
        (user) => user.id === currentUser.id
      );

      if (
        currentUser.user_type.name === "Administrator" ||
        currentUser.user_type.name === "Auditor"
      )
        return true;

      return isManager.length === 0 &&
        isOfficer.length === 0 &&
        isInternalExpertsMissionManager.length === 0 &&
        isExternalExpertsMissionManager.length === 0
        ? false
        : true;
    });
    return programmesAssignedToUser;
  };

  const missionsAssignedToUser = (missions) => {
    let missionsAssignedToUser = [];
    missionsAssignedToUser = missions.filter((mission) => {
      let isManager = mission.programme.ta_managers.filter(
        (user) => user.id === currentUser.id
      );
      let isOfficer = mission.programme.ta_officers.filter(
        (user) => user.id === currentUser.id
      );
      let isInternalExpertsMissionManager =
        mission.programme.internal_experts.filter(
          (user) => user.id === currentUser.id
        );
      let isExternalExpertsMissionManager =
        mission.programme.external_experts.filter(
          (user) => user.id === currentUser.id
        );

      let isInternalExpert = mission.internal_experts.filter(
        (user) => user.id === currentUser.id
      );
      let isExternalExpert = mission.external_experts.filter(
        (user) => user.id === currentUser.id
      );

      if (currentUser.user_type.name === "Administrator") return true;
      if (currentUser.user_type.name === "Auditor") return true;

      return isManager.length === 0 &&
        isOfficer.length === 0 &&
        isInternalExpertsMissionManager.length === 0 &&
        isExternalExpertsMissionManager.length === 0 &&
        isInternalExpert.length === 0 &&
        isExternalExpert.length === 0
        ? false
        : true;
    });
    return missionsAssignedToUser;
  };

  const userProgrammesWithMissions = (countries, missions) => {
    let userProgrammes = missionsAssignedToUser(missions);
    let userProgrammesByCountry = [];

    userProgrammesByCountry = countries.filter((country) => {
      let programmesByCountry = userProgrammes.filter(
        (programme) => programme.country.id === country.id
      );
      if (currentUser.user_type.name !== "Administrator")
        country.total_programmes = programmesByCountry.length;
      return programmesByCountry.length !== 0 ? true : false;
    });

    return currentUser.user_type.name !== "Administrator" &&
      currentUser.user_type.name !== "Auditor"
      ? userProgrammesByCountry
      : countries;
  };

  return {
    programmesAssignedToUser,
    userCountriesWithProgrammes,
    missionsAssignedToUser,
    userProgrammesWithMissions,
  };
};
