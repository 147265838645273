export const PORTAL = {
    LOGIN: `/login`,
    FORGOTPASSWORD: `/forgot-password`,
    DASHBOARD: `/`,
    SEARCH: `/search`,
    PROGRAMMES: `/programmes`,
    PROGRAMMES_BY_COUNTRY: `/programmes/country`,
    PROGRAMMES_BY_USER: `/programmes/user`,
    PROGRAMMES_BY_DEPARTMENT: `/programmes/department`,
    PROGRAMMES_BY_TYPE: `/programmes/type`,
    MISSIONS: `/missions`,
    MISSIONS_BY_PROGRAMME: `/missions/programme`,
    MISSIONS_BY_USER: `/missions/user`,
    MISSION: `/tasks/mission`,
    TASK: `/tasks/mission/task`,
    COUNTRIES: `/countries`,
    DEPARTMENTS: `/departments`,
    META: `/meta`,
    ACTIVITY: `/activity`,
    REPORTS: `/reports`,
    PROGRAMME_REPORTS: `/reports/programmes`,
    MISSION_REPORTS: `/reports/missions`,
    USERS: `/users`,
}
