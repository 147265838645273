import { useEffect, useState, Fragment, useContext } from "react";
import { PORTAL } from "portal";
import { NavLink, useRouteMatch, useLocation } from "react-router-dom";
import { PlusCircleIcon } from "@heroicons/react/outline";
import { compareDesc } from "date-fns";
import { useTextFormatHook } from "hooks/TextFormatHook";
import { AuthenticationContext } from "hooks/AuthenticationContext";
import Navigation from "components/navigation";
import Header from "components/header";
import PageHeader from "components/page-header";
import CardWide from "components/card-wide";
import AddEditProgramme from "components/add-edit-programme";
import Modal from "components/modal";
import Alert from "components/alert";
import TableHeader from "components/table-header";
import { ApiConsumer } from "api-consumer";
import { API_ROUTES } from "api";

import { Doughnut } from "components/doughnut";
import { useAuthentication } from "hooks/AuthenticationHook";
import { PERMISSIONS } from "hooks/permissions";
import { AppLoader } from "components/app-loader";
import { AppLoaderContext } from "hooks/AppLoaderContext";
import { usePermissionsHook } from "hooks/PermissionsHook";
import { getCache, createCache, deleteCache } from "hooks/cacheStorage";

export default function Programmes() {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const searchTerm = searchParams.get("search");
  let { setLoading } = useContext(AppLoaderContext);
  let { programmesAssignedToUser } = usePermissionsHook();
  const [searched, setSearched] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const searchParam = `?start_date=${startDate}&end_date=${endDate}`;
  /* Programmes by country */
  const programmesByCountry = useRouteMatch(
    PORTAL.PROGRAMMES_BY_COUNTRY + "/:country_id"
  );
  let countryId = programmesByCountry?.params?.country_id;

  /* Programmes by country and category */
  const programmesByCountryCategory = useRouteMatch(
    PORTAL.PROGRAMMES_BY_COUNTRY + "/:country_id/category/:category_id"
  );
  let categoryId = programmesByCountryCategory?.params?.category_id;

  /* Programmes by department */
  const programmesByDepartment = useRouteMatch(
    PORTAL.PROGRAMMES_BY_DEPARTMENT + "/:department_id"
  );
  let departmentId = programmesByDepartment?.params?.department_id;

  /* Programmes by user */
  const programmesByUser = useRouteMatch(
    PORTAL.PROGRAMMES_BY_USER + "/:user_id"
  );
  let userId = programmesByUser?.params?.user_id;

  /* Set programme API url */
  let programmeAPIURL = searched
    ? API_ROUTES.PROGRAMME.PROGRAMMES + searchParam
    : API_ROUTES.PROGRAMME.PROGRAMMES;

  if (countryId)
    programmeAPIURL = searched
      ? API_ROUTES.PROGRAMME.PROGRAMMES_BY_COUNTRY(countryId) + searchParam
      : API_ROUTES.PROGRAMME.PROGRAMMES_BY_COUNTRY(countryId);
  if (countryId && categoryId) {
    programmeAPIURL = searched
      ? API_ROUTES.PROGRAMME.PROGRAMMES_BY_COUNTRYCATEGORY(
          countryId,
          categoryId
        ) + searchParam
      : API_ROUTES.PROGRAMME.PROGRAMMES_BY_COUNTRYCATEGORY(
          countryId,
          categoryId
        );
  }
  if (departmentId)
    programmeAPIURL = searched
      ? API_ROUTES.PROGRAMME.PROGRAMMES_BY_DEPARTMENT(departmentId) +
        searchParam
      : API_ROUTES.PROGRAMME.PROGRAMMES_BY_DEPARTMENT(departmentId);
  if (userId)
    programmeAPIURL = searched
      ? API_ROUTES.PROGRAMME.PROGRAMMES_BY_USER(userId) + searchParam
      : API_ROUTES.PROGRAMME.PROGRAMMES_BY_USER(userId);

  let { hasPermission } = useAuthentication();

  let { normaliseTableData } = useTextFormatHook();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [country, setCountry] = useState({});
  const [programmes, setProgrammes] = useState([]);
  const [department, setDepartment] = useState({});
  const [category, setCategory] = useState({});
  const [user, setUser] = useState({});
  const [openAddEditProgramme, setOpenAddEditProgramme] = useState(false);
  const [editableRow, setEditableRow] = useState({});
  const [rowUpdated, setRowUpdated] = useState(false);
  const [filterString, setFilterString] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const PROGRAMME_HEADERS = [
    {
      key: "name",
      value: "Programme",
      showOnTable: true,
      showOnPrint: true,
    },
    {
      key: "country",
      value: "Country",
      showOnTable: true,
      showOnPrint: true,
    },
    {
      key: "overview",
      value: "Brief overview",
      showOnTable: false,
      showOnPrint: false,
    },
    {
      key: "workplan",
      value: "Workplan",
      showOnTable: false,
      showOnPrint: false,
    },
    { key: "type", value: "Type", showOnTable: true, showOnPrint: true },
    {
      key: "category",
      value: "Category",
      showOnTable: true,
      showOnPrint: true,
    },
    {
      key: "departments",
      value: "Specific Subject Matter",
      showOnTable: true,
      showOnPrint: true,
    },
    {
      key: "tax_types",
      value: "Tax Types",
      showOnTable: true,
      showOnPrint: true,
    },
    {
      key: "internal_units",
      value: "Internal Units",
      showOnTable: true,
      showOnPrint: true,
    },
    {
      key: "duration",
      value: "Duration",
      showOnTable: true,
      showOnPrint: false,
    },
    {
      key: "start_date",
      value: "Start Date",
      showOnTable: true,
      showOnPrint: true,
    },
    {
      key: "end_date",
      value: "End Date",
      showOnTable: true,
      showOnPrint: true,
    },
    {
      key: "overall_progress",
      value: "Progress",
      showOnTable: true,
      showOnPrint: true,
    },
    {
      key: "status",
      value: "Status",
      showOnTable: true,
      showOnPrint: false,
    },
    {
      key: "ta_managers",
      value: "Country Programmes Manager",
      showOnTable: true,
      showOnPrint: true,
    },
    {
      key: "ta_officers",
      value: "CP Programme Coordinator",
      showOnTable: true,
      showOnPrint: true,
    },
    {
      key: "internal_experts",
      value: "Internal expert(s) able to manage missions",
      showOnTable: true,
      showOnPrint: true,
    },
    {
      key: "external_experts",
      value: "External expert(s) able to manage missions",
      showOnTable: true,
      showOnPrint: true,
    },
    {
      key: "report_users",
      value: "View programme reports",
      showOnTable: true,
      showOnPrint: true,
    },
    {
      key: "created",
      value: "Created",
      showOnTable: false,
      showOnPrint: false,
    },
    {
      key: "updated",
      value: "Updated",
      showOnTable: false,
      showOnPrint: false,
    },
  ];

  const pageHeaderButtons = [
    hasPermission(PERMISSIONS.PROGRAMMES.CAN_ADD_PROGRAMME) &&
    country?.name !== undefined ? (
      <button
        type="button"
        onClick={() => setOpenAddEditProgramme(true)}
        className={`button`}
      >
        <span className="button-icon">
          <PlusCircleIcon className="h-5 w-5" aria-hidden="true" />
        </span>
        Add Programme
      </button>
    ) : null,
  ];

  const addLinksToTableData = (tableData) => {
    tableData.forEach((row) => {
      row.normalised.name = (
        <NavLink
          to={`${PORTAL.MISSIONS_BY_PROGRAMME}/${row.id}`}
          className={`brand-link underline`}
        >
          {row.normalised.name}
        </NavLink>
      );
    });
    return tableData;
  };

  useEffect(() => {
    const cacheData = getCache("dateFilter");
    if (cacheData) {
      setStartDate(cacheData[0]);
      setEndDate(cacheData[1]);
      setSearched(true);
    }
  }, []);

  const loadData = () => {
    setLoading(true);
    const cacheData = getCache("dateFilter");
    if ((cacheData && startDate) || (!cacheData)) {
      ApiConsumer.get(programmeAPIURL)
      .then((res) => {
        res.data.sort((a, b) =>
          compareDesc(new Date(a.created), new Date(b.created))
        );
        //Group by "id" to avoid duplicates
        res.data = res.data.filter(
          (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
        );

        if (
          (programmeAPIURL === API_ROUTES.PROGRAMME.PROGRAMMES ||
            programmeAPIURL ===
              API_ROUTES.PROGRAMME.PROGRAMMES + searchParam) &&
          searchTerm !== null &&
          searchTerm !== ""
        ) {
          let programmes = res.data;
          res.data = programmes.filter((p) =>
            p.name.toLowerCase().includes(searchTerm.toLowerCase())
          );
        }

        let userProgrammes = programmesAssignedToUser(res.data);

        let normalisedData = normaliseTableData(
          PROGRAMME_HEADERS,
          userProgrammes
        );

        setProgrammes(addLinksToTableData(normalisedData));
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    loadData();
    return () => {
      setSidebarOpen(false);
      setCountry({});
      setDepartment({});
      setProgrammes([]);
      setCategory({});
      setUser({});
      setOpenAddEditProgramme(false);
      setEditableRow({});
      setRowUpdated(false);
      setFilterString("");
      setFilteredData([]);
    };
  }, [
    programmeAPIURL,
    countryId,
    categoryId,
    departmentId,
    userId,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    if (countryId) {
      const url = searched
        ? API_ROUTES.CONTENT.COUNTRY_DETAIL(countryId) + searchParam
        : API_ROUTES.CONTENT.COUNTRY_DETAIL(countryId);
      ApiConsumer.get(url)
        .then((res) => {
          setCountry(res.data);
        })
        .catch((err) => {})
        .finally(() => {});
    }
  }, [countryId, programmeAPIURL, startDate, endDate]);

  useEffect(() => {
    if (departmentId) {
      ApiConsumer.get(API_ROUTES.PROGRAMME.DEPARTMENT_DETAIL(departmentId))
        .then((res) => {
          setDepartment(res.data);
        })
        .catch((err) => {})
        .finally(() => {});
    }
  }, [departmentId, programmeAPIURL, startDate, endDate]);

  useEffect(() => {
    if (categoryId) {
      ApiConsumer.get(API_ROUTES.PROGRAMME.CATEGORY_DETAIL(categoryId))
        .then((res) => {
          setCategory(res.data);
        })
        .catch((err) => {});
    }
  }, [categoryId, programmeAPIURL, startDate, endDate]);

  useEffect(() => {
    if (userId) {
      ApiConsumer.get(API_ROUTES.USERS.USER_DETAIL(userId))
        .then((res) => {
          setUser(res.data);
        })
        .catch((err) => {})
        .finally(() => {});
    }
  }, [userId, programmeAPIURL, startDate, endDate]);

  const onCloseAddEditProgramme = (modalStatus) => {
    //Modal closing
    if (modalStatus === false) {
      setEditableRow({});
      if (rowUpdated) loadData();
    }
    setOpenAddEditProgramme(modalStatus);
  };

  useEffect(() => {
    //Triggering table row Edit button
    Object.keys(editableRow).length === 0
      ? setOpenAddEditProgramme(false)
      : setOpenAddEditProgramme(true);
  }, [editableRow]);

  const getDate = (value) => {
    let day = value.getDate();
    day = String(day).length > 1 ? day : "0" + day;
    let month = value.getMonth() + 1;
    month = String(month).length > 1 ? month : "0" + month;
    const year = value.getFullYear();
    const finalDate = `${year}-${month}-${day}`;

    return finalDate;
  };

  const searchReport = (searchDate) => {
    if (searchDate.start_date && searchDate.end_date) {
      const startDate = getDate(searchDate.start_date);
      const endDate = getDate(searchDate.end_date);
      setStartDate(startDate);
      setEndDate(endDate);
      setSearched(true);
      deleteCache('dateFilter');
    } else if (!searchDate.end_date && searchDate.start_date) {
      //invalid case
    } else {
      setSearched(false);
      setStartDate("");
      setEndDate("");
      deleteCache('dateFilter');
    }
  };

  return (
    <>
      <Modal
        modalHeaderTag={country?.name !== undefined ? country.name : ""}
        modalHeader={
          Object.keys(editableRow).length === 0
            ? `Add New Programme`
            : `Edit Programme: ${editableRow.name}`
        }
        modalContent={
          <AddEditProgramme
            countryId={countryId}
            setOpen={onCloseAddEditProgramme}
            editableRow={editableRow}
            setRowUpdated={setRowUpdated}
          />
        }
        open={openAddEditProgramme}
        setOpen={onCloseAddEditProgramme}
      />
      <div className="min-h-full bg-gray-100">
        <Navigation 
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          filtered={searched}
          searchParam={searchParam}
          pageName="programmes"
        />
        <div className="lg:pl-64 flex flex-col flex-1">
          <Header />
          <main className="flex-1 pb-8">
            <PageHeader
              pageHeaderName={`${
                country?.name !== undefined ? country.name + " " : ""
              }${category?.name ? category?.name + " " : ""}${
                department?.name !== undefined ? department.name + " " : ""
              }${
                user?.first_name !== undefined && user?.last_name !== undefined
                  ? user.first_name + " " + user.last_name + " "
                  : ""
              }Programmes (${programmes?.length}) ${
                searchTerm !== null && searchTerm !== ""
                  ? ' Search results for "' + searchTerm + '"'
                  : ""
              }`}
              pageHeaderButtons={pageHeaderButtons}
              pageName="PROGRAMMES"
              loaded={setLoading}
              searchReport={searchReport}
            />
            {countryId !== undefined && (
              <>
                <div className="mx-full px-4 sm:px-6 lg:px-8 my-5 ">
                  <div className="flex space-x-3">
                    <Doughnut
                      url={
                        searched
                          ? API_ROUTES.PROGRAMME.GRAPHS.COUNTRY.CATEGORY(
                              countryId
                            ) + searchParam
                          : API_ROUTES.PROGRAMME.GRAPHS.COUNTRY.CATEGORY(
                              countryId
                            )
                      }
                      title={"Programmes by Category"}
                    />
                    <Doughnut
                      url={
                        searched
                          ? API_ROUTES.PROGRAMME.GRAPHS.COUNTRY.TYPE(
                              countryId
                            ) + searchParam
                          : API_ROUTES.PROGRAMME.GRAPHS.COUNTRY.TYPE(countryId)
                      }
                      title={"Programmes by Type"}
                    />
                    <Doughnut
                      url={
                        searched
                          ? API_ROUTES.PROGRAMME.GRAPHS.COUNTRY.DEPARTMENT(
                              countryId
                            ) + searchParam
                          : API_ROUTES.PROGRAMME.GRAPHS.COUNTRY.DEPARTMENT(
                              countryId
                            )
                      }
                      title={"Programmes by Specific Subject Matters"}
                    />
                  </div>
                </div>
              </>
            )}

            {hasPermission(PERMISSIONS.PROGRAMMES.CAN_VIEW_PROGRAMME) ? (
              <div className="relative">
                <AppLoader
                  pageContent={
                    programmes.length ? (
                      <>
                        <TableHeader
                          tableTitle={`${
                            country?.name !== undefined
                              ? country.name + " "
                              : ""
                          }${category?.name ? category?.name + " " : ""}${
                            department?.name !== undefined
                              ? department.name + " "
                              : ""
                          }${
                            user?.first_name !== undefined &&
                            user?.last_name !== undefined
                              ? user.first_name + " " + user.last_name + " "
                              : ""
                          }Programmes`}
                          tableHeaders={PROGRAMME_HEADERS}
                          tableData={programmes}
                          filterString={filterString}
                          setFilterString={setFilterString}
                          filteredData={filteredData}
                          setFilteredData={setFilteredData}
                        />

                        {(filterString.length || filteredData.length
                          ? filteredData
                          : programmes
                        ).map((programme, i) => (
                          <Fragment key={i}>
                            <CardWide
                              row={programme}
                              editable={true}
                              setEditableRow={setEditableRow}
                              reloadProgrammeData={loadData}
                              tableHeaders={PROGRAMME_HEADERS}
                            />
                          </Fragment>
                        ))}
                      </>
                    ) : (
                      <div className="mx-full px-8 my-4 text-center">
                        <Alert
                          type={`danger`}
                          message={`There are no programmes added under ${
                            country?.name !== undefined ? country.name : ""
                          }
                        ${category?.name ? category?.name : ""}
                        ${department?.name !== undefined ? department.name : ""}
                        ${
                          user?.first_name !== undefined &&
                          user?.last_name !== undefined
                            ? user.first_name + " " + user.last_name
                            : ""
                        }
                        ${
                          searchTerm !== null && searchTerm !== ""
                            ? searchTerm
                            : ""
                        }`}
                        />
                      </div>
                    )
                  }
                />
              </div>
            ) : (
              <div className="mx-full px-8 my-4 text-center">
                <Alert
                  type={`danger`}
                  message={`You do not have permission to view programmes. Please contact the system administrator.`}
                />
              </div>
            )}
          </main>
        </div>
      </div>
    </>
  );
}
