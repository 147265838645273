import { useState, useEffect } from "react";
import { TrashIcon } from "@heroicons/react/outline";
import Modal from "components/modal";
import ConfirmDelete from "components/confirm-delete";

export default function Delete({ rowData, loadData, apiRoute, titleValue }) {
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [rowToDelete, setRowToDelete] = useState({});
    const [rowDeleted, setRowDeleted] = useState(false);
    const [deleteAPILink, setDeleteAPILink] = useState("");

    const onClose = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setRowToDelete({});
            if (rowDeleted) loadData();
        }
        setOpenConfirmDelete(modalStatus);
    };

    useEffect(() => {
        return () => {
            setOpenConfirmDelete(false);
            setRowToDelete({});
            setRowDeleted(false);
        };
    }, []);

    useEffect(() => {
        //Triggering table row Edit button

        Object.keys(rowToDelete).length === 0
            ? setOpenConfirmDelete(false)
            : setOpenConfirmDelete(true);
    }, [rowToDelete]);

    return (
        <>
            <Modal
                modalHeaderTag={""}
                modalHeader={`Delete ${titleValue}${
                    rowToDelete.name ? `: ` + rowToDelete.name : ``
                }`}
                modalContent={
                    <ConfirmDelete
                        setOpen={onClose}
                        rowToDelete={rowToDelete}
                        setRowDeleted={setRowDeleted}
                        deleteAPILink={deleteAPILink}
                        deleteDesc={
                            rowToDelete.comment
                                ? `Comment: "` + rowToDelete.comment + `"`
                                : rowToDelete.name
                        }
                    />
                }
                open={openConfirmDelete}
                setOpen={onClose}
            />

            <button type="button" className="icon-transparent float-right">
                <TrashIcon
                    className="h-5 w-5 m-auto"
                    aria-hidden="true"
                    onClick={() => {
                        setDeleteAPILink(apiRoute(rowData.id));
                        setRowToDelete(rowData);
                    }}
                />
            </button>
        </>
    );
}
