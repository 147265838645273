import {
    BrowserRouter as Router,
    Switch,
    Route,
    useHistory,
} from "react-router-dom";
import { useEffect, useState } from "react";
import PrivateRoute from "private-route";
import { AppLoaderContext } from "hooks/AppLoaderContext";
import { AuthenticationContext } from "hooks/AuthenticationContext";
import { ActiveCountryContext } from "hooks/ActiveCountryContext";
import Login from "login";
import ForgotPassword from "forgot-password";
import Departments from "departments";
import Users from "users";
import Meta from "meta";
import Activity from "activity";
import Dashboard from "dashboard";
import Countries from "countries";
import Programmes from "programmes";
import Missions from "missions";
import Mission from "mission";
import Reports from "reports";
import ProgrammeReports from "programme-reports";
import MissionReports from "mission-reports";
import { PORTAL } from "portal";
import { NotificationContext } from "hooks/NotificationContext";
import NotificationsWrapper from "components/notification-wrapper";
import Notifications from "components/notifications";

export default function App() {
    const [loading, setLoading] = useState(false);
    const [showNotifications, setNotification] = useState({
        panel: false,
        popup: false,
    });
    const [currentUser, setCurrentUser] = useState({});
    const [activeCountry, setActiveCountry] = useState({});
    let history = useHistory();

    let loggedInUser = JSON.parse(sessionStorage.getItem(`ATAFTAX`));
    if (
        Object.keys(currentUser).length === 0 &&
        loggedInUser !== null &&
        loggedInUser !== undefined
    ) {
        setCurrentUser(loggedInUser);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [history]);

    return (
        <>
            <Router history={history}>
                <AppLoaderContext.Provider value={{ loading, setLoading }}>
                    <AuthenticationContext.Provider
                        value={{ currentUser, setCurrentUser }}
                    >
                        <ActiveCountryContext.Provider
                            value={{ activeCountry, setActiveCountry }}
                        >
                            <NotificationContext.Provider
                                value={{ showNotifications, setNotification }}
                            >
                                <NotificationsWrapper />
                                <Notifications />
                                <Switch>
                                    <Route
                                        exact
                                        path={PORTAL.LOGIN}
                                        component={Login}
                                    />
                                    <Route
                                        exact
                                        path={PORTAL.FORGOTPASSWORD}
                                        component={ForgotPassword}
                                    />
                                    <PrivateRoute
                                        exact
                                        path={PORTAL.DASHBOARD}
                                        component={Dashboard}
                                    />
                                    <PrivateRoute
                                        exact
                                        path={PORTAL.PROGRAMMES}
                                        component={Programmes}
                                    />
                                    <PrivateRoute
                                        exact
                                        path={
                                            PORTAL.PROGRAMMES_BY_COUNTRY +
                                            `/:country_id([a-zA-Z0-9-]+)`
                                        }
                                        component={Programmes}
                                    />
                                    <PrivateRoute
                                        exact
                                        path={
                                            PORTAL.PROGRAMMES_BY_DEPARTMENT +
                                            `/:department_id([a-zA-Z0-9-]+)`
                                        }
                                        component={Programmes}
                                    />
                                    <PrivateRoute
                                        exact
                                        path={
                                            PORTAL.PROGRAMMES_BY_USER +
                                            `/:user_id([a-zA-Z0-9-]+)`
                                        }
                                        component={Programmes}
                                    />
                                    <PrivateRoute
                                        exact
                                        path={
                                            PORTAL.PROGRAMMES_BY_COUNTRY +
                                            `/:country_id([a-zA-Z0-9-]+)/category/:category_id([a-zA-Z0-9-]+)`
                                        }
                                        component={Programmes}
                                    />
                                    <PrivateRoute
                                        exact
                                        path={PORTAL.MISSIONS}
                                        component={Missions}
                                    />
                                    <PrivateRoute
                                        exact
                                        path={
                                            PORTAL.MISSIONS_BY_PROGRAMME +
                                            `/:programme_id([a-zA-Z0-9-]+)`
                                        }
                                        component={Missions}
                                    />
                                    <PrivateRoute
                                        exact
                                        path={
                                            PORTAL.MISSIONS_BY_USER +
                                            `/:user_id([a-zA-Z0-9-]+)`
                                        }
                                        component={Missions}
                                    />
                                    <PrivateRoute
                                        exact
                                        path={
                                            PORTAL.MISSION +
                                            `/:mission_id([a-zA-Z0-9-]+)`
                                        }
                                        component={Mission}
                                    />
                                    <PrivateRoute
                                        exact
                                        path={PORTAL.REPORTS}
                                        component={Reports}
                                    />
                                    <PrivateRoute
                                        exact
                                        path={PORTAL.PROGRAMME_REPORTS}
                                        component={ProgrammeReports}
                                    />
                                    <PrivateRoute
                                        exact
                                        path={PORTAL.MISSION_REPORTS}
                                        component={MissionReports}
                                    />

                                    <PrivateRoute
                                        exact
                                        path={PORTAL.COUNTRIES}
                                        component={Countries}
                                    />
                                    <PrivateRoute
                                        exact
                                        path={PORTAL.DEPARTMENTS}
                                        component={Departments}
                                    />
                                    <PrivateRoute
                                        exact
                                        path={
                                            PORTAL.USERS +
                                            `/:type([a-zA-Z0-9-]+)?`
                                        }
                                        component={Users}
                                    />
                                    <PrivateRoute
                                        exact
                                        path={PORTAL.META}
                                        component={Meta}
                                    />
                                    <PrivateRoute
                                        exact
                                        path={PORTAL.ACTIVITY}
                                        component={Activity}
                                    />
                                </Switch>
                            </NotificationContext.Provider>
                        </ActiveCountryContext.Provider>
                    </AuthenticationContext.Provider>
                </AppLoaderContext.Provider>
            </Router>
        </>
    );
}
