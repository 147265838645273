import { useState, useEffect } from "react";
import { useAuthentication } from "hooks/AuthenticationHook";
import { PERMISSIONS } from "hooks/permissions";
import AddEditProgrammeOutcomes from "components/add-edit-programme-outcomes";
import Modal from "components/modal";

export default function CloseProgramme({ programme, reloadProgrammeData }) {
    const [editableRow, setEditableRow] = useState({});
    const [rowUpdated, setRowUpdated] = useState(false);
    let { hasPermission } = useAuthentication();

    const [openAddEditProgrammeOutcomes, setOpenAddEditProgrammeOutcomes] =
        useState(false);

    const onCloseAddEditProgrammeOutcomes = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditableRow({});
            if (rowUpdated) reloadProgrammeData();
        }
        setOpenAddEditProgrammeOutcomes(modalStatus);
    };

    useEffect(() => {
        return () => {
            setOpenAddEditProgrammeOutcomes(false);
            setEditableRow({});
            setRowUpdated(false);
        };
    }, []);

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableRow).length === 0
            ? setOpenAddEditProgrammeOutcomes(false)
            : setOpenAddEditProgrammeOutcomes(true);
    }, [editableRow]);

    return (
        hasPermission(PERMISSIONS.PROGRAMMES.CAN_CLOSE_PROGRAMME) && (
            <>
                <Modal
                    modalHeaderTag={""}
                    modalHeader={
                        Object.keys(editableRow).length === 0
                            ? `Add Programme Outcome`
                            : `Edit Programme Outcome`
                    }
                    modalContent={
                        <AddEditProgrammeOutcomes
                            programme={programme}
                            setOpen={onCloseAddEditProgrammeOutcomes}
                            editableRow={editableRow}
                            setRowUpdated={setRowUpdated}
                            setEditableRow={setEditableRow}
                        />
                    }
                    open={openAddEditProgrammeOutcomes}
                    setOpen={onCloseAddEditProgrammeOutcomes}
                />
                <div
                    onClick={() => {
                        setOpenAddEditProgrammeOutcomes(true);
                    }}
                    className={`button-default ml-2`}
                    rel="noreferrer"
                >
                    Close Programme
                </div>
            </>
        )
    );
}
