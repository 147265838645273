export const PERMISSIONS = {
    PROGRAMMES: {
        CAN_ADD_PROGRAMME: 'add_programme',
        CAN_EDIT_PROGRAMME: 'change_programme',
        CAN_CLOSE_PROGRAMME: 'close_programme',
        CAN_VIEW_PROGRAMME: 'view_programme',
        CAN_DELETE_PROGRAMME: 'delete_programme'
    },
    MISSIONS: {
        CAN_ADD_MISSION: 'add_mission',
        CAN_EDIT_MISSION: 'change_mission',
        CAN_CLOSE_MISSION: 'close_mission',
        CAN_VIEW_MISSION: 'view_mission',
        CAN_DELETE_MISSION: 'delete_mission',
    },
    TASKS: {
        CAN_ADD_TASK: 'add_task',
        CAN_EDIT_TASK: 'change_task',
        CAN_VIEW_TASK: 'view_task',
        CAN_DELETE_TASK: 'delete_task',
    },
    PERMISSIONS: {
        CAN_ADD_PERMISSION: 'add_permission',
        CAN_EDIT_PERMISSION: 'change_permission',
        CAN_DELETE_PERMISSION: 'delete_permission',
        CAN_VIEW_PERMISSION: 'view_permission'
    },
}
