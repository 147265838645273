import { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import { LockClosedIcon } from "@heroicons/react/solid";
import axios from "axios";
import { API_ROUTES } from "api";
import { useFormValidation } from "hooks/FormValidationHook";
import PageHeaderExternal from "components/page-header-external";
import Alert from "components/alert";
import { PORTAL } from "portal";
import { AppLoader } from "components/app-loader";
import { AppLoaderContext } from "hooks/AppLoaderContext";

export default function ForgotPassword() {
    let { setLoading } = useContext(AppLoaderContext);
    let { validFormDetails } = useFormValidation();
    const [invalidFormDetails, setInvalidFormDetails] = useState({});
    const [formDetails, setFormDetails] = useState({});
    const [formError, setFormError] = useState("");
    const [formRequested, setFormRequested] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    let formFields = [
        formRequested
            ? ({ name: "email", display: "email address" },
              { name: "otp", display: "OTP" },
              { name: "password", display: "password" })
            : { name: "email", display: "email address" },
    ];

    const validateForgotPasswordForm = () => {
        if (
            validFormDetails(
                formFields,
                formDetails,
                setInvalidFormDetails,
                setFormError
            )
        ) {
            setLoading(true);
            if (formRequested) {
                axios
                    .put(API_ROUTES.AUTHENTICATION.FORGOT_PASSWORD, formDetails)
                    .then((res) => {
                        setFormSubmitted(true);
                    })
                    .catch((err) => {
                        setFormError(err.response.data.message);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                axios
                    .post(API_ROUTES.AUTHENTICATION.PASSWORD_TOKEN, formDetails)
                    .then((res) => {
                        if (
                            res.data.token === undefined ||
                            res.data.token === null
                        ) {
                            setFormDetails((prevState) => ({
                                ...prevState,
                                [`token`]: "",
                            }));
                        } else {
                            setFormDetails((prevState) => ({
                                ...prevState,
                                [`token`]: res.data.token,
                            }));
                        }
                        setFormRequested(true);
                    })
                    .catch((err) => {
                        setFormError(err.response.data.message);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    };

    useEffect(() => {
        return () => {
            setInvalidFormDetails({});
            setFormDetails({});
            setFormError("");
            setFormSubmitted(false);
            setFormRequested(false);
        };
    }, []);

    return (
        <AppLoader
            pageContent={
                <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                    <div className="max-w-md w-full space-y-6">
                        <PageHeaderExternal
                            pageHeaderName={`Forgot your password?`}
                        />

                        {!formSubmitted ? (
                            <form
                                className="mt-8 space-y-6"
                                onSubmit={(event) => {
                                    event.preventDefault();
                                    validateForgotPasswordForm();
                                }}
                                method="POST"
                            >
                                <div className="space-y-1">
                                    <div>
                                        <label
                                            htmlFor="email_address"
                                            className="sr-only"
                                        >
                                            Email address
                                        </label>
                                        <input
                                            id="email_address"
                                            name="email"
                                            type="text"
                                            autoComplete="email"
                                            placeholder="Email address"
                                            className={`form-field ${
                                                invalidFormDetails.email
                                                    ? `border-red-600`
                                                    : `border-gray-300`
                                            }`}
                                            onChange={(event) => {
                                                setFormDetails((prevState) => ({
                                                    ...prevState,
                                                    [`email`]:
                                                        event.target.value.trim(),
                                                }));
                                            }}
                                            value={
                                                formDetails.email
                                                    ? formDetails.email
                                                    : ""
                                            }
                                        />
                                    </div>
                                    {formRequested && (
                                        <>
                                            <Alert
                                                type={`success`}
                                                message={`<strong>Email with OTP has been successfully sent to ${formDetails.email}</strong>. Should you not find the email in your Inbox please check in your Spam or Junk or alternatively submit again.`}
                                            />
                                            <div>
                                                <label
                                                    htmlFor="password"
                                                    className="sr-only"
                                                >
                                                    OTP
                                                </label>
                                                <input
                                                    id="otp"
                                                    name="otp"
                                                    type="text"
                                                    autoComplete="otp"
                                                    placeholder="OTP"
                                                    className={`form-field ${
                                                        invalidFormDetails.email
                                                            ? `border-red-600`
                                                            : `border-gray-300`
                                                    }`}
                                                    onChange={(event) => {
                                                        setFormDetails(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                [`otp`]:
                                                                    event.target.value.trim(),
                                                            })
                                                        );
                                                    }}
                                                    value={
                                                        formDetails.otp
                                                            ? formDetails.otp
                                                            : ""
                                                    }
                                                />
                                            </div>

                                            <div>
                                                <label
                                                    htmlFor="password"
                                                    className="sr-only"
                                                >
                                                    New password
                                                </label>
                                                <input
                                                    id="password"
                                                    name="password"
                                                    type="password"
                                                    autoComplete="current-password"
                                                    placeholder="Password"
                                                    className={`form-field ${
                                                        invalidFormDetails.password
                                                            ? `border-red-600`
                                                            : `border-gray-300`
                                                    }`}
                                                    onChange={(event) => {
                                                        setFormDetails(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                [`password`]:
                                                                    event.target.value.trim(),
                                                            })
                                                        );
                                                    }}
                                                    value={
                                                        formDetails.password
                                                            ? formDetails.password
                                                            : ""
                                                    }
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>

                                {formError.length !== 0 && (
                                    <div className="col-span-12 form-error text-center">
                                        {formError}
                                    </div>
                                )}

                                <div>
                                    <button
                                        type="submit"
                                        className="button m-auto"
                                    >
                                        <span className="button-icon">
                                            <LockClosedIcon
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                            />
                                        </span>
                                        Submit
                                    </button>
                                </div>
                            </form>
                        ) : (
                            <>
                                <Alert
                                    type={`success`}
                                    message={`<strong>Password for ${formDetails.email} has been reset.</strong>`}
                                />
                            </>
                        )}

                        <div>
                            <div className="text-sm text-center">
                                <NavLink
                                    to={PORTAL.LOGIN}
                                    className="brand-link font-medium"
                                >
                                    Return to login
                                </NavLink>
                                {formRequested && (
                                    <>
                                        {" "}
                                        |{" "}
                                        <span
                                            onClick={() => {
                                                setFormSubmitted(false);
                                                setFormRequested(false);
                                            }}
                                            className="brand-link font-medium"
                                        >
                                            Submit again
                                        </span>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            }
        />
    );
}
