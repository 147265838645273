import { useState, useEffect, useContext } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { API_ROUTES } from "api";
import { LockClosedIcon } from "@heroicons/react/solid";
import { PORTAL } from "portal";
import { useFormValidation } from "hooks/FormValidationHook";
import PageHeaderExternal from "components/page-header-external";
import { AppLoader } from "components/app-loader";
import { AppLoaderContext } from "hooks/AppLoaderContext";
import { useAuthentication } from "hooks/AuthenticationHook";
import { AuthenticationContext } from "hooks/AuthenticationContext";
import axios from "axios";

export default function Login(props) {
    let history = useHistory();

    let { setLoading } = useContext(AppLoaderContext);
    let { validFormDetails } = useFormValidation();
    const [invalidFormDetails, setInvalidFormDetails] = useState({});
    const [formDetails, setFormDetails] = useState({});
    const [formError, setFormError] = useState("");
    let { setAuthentication } = useAuthentication();
    let { setCurrentUser } = useContext(AuthenticationContext);

    const formFields = [
        { name: "username", display: "email address" },
        { name: "password", display: "password" },
    ];

    const validateLoginForm = () => {
        if (
            validFormDetails(
                formFields,
                formDetails,
                setInvalidFormDetails,
                setFormError
            )
        ) {
            setLoading(true);
            axios
                .post(API_ROUTES.AUTHENTICATION.LOGIN, formDetails)
                .then((res) => {
                    setAuthentication(res.data);
                    setCurrentUser(res.data);
                    history.push(
                        props?.location?.state?.referrer
                            ? props?.location?.state?.referrer
                            : "/"
                    );
                })
                .catch((err) => {
                    setFormError(err.response.data.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    useEffect(() => {
        return () => {
            setInvalidFormDetails({});
            setFormDetails({});
            setFormError("");
        };
    }, []);

    return (
        <AppLoader
            pageContent={
                <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                    <div className="max-w-md w-full space-y-6">
                        <PageHeaderExternal
                            pageHeaderName={`Sign in to your account`}
                        />

                        <form
                            className="mt-8 space-y-6"
                            onSubmit={(event) => {
                                event.preventDefault();
                                validateLoginForm();
                            }}
                            method="POST"
                        >
                            <div className="space-y-1">
                                <div>
                                    <label
                                        htmlFor="username"
                                        className="sr-only"
                                    >
                                        Email address
                                    </label>
                                    <input
                                        id="username"
                                        name="username"
                                        type="text"
                                        autoComplete="username"
                                        placeholder="Email address"
                                        className={`form-field ${
                                            invalidFormDetails.username
                                                ? `border-red-600`
                                                : `border-gray-300`
                                        }`}
                                        onChange={(event) => {
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`username`]:
                                                    event.target.value.trim(),
                                            }));
                                        }}
                                        value={
                                            formDetails.username
                                                ? formDetails.username
                                                : ""
                                        }
                                    />
                                </div>
                                <div>
                                    <label
                                        htmlFor="password"
                                        className="sr-only"
                                    >
                                        Password
                                    </label>
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        autoComplete="current-password"
                                        placeholder="Password"
                                        className={`form-field ${
                                            invalidFormDetails.password
                                                ? `border-red-600`
                                                : `border-gray-300`
                                        }`}
                                        onChange={(event) => {
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`password`]:
                                                    event.target.value.trim(),
                                            }));
                                        }}
                                        value={
                                            formDetails.password
                                                ? formDetails.password
                                                : ""
                                        }
                                    />
                                </div>
                            </div>

                            {formError.length !== 0 && (
                                <div className="col-span-12 form-error text-center">
                                    {formError}
                                </div>
                            )}

                            <div>
                                <button type="submit" className="button m-auto">
                                    <span className="button-icon">
                                        <LockClosedIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                        />
                                    </span>
                                    Sign in
                                </button>
                            </div>
                        </form>

                        <div>
                            <div className="text-sm text-center">
                                <NavLink
                                    to={PORTAL.FORGOTPASSWORD}
                                    className="brand-link font-medium"
                                >
                                    Forgot your password?
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            }
        />
    );
}
