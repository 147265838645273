import { useEffect, useState, useContext } from "react";
import { PORTAL } from "portal";
import { NavLink, useRouteMatch } from "react-router-dom";
import { ChevronLeftIcon, PencilIcon } from "@heroicons/react/outline";
import { useTextFormatHook } from "hooks/TextFormatHook";
import { format } from "date-fns";
import Navigation from "components/navigation";
import Header from "components/header";
import PageHeader from "components/page-header";
import Tasks from "components/tasks";
import AddEditMission from "components/add-edit-mission";
import CloseMission from "components/close-mission";
import ViewMissionOutcome from "components/view-mission-outcome";
import MissionActivity from "components/mission-activity";
import ModalUserDetails from "components/modal-user-details";
import Modal from "components/modal";
import Alert from "components/alert";
import ViewUserDetails from "components/view-user-details";
import Comments from "components/comments";
import Attachments from "components/attachments";
import AddEditProgramme from "components/add-edit-programme";
import { ApiConsumer } from "api-consumer";
import { API_ROUTES } from "api";
import Status from "components/status";
import Progress from "components/progress";
import ProgressBar from "components/progress-bar";
import DownloadAsPdf from "components/download-pdf";

import { useAuthentication } from "hooks/AuthenticationHook";
import { PERMISSIONS } from "hooks/permissions";

export default function Mission() {
    const missionId = useRouteMatch(PORTAL.MISSION + "/:mission_id").params
        .mission_id;

    let { normaliseTableData } = useTextFormatHook();
    let { hasPermission } = useAuthentication();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [programme, setProgramme] = useState({});
    const [mission, setMission] = useState({});
    const [progress, setProgress] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [openAddEditMission, setOpenAddEditMission] = useState(false);
    const [openAddEditProgramme, setOpenAddEditProgramme] = useState(false);
    const [editableRow, setEditableRow] = useState({});
    const [editableProgramme, setEditableProgramme] = useState({});
    const [rowUpdated, setRowUpdated] = useState(false);
    const [userDetailsRow, setUserDetailsRow] = useState({});
    const [openUserDetails, setOpenUserDetails] = useState(false);

    const PROGRAMME_HEADERS = [
        {
            key: "name",
            value: "Programme",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "country",
            value: "Country",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "overview",
            value: "Brief overview",
            showOnTable: false,
            showOnPrint: false,
        },
        {
            key: "workplan",
            value: "Workplan",
            showOnTable: false,
            showOnPrint: false,
        },
        { key: "type", value: "Type", showOnTable: true, showOnPrint: true },
        {
            key: "category",
            value: "Category",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "departments",
            value: "Specific Subject Matter",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "tax_types",
            value: "Tax Types",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "internal_units",
            value: "Internal Units",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "duration",
            value: "Duration",
            showOnTable: true,
            showOnPrint: false,
        },
        {
            key: "start_date",
            value: "Start Date",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "end_date",
            value: "End Date",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "overall_progress",
            value: "Progress",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "status",
            value: "Status",
            showOnTable: true,
            showOnPrint: false,
        },
        {
            key: "ta_managers",
            value: "Country Programmes Manager",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "ta_officers",
            value: "CP Programme Coordinator",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "internal_experts",
            value: "Internal expert(s) able to manage missions",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "external_experts",
            value: "External expert(s) able to manage missions",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "report_users",
            value: "View programme reports",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "created",
            value: "Created",
            showOnTable: false,
            showOnPrint: false,
        },
        {
            key: "updated",
            value: "Updated",
            showOnTable: false,
            showOnPrint: false,
        },
    ];

    const MISSION_HEADERS = [
        { key: "name", value: "Mission", showOnTable: true, showOnPrint: true },
        {
            key: "country",
            value: "Country",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "overview",
            value: "Brief overview",
            showOnTable: false,
            showOnPrint: false,
        },
        {
            key: "type",
            value: "Mission Type",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "activity",
            value: "Mission Activity",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "areas_of_support",
            value: "Area of Support",
            showOnTable: true,
            showOnPrint: true,
        },
        ,
        {
            key: "duration",
            value: "Duration",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "start_date",
            value: "Start Date",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "end_date",
            value: "End Date",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "overall_progress",
            value: "Progress",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "status",
            value: "Status",
            showOnTable: true,
            showOnPrint: false,
        },
        {
            key: "internal_experts",
            value: "Internal Experts",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "external_experts",
            value: "External Experts",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "report_users",
            value: "View mission reports",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "partners",
            value: "Partners",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "created",
            value: "Created",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "updated",
            value: "Updated",
            showOnTable: false,
            showOnPrint: true,
        },
    ];

    const pageHeaderButtons = [
        <NavLink
            to={`${PORTAL.PROGRAMMES_BY_COUNTRY}/${programme?.country?.id}`}
            className={`button-default`}
        >
            <span className="button-icon">
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </span>
            Programmes
        </NavLink>,
    ];

    const pageHeaderIcons = [
        !programme.closed &&
            hasPermission(PERMISSIONS.PROGRAMMES.CAN_EDIT_PROGRAMME) && (
                <button
                    type="button"
                    className="icon-transparent bg-gray-100 ml-3"
                >
                    <PencilIcon
                        className="h-6 w-6"
                        aria-hidden="true"
                        onClick={() => {
                            setEditableProgramme(programme);
                            setOpenAddEditProgramme(true);
                        }}
                    />
                </button>
            ),
        <DownloadAsPdf
            tableTitle={`Programme:`}
            tableHeaders={PROGRAMME_HEADERS}
            tableData={[programme]}
            tableDataResult={programme?.name}
            printType={`page`}
            filename={`Programme: ${programme?.name}`}
        />,
    ];

    const subHeaderButtons = [
        programme?.workplan ? (
            <a
                href={programme?.workplan}
                target="_blank"
                className={`button-default ml-2`}
                rel="noreferrer"
            >
                View Workplan
            </a>
        ) : null,
        <NavLink
            to={`${PORTAL.MISSIONS_BY_PROGRAMME}/${mission?.programme?.id}`}
            className={`button-default`}
        >
            <span className="button-icon">
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </span>
            Missions
        </NavLink>,
    ];

    const subHeaderIcons = [
        !mission.closed &&
            hasPermission(PERMISSIONS.MISSIONS.CAN_EDIT_MISSION) && (
                <button
                    type="button"
                    className="icon-transparent bg-gray-100 ml-3"
                >
                    <PencilIcon
                        className="h-6 w-6"
                        aria-hidden="true"
                        onClick={() => setEditableRow(mission)}
                    />
                </button>
            ),
        <DownloadAsPdf
            tableTitle={`${mission?.programme?.name}:`}
            tableHeaders={MISSION_HEADERS}
            tableData={[mission]}
            tableDataResult={mission?.name}
            printType={`page`}
            filename={`Mission: ${mission?.name}`}
        />,
    ];

    const addLinksToTableData = (tableData) => {
        tableData.forEach((row) => {
            row.normalised.name = (
                <NavLink
                    to={`${PORTAL.TASK}/${row.id}`}
                    className={`brand-link`}
                >
                    {row.normalised.name}
                </NavLink>
            );
        });
        return tableData;
    };

    const loadData = () => {
        ApiConsumer.get(API_ROUTES.MISSION.MISSION_DETAIL(missionId))
            .then((res) => {
                let normalisedData = normaliseTableData(MISSION_HEADERS, [
                    res.data,
                ]);
                res.data.ta_managers = [...res.data.programme.ta_managers];
                res.data.ta_officers = [...res.data.programme.ta_officers];
                setMission(addLinksToTableData(normalisedData)[0]);
                setProgress(parseInt(res.data.overall_progress));
                setProgramme(
                    normaliseTableData(PROGRAMME_HEADERS, [
                        res.data.programme,
                    ])[0]
                );
            })
            .catch((err) => {})
            .finally(() => {
                setLoaded(true);
            });
    };

    useEffect(() => {
        loadData();
        return () => {
            setSidebarOpen(false);
            setProgramme({});
            setMission({});
            setProgress(0);
            setLoaded(false);
            setOpenAddEditMission(false);
            setEditableRow({});
            setEditableProgramme({});
            setRowUpdated(false);
        };
    }, []);

    const onCloseAddEditMission = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditableRow({});
            if (rowUpdated) loadData();
        }
        setOpenAddEditMission(modalStatus);
    };

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableRow).length === 0
            ? setOpenAddEditMission(false)
            : setOpenAddEditMission(true);
    }, [editableRow]);

    const onCloseAddEditProgramme = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditableProgramme({});
            if (rowUpdated) loadData();
        }
        setOpenAddEditProgramme(modalStatus);
    };

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableProgramme).length === 0
            ? setOpenAddEditProgramme(false)
            : setOpenAddEditProgramme(true);
    }, [editableProgramme]);

    return (
        <>
            <Modal
                modalHeaderTag={
                    programme?.country?.name !== undefined
                        ? programme.country.name
                        : ""
                }
                modalHeader={
                    Object.keys(editableProgramme).length === 0
                        ? `Add New Programme`
                        : `Edit Programme: ${editableProgramme.name}`
                }
                modalContent={
                    <AddEditProgramme
                        countryId={
                            programme?.country?.id !== undefined
                                ? programme.country.id
                                : ""
                        }
                        setOpen={onCloseAddEditProgramme}
                        editableRow={editableProgramme}
                        setRowUpdated={setRowUpdated}
                    />
                }
                open={openAddEditProgramme}
                setOpen={onCloseAddEditProgramme}
            />

            <Modal
                modalHeaderTag={
                    programme?.country?.name !== undefined
                        ? programme.country.name
                        : ""
                }
                modalHeader={
                    Object.keys(editableRow).length === 0
                        ? `Add New Mission`
                        : `Edit Mission: ${editableRow.name}`
                }
                modalContent={
                    <AddEditMission
                        programme={programme}
                        setOpen={onCloseAddEditMission}
                        editableRow={editableRow}
                        setRowUpdated={setRowUpdated}
                    />
                }
                open={openAddEditMission}
                setOpen={onCloseAddEditMission}
            />

            <div className="min-h-full bg-gray-100">
                <Navigation
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                />

                <div className="lg:pl-64 flex flex-col flex-1">
                    <Header />

                    <main className="flex-1 pb-8">
                        <PageHeader
                            pageHeaderName={`${
                                programme?.name !== undefined
                                    ? programme.name
                                    : ""
                            } <span class="header-tag-green">
                ${
                    programme?.country?.name !== undefined
                        ? programme.country.name
                        : ""
                }
              </span>
              <span class="header-tag-green">
                ${
                    programme?.type?.name !== undefined
                        ? programme.type.name
                        : ""
                }
              </span>
              <span class="header-tag-green">
                ${
                    programme?.category?.name !== undefined
                        ? programme.category.name
                        : ""
                }
              </span>`}
                            pageHeaderButtons={pageHeaderButtons}
                            pageHeaderIcons={pageHeaderIcons}
                        />
                        <PageHeader
                            pageHeaderName={`${
                                mission?.name !== undefined ? mission.name : ""
                            } <span class="header-tag-green">
                ${mission?.type?.name !== undefined ? mission.type.name : ""}
              </span>`}
                            pageHeaderButtons={subHeaderButtons}
                            pageHeaderIcons={subHeaderIcons}
                        />

                        {hasPermission(
                            PERMISSIONS.MISSIONS.CAN_VIEW_MISSION
                        ) ? (
                            loaded && (
                                <>
                                    <Modal
                                        modalHeaderTag={""}
                                        modalHeader={`User Details: ${mission.created_by.first_name} ${mission.created_by.last_name}`}
                                        modalContent={
                                            <ModalUserDetails
                                                setOpen={setOpenUserDetails}
                                                userDetailsRow={userDetailsRow}
                                            />
                                        }
                                        open={openUserDetails}
                                        setOpen={setOpenUserDetails}
                                    />
                                    <div className="mt-8 grid grid-cols-1 gap-6 sm:px-6 lg:grid-flow-col-dense lg:grid-cols-3">
                                        <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                                            <section aria-labelledby="applicant-information-title">
                                                <div className="bg-white shadow sm:rounded-lg">
                                                    <div className="p-5">
                                                        <div className="flex items-center">
                                                            <h2
                                                                id="applicant-information-title"
                                                                className="flex-1 text-lg font-medium text-gray-900"
                                                            >
                                                                Mission overview
                                                            </h2>
                                                            {mission.closed ? (
                                                                <ViewMissionOutcome
                                                                    mission={
                                                                        mission
                                                                    }
                                                                />
                                                            ) : (
                                                                <CloseMission
                                                                    mission={
                                                                        mission
                                                                    }
                                                                    reloadMissionData={
                                                                        loadData
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                        <p className="mt-1 max-w-2xl  text-gray-500">
                                                            {mission.overview}
                                                        </p>
                                                    </div>
                                                    <div className="border-t border-gray-200 p-5">
                                                        <p className="mt-1 max-w-2xl  text-gray-900">
                                                            Date &amp; Duration
                                                        </p>
                                                        <p className="mt-1 mb-3 max-w-2xl  text-gray-500">
                                                            {
                                                                mission
                                                                    .normalised
                                                                    .start_date
                                                            }{" "}
                                                            -{" "}
                                                            {
                                                                mission
                                                                    .normalised
                                                                    .end_date
                                                            }{" "}
                                                            (
                                                            {
                                                                mission
                                                                    .normalised
                                                                    .duration
                                                            }
                                                            )
                                                        </p>
                                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
                                                            <div className="sm:col-span-1">
                                                                <dt className=" font-base ">
                                                                    Mission type
                                                                </dt>
                                                                <dd className="mt-1  text-gray-500">
                                                                    {
                                                                        mission
                                                                            .type
                                                                            .name
                                                                    }
                                                                </dd>
                                                            </div>
                                                            <div className="sm:col-span-1">
                                                                <dt className=" font-base ">
                                                                    Mission
                                                                    activity
                                                                </dt>
                                                                <dd className="mt-1  text-gray-500">
                                                                    {
                                                                        mission
                                                                            .activity
                                                                            .name
                                                                    }
                                                                </dd>
                                                            </div>

                                                            <div className="sm:col-span-1">
                                                                <dt className=" font-base ">
                                                                    Area(s) of
                                                                    support
                                                                </dt>
                                                                <dd className="mt-1 text-gray-500">
                                                                    {mission.areas_of_support.map(
                                                                        (
                                                                            area,
                                                                            i
                                                                        ) => (
                                                                            <span
                                                                                key={
                                                                                    i
                                                                                }
                                                                            >
                                                                                {
                                                                                    area.name
                                                                                }
                                                                                {i +
                                                                                    1 !==
                                                                                    mission
                                                                                        ?.areas_of_support
                                                                                        ?.length &&
                                                                                    ", "}
                                                                            </span>
                                                                        )
                                                                    )}
                                                                </dd>
                                                            </div>
                                                            <div className="sm:col-span-1">
                                                                <dt className=" font-base ">
                                                                    Partner
                                                                    organization(s)
                                                                </dt>
                                                                <dd className="mt-1  text-gray-500">
                                                                    {mission.partners.map(
                                                                        (
                                                                            partner,
                                                                            i
                                                                        ) => (
                                                                            <span
                                                                                key={
                                                                                    i
                                                                                }
                                                                            >
                                                                                {
                                                                                    partner.name
                                                                                }
                                                                                {i +
                                                                                    1 !==
                                                                                    mission
                                                                                        ?.partners
                                                                                        ?.length &&
                                                                                    ", "}
                                                                            </span>
                                                                        )
                                                                    )}
                                                                </dd>
                                                            </div>
                                                        </dl>
                                                    </div>

                                                    <div className="border-t border-gray-200 p-5">
                                                        <ViewUserDetails
                                                            row={mission}
                                                            type={`mission`}
                                                        />
                                                    </div>

                                                    <div className="border-t border-gray-200 p-5">
                                                        <h2 className="text-lg font-medium text-gray-900">
                                                            Mission progress
                                                        </h2>

                                                        <div className="mt-1  text-gray-900">
                                                            <div className="pt-2">
                                                                <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                                                                    <ProgressBar
                                                                        progressValue={
                                                                            progress
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="pt-1 text-gray-900 text-sm">
                                                                <div className="flex justify-between mb-1">
                                                                    <Status
                                                                        progressValue={
                                                                            progress
                                                                        }
                                                                    />
                                                                    <Progress
                                                                        progressValue={
                                                                            progress
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="border-t border-gray-200 p-5 rounded-bl-lg rounded-br-lg bg-gray-100 text-sm">
                                                        <span className="font-bold">
                                                            Created:
                                                        </span>{" "}
                                                        {format(
                                                            new Date(
                                                                mission.created
                                                            ),
                                                            "dd MMM yyyy"
                                                        )}{" "}
                                                        by{" "}
                                                        <span
                                                            className="hover:underline cursor-pointer"
                                                            onClick={() => {
                                                                setUserDetailsRow(
                                                                    mission.created_by
                                                                );
                                                                setOpenUserDetails(
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            {mission.created_by
                                                                .first_name +
                                                                " " +
                                                                mission
                                                                    .created_by
                                                                    .last_name}
                                                        </span>{" "}
                                                        <span className="font-bold">
                                                            Last updated:
                                                        </span>{" "}
                                                        {
                                                            mission.normalised
                                                                .updated
                                                        }
                                                    </div>
                                                </div>
                                            </section>

                                            <section aria-labelledby="applicant-information-title">
                                                <div className="bg-white shadow sm:rounded-lg">
                                                    <Tasks
                                                        mission={mission}
                                                        reloadMissionData={
                                                            loadData
                                                        }
                                                    />
                                                </div>
                                            </section>

                                            <section aria-labelledby="notes-title">
                                                <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                                                    <Comments
                                                        type={`mission`}
                                                        route={
                                                            API_ROUTES.COMMENTS
                                                                .MISSIONS
                                                        }
                                                        id={missionId}
                                                        missionClosed={
                                                            mission.closed
                                                        }
                                                    />
                                                </div>
                                            </section>
                                        </div>

                                        <section
                                            aria-labelledby="timeline-title"
                                            className="lg:col-start-3 lg:col-span-1"
                                        >
                                            <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6 mb-4">
                                                <Attachments
                                                    type={`mission`}
                                                    route={
                                                        API_ROUTES.ATTACHMENTS
                                                            .MISSIONS
                                                    }
                                                    id={missionId}
                                                    missionClosed={
                                                        mission.closed
                                                    }
                                                />
                                            </div>
                                            <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                                                <MissionActivity
                                                    id={missionId}
                                                />
                                            </div>
                                        </section>
                                    </div>
                                </>
                            )
                        ) : (
                            <div className="mx-full px-8 my-4 text-center">
                                <Alert
                                    type={`danger`}
                                    message={`You do not have permission to view missions. Please contact the system administrator.`}
                                />
                            </div>
                        )}
                    </main>
                </div>
            </div>
        </>
    );
}
