import { Fragment, useEffect, useState } from "react";
import { PlusCircleIcon } from "@heroicons/react/outline";
import { compareAsc } from "date-fns";
import { useTextFormatHook } from "hooks/TextFormatHook";
import Task from "components/task";
import AddEditTask from "components/add-edit-task";
import Modal from "components/modal";
import Alert from "components/alert";
import { ApiConsumer } from "api-consumer";
import { API_ROUTES } from "api";
import { useAuthentication } from "hooks/AuthenticationHook";
import { PERMISSIONS } from "hooks/permissions";

export default function Tasks({ mission, reloadMissionData }) {
    let { normaliseTableData } = useTextFormatHook();
    let { hasPermission } = useAuthentication();
    const [tasks, setTasks] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [openAddEditTask, setOpenAddEditTask] = useState(false);
    const [editableRow, setEditableRow] = useState({});
    const [rowUpdated, setRowUpdated] = useState(false);

    const pageHeaderButtons = [
        !mission.closed && hasPermission(PERMISSIONS.TASKS.CAN_ADD_TASK) && (
            <button
                type="button"
                onClick={() => setOpenAddEditTask(true)}
                className={`button`}
            >
                <span className="button-icon">
                    <PlusCircleIcon className="h-5 w-5" aria-hidden="true" />
                </span>
                Add Task
            </button>
        ),
    ];

    const tableHeaders = [
        { key: "name", value: "Task", showOnTable: true, showOnPrint: true },
        {
            key: "duration",
            value: "Duration",
            showOnTable: true,
            showOnPrint: true,
        },
    ];

    const addLinksToTableData = (tableData) => {
        tableData.forEach((row) => {});
        return tableData;
    };

    const onCloseAddEditTask = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditableRow({});
            if (rowUpdated) loadData();
        }
        setOpenAddEditTask(modalStatus);
    };

    const loadData = () => {
        ApiConsumer.get(API_ROUTES.TASKS.TASKS_BY_MISSION(mission.id))
            .then((res) => {
                res.data.sort((a, b) =>
                    compareAsc(new Date(a.start_date), new Date(b.start_date))
                );
                let normalisedData = normaliseTableData(tableHeaders, res.data);
                setTasks(addLinksToTableData(normalisedData));
            })
            .catch((err) => {})
            .finally(() => {
                setLoaded(true);
            });
    };

    useEffect(() => {
        loadData();
        return () => {
            setTasks([]);
            setLoaded(false);
            setOpenAddEditTask(false);
            setEditableRow({});
            setRowUpdated(false);
        };
    }, []);

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableRow).length === 0
            ? setOpenAddEditTask(false)
            : setOpenAddEditTask(true);
    }, [editableRow]);

    return (
        <>
            <Modal
                modalHeaderTag={""}
                modalHeader={
                    Object.keys(editableRow).length === 0
                        ? `Add New Task`
                        : `Edit Task: ${editableRow.name}`
                }
                modalContent={
                    <AddEditTask
                        mission={mission}
                        setOpen={onCloseAddEditTask}
                        editableRow={editableRow}
                        setRowUpdated={setRowUpdated}
                    />
                }
                open={openAddEditTask}
                setOpen={onCloseAddEditTask}
            />

            <section aria-labelledby="notes-title">
                <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                    <div className="p-5 flex border-b border-gray-200">
                        <div className="flex-1 min-w-0">
                            <div className="flex items-center">
                                <div>
                                    <div className="flex items-center">
                                        <h2 className="text-lg font-medium text-gray-900">
                                            {`Tasks (${tasks?.length})`}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {pageHeaderButtons !== undefined && (
                            <div className="flex space-x-3">
                                {pageHeaderButtons.map((button, i) => (
                                    <Fragment key={i}>{button}</Fragment>
                                ))}
                            </div>
                        )}
                    </div>

                    {hasPermission(PERMISSIONS.TASKS.CAN_VIEW_TASK) ? (
                        loaded && (
                            <>
                                {tasks.length ? (
                                    <ul className="mx-full">
                                        {tasks.map((row) => (
                                            <li
                                                key={row.id}
                                                className="col-span-1 "
                                            >
                                                <Task
                                                    tableHeaders={tableHeaders}
                                                    tableData={row}
                                                    editable={true}
                                                    setEditableRow={
                                                        setEditableRow
                                                    }
                                                    loadData={loadData}
                                                    reloadMissionData={
                                                        reloadMissionData
                                                    }
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <div className="p-5 text-center">
                                        <Alert
                                            type={`danger`}
                                            message={`There are no tasks added under ${
                                                mission?.name !== undefined
                                                    ? mission.name
                                                    : ""
                                            }`}
                                        />
                                    </div>
                                )}
                            </>
                        )
                    ) : (
                        <div className="mx-full px-8 my-4 text-center">
                            <Alert
                                type={`danger`}
                                message={`You do not have permission to view tasks. Please contact the system administrator.`}
                            />
                        </div>
                    )}
                </div>
            </section>
        </>
    );
}
