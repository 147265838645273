import { useState } from 'react'
import { useAuthentication } from 'hooks/AuthenticationHook'
import ModalMissionOutcome from 'components/modal-mission-outcome'
import Modal from 'components/modal'

export default function ViewMissionOutcome({ mission }) {
  const [missionDetailsRow, setMissionDetailsRow] = useState({})
  const [viewMissionOutcome, setViewMissionOutcome] = useState(false)
  let { canViewReport } = useAuthentication()

  const modalHeaderIcons = []

  return canViewReport(mission) ? (
    <>
      <Modal
        modalHeaderTag={''}
        modalHeader={`Mission Outcome: ${mission?.name}`}
        modalHeaderIcons={modalHeaderIcons}
        modalContent={
          <ModalMissionOutcome
            setOpen={setViewMissionOutcome}
            missionDetailsRow={missionDetailsRow}
          />
        }
        open={viewMissionOutcome}
        setOpen={setViewMissionOutcome}
      />
      <div
        onClick={() => {
          setMissionDetailsRow(mission)
          setViewMissionOutcome(true)
        }}
        className={`button-default ml-2`}
        rel="noreferrer"
      >
        View Outcome
      </div>
    </>
  ) : (
    <span className="text-red-600 mt-2 text-sm">
      <em>(Report Restricted)</em>
    </span>
  )
}
