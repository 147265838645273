import { useEffect, useState, useContext } from "react";
import { useFormValidation } from "hooks/FormValidationHook";
import { ApiConsumer } from "api-consumer";
import { API_ROUTES } from "api";
import { format, compareDesc } from "date-fns";
import { PaperClipIcon } from "@heroicons/react/outline";
import { AuthenticationContext } from "hooks/AuthenticationContext";
import Delete from "components/delete";
import Alert from "components/alert";
import ModalUserDetails from "components/modal-user-details";
import Modal from "components/modal";

export default function Attachments({ type, route, id, missionClosed }) {
    let { currentUser } = useContext(AuthenticationContext);
    let { validFormDetails } = useFormValidation();
    const [userDetailsRow, setUserDetailsRow] = useState({});
    const [openUserDetails, setOpenUserDetails] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [invalidFormDetails, setInvalidFormDetails] = useState({});
    const [formDetails, setFormDetails] = useState({});
    const [formError, setFormError] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);

    const formFields = [
        { name: "name", display: "attachment name" },
        { name: "attachment", display: "attachment" },
    ];
    const validateAddAttachment = () => {
        setFormSubmitted(false);
        if (
            validFormDetails(
                formFields,
                formDetails,
                setInvalidFormDetails,
                setFormError
            )
        ) {
            let formData = new FormData();
            for (const key in formDetails) {
                formData.append(key, formDetails[key]);
            }
            formData.append("attachment", formDetails.attachment);

            ApiConsumer.post(API_ROUTES.ATTACHMENTS.ATTACHMENTS, formData)
                .then((res) => {
                    loadAttachments();
                    setFormSubmitted(true);
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`name`]: "",
                    }));
                    setFormDetails((prevState) => ({
                        ...prevState,
                        [`attachment`]: "",
                    }));
                })
                .catch((err) => {
                    setFormError(err.toString());
                })
                .finally(() => {});
        }
    };

    const loadAttachments = () => {
        ApiConsumer.get(route(id))
            .then((res) => {
                res.data.sort((a, b) =>
                    compareDesc(new Date(a.updated), new Date(b.updated))
                );
                setAttachments(res.data);
            })
            .catch((err) => {});
    };

    useEffect(() => {
        loadAttachments();
        /* Defaults */
        setFormDetails((prevState) => ({
            ...prevState,
            [`name`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`uploaded_by`]: currentUser.id,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [type]: id,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`attachment`]: "",
        }));
        return () => {
            setAttachments([]);
            setInvalidFormDetails({});
            setFormDetails({});
            setFormError("");
            setFormSubmitted(false);
        };
    }, [id, currentUser.id]);

    return (
        <>
            <Modal
                modalHeaderTag={""}
                modalHeader={`User Details: ${userDetailsRow?.first_name} ${userDetailsRow?.last_name}`}
                modalContent={
                    <ModalUserDetails
                        setOpen={setOpenUserDetails}
                        userDetailsRow={userDetailsRow}
                    />
                }
                open={openUserDetails}
                setOpen={setOpenUserDetails}
            />

            <h2
                id="timeline-title"
                className="text-lg font-medium text-gray-900"
            >
                Attachments ({attachments.length})
            </h2>
            <p className="text-sm">
                <span className="font-bold">Please do not</span> attach any
                reports under this section.
            </p>

            {!missionClosed && (
                <form
                    className="space-y-1"
                    onSubmit={(event) => {
                        event.preventDefault();
                        validateAddAttachment();
                    }}
                    method="POST"
                    encType="multipart/form-data"
                >
                    <input
                        id="name"
                        name="name"
                        type="text"
                        autoComplete="name"
                        placeholder="Attachment name"
                        className={`form-field ${
                            invalidFormDetails.name
                                ? `border-red-600`
                                : `border-gray-300`
                        }`}
                        onChange={(event) => {
                            setFormDetails((prevState) => ({
                                ...prevState,
                                [`name`]: event.target.value,
                            }));
                            setFormSubmitted(false);
                        }}
                        value={formDetails.name ? formDetails.name : ""}
                    />
                    <input
                        id="attachment"
                        name="attachment"
                        autoComplete="attachment"
                        className={`form-field ${
                            invalidFormDetails.attachment
                                ? `border-red-600`
                                : `border-gray-300`
                        }`}
                        onChange={(event) => {
                            setFormDetails((prevState) => ({
                                ...prevState,
                                [`attachment`]: event.target.files[0],
                            }));
                            setFormSubmitted(false);
                        }}
                        //value={formDetails.attachment}
                        type="file"
                    />

                    {formError.length !== 0 && (
                        <div className="col-span-12 form-error">
                            {formError}
                        </div>
                    )}
                    {formSubmitted && (
                        <Alert
                            type={`success`}
                            message={`File successfully uploaded.`}
                        />
                    )}

                    <button type="submit" className="button float-right">
                        Upload
                    </button>
                    <div className="clear-both"></div>
                </form>
            )}

            <div className="mt-2 flow-root">
                <dd className="mt-1 text-sm text-gray-900">
                    <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                        {attachments.length ? (
                            attachments.map((attachment, i) => (
                                <li key={i} className="pl-3 pr-4 py-3 text-sm">
                                    <PaperClipIcon
                                        className="h-5 w-5 float-left"
                                        aria-hidden="true"
                                    />
                                    <a
                                        href={attachment.attachment}
                                        target="_blank"
                                        className="brand-link ml-1 truncate float-left"
                                    >
                                        {attachment.name}
                                    </a>
                                    {attachment.uploaded_by.id ===
                                        currentUser.id &&
                                        !missionClosed && (
                                            <Delete
                                                apiRoute={
                                                    API_ROUTES.ATTACHMENTS
                                                        .ATTACHMENT_DETAIL
                                                }
                                                rowData={attachment}
                                                loadData={loadAttachments}
                                                titleValue={"Attachment"}
                                            />
                                        )}
                                    <div className="clear-both"></div>
                                    <div className="mt-0">
                                        <span className="text-gray-400 text-sm">
                                            By{" "}
                                            <span
                                                className="hover:underline cursor-pointer"
                                                onClick={() => {
                                                    setUserDetailsRow(
                                                        attachment.uploaded_by
                                                    );
                                                    setOpenUserDetails(true);
                                                }}
                                            >
                                                {attachment.uploaded_by
                                                    .first_name +
                                                    " " +
                                                    attachment.uploaded_by
                                                        .last_name}
                                            </span>{" "}
                                            {format(
                                                new Date(attachment.created),
                                                "dd MMM yyyy HH:mm:ss"
                                            )}
                                        </span>
                                    </div>

                                    <div className="clear-both"></div>
                                </li>
                            ))
                        ) : (
                            <div className="pl-3 pr-4 py-3 text-sm">
                                There are no attachments uploaded.
                            </div>
                        )}
                    </ul>
                </dd>
            </div>
        </>
    );
}
