import { useState } from "react";
import {
    DocumentReportIcon,
    UserIcon,
    UserAddIcon,
} from "@heroicons/react/outline";
import ModalUserDetails from "components/modal-user-details";
import Modal from "components/modal";

export default function ViewUserDetails({ row, type }) {
    const [userDetailsRow, setUserDetailsRow] = useState({});
    const [openUserDetails, setOpenUserDetails] = useState(false);

    return (
        <>
            <Modal
                modalHeaderTag={""}
                modalHeader={`User Details: ${userDetailsRow?.first_name} ${userDetailsRow?.last_name}`}
                modalContent={
                    <ModalUserDetails
                        setOpen={setOpenUserDetails}
                        userDetailsRow={userDetailsRow}
                    />
                }
                open={openUserDetails}
                setOpen={setOpenUserDetails}
            />

            {type === `programme` && (
                <>
                    {row.ta_managers !== undefined &&
                        row.ta_managers.length !== 0 && (
                            <>
                                <strong>Country programmes manager(s):</strong>{" "}
                                {row.ta_managers.map((contact, i) => (
                                    <span key={i}>
                                        <span
                                            className="hover:underline cursor-pointer"
                                            onClick={() => {
                                                setUserDetailsRow(contact);
                                                setOpenUserDetails(true);
                                            }}
                                        >
                                            {contact.first_name +
                                                " " +
                                                contact.last_name}
                                        </span>
                                        {i + 1 !==
                                            row?.normalised?.ta_managers
                                                ?.length && ", "}
                                    </span>
                                ))}
                                {" | "}
                            </>
                        )}
                    {row.ta_officers !== undefined &&
                        row.ta_officers.length !== 0 && (
                            <>
                                <strong>CP programme coordinator(s):</strong>{" "}
                                {row.ta_officers.map((contact, i) => (
                                    <span key={i}>
                                        <span
                                            className="hover:underline cursor-pointer"
                                            onClick={() => {
                                                setUserDetailsRow(contact);
                                                setOpenUserDetails(true);
                                            }}
                                        >
                                            {contact.first_name +
                                                " " +
                                                contact.last_name}
                                        </span>
                                        {i + 1 !==
                                            row?.normalised?.ta_officers
                                                ?.length && ", "}
                                    </span>
                                ))}
                                {row.internal_experts !== undefined &&
                                    row.internal_experts.length !== 0 &&
                                    " | "}
                            </>
                        )}
                </>
            )}

            {row.internal_experts !== undefined &&
                row.internal_experts.length !== 0 && (
                    <>
                        <strong>
                            <UserIcon
                                className="h-5 w-5 mr-0.5 inline-block"
                                aria-hidden="true"
                            />
                            Internal expert(s):
                        </strong>{" "}
                        {row.internal_experts.map((contact, i) => (
                            <span key={i}>
                                <span
                                    className="hover:underline cursor-pointer"
                                    onClick={() => {
                                        setUserDetailsRow(contact);
                                        setOpenUserDetails(true);
                                    }}
                                >
                                    {contact.first_name +
                                        " " +
                                        contact.last_name}
                                </span>
                                {i + 1 !==
                                    row?.normalised?.internal_experts?.length &&
                                    ", "}
                            </span>
                        ))}
                        {row.external_experts !== undefined &&
                            row.external_experts.length !== 0 &&
                            " | "}
                    </>
                )}
            {row.external_experts !== undefined &&
                row.external_experts.length !== 0 && (
                    <>
                        <strong>
                            <UserAddIcon
                                className="h-5 w-5 mr-0.5 inline-block"
                                aria-hidden="true"
                            />
                            External expert(s):
                        </strong>{" "}
                        {row.external_experts.map((contact, i) => (
                            <span key={i}>
                                <span
                                    className="hover:underline cursor-pointer"
                                    onClick={() => {
                                        setUserDetailsRow(contact);
                                        setOpenUserDetails(true);
                                    }}
                                >
                                    {contact.first_name +
                                        " " +
                                        contact.last_name}
                                </span>
                                {i + 1 !==
                                    row?.normalised?.external_experts?.length &&
                                    ", "}
                            </span>
                        ))}
                        {row.report_users !== undefined &&
                            row.report_users.length !== 0 &&
                            " | "}
                    </>
                )}
            {row.report_users !== undefined &&
                row.report_users.length !== 0 && (
                    <>
                        <strong>
                            <DocumentReportIcon
                                className="h-5 w-5 mr-0.5 inline-block"
                                aria-hidden="true"
                            />
                            View programme reports:
                        </strong>{" "}
                        {row.report_users.map((contact, i) => (
                            <span key={i}>
                                <span
                                    className="hover:underline cursor-pointer"
                                    onClick={() => {
                                        setUserDetailsRow(contact);
                                        setOpenUserDetails(true);
                                    }}
                                >
                                    {contact.first_name +
                                        " " +
                                        contact.last_name}
                                </span>
                                {i + 1 !==
                                    row?.normalised?.report_users?.length &&
                                    ", "}
                            </span>
                        ))}
                    </>
                )}
        </>
    );
}
