import { InformationCircleIcon, XIcon } from "@heroicons/react/solid";
import { useContext, useEffect, useState } from "react";
import { Fragment } from "react";
import { Transition } from "@headlessui/react";

import { NotificationContext } from "hooks/NotificationContext";
import Notifications from "components/notifications";
import { BellIcon } from "@heroicons/react/outline";

export default function NotificationIcon() {
    const [open, setOpen] = useState(false);
    let { showNotifications, setNotification } =
        useContext(NotificationContext);

    useEffect(() => {
        setTimeout(function () {
            setNotification((prevState) => ({
                ...prevState,
                [`popup`]: false,
            }));
        }, 30000);
    }, [showNotifications]);

    return (
        <>
            <span
                className="inline-block relative cursor-pointer"
                onClick={() => {
                    setNotification((prevState) => ({
                        ...prevState,
                        [`panel`]: true,
                    }));
                }}
            >
                <BellIcon className="h-6 w-6" aria-hidden="true" />
                <span className="absolute top-0 right-0 block h-1.5 w-1.5 transform -translate-y-1/2 translate-x-1/2 rounded-full" />
            </span>
            <div
                aria-live="assertive"
                className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-40"
            >
                <Notifications show={open} setShow={setOpen} />
                <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
                    <Transition
                        show={showNotifications?.popup ?? false}
                        as={Fragment}
                        enter="transform ease-out duration-300 transition"
                        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                        leave="transition ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                            <div className="p-4">
                                <div className="flex items-start">
                                    <div className="flex-shrink-0">
                                        <InformationCircleIcon
                                            className="h-6 w-6 text-blue-500"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <div className="ml-3 w-0 flex-1 pt-0.5">
                                        <p className="text-sm font-medium text-gray-900">
                                            Notification!
                                        </p>
                                        <p className="mt-1 text-sm text-gray-500">
                                            An action has been assigned/linked
                                            to you.
                                        </p>
                                    </div>
                                    <div className="ml-4 flex-shrink-0 flex">
                                        <button
                                            className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            onClick={() => {
                                                setNotification(
                                                    (prevState) => ({
                                                        ...prevState,
                                                        [`popup`]: false,
                                                    })
                                                );
                                            }}
                                        >
                                            <span className="sr-only">
                                                Close
                                            </span>
                                            <XIcon
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </>
    );
}
