import { useEffect, useState } from "react";
import { SearchIcon } from "@heroicons/react/outline";
import Navigation from "components/navigation";
import Header from "components/header";
import PageHeader from "components/page-header";
import Table from "components/table";
import { sub, add, compareDesc } from "date-fns";
import { useTextFormatHook } from "hooks/TextFormatHook";
import { ApiConsumer } from "api-consumer";
import { NavLink } from "react-router-dom";

import { API_ROUTES } from "api";
import { PORTAL } from "portal";

import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function Reports() {
    let { normaliseTableData } = useTextFormatHook();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [formDetails, setFormDetails] = useState({});
    const [countries, setCountries] = useState([]);
    const [programmeTypes, setProgrammeTypes] = useState([]);
    const [programmeCategories, setProgrammeCategories] = useState([]);
    const [programmeDepartments, setProgrammeDepartments] = useState([]);
    const [missionTypes, setMissionTypes] = useState([]);
    const [programmeManagers, setProgrammeManagers] = useState([]);
    const [missionExperts, setMissionExperts] = useState([]);
    const [programmesReport, setProgrammesReport] = useState([]);
    const [missionsReport, setMissionsReport] = useState([]);

    const programmesTableHeaders = [
        {
            key: "name",
            value: "Programme",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "category",
            value: "Category",
            showOnTable: true,
            showOnPrint: true,
        },
        { key: "type", value: "Type", showOnTable: true, showOnPrint: true },
        {
            key: "department",
            value: "Specific Subject Matters",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "duration",
            value: "Duration",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "start_date",
            value: "Start",
            showOnTable: true,
            showOnPrint: true,
        },
        { key: "end_date", value: "End", showOnTable: true, showOnPrint: true },
        {
            key: "overall_progress",
            value: "Progress",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "progress",
            value: "Progress",
            showOnTable: true,
            showOnPrint: false,
        },
        {
            key: "status",
            value: "Status",
            showOnTable: true,
            showOnPrint: false,
        },
    ];
    const missionsTableHeaders = [
        { key: "name", value: "Mission", showOnTable: true, showOnPrint: true },
        { key: "type", value: "Type", showOnTable: true, showOnPrint: true },
        {
            key: "duration",
            value: "Duration",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "start_date",
            value: "Start",
            showOnTable: true,
            showOnPrint: true,
        },
        { key: "end_date", value: "End", showOnTable: true, showOnPrint: true },
        {
            key: "overall_progress",
            value: "Progress",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "progress",
            value: "Progress",
            showOnTable: true,
            showOnPrint: false,
        },
        {
            key: "status",
            value: "Status",
            showOnTable: true,
            showOnPrint: false,
        },
    ];

    const STATUS = [
        {
            value: "open",
            label: "OPEN",
        },
        {
            value: "closed",
            label: "CLOSED",
        },
        {
            value: "overdue",
            label: "OVERDUE",
        },
    ];

    const addProgrammeLinksToTableData = (tableData) => {
        tableData.forEach((row) => {
            row.normalised.name = (
                <NavLink
                    to={`${PORTAL.MISSIONS_BY_PROGRAMME}/${row.id}`}
                    className={`brand-link`}
                >
                    {row.normalised.name}
                </NavLink>
            );
        });
        return tableData;
    };

    const addMissionLinksToTableData = (tableData) => {
        tableData.forEach((row) => {
            row.normalised.name = (
                <NavLink
                    to={`${PORTAL.MISSION}/${row.id}`}
                    className={`brand-link`}
                >
                    {row.normalised.name}
                </NavLink>
            );
        });
        return tableData;
    };

    const searchReport = () => {
        for (const key in formDetails) {
            if (
                Array.isArray(formDetails[key]) &&
                formDetails[key].length === 0
            ) {
                delete formDetails[key];
            }
        }
        ApiConsumer.post(API_ROUTES.REPORT.REPORTS, formDetails)
            .then((res) => {
                /* Programmes */
                res.data.programmes.sort((a, b) =>
                    compareDesc(new Date(a.updated), new Date(b.updated))
                );
                //Group by "id" to avoid duplicates
                res.data.programmes = res.data.programmes.filter(
                    (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
                );

                let normalisedProgrammeData = normaliseTableData(
                    programmesTableHeaders,
                    res.data.programmes
                );
                setProgrammesReport(
                    addProgrammeLinksToTableData(normalisedProgrammeData)
                );

                /* Missions */
                res.data.missions.sort((a, b) =>
                    compareDesc(new Date(a.updated), new Date(b.updated))
                );
                //Group by "id" to avoid duplicates
                res.data.missions = res.data.missions.filter(
                    (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
                );

                let normalisedMissionData = normaliseTableData(
                    missionsTableHeaders,
                    res.data.missions
                );
                setMissionsReport(
                    addMissionLinksToTableData(normalisedMissionData)
                );
            })
            .catch((err) => {})
            .finally(() => {});
    };

    const onProgrammeDateChange = (dates) => {
        const [start, end] = dates;
        setFormDetails((prevState) => ({
            ...prevState,
            [`programme_start_date`]: start,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`programme_end_date`]: end,
        }));
    };

    const onMissionDateChange = (dates) => {
        const [start, end] = dates;
        setFormDetails((prevState) => ({
            ...prevState,
            [`mission_start_date`]: start,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`mission_end_date`]: end,
        }));
    };

    useEffect(() => {
        setFormDetails((prevState) => ({
            ...prevState,
            [`programme_start_date`]: sub(new Date(), { years: 1 }),
        }));

        setFormDetails((prevState) => ({
            ...prevState,
            [`programme_end_date`]: add(new Date(), { years: 1 }),
        }));

        setFormDetails((prevState) => ({
            ...prevState,
            [`mission_start_date`]: sub(new Date(), { years: 1 }),
        }));

        setFormDetails((prevState) => ({
            ...prevState,
            [`mission_end_date`]: add(new Date(), { years: 1 }),
        }));
    }, []);

    const loadDropdown = (link, setMethod) => {
        ApiConsumer.get(link)
            .then((res) => {
                res.data.sort((a, b) => a.name.localeCompare(b.name));
                let options = res.data.map((option) => {
                    return {
                        value: option.id,
                        label: option.name,
                    };
                });
                setMethod(options);
            })
            .catch((err) => {});
    };

    useEffect(() => {
        loadDropdown(API_ROUTES.CONTENT.COUNTRY, setCountries);
        loadDropdown(API_ROUTES.PROGRAMME.CATEGORIES, setProgrammeCategories);
        loadDropdown(API_ROUTES.PROGRAMME.TYPES, setProgrammeTypes);
        loadDropdown(API_ROUTES.PROGRAMME.DEPARTMENTS, setProgrammeDepartments);
        loadDropdown(API_ROUTES.MISSION.TYPES, setMissionTypes);

        ApiConsumer.get(API_ROUTES.USERS.USERS)
            .then((res) => {
                res.data.sort((a, b) =>
                    a.first_name.localeCompare(b.first_name)
                );
                let users = res.data.filter(
                    (type) =>
                        type.user_type.name === "Country Programmes Manager" ||
                        type.user_type.name === "Country Programmes Officer" ||
                        type.user_type.name === "Administrator"
                );
                let userOptions = users.map((user) => {
                    return {
                        value: user.id,
                        label: user.first_name + " " + user.last_name,
                    };
                });
                setProgrammeManagers(userOptions);
                users = res.data.filter(
                    (type) =>
                        type.user_type.name === "Internal Expert" ||
                        type.user_type.name === "External Expert"
                );
                userOptions = users.map((user) => {
                    return {
                        value: user.id,
                        label: user.first_name + " " + user.last_name,
                    };
                });
                setMissionExperts(userOptions);
            })
            .catch((err) => {});

        return () => {
            setProgrammeManagers([]);
            setMissionExperts([]);
            setProgrammeTypes([]);
            setProgrammeCategories([]);
            setProgrammeDepartments([]);
            setSidebarOpen(false);
        };
    }, []);

    return (
        <>
            <div className="min-h-full bg-gray-100">
                <Navigation
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                />

                <div className="lg:pl-64 flex flex-col flex-1">
                    <Header />

                    <main className="flex-1 pb-8">
                        <PageHeader pageHeaderName={`Overall Reports`} />

                        <div className="mx-full px-4 sm:px-6 lg:px-8 pt-2 my-2">
                            <div className="grid grid-cols-12 space-x-4">
                                <div className="col-span-3">
                                    <label
                                        className="text-sm"
                                        htmlFor="countries"
                                    >
                                        Countries:
                                    </label>
                                    <Select
                                        id="countries"
                                        name="countries"
                                        placeholder="All countries"
                                        isMulti
                                        options={countries}
                                        classNamePrefix="multi-select"
                                        className={`multi-select text-xs`}
                                        onChange={(event) => {
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`countries`]: [...event].map(
                                                    (option) => option.value
                                                ),
                                            }));
                                        }}
                                        defaultValue={0}
                                        value={
                                            Object.keys(countries).length !== 0
                                                ? formDetails?.countries?.map(
                                                      (selectedOption) => {
                                                          return countries[
                                                              countries.findIndex(
                                                                  (option) =>
                                                                      option.value ===
                                                                      selectedOption
                                                              )
                                                          ];
                                                      }
                                                  )
                                                : 0
                                        }
                                    />
                                </div>
                                <div className="col-span-3">
                                    <label
                                        className="text-sm"
                                        htmlFor="categories"
                                    >
                                        Programme categories:
                                    </label>
                                    <Select
                                        id="categories"
                                        name="categories"
                                        placeholder="All categories"
                                        isMulti
                                        options={programmeCategories}
                                        classNamePrefix="multi-select"
                                        className={`multi-select text-xs`}
                                        onChange={(event) => {
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`categories`]: [...event].map(
                                                    (option) => option.value
                                                ),
                                            }));
                                        }}
                                        defaultValue={0}
                                        value={
                                            Object.keys(programmeCategories)
                                                .length !== 0
                                                ? formDetails?.categories?.map(
                                                      (selectedOption) => {
                                                          return programmeCategories[
                                                              programmeCategories.findIndex(
                                                                  (option) =>
                                                                      option.value ===
                                                                      selectedOption
                                                              )
                                                          ];
                                                      }
                                                  )
                                                : 0
                                        }
                                    />
                                </div>

                                <div className="col-span-3">
                                    <label
                                        className="text-sm"
                                        htmlFor="departments"
                                    >
                                        Programme departments:
                                    </label>
                                    <Select
                                        id="departments"
                                        name="departments"
                                        placeholder="All departments"
                                        isMulti
                                        options={programmeDepartments}
                                        classNamePrefix="multi-select"
                                        className={`multi-select text-xs`}
                                        onChange={(event) => {
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`departments`]: [...event].map(
                                                    (option) => option.value
                                                ),
                                            }));
                                        }}
                                        defaultValue={0}
                                        value={
                                            Object.keys(programmeDepartments)
                                                .length !== 0
                                                ? formDetails?.departments?.map(
                                                      (selectedOption) => {
                                                          return programmeDepartments[
                                                              programmeDepartments.findIndex(
                                                                  (option) =>
                                                                      option.value ===
                                                                      selectedOption
                                                              )
                                                          ];
                                                      }
                                                  )
                                                : 0
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="border-b border-gray-200 mx-full px-4 sm:px-6 lg:px-8 pb-3 my-3">
                            <div className="grid grid-cols-12 space-x-4">
                                <div className="col-span-3">
                                    <label
                                        className="text-sm"
                                        htmlFor="programme_types"
                                    >
                                        Programme types:
                                    </label>
                                    <Select
                                        id="programme_types"
                                        name="programme_types"
                                        placeholder="All types"
                                        isMulti
                                        options={programmeTypes}
                                        classNamePrefix="multi-select"
                                        className={`multi-select text-xs`}
                                        onChange={(event) => {
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`programme_types`]: [
                                                    ...event,
                                                ].map((option) => option.value),
                                            }));
                                        }}
                                        defaultValue={0}
                                        value={
                                            Object.keys(programmeTypes)
                                                .length !== 0
                                                ? formDetails?.programme_types?.map(
                                                      (selectedOption) => {
                                                          return programmeTypes[
                                                              programmeTypes.findIndex(
                                                                  (option) =>
                                                                      option.value ===
                                                                      selectedOption
                                                              )
                                                          ];
                                                      }
                                                  )
                                                : 0
                                        }
                                    />
                                </div>
                                <div className="col-span-3">
                                    <label
                                        className="text-sm"
                                        htmlFor="programme_users"
                                    >
                                        Programme managers/officers:
                                    </label>
                                    <Select
                                        id="programme_users"
                                        name="programme_users"
                                        placeholder="All users"
                                        isMulti
                                        options={programmeManagers}
                                        classNamePrefix="multi-select"
                                        className={`multi-select text-xs`}
                                        onChange={(event) => {
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`programme_users`]: [
                                                    ...event,
                                                ].map((option) => option.value),
                                            }));
                                        }}
                                        defaultValue={0}
                                        value={
                                            Object.keys(programmeManagers)
                                                .length !== 0
                                                ? formDetails?.programme_users?.map(
                                                      (selectedOption) => {
                                                          return programmeManagers[
                                                              programmeManagers.findIndex(
                                                                  (option) =>
                                                                      option.value ===
                                                                      selectedOption
                                                              )
                                                          ];
                                                      }
                                                  )
                                                : 0
                                        }
                                    />
                                </div>
                                <div className="col-span-3">
                                    <label
                                        className="text-sm"
                                        htmlFor="programme_status"
                                    >
                                        Programme status:
                                    </label>
                                    <Select
                                        id="programme_status"
                                        name="programme_status"
                                        placeholder="All statuses"
                                        isMulti
                                        options={STATUS}
                                        classNamePrefix="multi-select"
                                        className={`multi-select text-xs`}
                                        onChange={(event) => {
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`programme_status`]: [
                                                    ...event,
                                                ].map((option) => option.value),
                                            }));
                                        }}
                                        defaultValue={0}
                                        value={
                                            Object.keys(STATUS).length !== 0
                                                ? formDetails?.programme_status?.map(
                                                      (selectedOption) => {
                                                          return STATUS[
                                                              STATUS.findIndex(
                                                                  (option) =>
                                                                      option.value ===
                                                                      selectedOption
                                                              )
                                                          ];
                                                      }
                                                  )
                                                : 0
                                        }
                                    />
                                </div>
                                <div className="col-span-3">
                                    <label
                                        className="text-sm"
                                        htmlFor="date_range"
                                    >
                                        Date range:
                                    </label>
                                    <DatePicker
                                        fixedHeight
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        showYearDropdown
                                        dateFormat="dd MMMM yyyy"
                                        selected={
                                            formDetails.programme_start_date
                                        }
                                        onChange={(event) =>
                                            onProgrammeDateChange(event)
                                        }
                                        className="form-field"
                                        startDate={
                                            formDetails.programme_start_date
                                        }
                                        endDate={formDetails.programme_end_date}
                                        selectsRange
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="border-b border-gray-200 mx-full px-4 sm:px-6 lg:px-8 my-3 pb-3">
                            <div className="grid grid-cols-12 space-x-4">
                                <div className="col-span-3">
                                    <label
                                        className="text-sm"
                                        htmlFor="mission_types"
                                    >
                                        Mission types:
                                    </label>
                                    <Select
                                        id="mission_types"
                                        name="mission_types"
                                        placeholder="All missions"
                                        isMulti
                                        options={missionTypes}
                                        classNamePrefix="multi-select"
                                        className={`multi-select text-xs`}
                                        onChange={(event) => {
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`mission_types`]: [
                                                    ...event,
                                                ].map((option) => option.value),
                                            }));
                                        }}
                                        defaultValue={0}
                                        value={
                                            Object.keys(missionTypes).length !==
                                            0
                                                ? formDetails?.mission_types?.map(
                                                      (selectedOption) => {
                                                          return missionTypes[
                                                              missionTypes.findIndex(
                                                                  (option) =>
                                                                      option.value ===
                                                                      selectedOption
                                                              )
                                                          ];
                                                      }
                                                  )
                                                : 0
                                        }
                                    />
                                </div>

                                <div className="col-span-3">
                                    <label
                                        className="text-sm"
                                        htmlFor="mission_users"
                                    >
                                        Internal/external experts:
                                    </label>
                                    <Select
                                        id="mission_users"
                                        name="mission_users"
                                        placeholder="All users"
                                        isMulti
                                        options={missionExperts}
                                        classNamePrefix="multi-select"
                                        className={`multi-select text-xs`}
                                        onChange={(event) => {
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`mission_users`]: [
                                                    ...event,
                                                ].map((option) => option.value),
                                            }));
                                        }}
                                        defaultValue={0}
                                        value={
                                            Object.keys(missionExperts)
                                                .length !== 0
                                                ? formDetails?.mission_users?.map(
                                                      (selectedOption) => {
                                                          return missionExperts[
                                                              missionExperts.findIndex(
                                                                  (option) =>
                                                                      option.value ===
                                                                      selectedOption
                                                              )
                                                          ];
                                                      }
                                                  )
                                                : 0
                                        }
                                    />
                                </div>
                                <div className="col-span-3">
                                    <label
                                        className="text-sm"
                                        htmlFor="mission_status"
                                    >
                                        Mission status:
                                    </label>
                                    <Select
                                        id="mission_status"
                                        name="mission_status"
                                        placeholder="All statuses"
                                        isMulti
                                        options={STATUS}
                                        classNamePrefix="multi-select"
                                        className={`multi-select text-xs`}
                                        onChange={(event) => {
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`mission_status`]: [
                                                    ...event,
                                                ].map((option) => option.value),
                                            }));
                                        }}
                                        defaultValue={0}
                                        value={
                                            Object.keys(STATUS).length !== 0
                                                ? formDetails?.mission_status?.map(
                                                      (selectedOption) => {
                                                          return STATUS[
                                                              STATUS.findIndex(
                                                                  (option) =>
                                                                      option.value ===
                                                                      selectedOption
                                                              )
                                                          ];
                                                      }
                                                  )
                                                : 0
                                        }
                                    />
                                </div>
                                <div className="col-span-3">
                                    <label
                                        className="text-sm"
                                        htmlFor="date_range"
                                    >
                                        Date range:
                                    </label>
                                    <DatePicker
                                        fixedHeight
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        showYearDropdown
                                        dateFormat="dd MMMM yyyy"
                                        selected={
                                            formDetails.mission_start_date
                                        }
                                        onChange={(event) =>
                                            onMissionDateChange(event)
                                        }
                                        className="form-field"
                                        startDate={
                                            formDetails.mission_start_date
                                        }
                                        endDate={formDetails.mission_end_date}
                                        selectsRange
                                    />
                                </div>
                            </div>
                        </div>

                        <h2 className="flex mx-full px-4 sm:px-6 lg:px-8 mt-4 text-lg leading-6 font-medium text-gray-900 text-left items-center">
                            <span className="min-w-0 flex-1 items-center">
                                Programme Results ({programmesReport.length})
                            </span>
                            <button
                                type="button"
                                className="button flex-shrink-0"
                                onClick={() => searchReport()}
                            >
                                <div
                                    className="flex items-center pointer-events-none mr-1"
                                    aria-hidden="true"
                                >
                                    <SearchIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                    />
                                </div>
                                Search
                            </button>
                        </h2>
                        <Table
                            tableTitle={`Programme Report Results`}
                            tableHeaders={programmesTableHeaders}
                            tableData={programmesReport}
                        />
                        <h2 className="flex mx-full px-4 sm:px-6 lg:px-8 mt-4 text-lg leading-6 font-medium text-gray-900 text-left items-center">
                            <span className="min-w-0 flex-1 items-center">
                                Mission Results ({missionsReport.length})
                            </span>
                        </h2>
                        <Table
                            tableTitle={`Mission Report Results`}
                            tableHeaders={missionsTableHeaders}
                            tableData={missionsReport}
                        />
                    </main>
                </div>
            </div>
        </>
    );
}
