import { useEffect, useState } from "react";
import { PencilIcon, PlusCircleIcon } from "@heroicons/react/outline";
import Navigation from "components/navigation";
import Header from "components/header";
import PageHeader from "components/page-header";
import Modal from "components/modal";
import Delete from "components/delete";
import AddEditMeta from "components/add-edit-meta";
import { Redirect } from "react-router-dom";
import { useTextFormatHook } from "hooks/TextFormatHook";
import { ApiConsumer } from "api-consumer";
import { API_ROUTES } from "api";
import { PORTAL } from "portal";

export default function Meta() {
    let loggedInUser = JSON.parse(sessionStorage.getItem(`ATAFTAX`));
    let { normaliseTableData } = useTextFormatHook();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [internalUnits, setInternalUnits] = useState([]);
    const [partners, setPartners] = useState([]);
    const [programmeCategories, setProgrammeCategories] = useState([]);
    const [programmeTypes, setProgrammeTypes] = useState([]);
    const [programmeTaxTypes, setProgrammeTaxTypes] = useState([]);
    const [programmeOutcomeImpacts, setProgrammeOutcomeImpacts] = useState([]);
    const [missionOutcomeImpacts, setMissionOutcomeImpacts] = useState([]);
    const [missionTypes, setMissionTypes] = useState([]);
    const [missionActivity, setMissionActivity] = useState([]);
    const [missionAreaOfSupport, setMissionAreaOfSupport] = useState([]);
    const [expertInstitution, setExpertInstitution] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [loadFlag, setLoadFlag] = useState(false);

    const [metaType, setMetaType] = useState("");
    const [openAddEditMeta, setOpenAddEditMeta] = useState(false);
    const [editableRow, setEditableRow] = useState({});
    const [rowUpdated, setRowUpdated] = useState(false);

    const pageHeaderButtons = [];

    const tableHeaders = [
        { key: "name", value: "Name", showOnTable: true, showOnPrint: true },
    ];

    const addLinksToTableData = (tableData) => {
        tableData.forEach((row) => {});
        return tableData;
    };

    const loadMeta = (link, setMethod) => {
        ApiConsumer.get(link)
            .then((res) => {
                res.data.sort((a, b) => a.name.localeCompare(b.name));
                let normalisedData = normaliseTableData(tableHeaders, res.data);
                setMethod(addLinksToTableData(normalisedData));
            })
            .catch((err) => {});
    };
    const loadData = () => {
        loadMeta(
            API_ROUTES.METADATA.PROGRAMME.CATEGORIES,
            setProgrammeCategories
        );
        loadMeta(API_ROUTES.METADATA.PROGRAMME.TYPES, setProgrammeTypes);
        loadMeta(API_ROUTES.METADATA.PROGRAMME.TAX_TYPES, setProgrammeTaxTypes);
        loadMeta(
            API_ROUTES.METADATA.PROGRAMME.INTERNAL_UNITS,
            setInternalUnits
        );
        loadMeta(API_ROUTES.METADATA.MISSION.TYPES, setMissionTypes);
        loadMeta(API_ROUTES.METADATA.MISSION.ACTIVITY, setMissionActivity);
        loadMeta(
            API_ROUTES.METADATA.MISSION.AREAS_OF_SUPPORT,
            setMissionAreaOfSupport
        );
        loadMeta(
            API_ROUTES.METADATA.MISSION.EXPERT_INSTITUTION,
            setExpertInstitution
        );
        loadMeta(API_ROUTES.METADATA.MISSION.PARTNERS, setPartners);
        loadMeta(
            API_ROUTES.METADATA.PROGRAMME.IMPACT,
            setProgrammeOutcomeImpacts
        );
        loadMeta(API_ROUTES.METADATA.MISSION.IMPACT, setMissionOutcomeImpacts);

        loadMeta(API_ROUTES.METADATA.PROGRAMME.DEPARTMENTS, setDepartments);
    };

    useEffect(() => {
        loadData();
        return () => {
            setProgrammeCategories([]);
            setProgrammeTypes([]);
            setProgrammeTaxTypes([]);
            setPartners([]);
            setInternalUnits([]);
            setMissionTypes([]);
            setMissionActivity([]);
            setMissionAreaOfSupport([]);
            setExpertInstitution([]);
            setProgrammeOutcomeImpacts([]);
            setMissionOutcomeImpacts([]);
            setMetaType("");
            setSidebarOpen(false);
            setOpenAddEditMeta(false);
            setEditableRow({});
            setRowUpdated(false);
        };
    }, []);

    const delay = (delayInms) => {
        return new Promise(resolve => setTimeout(resolve, delayInms));
      };

    const onCloseAddEditMeta = async (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            if (rowUpdated) {
                loadData();
                setLoadFlag(true);
                await delay(2000);
                setLoadFlag(false);
                setRowUpdated(false);
            }
            setEditableRow({});
        }
        setOpenAddEditMeta(modalStatus);
    };

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableRow).length === 0
            ? setOpenAddEditMeta(false)
            : setOpenAddEditMeta(true);
    }, [editableRow]);

    if (loggedInUser.user_type.name !== "Administrator")
        return <Redirect to={PORTAL.LOGIN} />;

    return (
        <>
            <Modal
                modalHeaderTag={""}
                modalHeader={
                    Object.keys(editableRow).length === 0
                        ? `Add New ${metaType}`
                        : `Edit ${metaType}: ${editableRow.name}`
                }
                modalContent={
                    <AddEditMeta
                        setOpen={onCloseAddEditMeta}
                        editableRow={editableRow}
                        setRowUpdated={setRowUpdated}
                        metaType={metaType}
                        loading={loadFlag}
                    />
                }
                open={openAddEditMeta}
                setOpen={onCloseAddEditMeta}
            />

            <div className="min-h-full bg-gray-100">
                <Navigation
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                />

                <div className="lg:pl-64 flex flex-col flex-1">
                    <Header />

                    <main className="flex-1 pb-8">
                        <PageHeader
                            pageHeaderName={`System Meta`}
                            pageHeaderButtons={pageHeaderButtons}
                        />

                        <div className="mx-full px-4 sm:px-6 lg:px-8 my-5">
                            <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
                                <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                                    <div className="w-full p-5">
                                        <h2 className="h2 whitespace-normal text-xl">
                                            Programme Types
                                        </h2>

                                        <div className=" divide-y divide-gray-100">
                                            <p className="text-gray-400 mb-4">
                                                List of programme types
                                            </p>

                                            {programmeTypes?.map(
                                                (programmeType, i) => (
                                                    <div
                                                        key={i}
                                                        className="py-3"
                                                    >
                                                        {programmeType.name}
                                                        <button
                                                            type="button"
                                                            className="bg-white p-1 mx-1 rounded-full text-gray-400 hover:text-gray-500"
                                                        >
                                                            <PencilIcon
                                                                className="h-4 w-4 mr-1"
                                                                aria-hidden="true"
                                                                onClick={() => {
                                                                    setMetaType(
                                                                        "Programme Type"
                                                                    );
                                                                    setEditableRow(
                                                                        programmeType
                                                                    );
                                                                }}
                                                            />
                                                        </button>

                                                        <Delete
                                                            apiRoute={
                                                                API_ROUTES
                                                                    .METADATA
                                                                    .PROGRAMME
                                                                    .TYPE_DETAIL
                                                            }
                                                            rowData={
                                                                programmeType
                                                            }
                                                            loadData={loadData}
                                                            titleValue={
                                                                "Programme Type"
                                                            }
                                                        />
                                                    </div>
                                                )
                                            )}
                                        </div>

                                        <div className="mt-5">
                                            <button
                                                type="button"
                                                className={`button text-left`}
                                                onClick={() => {
                                                    setMetaType(
                                                        "Programme Type"
                                                    );
                                                    setOpenAddEditMeta(true);
                                                }}
                                            >
                                                <span className="button-icon">
                                                    <PlusCircleIcon
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                                Add Programme Type
                                            </button>
                                        </div>
                                    </div>
                                </li>

                                <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                                    <div className="w-full p-5">
                                        <h2 className="h2 whitespace-normal text-xl">
                                            Programme Categories
                                        </h2>
                                        <div className=" divide-y divide-gray-100">
                                            <p className="text-gray-400 mb-4">
                                                List of programme categories
                                            </p>

                                            {programmeCategories?.map(
                                                (programmeCategory, i) => (
                                                    <div
                                                        key={i}
                                                        className="py-3"
                                                    >
                                                        {programmeCategory.name}
                                                        <button
                                                            type="button"
                                                            className="bg-white p-1 mx-1 rounded-full text-gray-400 hover:text-gray-500"
                                                        >
                                                            <PencilIcon
                                                                className="h-4 w-4 mr-1"
                                                                aria-hidden="true"
                                                                onClick={() => {
                                                                    setMetaType(
                                                                        "Programme Category"
                                                                    );
                                                                    setEditableRow(
                                                                        programmeCategory
                                                                    );
                                                                }}
                                                            />
                                                        </button>

                                                        <Delete
                                                            apiRoute={
                                                                API_ROUTES
                                                                    .METADATA
                                                                    .PROGRAMME
                                                                    .CATEGORY_DETAIL
                                                            }
                                                            rowData={
                                                                programmeCategory
                                                            }
                                                            loadData={loadData}
                                                            titleValue={
                                                                "Programme Category"
                                                            }
                                                        />
                                                    </div>
                                                )
                                            )}
                                        </div>
                                        <div className="mt-5">
                                            <button
                                                type="button"
                                                className={`button text-left`}
                                                onClick={() => {
                                                    setMetaType(
                                                        "Programme Category"
                                                    );
                                                    setOpenAddEditMeta(true);
                                                }}
                                            >
                                                <span className="button-icon">
                                                    <PlusCircleIcon
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                                Add Programme Category
                                            </button>
                                        </div>
                                    </div>
                                </li>

                                <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                                    <div className="w-full p-5">
                                        <h2 className="h2 whitespace-normal text-xl">
                                            Programme Tax Types
                                        </h2>

                                        <div className=" divide-y divide-gray-100">
                                            <p className="text-gray-400 mb-4">
                                                List of programme tax types
                                            </p>

                                            {programmeTaxTypes?.map(
                                                (programmeTaxType, i) => (
                                                    <div
                                                        key={i}
                                                        className="py-3"
                                                    >
                                                        {programmeTaxType.name}
                                                        <button
                                                            type="button"
                                                            className="bg-white p-1 mx-1 rounded-full text-gray-400 hover:text-gray-500"
                                                        >
                                                            <PencilIcon
                                                                className="h-4 w-4 mr-1"
                                                                aria-hidden="true"
                                                                onClick={() => {
                                                                    setMetaType(
                                                                        "Programme Tax Type"
                                                                    );
                                                                    setEditableRow(
                                                                        programmeTaxType
                                                                    );
                                                                }}
                                                            />
                                                        </button>
                                                        <Delete
                                                            apiRoute={
                                                                API_ROUTES
                                                                    .METADATA
                                                                    .PROGRAMME
                                                                    .TAX_TYPE_DETAIL
                                                            }
                                                            rowData={
                                                                programmeTaxType
                                                            }
                                                            loadData={loadData}
                                                            titleValue={
                                                                "Programme Tax Type"
                                                            }
                                                        />
                                                    </div>
                                                )
                                            )}
                                        </div>

                                        <div className="mt-5">
                                            <button
                                                type="button"
                                                className={`button text-left`}
                                                onClick={() => {
                                                    setMetaType(
                                                        "Programme Tax Type"
                                                    );
                                                    setOpenAddEditMeta(true);
                                                }}
                                            >
                                                <span className="button-icon">
                                                    <PlusCircleIcon
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                                Add Programme Tax Type
                                            </button>
                                        </div>
                                    </div>
                                </li>

                                <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                                    <div className="w-full p-5">
                                        <h2 className="h2 whitespace-normal text-xl">
                                            Internal Units
                                        </h2>
                                        <div className=" divide-y divide-gray-100">
                                            <p className="text-gray-400 mb-4">
                                                List of MS &amp; DRM units
                                            </p>

                                            {internalUnits?.map(
                                                (internalUnit, i) => (
                                                    <div
                                                        key={i}
                                                        className="py-3"
                                                    >
                                                        {internalUnit.name}
                                                        <button
                                                            type="button"
                                                            className="bg-white p-1 mx-1 rounded-full text-gray-400 hover:text-gray-500"
                                                        >
                                                            <PencilIcon
                                                                className="h-4 w-4 mr-1"
                                                                aria-hidden="true"
                                                                onClick={() => {
                                                                    setMetaType(
                                                                        "Internal Unit"
                                                                    );
                                                                    setEditableRow(
                                                                        internalUnit
                                                                    );
                                                                }}
                                                            />
                                                        </button>
                                                        <Delete
                                                            apiRoute={
                                                                API_ROUTES
                                                                    .METADATA
                                                                    .PROGRAMME
                                                                    .INTERNAL_UNIT_DETAIL
                                                            }
                                                            rowData={
                                                                internalUnit
                                                            }
                                                            loadData={loadData}
                                                            titleValue={
                                                                "Internal Unit"
                                                            }
                                                        />
                                                    </div>
                                                )
                                            )}
                                        </div>
                                        <div className="mt-5">
                                            <button
                                                type="button"
                                                className={`button text-left`}
                                                onClick={() => {
                                                    setMetaType(
                                                        "Internal Unit"
                                                    );
                                                    setOpenAddEditMeta(true);
                                                }}
                                            >
                                                <span className="button-icon">
                                                    <PlusCircleIcon
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                                Add Internal Unit
                                            </button>
                                        </div>
                                    </div>
                                </li>

                                <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                                    <div className="w-full p-5">
                                        <h2 className="h2 whitespace-normal text-xl">
                                            Specific Subject Matters
                                        </h2>
                                        <div className=" divide-y divide-gray-100">
                                            <p className="text-gray-400 mb-4">
                                                List of Specific Subject Matters
                                            </p>

                                            {departments?.map(
                                                (department, i) => (
                                                    <div
                                                        key={i}
                                                        className="py-3"
                                                    >
                                                        {department.name}
                                                        <button
                                                            type="button"
                                                            className="bg-white p-1 mx-1 rounded-full text-gray-400 hover:text-gray-500"
                                                        >
                                                            <PencilIcon
                                                                className="h-4 w-4 mr-1"
                                                                aria-hidden="true"
                                                                onClick={() => {
                                                                    setMetaType(
                                                                        "Specific Subject Matters"
                                                                    );
                                                                    setEditableRow(
                                                                        department
                                                                    );
                                                                }}
                                                            />
                                                        </button>
                                                        <Delete
                                                            apiRoute={
                                                                API_ROUTES
                                                                    .METADATA
                                                                    .PROGRAMME
                                                                    .DEPARTMENT_DETAIL
                                                            }
                                                            rowData={department}
                                                            loadData={loadData}
                                                            titleValue={
                                                                "Specific Subject Matters"
                                                            }
                                                        />
                                                    </div>
                                                )
                                            )}
                                        </div>
                                        <div className="mt-5">
                                            <button
                                                type="button"
                                                className={`button text-left`}
                                                onClick={() => {
                                                    setMetaType(
                                                        "Specific Subject Matters"
                                                    );
                                                    setOpenAddEditMeta(true);
                                                }}
                                            >
                                                <span className="button-icon">
                                                    <PlusCircleIcon
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                                Add Specific Subject Matters
                                            </button>
                                        </div>
                                    </div>
                                </li>

                                <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                                    <div className="w-full p-5">
                                        <h2 className="h2 whitespace-normal text-xl">
                                            Mission Types
                                        </h2>
                                        <div className=" divide-y divide-gray-100">
                                            <p className="text-gray-400 mb-4">
                                                List of mission types
                                            </p>

                                            {missionTypes?.map(
                                                (missionType, i) => (
                                                    <div
                                                        key={i}
                                                        className="py-3"
                                                    >
                                                        {missionType.name}
                                                        <button
                                                            type="button"
                                                            className="bg-white p-1 mx-1 rounded-full text-gray-400 hover:text-gray-500"
                                                        >
                                                            <PencilIcon
                                                                className="h-4 w-4 mr-1"
                                                                aria-hidden="true"
                                                                onClick={() => {
                                                                    setMetaType(
                                                                        "Mission Type"
                                                                    );
                                                                    setEditableRow(
                                                                        missionType
                                                                    );
                                                                }}
                                                            />
                                                        </button>
                                                        <Delete
                                                            apiRoute={
                                                                API_ROUTES
                                                                    .METADATA
                                                                    .MISSION
                                                                    .TYPE_DETAIL
                                                            }
                                                            rowData={
                                                                missionType
                                                            }
                                                            loadData={loadData}
                                                            titleValue={
                                                                "Mission Type"
                                                            }
                                                        />
                                                    </div>
                                                )
                                            )}
                                        </div>
                                        <div className="mt-5">
                                            <button
                                                type="button"
                                                className={`button text-left`}
                                                onClick={() => {
                                                    setMetaType("Mission Type");
                                                    setOpenAddEditMeta(true);
                                                }}
                                            >
                                                <span className="button-icon">
                                                    <PlusCircleIcon
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                                Add Mission Type
                                            </button>
                                        </div>
                                    </div>
                                </li>

                                <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                                    <div className="w-full p-5">
                                        <h2 className="h2 whitespace-normal text-xl">
                                            Mission Activities
                                        </h2>
                                        <div className=" divide-y divide-gray-100">
                                            <p className="text-gray-400 mb-4">
                                                List of mission activities
                                            </p>

                                            {missionActivity?.map(
                                                (activity, i) => (
                                                    <div
                                                        key={i}
                                                        className="py-3"
                                                    >
                                                        {activity.name}
                                                        <button
                                                            type="button"
                                                            className="bg-white p-1 mx-1 rounded-full text-gray-400 hover:text-gray-500"
                                                        >
                                                            <PencilIcon
                                                                className="h-4 w-4 mr-1"
                                                                aria-hidden="true"
                                                                onClick={() => {
                                                                    setMetaType(
                                                                        "Mission Activity"
                                                                    );
                                                                    setEditableRow(
                                                                        activity
                                                                    );
                                                                }}
                                                            />
                                                        </button>

                                                        <Delete
                                                            apiRoute={
                                                                API_ROUTES
                                                                    .METADATA
                                                                    .MISSION
                                                                    .ACTIVITY_DETAIL
                                                            }
                                                            rowData={activity}
                                                            loadData={loadData}
                                                            titleValue={
                                                                "Mission Activity"
                                                            }
                                                        />
                                                    </div>
                                                )
                                            )}
                                        </div>
                                        <div className="mt-5">
                                            <button
                                                type="button"
                                                className={`button text-left`}
                                                onClick={() => {
                                                    setMetaType(
                                                        "Mission Activity"
                                                    );
                                                    setOpenAddEditMeta(true);
                                                }}
                                            >
                                                <span className="button-icon">
                                                    <PlusCircleIcon
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                                Add Mission Activity
                                            </button>
                                        </div>
                                    </div>
                                </li>

                                <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                                    <div className="w-full p-5">
                                        <h2 className="h2 whitespace-normal text-xl">
                                            Mission Area of Support
                                        </h2>
                                        <div className=" divide-y divide-gray-100">
                                            <p className="text-gray-400 mb-4">
                                                List of mission area of support
                                            </p>

                                            {missionAreaOfSupport?.map(
                                                (area, i) => (
                                                    <div
                                                        key={i}
                                                        className="py-3"
                                                    >
                                                        {area.name}
                                                        <button
                                                            type="button"
                                                            className="bg-white p-1 mx-1 rounded-full text-gray-400 hover:text-gray-500"
                                                        >
                                                            <PencilIcon
                                                                className="h-4 w-4 mr-1"
                                                                aria-hidden="true"
                                                                onClick={() => {
                                                                    setMetaType(
                                                                        "Area of Support"
                                                                    );
                                                                    setEditableRow(
                                                                        area
                                                                    );
                                                                }}
                                                            />
                                                        </button>
                                                        <Delete
                                                            apiRoute={
                                                                API_ROUTES
                                                                    .METADATA
                                                                    .MISSION
                                                                    .AREAS_OF_SUPPORT_DETAIL
                                                            }
                                                            rowData={area}
                                                            loadData={loadData}
                                                            titleValue={
                                                                "Area of Support"
                                                            }
                                                        />
                                                    </div>
                                                )
                                            )}
                                        </div>
                                        <div className="mt-5">
                                            <button
                                                type="button"
                                                className={`button text-left`}
                                                onClick={() => {
                                                    setMetaType(
                                                        "Area of Support"
                                                    );
                                                    setOpenAddEditMeta(true);
                                                }}
                                            >
                                                <span className="button-icon">
                                                    <PlusCircleIcon
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                                Add Mission Area of Support
                                            </button>
                                        </div>
                                    </div>
                                </li>

                                <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                                    <div className="w-full p-5">
                                        <h2 className="h2 whitespace-normal text-xl">
                                            Institutions of External Expert
                                        </h2>
                                        <div className=" divide-y divide-gray-100">
                                            <p className="text-gray-400 mb-4">
                                                List of institutions of external
                                                expert
                                            </p>

                                            {expertInstitution?.map(
                                                (institution, i) => (
                                                    <div
                                                        key={i}
                                                        className="py-3"
                                                    >
                                                        {institution.name}
                                                        <button
                                                            type="button"
                                                            className="bg-white p-1 mx-1 rounded-full text-gray-400 hover:text-gray-500"
                                                        >
                                                            <PencilIcon
                                                                className="h-4 w-4 mr-1"
                                                                aria-hidden="true"
                                                                onClick={() => {
                                                                    setMetaType(
                                                                        "Institution of Expert"
                                                                    );
                                                                    setEditableRow(
                                                                        institution
                                                                    );
                                                                }}
                                                            />
                                                        </button>

                                                        <Delete
                                                            apiRoute={
                                                                API_ROUTES
                                                                    .METADATA
                                                                    .MISSION
                                                                    .EXPERT_INSTITUTION_DETAIL
                                                            }
                                                            rowData={
                                                                institution
                                                            }
                                                            loadData={loadData}
                                                            titleValue={
                                                                "Institution of Expert"
                                                            }
                                                        />
                                                    </div>
                                                )
                                            )}
                                        </div>
                                        <div className="mt-5">
                                            <button
                                                type="button"
                                                className={`button text-left`}
                                                onClick={() => {
                                                    setMetaType(
                                                        "Institution of Expert"
                                                    );
                                                    setOpenAddEditMeta(true);
                                                }}
                                            >
                                                <span className="button-icon">
                                                    <PlusCircleIcon
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                                Add Institution of Expert
                                            </button>
                                        </div>
                                    </div>
                                </li>

                                <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                                    <div className="w-full p-5">
                                        <h2 className="h2 whitespace-normal text-xl">
                                            Capacity Development Partners
                                        </h2>
                                        <div className=" divide-y divide-gray-100">
                                            <p className="text-gray-400 mb-4">
                                                List of capacity development
                                                partners
                                            </p>

                                            {partners?.map((partner, i) => (
                                                <div key={i} className="py-3">
                                                    {partner.name}
                                                    <button
                                                        type="button"
                                                        className="bg-white p-1 mx-1 rounded-full text-gray-400 hover:text-gray-500"
                                                    >
                                                        <PencilIcon
                                                            className="h-4 w-4 mr-1"
                                                            aria-hidden="true"
                                                            onClick={() => {
                                                                setMetaType(
                                                                    "Partner"
                                                                );
                                                                setEditableRow(
                                                                    partner
                                                                );
                                                            }}
                                                        />
                                                    </button>
                                                    <Delete
                                                        apiRoute={
                                                            API_ROUTES.METADATA
                                                                .MISSION
                                                                .PARTNER_DETAIL
                                                        }
                                                        rowData={partner}
                                                        loadData={loadData}
                                                        titleValue={"Partner"}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                        <div className="mt-5">
                                            <button
                                                type="button"
                                                className={`button text-left`}
                                                onClick={() => {
                                                    setMetaType("Partner");
                                                    setOpenAddEditMeta(true);
                                                }}
                                            >
                                                <span className="button-icon">
                                                    <PlusCircleIcon
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                                Add Partner
                                            </button>
                                        </div>
                                    </div>
                                </li>

                                <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                                    <div className="w-full p-5">
                                        <h2 className="h2 whitespace-normal text-xl">
                                            Programme Outcome Impacts
                                        </h2>
                                        <div className=" divide-y divide-gray-100">
                                            <p className="text-gray-400 mb-4">
                                                List of sectors impacted under
                                                the programme outcome
                                            </p>

                                            {programmeOutcomeImpacts?.map(
                                                (programmeImpact, i) => (
                                                    <div
                                                        key={i}
                                                        className="py-3"
                                                    >
                                                        {programmeImpact.name}
                                                        <button
                                                            type="button"
                                                            className="bg-white p-1 mx-1 rounded-full text-gray-400 hover:text-gray-500"
                                                        >
                                                            <PencilIcon
                                                                className="h-4 w-4 mr-1"
                                                                aria-hidden="true"
                                                                onClick={() => {
                                                                    setMetaType(
                                                                        "Programme Impact"
                                                                    );
                                                                    setEditableRow(
                                                                        programmeImpact
                                                                    );
                                                                }}
                                                            />
                                                        </button>
                                                        <Delete
                                                            apiRoute={
                                                                API_ROUTES
                                                                    .METADATA
                                                                    .PROGRAMME
                                                                    .IMPACT_DETAIL
                                                            }
                                                            rowData={
                                                                programmeImpact
                                                            }
                                                            loadData={loadData}
                                                            titleValue={
                                                                "Programme Impact"
                                                            }
                                                        />
                                                    </div>
                                                )
                                            )}
                                        </div>
                                        <div className="mt-5">
                                            <button
                                                type="button"
                                                className={`button text-left`}
                                                onClick={() => {
                                                    setMetaType(
                                                        "Programme Impact"
                                                    );
                                                    setOpenAddEditMeta(true);
                                                }}
                                            >
                                                <span className="button-icon">
                                                    <PlusCircleIcon
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                                Add Programme Outcome Impact
                                            </button>
                                        </div>
                                    </div>
                                </li>

                                <li className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                                    <div className="w-full p-5">
                                        <h2 className="h2 whitespace-normal text-xl">
                                            Mission Outcome Impacts
                                        </h2>
                                        <div className=" divide-y divide-gray-100">
                                            <p className="text-gray-400 mb-4">
                                                List of sectors impacted under
                                                the mission outcome
                                            </p>

                                            {missionOutcomeImpacts?.map(
                                                (missionImpact, i) => (
                                                    <div
                                                        key={i}
                                                        className="py-3"
                                                    >
                                                        {missionImpact.name}
                                                        <button
                                                            type="button"
                                                            className="bg-white p-1 mx-1 rounded-full text-gray-400 hover:text-gray-500"
                                                        >
                                                            <PencilIcon
                                                                className="h-4 w-4 mr-1"
                                                                aria-hidden="true"
                                                                onClick={() => {
                                                                    setMetaType(
                                                                        "Mission Impact"
                                                                    );
                                                                    setEditableRow(
                                                                        missionImpact
                                                                    );
                                                                }}
                                                            />
                                                        </button>
                                                        <Delete
                                                            apiRoute={
                                                                API_ROUTES
                                                                    .METADATA
                                                                    .MISSION
                                                                    .IMPACT_DETAIL
                                                            }
                                                            rowData={
                                                                missionImpact
                                                            }
                                                            loadData={loadData}
                                                            titleValue={
                                                                "Mission Impact"
                                                            }
                                                        />
                                                    </div>
                                                )
                                            )}
                                        </div>
                                        <div className="mt-5">
                                            <button
                                                type="button"
                                                className={`button text-left`}
                                                onClick={() => {
                                                    setMetaType(
                                                        "Mission Impact"
                                                    );
                                                    setOpenAddEditMeta(true);
                                                }}
                                            >
                                                <span className="button-icon">
                                                    <PlusCircleIcon
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                                Add Mission Outcome Impact
                                            </button>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
}
