import { useState, useEffect, useContext } from "react";
import { format } from "date-fns";
import { useFormValidation } from "hooks/FormValidationHook";
import { API_ROUTES } from "api";
import { ApiConsumer } from "api-consumer";
import { AuthenticationContext } from "hooks/AuthenticationContext";

import "react-datepicker/dist/react-datepicker.css";
import Alert from "components/alert";
import { AppLoaderContext } from "hooks/AppLoaderContext";

export default function AddEditProgrammeOutcomes({
    programme,
    setOpen,
    editableRow,
    setRowUpdated,
    setEditableRow,
}) {
    let { setLoading } = useContext(AppLoaderContext);
    let { currentUser } = useContext(AuthenticationContext);
    let { validFormDetails } = useFormValidation();
    const [invalidFormDetails, setInvalidFormDetails] = useState({});
    const [formDetails, setFormDetails] = useState({});
    const [formError, setFormError] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [allowCloseProgramme, setAllowCloseProgramme] = useState(false);
    const [programmeOutcomeImpacts, setProgrammeOutcomeImpacts] = useState([]);

    const formFields = [
        { name: "programme_objectives", display: "programme objective" },
        { name: "programme_outcomes", display: "programme outcomes" },
        { name: "lessons_learned", display: "lessons learned" },
        { name: "programme_impact", display: "programme impact" },
        {
            name: "programme_impact_details",
            display: "programme impact details",
        },
        { name: "programme_report", display: "programme report" },
    ];

    const validateAddEditProgrammeOutcomes = (isEdit) => {
        if (
            validFormDetails(
                formFields,
                formDetails,
                setInvalidFormDetails,
                setFormError,
            )
        ) {
            setLoading(true);
            let formData = new FormData();
            for (const key in formDetails) {
                formData.append(
                    key,
                    key.includes("date")
                        ? formDetails[key].toISOString().substring(0, 10)
                        : formDetails[key]
                );
            }
            formData.append("programme_report", formDetails.programme_report);

            if (isEdit) {
                ApiConsumer.put(
                    API_ROUTES.OUTCOMES.PROGRAMME_DETAIL(programme.id),
                    formData
                )
                    .then((res) => {
                        setRowUpdated(true);
                        setFormSubmitted(true);
                    })
                    .catch((err) => {
                        setFormError(err.toString());
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                ApiConsumer.post(API_ROUTES.OUTCOMES.OUTCOMES, formData)
                    .then((res) => {
                        setRowUpdated(true);
                        setFormSubmitted(true);
                    })
                    .catch((err) => {
                        setFormError(err.toString());
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    };

    useEffect(() => {
        ApiConsumer.get(
            API_ROUTES.MISSION.MISSIONS_BY_PROGRAMME(programme.id)
        ).then((res) => {
            let openMissions = res.data.filter(
                (mission) => mission.closed === false
            );
            setAllowCloseProgramme(openMissions.length !== 0 ? false : true);
        });

        ApiConsumer.get(API_ROUTES.PROGRAMME.IMPACT)
            .then((res) => {
                res.data.sort((a, b) => a.name.localeCompare(b.name));
                setProgrammeOutcomeImpacts(res.data);
            })
            .catch((err) => {});
    }, []);

    useEffect(() => {
        ApiConsumer.get(
            API_ROUTES.OUTCOMES.PROGRAMME_DETAIL(programme.id)
        ).then((res) => {
            if (res.data.length) setEditableRow(res.data[0]);
        });
    }, []);

    useEffect(() => {
        /* Defaults */
        setFormDetails((prevState) => ({
            ...prevState,
            [`creator`]: currentUser.id,
        }));

        setFormDetails((prevState) => ({
            ...prevState,
            [`programme`]: programme.id,
        }));

        setFormDetails((prevState) => ({
            ...prevState,
            [`programme_objectives`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`programme_outcomes`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`lessons_learned`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`programme_impact`]: "selected",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`programme_impact_details`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`revenue_collected`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`programme_report`]: null,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`closed`]: true,
        }));

        /* If editing */
        if (Object.keys(editableRow).length !== 0) {
            setFormDetails((prevState) => ({
                ...prevState,
                [`programme_objectives`]: editableRow.programme_objectives,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`programme_outcomes`]: editableRow.programme_outcomes,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`lessons_learned`]: editableRow.lessons_learned,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`programme_impact`]: editableRow.programme_impact.id,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`programme_impact_details`]:
                    editableRow.programme_impact_details,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`revenue_collected`]: editableRow.revenue_collected,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`programme_report_link`]: editableRow.programme_report,
            }));
        }
    }, [editableRow, currentUser, programme]);

    useEffect(() => {
        return () => {
            setProgrammeOutcomeImpacts([]);
            setInvalidFormDetails({});
            setFormDetails({});
            setFormError("");
            setFormSubmitted(false);
        };
    }, []);

    return (
        <>
            {!formSubmitted ? (
                <form
                    className="space-y-6"
                    onSubmit={(event) => {
                        event.preventDefault();
                        validateAddEditProgrammeOutcomes(
                            Object.keys(editableRow).length
                        );
                    }}
                    method="POST"
                    encType="multipart/form-data"
                >
                    {Object.keys(editableRow).length !== 0 && (
                        <div className="text-sm border-gray-200 p-4 rounded-lg bg-gray-100">
                            <div className="float-left">
                                <span className="font-bold">Created:</span>{" "}
                                {format(
                                    new Date(editableRow.created),
                                    "dd MMM yyyy"
                                )}{" "}
                            </div>
                            <div className="float-right">
                                <span className="font-bold">Last updated:</span>{" "}
                                {format(
                                    new Date(editableRow.updated),
                                    "dd MMM yyyy"
                                )}{" "}
                            </div>
                            <div className="clear-both"></div>
                        </div>
                    )}
                    <div className="space-y-2">
                        <div className="grid grid-cols-12">
                            <div className="col-span-12 text-sm">
                                <strong>Programme: {programme.name}</strong>
                            </div>
                        </div>

                        {allowCloseProgramme ? (
                            <>
                                <div className="grid grid-cols-12">
                                    <div className="col-span-5">
                                        <label
                                            className="text-sm"
                                            htmlFor="programme_objectives"
                                        >
                                            Programme objectives:{" "}
                                            <span className="text-red-600">
                                                *
                                            </span>
                                        </label>
                                    </div>
                                    <div className="col-span-7">
                                        <textarea
                                            id="programme_objectives"
                                            name="programme_objectives"
                                            autoComplete="programme_objectives"
                                            className={`form-field ${
                                                invalidFormDetails.programme_objectives
                                                    ? `border-red-600`
                                                    : `border-gray-300`
                                            }`}
                                            onChange={(event) => {
                                                setFormDetails((prevState) => ({
                                                    ...prevState,
                                                    [`programme_objectives`]:
                                                        event.target.value,
                                                }));
                                            }}
                                            value={
                                                formDetails.programme_objectives
                                                    ? formDetails.programme_objectives
                                                    : ""
                                            }
                                        ></textarea>
                                    </div>
                                </div>

                                <div className="grid grid-cols-12">
                                    <div className="col-span-5">
                                        <label
                                            className="text-sm"
                                            htmlFor="programme_outcomes"
                                        >
                                            Programme outcomes:{" "}
                                            <span className="text-red-600">
                                                *
                                            </span>
                                        </label>
                                    </div>
                                    <div className="col-span-7">
                                        <textarea
                                            id="programme_outcomes"
                                            name="programme_outcomes"
                                            autoComplete="programme_outcomes"
                                            className={`form-field ${
                                                invalidFormDetails.programme_outcomes
                                                    ? `border-red-600`
                                                    : `border-gray-300`
                                            }`}
                                            onChange={(event) => {
                                                setFormDetails((prevState) => ({
                                                    ...prevState,
                                                    [`programme_outcomes`]:
                                                        event.target.value,
                                                }));
                                            }}
                                            value={
                                                formDetails.programme_outcomes
                                                    ? formDetails.programme_outcomes
                                                    : ""
                                            }
                                        ></textarea>
                                    </div>
                                </div>

                                <div className="grid grid-cols-12">
                                    <div className="col-span-5">
                                        <label
                                            className="text-sm"
                                            htmlFor="lessons_learned"
                                        >
                                            Lessons learned:{" "}
                                            <span className="text-red-600">
                                                *
                                            </span>
                                        </label>
                                    </div>
                                    <div className="col-span-7">
                                        <textarea
                                            id="lessons_learned"
                                            name="lessons_learned"
                                            autoComplete="lessons_learned"
                                            className={`form-field ${
                                                invalidFormDetails.lessons_learned
                                                    ? `border-red-600`
                                                    : `border-gray-300`
                                            }`}
                                            onChange={(event) => {
                                                setFormDetails((prevState) => ({
                                                    ...prevState,
                                                    [`lessons_learned`]:
                                                        event.target.value,
                                                }));
                                            }}
                                            value={
                                                formDetails.lessons_learned
                                                    ? formDetails.lessons_learned
                                                    : ""
                                            }
                                        ></textarea>
                                    </div>
                                </div>

                                <div className="grid grid-cols-12">
                                    <div className="col-span-5">
                                        <label
                                            className="text-sm"
                                            htmlFor="revenue_collected"
                                        >
                                            Revenue:{" "}
                                        </label>
                                    </div>
                                    <div className="col-span-7">
                                        <input
                                            type="text"
                                            id="revenue_collected"
                                            name="revenue_collected"
                                            autoComplete="revenue_collected"
                                            className={`form-field ${
                                                invalidFormDetails.revenue_collected
                                                    ? `border-red-600`
                                                    : `border-gray-300`
                                            }`}
                                            onChange={(event) => {
                                                setFormDetails((prevState) => ({
                                                    ...prevState,
                                                    [`revenue_collected`]:
                                                        event.target.value,
                                                }));
                                            }}
                                            value={
                                                formDetails.revenue_collected
                                                    ? formDetails.revenue_collected
                                                    : ""
                                            }
                                        ></input>
                                    </div>
                                </div>

                                <div className="grid grid-cols-12">
                                    <div className="col-span-5">
                                        <label
                                            className="text-sm"
                                            htmlFor="programme_impact"
                                        >
                                            Programme impact:{" "}
                                            <span className="text-red-600">
                                                *
                                            </span>
                                        </label>
                                    </div>
                                    <div className="col-span-7">
                                        <select
                                            id="programme_impact"
                                            name="programme_impact"
                                            autoComplete="programme_impact"
                                            className={`${
                                                invalidFormDetails.programme_impact
                                                    ? `border-red-600`
                                                    : `border-gray-300`
                                            }`}
                                            onChange={(event) => {
                                                setFormDetails((prevState) => ({
                                                    ...prevState,
                                                    [`programme_impact`]:
                                                        event.target.value,
                                                }));
                                            }}
                                            value={
                                                formDetails.programme_impact
                                                    ? formDetails.programme_impact
                                                    : "selected"
                                            }
                                        >
                                            <option value="selected">
                                                Select programme impact
                                            </option>
                                            {programmeOutcomeImpacts.map(
                                                (impact) => {
                                                    return (
                                                        <option
                                                            key={impact.id}
                                                            value={impact.id}
                                                        >
                                                            {impact.name}
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </select>
                                    </div>
                                </div>

                                {formDetails.programme_impact !==
                                    "selected" && (
                                    <div className="grid grid-cols-12">
                                        <div className="col-span-5">
                                            <label
                                                className="text-sm"
                                                htmlFor="programme_impact_details"
                                            >
                                                Programme impact details:{" "}
                                                <span className="text-red-600">
                                                    *
                                                </span>
                                            </label>
                                        </div>
                                        <div className="col-span-7">
                                            <textarea
                                                id="programme_impact_details"
                                                name="programme_impact_details"
                                                autoComplete="programme_impact_details"
                                                className={`form-field ${
                                                    invalidFormDetails.programme_impact_details
                                                        ? `border-red-600`
                                                        : `border-gray-300`
                                                }`}
                                                onChange={(event) => {
                                                    setFormDetails(
                                                        (prevState) => ({
                                                            ...prevState,
                                                            [`programme_impact_details`]:
                                                                event.target
                                                                    .value,
                                                        })
                                                    );
                                                }}
                                                value={
                                                    formDetails.programme_impact_details
                                                        ? formDetails.programme_impact_details
                                                        : ""
                                                }
                                            ></textarea>
                                        </div>
                                    </div>
                                )}

                                <div className="grid grid-cols-12">
                                    <div className="col-span-5">
                                        <label
                                            className="text-sm"
                                            htmlFor="programme_report"
                                        >
                                            Upload programme report:
                                        </label>
                                    </div>
                                    <div className="col-span-7">
                                        {formDetails.programme_report_link && (
                                            <a
                                                href={
                                                    formDetails.programme_report_link
                                                }
                                                target="_blank"
                                                className={`brand-link mb-2`}
                                                rel="noreferrer"
                                            >
                                                View current programme report
                                            </a>
                                        )}
                                        <input
                                            id="programme_report"
                                            name="programme_report"
                                            autoComplete="programme_report"
                                            className={`form-field ${
                                                invalidFormDetails.programme_report
                                                    ? `border-red-600`
                                                    : `border-gray-300`
                                            }`}
                                            onChange={(event) => {
                                                setFormDetails((prevState) => ({
                                                    ...prevState,
                                                    [`programme_report`]:
                                                        event.target.files[0],
                                                }));
                                            }}
                                            //value={formDetails.programme_report}
                                            type="file"
                                        />
                                    </div>
                                </div>
                            </>
                        ) : (
                            <Alert
                                type={`danger`}
                                message={`Please ensure that all missions under this programme are closed before closing this programme.`}
                            />
                        )}

                        {formError.length !== 0 && (
                            <div className="col-span-12 form-error text-center">
                                {formError}
                            </div>
                        )}

                        <div className="flex justify-center">
                            <button
                                type="button"
                                className="button-default mx-1 mt-4"
                                onClick={() => setOpen(false)}
                            >
                                Close Popup
                            </button>
                            {allowCloseProgramme && (
                                <button
                                    type="submit"
                                    className="button mx-1 mt-4"
                                >
                                    {Object.keys(editableRow).length === 0
                                        ? `Add Outcome & Close Programme`
                                        : `Update`}
                                </button>
                            )}
                        </div>
                    </div>
                </form>
            ) : (
                <>
                    <Alert
                        type={`success`}
                        message={`Programme outcome successfully ${
                            Object.keys(editableRow).length === 0
                                ? `added`
                                : `updated`
                        }.`}
                    />

                    <div className="flex justify-center">
                        <button
                            type="button"
                            className="button-default mx-1 mt-4"
                            onClick={() => setOpen(false)}
                        >
                            Close
                        </button>
                    </div>
                </>
            )}
        </>
    );
}
