import { useEffect, useState } from "react";
import { ApiConsumer } from "api-consumer";
import { API_ROUTES } from "api";
import { format, compareDesc } from "date-fns";
import ModalUserDetails from "components/modal-user-details";
import Modal from "components/modal";

export default function MissionActivity({ id }) {
    const [userDetailsRow, setUserDetailsRow] = useState({});
    const [openUserDetails, setOpenUserDetails] = useState(false);
    const [activity, setActivity] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const loadActivity = () => {
        ApiConsumer.get(API_ROUTES.USERS.ACTIVITY_BY_MISSION(id))
            .then((res) => {
                res.data.sort((a, b) =>
                    compareDesc(new Date(a.created), new Date(b.created))
                );
                setActivity(res.data);
            })
            .catch((err) => {})
            .finally(() => {
                setLoaded(true);
            });
    };

    useEffect(() => {
        loadActivity();
        return () => {
            setActivity({});
            setLoaded(false);
        };
    }, [id]);

    return (
        <>
            <Modal
                modalHeaderTag={""}
                modalHeader={`User Details: ${userDetailsRow?.first_name} ${userDetailsRow?.last_name}`}
                modalContent={
                    <ModalUserDetails
                        setOpen={setOpenUserDetails}
                        userDetailsRow={userDetailsRow}
                    />
                }
                open={openUserDetails}
                setOpen={setOpenUserDetails}
            />

            <h2
                id="timeline-title"
                className="text-lg font-medium text-gray-900"
            >
                Recent activity
            </h2>

            <div className="mt-2 flow-root">
                <ul className="-mb-8">
                    {loaded && (
                        <>
                            {activity.length === 0 ? (
                                <li className="text-xs">
                                    <div className="relative pb-8">
                                        There has been no activity under this
                                        mission.
                                    </div>
                                </li>
                            ) : (
                                activity.map((row, i) => (
                                    <li key={i}>
                                        <div className="relative pb-8">
                                            {i !== activity.length - 1 && (
                                                <span
                                                    className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                                                    aria-hidden="true"
                                                ></span>
                                            )}
                                            <div className="relative flex space-x-3">
                                                <div>
                                                    <span className="h-8 w-8 rounded-full bg-gray-200 flex items-center justify-center ring-8 ring-white">
                                                        <svg
                                                            className="w-5 h-5 text-white"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 20 20"
                                                            fill="currentColor"
                                                            aria-hidden="true"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                                                clipRule="evenodd"
                                                            />
                                                        </svg>
                                                    </span>
                                                </div>
                                                <div className="min-w-0 flex-1 flex justify-between space-x-4">
                                                    <div>
                                                        <p className="text-xs text-gray-500">
                                                            <span
                                                                className={`brand-link text-sm`}
                                                                onClick={() => {
                                                                    setUserDetailsRow(
                                                                        row.user
                                                                    );
                                                                    setOpenUserDetails(
                                                                        true
                                                                    );
                                                                }}
                                                            >
                                                                {row.user
                                                                    .first_name +
                                                                    " " +
                                                                    row.user
                                                                        .last_name}
                                                            </span>
                                                            <br />
                                                            {row.activity}
                                                        </p>
                                                    </div>
                                                    <div className="text-right text-xs whitespace-nowrap text-gray-500">
                                                        <time dateTime="2020-09-20">
                                                            {format(
                                                                new Date(
                                                                    row.created
                                                                ),
                                                                "dd MMM yyyy"
                                                            )}
                                                            <br />
                                                            {format(
                                                                new Date(
                                                                    row.created
                                                                ),
                                                                "HH:ii:ss"
                                                            )}
                                                        </time>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))
                            )}
                        </>
                    )}
                </ul>
            </div>
        </>
    );
}
