import { format } from "date-fns";
import { DocumentDownloadIcon } from "@heroicons/react/outline";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

import logo from "images/ATAF-Logo-Colour.png";

export default function DownloadAsPdf({
    tableTitle,
    tableHeaders,
    tableData,
    tableDataResult,
    printType,
    filename = "",
}) {
    const SLIDER = [
        {
            NAME: "NOT STARTED",
            MIN: 0,
            MAX: 1,
            STYLE: "bg-red-800",
        },
        {
            NAME: "POOR",
            MIN: 1,
            MAX: 33,
            STYLE: "bg-red-500",
        },
        {
            NAME: "PROGRESSIVE",
            MIN: 33,
            MAX: 66,
            STYLE: "bg-yellow-500",
        },
        {
            NAME: "GOOD",
            MIN: 66,
            MAX: 99,
            STYLE: "bg-green-500",
        },
        {
            NAME: "COMPLETE",
            MIN: 99,
            MAX: 100,
            STYLE: "bg-green-800",
        },
    ];
    
    const processDate = (data) => {
        if (data === '' || data === undefined || data === '') return '';
        const monthNames = ["Jan", "Feb", "Mar", "Apr",
                        "May", "Jun", "Jul", "Aug",
                        "Sep", "Oct", "Nov", "Dec"];
        
        const dateTime = new Date(data);
        let day = dateTime.getDate();
        day = day.toString().length > 1 ? day : '0' + day;
        const monthIndex = dateTime.getMonth();
        const monthName = monthNames[monthIndex];
        const year = dateTime.getFullYear();
    
        return `${day} ${monthName} ${year}`; 
    };

    const processExperts = (expert) => {
        if (expert.length === 0) return '';
        const allExperts = expert.map((item) => item.first_name + " " + item.last_name);
        const joinedData = allExperts.join(', ');

        return joinedData;
    };

    const generateData = () => {
        let printResults = [];
        let printData = [...tableData];

        printData.sort((a, b) => a.name.localeCompare(b.name));

        printData.forEach((data, i) => {
            let normalised = { ...data.normalised };
            normalised["#"] = i + 1;
            Object.entries(normalised).forEach(([key, value]) => {
                if (
                    tableHeaders.filter(
                        (header) =>
                            header.key === key && header.showOnPrint === false
                    ).length !== 0
                ) {
                    delete normalised[key];
                    return false;
                }

                if (value === null || value === undefined)
                    normalised[key] = "-";
                else if (typeof value === "object")
                    normalised[key] = data[key].toString(); //Take original
                //Format Array
                if (Array.isArray(value)) normalised[key] = value.join(", ");
                //Format `Closed` as part of name
                if (key === "name") {
                    normalised["name"] += data.closed ? ` (CLOSED)` : ``;
                    let missionSuffix =
                        data?.total_missions && data?.total_missions === 1
                            ? ` mission`
                            : ` missions`;
                    normalised["name"] += data?.total_missions
                        ? ` (` + data?.total_missions + missionSuffix + `)`
                        : ``;
                }
                if (key === "mission" && tableTitle === "Mission Outcome: ") {
                    normalised["start_date"] = processDate(value.start_date);
                    normalised["end_date"] = processDate(value.end_date);
                    normalised["internal_experts"] = processExperts(value.internal_experts);
                    normalised["external_experts"] = processExperts(value.external_experts);
                    tableHeaders[17].key = "start_date";
                    tableHeaders[18].key = "end_date";
                    tableHeaders[19].key = "internal_experts";
                    tableHeaders[20].key = "external_experts";
                    delete normalised["mission"];
                }
            });

            printResults.push(normalised);
        });

        return printResults;
    };

    const createHeaders = () => {
        let printHeaders = [];

        if (printType === `table`)
            printHeaders.push({
                dataKey: "#",
                header: "#",
            });

        tableHeaders
            .filter((header) => header.showOnPrint === true)
            .forEach((header) => {
                printHeaders.push({
                    dataKey: header.key,
                    header: header.value,
                });
            });
        return printHeaders;
    };

    const downloadAsPdf = () => {
        let colData = generateData();
        let colHeaders = createHeaders();

        let image = new Image();
        image.src = logo;
        const pdf = new jsPDF({
            orientation: printType === `table` ? "landscape" : "portrait",
        });
        const pdfWidth = pdf.internal.pageSize.getWidth();

        pdf.setFontSize(8);
        pdf.text(
            `ATAF TA Management System - Downloaded ${format(
                new Date(),
                "dd MMMM yyyy"
            )}`,
            13,
            11
        );

        if (printType === `table`) {
            pdf.setFontSize(14);
            pdf.text(`${tableTitle}`, 13, 19);
            pdf.setFontSize(8);
            pdf.text(`${tableDataResult}`, 13, 24);
        }
        if (printType === `page`) {
            pdf.setFontSize(12);
            pdf.text(`${tableTitle}`, 13, 18);
            pdf.setFontSize(12);
            pdf.text(`${tableDataResult}`, 13, 24);

            let printHeaders = [
                { dataKey: "name", header: "Name" },
                { dataKey: "value", header: "Value" },
            ];
            let printResults = [];
            colData.forEach((data) => {
                colHeaders.forEach((colHeader) => {
                    printResults.push({
                        name: colHeader.header,
                        value: data[colHeader.dataKey].toString(),
                    });
                });
            });
            colHeaders = printHeaders;
            colData = printResults;
        }
        pdf.setFontSize(8);

        autoTable(pdf, {
            margin: { left: 13, top: 30 },
            columns: colHeaders,
            body: colData,
            styles: { fontSize: 8 },
            headStyles: { fillColor: [22, 163, 74] },
            didParseCell: (data) => {
                if (
                    data.section === "body" &&
                    data.column.dataKey === "overall_progress"
                ) {
                    data.cell.text =
                        data.cell.text[0] +
                        `% ${
                            `(` +
                            SLIDER.filter(
                                (x) =>
                                    x.MIN <= data.cell.raw &&
                                    x.MAX >= data.cell.raw
                            )[0].NAME +
                            `)`
                        }`;
                }
            },
            columnStyles: printType === `page` ? { 0: { cellWidth: 50 } } : {},
        });

        pdf.addImage(image, "PNG", pdfWidth - 53, 7, 38, 18);
        pdf.save(filename !== "" ? filename : tableTitle);
    };

    return (
        <button type="button" className="icon-transparent bg-gray-100">
            <DocumentDownloadIcon
                onClick={() => downloadAsPdf()}
                className="h-6 w-6"
                aria-hidden="true"
            />
        </button>
    );
}
