import {
    CursorClickIcon,
    HomeIcon,
    ViewGridAddIcon,
    UserGroupIcon,
    GlobeAltIcon,
    ShieldCheckIcon,
    DocumentTextIcon,
    PresentationChartBarIcon,
} from "@heroicons/react/outline";
import { PORTAL } from "portal";

export const primaryNavigation = [
    {
        name: "Dashboard",
        href: "/",
        icon: HomeIcon,
    },
];

export const reportingNavigation = [
    {
        name: "Programme Reports",
        href: `${PORTAL.PROGRAMME_REPORTS}`,
        icon: PresentationChartBarIcon,
        permission: "view_programmereports",
    },
    {
        name: "Mission Reports",
        href: `${PORTAL.MISSION_REPORTS}`,
        icon: PresentationChartBarIcon,
        permission: "view_missionreports",
    },
];

export const adminNavigation = [
    {
        name: "Users",
        href: `${PORTAL.USERS}`,
        icon: UserGroupIcon,
        permission: "view_customuser",
    },
    {
        name: "Administrators",
        href: `${PORTAL.USERS}/administrators`,
        icon: ShieldCheckIcon,
        permission: "view_administrator",
    },
    {
        name: "Countries",
        href: `${PORTAL.COUNTRIES}`,
        icon: GlobeAltIcon,
        permission: "view_country",
    },
    {
        name: "Meta",
        href: `${PORTAL.META}`,
        icon: DocumentTextIcon,
        permission: "view_meta",
    },
    {
        name: "System Activity",
        href: `${PORTAL.ACTIVITY}`,
        icon: CursorClickIcon,
        permission: "view_useractivity",
    },
];
