import { useEffect, useState, useContext } from "react";
import { ApiConsumer } from "api-consumer";
import { API_ROUTES } from "api";

import Alert from "components/alert";
import { format } from "date-fns";
import ModalUserDetails from "components/modal-user-details";
import Modal from "components/modal";
import DownloadAsPdf from "components/download-pdf";
import { useTextFormatHook } from "hooks/TextFormatHook";
import { AppLoaderContext } from "hooks/AppLoaderContext";

export default function ModalProgrammeOutcome({
    setOpen,
    programmeDetailsRow,
}) {
    let loggedInUser = JSON.parse(sessionStorage.getItem(`ATAFTAX`));
    let { setLoading } = useContext(AppLoaderContext);
    let { normaliseTableData } = useTextFormatHook();
    const [userDetailsRow, setUserDetailsRow] = useState({});
    const [openUserDetails, setOpenUserDetails] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [outcome, setOutcome] = useState([]);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const tableHeaders = [
        {
            key: "closed",
            value: "Closed on",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "updated",
            value: "Last updated",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "programme_objectives",
            value: "Programme objectives",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "programme_outcomes",
            value: "Programme outcomes",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "lessons_learned",
            value: "Lessons learned",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "programme_impact",
            value: "Programme impact",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "programme_impact_details",
            value: "Programme impact details",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "programme_report",
            value: "Programme report",
            showOnTable: false,
            showOnPrint: true,
        },
    ];

    useEffect(() => {
        ApiConsumer.get(
            API_ROUTES.OUTCOMES.PROGRAMME_DETAIL(programmeDetailsRow.id)
        )
            .then((res) => {
                let normalisedData = normaliseTableData(tableHeaders, res.data);
                setOutcome(normalisedData[0]);
            })
            .catch((err) => {})
            .finally(() => {
                setLoaded(true);
            });
    }, [programmeDetailsRow.id]);

    const reOpenProgramme = () => {
        setLoading(true);
        ApiConsumer.put(
            API_ROUTES.PROGRAMME.PROGRAMME_REOPEN(programmeDetailsRow.id),
            {
                closed: false,
                name: programmeDetailsRow.name,
            }
        )
            .then((res) => {
                setFormSubmitted(true);
            })
            .catch((err) => {})
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {loaded && outcome.length === 0 && (
                <Alert
                    type={`danger`}
                    message={`Programme outcome not loaded. Please contact system administrator.`}
                />
            )}
            {loaded && (
                <>
                    <Modal
                        modalHeaderTag={""}
                        modalHeader={`User Details: ${outcome.creator.first_name} ${outcome.creator.last_name}`}
                        modalContent={
                            <ModalUserDetails
                                setOpen={setOpenUserDetails}
                                userDetailsRow={userDetailsRow}
                            />
                        }
                        open={openUserDetails}
                        setOpen={setOpenUserDetails}
                    />
                    {!formSubmitted ? (
                        <>
                            <div className="text-sm border-gray-200 p-4 rounded-lg bg-gray-100 mb-4 flex">
                                <div className="flex-1">
                                    <span className="font-bold">
                                        Closed on:
                                    </span>{" "}
                                    {format(
                                        new Date(outcome.created),
                                        "dd MMM yyyy"
                                    )}{" "}
                                    by{" "}
                                    <span
                                        className="hover:underline cursor-pointer"
                                        onClick={() => {
                                            setUserDetailsRow(outcome.creator);
                                            setOpenUserDetails(true);
                                        }}
                                    >
                                        {outcome.creator.first_name +
                                            " " +
                                            outcome.creator.last_name}
                                    </span>
                                    <div className="clear-both"></div>
                                    <span className="font-bold">
                                        Last updated:
                                    </span>{" "}
                                    {format(
                                        new Date(outcome.updated),
                                        "dd MMM yyyy"
                                    )}
                                </div>
                                <div>
                                    <DownloadAsPdf
                                        tableTitle={`Programme Outcome: `}
                                        tableHeaders={tableHeaders}
                                        tableData={[outcome]}
                                        tableDataResult={outcome.programme.name}
                                        printType={`page`}
                                        filename={`Programme Outcome: ${outcome.programme.name}`}
                                    />
                                </div>
                            </div>
                            <div className="space-y-2">
                                <div className="grid grid-cols-12">
                                    <div className="col-span-12">
                                        <label
                                            className="text-sm font-bold"
                                            htmlFor="programme_objectives"
                                        >
                                            Programme objectives:
                                        </label>
                                    </div>
                                    <div className="col-span-12 text-sm">
                                        {outcome.programme_objectives}
                                    </div>
                                </div>

                                <div className="grid grid-cols-12">
                                    <div className="col-span-12">
                                        <label
                                            className="text-sm font-bold"
                                            htmlFor="programme_outcomes"
                                        >
                                            Programme outcomes:
                                        </label>
                                    </div>
                                    <div className="col-span-12 text-sm">
                                        {outcome.programme_outcomes}
                                    </div>
                                </div>

                                <div className="grid grid-cols-12">
                                    <div className="col-span-12">
                                        <label
                                            className="text-sm font-bold"
                                            htmlFor="lessons_learned"
                                        >
                                            Lessons learned:
                                        </label>
                                    </div>
                                    <div className="col-span-12 text-sm">
                                        {outcome.lessons_learned}
                                    </div>
                                </div>

                                <div className="grid grid-cols-12">
                                    <div className="col-span-12">
                                        <label
                                            className="text-sm font-bold"
                                            htmlFor="revenue_collected"
                                        >
                                            Revenue:
                                        </label>
                                    </div>
                                    <div className="col-span-12 text-sm">
                                        {outcome.revenue_collected}
                                    </div>
                                </div>

                                <div className="grid grid-cols-12">
                                    <div className="col-span-12">
                                        <label
                                            className="text-sm font-bold"
                                            htmlFor="programme_impact"
                                        >
                                            Programme impact:
                                        </label>
                                    </div>
                                    <div className="col-span-12 text-sm">
                                        {outcome.programme_impact.name}
                                    </div>
                                </div>

                                <div className="grid grid-cols-12">
                                    <div className="col-span-12">
                                        <label
                                            className="text-sm font-bold"
                                            htmlFor="programme_impact_details"
                                        >
                                            Programme impact details:
                                        </label>
                                    </div>
                                    <div className="col-span-12 text-sm">
                                        {outcome.programme_impact_details}
                                    </div>
                                </div>

                                <div className="grid grid-cols-12">
                                    <div className="col-span-12">
                                        <label
                                            className="text-sm font-bold"
                                            htmlFor="programme_report"
                                        >
                                            Programme report:
                                        </label>
                                    </div>
                                    <div className="col-span-12 text-sm">
                                        <a
                                            href={outcome.programme_report}
                                            target="_blank"
                                            className={`brand-link`}
                                            rel="noreferrer"
                                        >
                                            {outcome.programme_report}
                                        </a>
                                    </div>
                                </div>

                                <div className="flex justify-center">
                                    {loggedInUser.user_type.name ===
                                        "Country Programmes Manager" ||
                                    loggedInUser.user_type.name ===
                                        "Administrator" ? (
                                        <button
                                            type="button"
                                            className="button mx-1 mt-4"
                                            onClick={() => reOpenProgramme()}
                                        >
                                            Re-Open Programme
                                        </button>
                                    ) : null}
                                    <button
                                        type="button"
                                        className="button-default mx-1 mt-4"
                                        onClick={() => setOpen(false)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <Alert
                                type={`success`}
                                message={`Programme successfully re-opened.`}
                            />

                            <div className="flex justify-center">
                                <button
                                    type="button"
                                    className="button-default mx-1 mt-4"
                                    onClick={() => {
                                        setOpen(false);
                                        window.location.reload(false);
                                    }}
                                >
                                    Close
                                </button>
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    );
}
