import { useState, useEffect, useContext } from "react";
import { format } from "date-fns";
import { useFormValidation } from "hooks/FormValidationHook";
import { API_ROUTES } from "api";
import { ApiConsumer } from "api-consumer";
import { AuthenticationContext } from "hooks/AuthenticationContext";

import "react-datepicker/dist/react-datepicker.css";
import Alert from "components/alert";
import { AppLoaderContext } from "hooks/AppLoaderContext";

export default function AddEditMissionOutcomes({
    mission,
    setOpen,
    editableRow,
    setRowUpdated,
    setEditableRow,
}) {
    let { setLoading } = useContext(AppLoaderContext);
    let { currentUser } = useContext(AuthenticationContext);
    let { validFormDetails } = useFormValidation();
    const [invalidFormDetails, setInvalidFormDetails] = useState({});
    const [formDetails, setFormDetails] = useState({});
    const [formError, setFormError] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [missionOutcomeImpacts, setMissionOutcomeImpacts] = useState([]);

    const formFields = [
        {
            name: "no_of_participants",
            display: "beneficiaries/no of participants",
        },
        {
            name: "participants_by_language",
            display: "no of participants by language",
        },
        {
            name: "participants_by_org",
            display: "no of participant by organization",
        },
        {
            name: "participants_by_region",
            display: "no of participant by region",
        },
        { name: "african_experts", display: "no of African experts utilised" },
        { name: "mission_objectives", display: "mission objectives" },
        {
            name: "geographic_coverage",
            display: "activity geographic coverage",
        },
        { name: "gender_disaggregation", display: "gender" },
        { name: "context_of_activity", display: "context of activity" },
        {
            name: "risks_assumptions",
            display: "activity associated risks/assumptions",
        },
        { name: "additional_outcomes", display: "additional outcomes" },
        { name: "lessons_learned", display: "lessons learned" },
        { name: "mission_impact", display: "mission impact" },
        { name: "mission_impact_details", display: "mission impact details" },
        { name: "mission_report", display: "mission report" },
    ];

    const validateAddEditMissionOutcomes = (isEdit) => {
        if (
            validFormDetails(
                formFields,
                formDetails,
                setInvalidFormDetails,
                setFormError,
                editableRow
            )
        ) {
            setLoading(true);

            let formData = new FormData();
            for (const key in formDetails) {
                formData.append(
                    key,
                    key.includes("date")
                        ? formDetails[key].toISOString().substring(0, 10)
                        : formDetails[key]
                );
            }

            formData.append("mission_report", formDetails.mission_report);

            if (isEdit) {
                ApiConsumer.put(
                    API_ROUTES.OUTCOMES.MISSION_DETAIL(mission.id),
                    formData
                )
                    .then((res) => {
                        setRowUpdated(true);
                        setFormSubmitted(true);
                    })
                    .catch((err) => {
                        setFormError(err.toString());
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                ApiConsumer.post(API_ROUTES.OUTCOMES.OUTCOMES, formData)
                    .then((res) => {
                        setRowUpdated(true);
                        setFormSubmitted(true);
                    })
                    .catch((err) => {
                        setFormError(err.toString());
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    };

    useEffect(() => {
        ApiConsumer.get(API_ROUTES.MISSION.IMPACT)
            .then((res) => {
                res.data.sort((a, b) => a.name.localeCompare(b.name));
                setMissionOutcomeImpacts(res.data);
            })
            .catch((err) => {});
    }, [editableRow]);

    useEffect(() => {
        ApiConsumer.get(API_ROUTES.OUTCOMES.MISSION_DETAIL(mission.id)).then(
            (res) => {
                if (res.data.length) setEditableRow(res.data[0]);
            }
        );
    }, []);

    useEffect(() => {
        /* Defaults */
        setFormDetails((prevState) => ({
            ...prevState,
            [`creator`]: currentUser.id,
        }));

        setFormDetails((prevState) => ({
            ...prevState,
            [`mission`]: mission.id,
        }));

        setFormDetails((prevState) => ({
            ...prevState,
            [`no_of_participants`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`participants_by_language`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`participants_by_org`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`participants_by_region`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`african_experts`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`mission_objectives`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`geographic_coverage`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`gender_disaggregation`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`context_of_activity`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`risks_assumptions`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`additional_outcomes`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`lessons_learned`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`mission_impact`]: "selected",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`mission_impact_details`]: "",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`mission_report`]: null,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`closed`]: true,
        }));

        /* If editing */
        if (Object.keys(editableRow).length !== 0) {
            setFormDetails((prevState) => ({
                ...prevState,
                [`no_of_participants`]: editableRow.no_of_participants,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`participants_by_language`]:
                    editableRow.participants_by_language,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`participants_by_org`]: editableRow.participants_by_org,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`participants_by_region`]: editableRow.participants_by_region,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`african_experts`]: editableRow.african_experts,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`mission_objectives`]: editableRow.mission_objectives,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`geographic_coverage`]: editableRow.geographic_coverage,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`gender_disaggregation`]: editableRow.gender_disaggregation,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`context_of_activity`]: editableRow.context_of_activity,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`risks_assumptions`]: editableRow.risks_assumptions,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`additional_outcomes`]: editableRow.additional_outcomes,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`lessons_learned`]: editableRow.lessons_learned,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`mission_impact`]: editableRow.mission_impact.id,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`mission_impact_details`]: editableRow.mission_impact_details,
            }));
        }
    }, [editableRow, currentUser, mission]);

    useEffect(() => {
        return () => {
            setMissionOutcomeImpacts([]);
            setInvalidFormDetails({});
            setFormDetails({});
            setFormError("");
            setFormSubmitted(false);
        };
    }, []);

    return (
        <>
            {!formSubmitted ? (
                <form
                    className="space-y-6"
                    onSubmit={(event) => {
                        event.preventDefault();
                        validateAddEditMissionOutcomes(
                            Object.keys(editableRow).length
                        );
                    }}
                    method="POST"
                    encType="multipart/form-data"
                >
                    {Object.keys(editableRow).length !== 0 && (
                        <div className="text-sm border-gray-200 p-4 rounded-lg bg-gray-100">
                            <div className="float-left">
                                <span className="font-bold">Created:</span>{" "}
                                {format(
                                    new Date(editableRow.created),
                                    "dd MMM yyyy"
                                )}{" "}
                            </div>
                            <div className="float-right">
                                <span className="font-bold">Last updated:</span>{" "}
                                {format(
                                    new Date(editableRow.updated),
                                    "dd MMM yyyy"
                                )}{" "}
                            </div>
                            <div className="clear-both"></div>
                        </div>
                    )}
                    <div className="space-y-2">
                        <div className="grid grid-cols-12">
                            <div className="col-span-5">
                                <label className="text-sm" htmlFor="first_name">
                                    <strong>Mission:</strong>
                                </label>
                            </div>
                            <div className="col-span-7 text-sm">
                                <strong>{mission.name}</strong>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-5">
                                <label
                                    className="text-sm"
                                    htmlFor="no_of_participants"
                                >
                                    Beneficiaries/No of participants:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-7">
                                <input
                                    id="no_of_participants"
                                    name="no_of_participants"
                                    type="text"
                                    autoComplete="no_of_participants"
                                    className={`form-field ${
                                        invalidFormDetails.no_of_participants
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`no_of_participants`]:
                                                event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.no_of_participants
                                            ? formDetails.no_of_participants
                                            : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-5">
                                <label
                                    className="text-sm"
                                    htmlFor="participants_by_language"
                                >
                                    No of participants by language:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-7">
                                <input
                                    id="participants_by_language"
                                    name="participants_by_language"
                                    type="text"
                                    autoComplete="participants_by_language"
                                    className={`form-field ${
                                        invalidFormDetails.participants_by_language
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`participants_by_language`]:
                                                event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.participants_by_language
                                            ? formDetails.participants_by_language
                                            : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-5">
                                <label
                                    className="text-sm"
                                    htmlFor="participants_by_org"
                                >
                                    No of participant by organization:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-7">
                                <input
                                    id="participants_by_org"
                                    name="participants_by_org"
                                    type="text"
                                    autoComplete="participants_by_org"
                                    className={`form-field ${
                                        invalidFormDetails.participants_by_org
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`participants_by_org`]:
                                                event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.participants_by_org
                                            ? formDetails.participants_by_org
                                            : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-5">
                                <label
                                    className="text-sm"
                                    htmlFor="participants_by_region"
                                >
                                    No of participant by region:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-7">
                                <input
                                    id="participants_by_region"
                                    name="participants_by_region"
                                    type="text"
                                    autoComplete="participants_by_region"
                                    className={`form-field ${
                                        invalidFormDetails.participants_by_region
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`participants_by_region`]:
                                                event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.participants_by_region
                                            ? formDetails.participants_by_region
                                            : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-5">
                                <label
                                    className="text-sm"
                                    htmlFor="african_experts"
                                >
                                    No of African experts utilised:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-7">
                                <input
                                    id="african_experts"
                                    name="african_experts"
                                    type="text"
                                    autoComplete="african_experts"
                                    className={`form-field ${
                                        invalidFormDetails.african_experts
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`african_experts`]:
                                                event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.african_experts
                                            ? formDetails.african_experts
                                            : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-5">
                                <label
                                    className="text-sm"
                                    htmlFor="mission_objectives"
                                >
                                    Mission objectives:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-7">
                                <textarea
                                    id="mission_objectives"
                                    name="mission_objectives"
                                    autoComplete="mission_objectives"
                                    className={`form-field ${
                                        invalidFormDetails.mission_objectives
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`mission_objectives`]:
                                                event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.mission_objectives
                                            ? formDetails.mission_objectives
                                            : ""
                                    }
                                ></textarea>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-5">
                                <label
                                    className="text-sm"
                                    htmlFor="geographic_coverage"
                                >
                                    Activity geographic coverage:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-7">
                                <textarea
                                    id="geographic_coverage"
                                    name="geographic_coverage"
                                    autoComplete="geographic_coverage"
                                    className={`form-field ${
                                        invalidFormDetails.geographic_coverage
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`geographic_coverage`]:
                                                event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.geographic_coverage
                                            ? formDetails.geographic_coverage
                                            : ""
                                    }
                                ></textarea>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-5">
                                <label
                                    className="text-sm"
                                    htmlFor="gender_disaggregation"
                                >
                                    Gender (disaggregation by female and male):{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-7">
                                <textarea
                                    id="gender_disaggregation"
                                    name="gender_disaggregation"
                                    autoComplete="gender_disaggregation"
                                    className={`form-field ${
                                        invalidFormDetails.gender_disaggregation
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`gender_disaggregation`]:
                                                event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.gender_disaggregation
                                            ? formDetails.gender_disaggregation
                                            : ""
                                    }
                                ></textarea>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-5">
                                <label
                                    className="text-sm"
                                    htmlFor="context_of_activity"
                                >
                                    Context of activity:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-7">
                                <textarea
                                    id="context_of_activity"
                                    name="context_of_activity"
                                    autoComplete="context_of_activity"
                                    className={`form-field ${
                                        invalidFormDetails.context_of_activity
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`context_of_activity`]:
                                                event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.context_of_activity
                                            ? formDetails.context_of_activity
                                            : ""
                                    }
                                ></textarea>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-5">
                                <label
                                    className="text-sm"
                                    htmlFor="risks_assumptions"
                                >
                                    Activity associated risks/assumptions:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-7">
                                <textarea
                                    id="risks_assumptions"
                                    name="risks_assumptions"
                                    autoComplete="risks_assumptions"
                                    className={`form-field ${
                                        invalidFormDetails.risks_assumptions
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`risks_assumptions`]:
                                                event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.risks_assumptions
                                            ? formDetails.risks_assumptions
                                            : ""
                                    }
                                ></textarea>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-5">
                                <label
                                    className="text-sm"
                                    htmlFor="additional_outcomes"
                                >
                                    Additional outcomes:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-7">
                                <textarea
                                    id="additional_outcomes"
                                    name="additional_outcomes"
                                    autoComplete="additional_outcomes"
                                    className={`form-field ${
                                        invalidFormDetails.additional_outcomes
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`additional_outcomes`]:
                                                event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.additional_outcomes
                                            ? formDetails.additional_outcomes
                                            : ""
                                    }
                                ></textarea>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-5">
                                <label
                                    className="text-sm"
                                    htmlFor="lessons_learned"
                                >
                                    Lessons learned:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-7">
                                <textarea
                                    id="lessons_learned"
                                    name="lessons_learned"
                                    autoComplete="lessons_learned"
                                    className={`form-field ${
                                        invalidFormDetails.lessons_learned
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`lessons_learned`]:
                                                event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.lessons_learned
                                            ? formDetails.lessons_learned
                                            : ""
                                    }
                                ></textarea>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-5">
                                <label
                                    className="text-sm"
                                    htmlFor="mission_impact"
                                >
                                    Mission impact:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-7">
                                <select
                                    id="mission_impact"
                                    name="mission_impact"
                                    autoComplete="mission_impact"
                                    className={`${
                                        invalidFormDetails.mission_impact
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`mission_impact`]:
                                                event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.mission_impact
                                            ? formDetails.mission_impact
                                            : "selected"
                                    }
                                >
                                    <option value="selected">
                                        Select mission impact
                                    </option>
                                    {missionOutcomeImpacts.map((impact) => {
                                        return (
                                            <option
                                                key={impact.id}
                                                value={impact.id}
                                            >
                                                {impact.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>

                        {formDetails.mission_impact !== "selected" && (
                            <div className="grid grid-cols-12">
                                <div className="col-span-5">
                                    <label
                                        className="text-sm"
                                        htmlFor="mission_impact_details"
                                    >
                                        Mission impact details:{" "}
                                        <span className="text-red-600">*</span>
                                    </label>
                                </div>
                                <div className="col-span-7">
                                    <textarea
                                        id="mission_impact_details"
                                        name="mission_impact_details"
                                        autoComplete="mission_impact_details"
                                        className={`form-field ${
                                            invalidFormDetails.mission_impact_details
                                                ? `border-red-600`
                                                : `border-gray-300`
                                        }`}
                                        onChange={(event) => {
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`mission_impact_details`]:
                                                    event.target.value,
                                            }));
                                        }}
                                        value={
                                            formDetails.mission_impact_details
                                                ? formDetails.mission_impact_details
                                                : ""
                                        }
                                    ></textarea>
                                </div>
                            </div>
                        )}

                        <div className="grid grid-cols-12">
                            <div className="col-span-5">
                                <label
                                    className="text-sm"
                                    htmlFor="mission_report"
                                >
                                    Upload mission report:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-7">
                                {editableRow?.mission_report && (
                                    <a
                                        href={editableRow?.mission_report}
                                        target="_blank"
                                        className={`brand-link mb-2`}
                                        rel="noreferrer"
                                    >
                                        View current mission report
                                    </a>
                                )}
                                <input
                                    id="mission_report"
                                    name="mission_report"
                                    autoComplete="mission_report"
                                    className={`form-field ${
                                        invalidFormDetails.mission_report
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`mission_report`]:
                                                event.target.files[0],
                                        }));
                                    }}
                                    //value={formDetails.mission_report}
                                    type="file"
                                />
                            </div>
                        </div>

                        {formError.length !== 0 && (
                            <div className="col-span-12 form-error text-center">
                                {formError}
                            </div>
                        )}

                        <div className="flex justify-center">
                            <button
                                type="button"
                                className="button-default mx-1 mt-4"
                                onClick={() => setOpen(false)}
                            >
                                Close Popup
                            </button>
                            <button type="submit" className="button mx-1 mt-4">
                                {Object.keys(editableRow).length === 0
                                    ? `Add Outcome & Close Mission`
                                    : `Update`}
                            </button>
                        </div>
                    </div>
                </form>
            ) : (
                <>
                    <Alert
                        type={`success`}
                        message={`Mission outcome successfully ${
                            Object.keys(editableRow).length === 0
                                ? `added`
                                : `updated`
                        }.`}
                    />

                    <div className="flex justify-center">
                        <button
                            type="button"
                            className="button-default mx-1 mt-4"
                            onClick={() => setOpen(false)}
                        >
                            Close
                        </button>
                    </div>
                </>
            )}
        </>
    );
}
