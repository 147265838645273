import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { SearchIcon } from "@heroicons/react/outline";
import { getCache } from "hooks/cacheStorage";

export default function SearchDate({ loaded, searchReport, pageName }) {
  const [searchDetails, setSearchDetails] = useState({});

  useEffect(() => {
    let currDate;
    let lastYearDate;
    const cacheData = getCache('dateFilter');
    if (cacheData && pageName !== "DASHBOARD") {
      currDate = new Date(cacheData[1]);
      lastYearDate = new Date(cacheData[0]);
    }
    setSearchDetails((prevState) => ({
      ...prevState,
      [`start_date`]: lastYearDate,
    }));
    setSearchDetails((prevState) => ({
      ...prevState,
      [`end_date`]: currDate,
    }));
  }, []);

  const onDateChange = (dates) => {
    const [start, end] = dates;
    setSearchDetails((prevState) => ({
      ...prevState,
      [`start_date`]: start,
    }));
    setSearchDetails((prevState) => ({
      ...prevState,
      [`end_date`]: end,
    }));
  };

  return (
    <>
      <div className="col-span-3 ml-4 sm:ml-8 lg:ml-8 xl:ml-24 2xl:ml-64">
        <label className="text-sm float-left" htmlFor="date_range">
          Date range:
        </label>
        <DatePicker
          fixedHeight
          isClearable
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          showYearDropdown
          wrapperClassName="w-full"
          dateFormat="dd MMMM yyyy"
          selected={searchDetails.start_date}
          onChange={(event) => onDateChange(event)}
          className="form-field"
          startDate={searchDetails.start_date}
          endDate={searchDetails.end_date}
          selectsRange
        />
      </div>
      <button
        disabled={!loaded}
        type="button"
        className="button flex-shrink-0 ml-2 mt-4 mr-3"
        onClick={() => searchReport(searchDetails)}
      >
        <div
          className="flex items-center pointer-events-none mr-1"
          aria-hidden="true"
        >
          <SearchIcon className="h-5 w-5" aria-hidden="true" />
        </div>
        Search
      </button>
    </>
  );
}
