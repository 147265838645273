import { useEffect, useState } from "react";
import { PlusCircleIcon } from "@heroicons/react/outline";
import Navigation from "components/navigation";
import Header from "components/header";
import PageHeader from "components/page-header";
import Table from "components/table";
import Modal from "components/modal";
import Delete from "components/delete";
import AddEditUser from "components/add-edit-user";
import { useTextFormatHook } from "hooks/TextFormatHook";
import { ApiConsumer } from "api-consumer";
import { Redirect, NavLink, useRouteMatch } from "react-router-dom";
import { API_ROUTES } from "api";
import { PORTAL } from "portal";

export default function Users() {
    let loggedInUser = JSON.parse(sessionStorage.getItem(`ATAFTAX`));
    let { normaliseTableData } = useTextFormatHook();
    const userTypeParam = useRouteMatch(PORTAL.USERS + "/:type")?.params?.type;
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [openAddEditUser, setOpenAddEditUser] = useState(false);
    const [editableRow, setEditableRow] = useState({});
    const [rowUpdated, setRowUpdated] = useState(false);
    const [loadFlag, setLoadFlag] = useState(false);

    const pageHeaderButtons = [
        <button
            type="button"
            onClick={() => setOpenAddEditUser(true)}
            className={`button`}
        >
            <span className="button-icon">
                <PlusCircleIcon className="h-5 w-5" aria-hidden="true" />
            </span>
            Add {userTypeParam === "administrators" ? `Administrator` : `User`}
        </button>,
    ];

    const tableHeaders = [
        {
            key: "name",
            value: "Name",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "designation",
            value: "Designation",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "email",
            value: "Email Address",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "contact_number",
            value: "Contact Number",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "alternative_contact_number",
            value: "Alt Contact Number",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "user_type",
            value: "User Type",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "country_id",
            value: "Country ID",
            showOnTable: false,
            showOnPrint: false,
        },
        {
            key: "country_name",
            value: "Country of Citizenship",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "created",
            value: "Created",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "updated",
            value: "Updated",
            showOnTable: false,
            showOnPrint: true,
        },
    ];

    const addLinksToTableData = (tableData) => {
        tableData.forEach((row) => {
            row.normalised.name = (
                <>
                    <NavLink
                        to={`${PORTAL.PROGRAMMES_BY_USER}/${row.id}`}
                        className={`brand-link`}
                    >
                        {row.normalised.name}
                    </NavLink>
                    <Delete
                        apiRoute={API_ROUTES.USERS.USER_DETAIL}
                        rowData={row}
                        loadData={loadData}
                        titleValue={"User"}
                    />
                </>
            );
            row.name = row.first_name + " " + row.last_name;
        });
        return tableData;
    };

    const loadData = () => {
        ApiConsumer.get(API_ROUTES.USERS.USERS)
            .then((res) => {
                res.data.sort((a, b) =>
                    a.first_name.localeCompare(b.first_name)
                );
                function AdminFilter(user_type) {
                    return user_type === "Administrator";
                }
                let normalisedData = normaliseTableData(
                    tableHeaders,
                    userTypeParam === "administrators"
                        ? res.data.filter((type) =>
                              AdminFilter(type?.user_type?.name)
                          )
                        : res.data.filter(
                              (type) => !AdminFilter(type?.user_type?.name)
                          )
                );
                setUsers(addLinksToTableData(normalisedData));
            })
            .catch((err) => {})
            .finally(() => {
                setLoaded(true);
            });
    };

    useEffect(() => {
        loadData();
        return () => {
            setUsers([]);
            setLoaded(false);
            setSidebarOpen(false);
            setOpenAddEditUser(false);
            setEditableRow({});
            setRowUpdated(false);
        };
    }, [userTypeParam]);

    const delay = (delayInms) => {
        return new Promise(resolve => setTimeout(resolve, delayInms));
      };

    const onCloseAddEditUser = async (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            if (rowUpdated) {
                loadData();
                setLoadFlag(true);
                await delay(1500);
                setLoadFlag(false);
                setRowUpdated(false);
            }
            setEditableRow({});
        }
        setOpenAddEditUser(modalStatus);
    };

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableRow).length === 0
            ? setOpenAddEditUser(false)
            : setOpenAddEditUser(true);
    }, [editableRow]);

    if (loggedInUser.user_type.name !== "Administrator")
        return <Redirect to={PORTAL.LOGIN} />;

    return (
        <>
            <Modal
                modalHeaderTag={""}
                modalHeader={
                    Object.keys(editableRow).length === 0
                        ? `Add New ${
                              userTypeParam === "administrators"
                                  ? `Administrator`
                                  : `User`
                          }`
                        : `Edit ${
                              userTypeParam === "administrators"
                                  ? `Administrator`
                                  : `User`
                          }: ${
                              editableRow.first_name +
                              " " +
                              editableRow.last_name
                          }`
                }
                modalContent={
                    <AddEditUser
                        userTypeParam={userTypeParam}
                        setOpen={onCloseAddEditUser}
                        editableRow={editableRow}
                        setRowUpdated={setRowUpdated}
                        loading={loadFlag}
                    />
                }
                open={openAddEditUser}
                setOpen={onCloseAddEditUser}
            />

            <div className="min-h-full bg-gray-100">
                <Navigation
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                />

                <div className="lg:pl-64 flex flex-col flex-1">
                    <Header />

                    <main className="flex-1 pb-8">
                        <PageHeader
                            pageHeaderName={`${
                                userTypeParam === "administrators"
                                    ? `System Administrators`
                                    : `System Users`
                            } (${users?.length})`}
                            pageHeaderButtons={pageHeaderButtons}
                        />

                        {loaded && (
                            <Table
                                tableTitle={`${
                                    userTypeParam === "administrators"
                                        ? `System Administrators`
                                        : `System Users`
                                }`}
                                tableHeaders={tableHeaders}
                                tableData={users}
                                editable={true}
                                setEditableRow={setEditableRow}
                            />
                        )}
                    </main>
                </div>
            </div>
        </>
    );
}
