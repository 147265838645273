import React from 'react';

export const NotificationContext = React.createContext({
    showNotifications: {
        panel: false,
        popup: false
    },
    setNotification: () => {
    }
});
