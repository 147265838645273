import ReactEcharts from "echarts-for-react";
import { useEffect, useState } from "react";
import { ApiConsumer } from "api-consumer";

export function Doughnut({ url = "", name = "", title = "", type = "" }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState('loading ..');

  const colours = ["#5fa83f", "#f72585", "#118ab2", "#ff9770", "#d35400"];

  useEffect(() => {
    setLoading(true);
    ApiConsumer.get(url)
    .then((res) => {
      let temp = [];
      let sum = 0;
      res.data.map((r) => { sum = sum + r.count});
      res.data.map((r) => {
        if (r.name) temp.push({name: r.name, value: r.count});
      });
      setData(temp);
      if (data.length === 0) {
        setErrorMsg('No data');
      }
    })
    .catch((err) => console.error(err))
    .finally(() => setLoading(false));
  }, [url]);

  const displayPercent = (data) => {
    if (data.percent % 1 === 0) {
        return `${data.percent}%`;
    } else if (data.percent.toFixed(1) % 1 === 0) {
        return `${data.percent.toFixed()}%`;
    } else {
        return `${data.percent.toFixed(1)}%`;
    }
  };

  const getOptions = () => ({
    tooltip: {
      trigger: "item",
      formatter: function (params) {
        return `${params.name} : ${params.value} (${displayPercent(params)})`;
      },
    },
    legend: {
      orient: "horizontal",
      bottom: "bottom",
      x: 'center',
      data: data.map((item) => item.name)
    },
    series: [
      {
        name: "",
        type: "pie",
        radius: ["30%", "55%"],
        center: ["50%", "35%"],
        label: {
          formatter: function (params) {
            if (params.percent % 1 === 0) {
                return `${params.percent}%`;
            } else if (params.percent.toFixed(1) % 1 === 0) {
                return `${params.percent.toFixed()}%`;
            } else {
                return `${params.percent.toFixed(1)}%`;
            }
          },
          position: 'outside'
        },
        value: data.map((item) => item.value),
        startAngle: 150,
        data: data,
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)"
          }
        }
      }
    ]
  });

  return (
    <>
      <div className="min-w-0 flex-1">
        <div className="align-middle min-w-full overflow-x-auto shadow-sm overflow-hidden sm:rounded-lg">
          <h3 className="bg-white p-2 text-center">{title}</h3>
          <div style={{ height: "530px", backgroundColor: "#fff"}}>
            {!data || data.length === 0 ? (
                <div style={{textAlign: 'center', position: 'relative', top: '40%'}}>{errorMsg}</div>
            ) : (
                <ReactEcharts option={getOptions()} style={{ height: 500 }} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
