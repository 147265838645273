import { useEffect, useState, useContext } from "react";
import { SearchIcon } from "@heroicons/react/outline";
import Navigation from "components/navigation";
import Header from "components/header";
import PageHeader from "components/page-header";
import Table from "components/table";
import { sub, add, compareDesc, isAfter } from "date-fns";
import { ApiConsumer } from "api-consumer";
import { NavLink } from "react-router-dom";

import { API_ROUTES } from "api";
import { PORTAL } from "portal";
import { useTextFormatHook } from "hooks/TextFormatHook";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AppLoader } from "components/app-loader";
import { AppLoaderContext } from "hooks/AppLoaderContext";

export default function ProgrammeReports() {
    let { setLoading } = useContext(AppLoaderContext);
    let { normaliseTableData } = useTextFormatHook();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [formDetails, setFormDetails] = useState({});
    const [countries, setCountries] = useState([]);
    const [programmeTypes, setProgrammeTypes] = useState([]);
    const [programmeCategories, setProgrammeCategories] = useState([]);
    const [programmeTaxTypes, setProgrammeTaxTypes] = useState([]);
    const [programmeUnits, setProgrammeUnits] = useState([]);

    const [programmeDepartments, setProgrammeDepartments] = useState([]);
    const [programmeManagers, setProgrammeManagers] = useState([]);
    const [programmesReport, setProgrammesReport] = useState([]);

    const [expertUsers, setExpertUsers] = useState([]);

    const PROGRAMME_HEADERS = [
        {
            key: "name",
            value: "Programme",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "country",
            value: "Country",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "overview",
            value: "Brief overview",
            showOnTable: false,
            showOnPrint: false,
        },
        {
            key: "workplan",
            value: "Workplan",
            showOnTable: false,
            showOnPrint: false,
        },
        { key: "type", value: "Type", showOnTable: true, showOnPrint: true },
        {
            key: "category",
            value: "Category",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "departments",
            value: "Specific Subject Matter",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "tax_types",
            value: "Tax Types",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "internal_experts",
            value: "Internal expert(s) able to manage missions",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "external_experts",
            value: "External expert(s) able to manage missions",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "internal_units",
            value: "Internal Units",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "duration",
            value: "Duration",
            showOnTable: true,
            showOnPrint: false,
        },
        {
            key: "start_date",
            value: "Start Date",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "end_date",
            value: "End Date",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "report_users",
            value: "View programme reports",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "created",
            value: "Created",
            showOnTable: false,
            showOnPrint: false,
        },
        {
            key: "updated",
            value: "Updated",
            showOnTable: false,
            showOnPrint: false,
        },
    ];

    const STATUS = [
        {
            value: "open",
            label: "OPEN",
        },
        {
            value: "closed",
            label: "CLOSED",
        },
        {
            value: "overdue",
            label: "OVERDUE",
        },
    ];

    const SLIDER = [
        {
            NAME: "NOT STARTED",
            MIN: 0,
            MAX: 1,
            STYLE: "bg-red-800",
        },
        {
            NAME: "POOR",
            MIN: 1,
            MAX: 33,
            STYLE: "bg-red-500",
        },
        {
            NAME: "PROGRESSIVE",
            MIN: 33,
            MAX: 66,
            STYLE: "bg-yellow-500",
        },
        {
            NAME: "GOOD",
            MIN: 66,
            MAX: 99,
            STYLE: "bg-green-500",
        },
        {
            NAME: "COMPLETE",
            MIN: 99,
            MAX: 100,
            STYLE: "bg-green-800",
        },
    ];

    const addProgrammeLinksToTableData = (tableData) => {
        tableData.forEach((row) => {
            row.normalised.name = (
                <>
                    <NavLink
                        to={`${PORTAL.MISSIONS_BY_PROGRAMME}/${row.id}`}
                        className={`brand-link`}
                    >
                        {row.normalised.name}
                    </NavLink>
                    <br />
                    <span className="text-xs">
                        ({row.total_missions}{" "}
                        {row.total_missions === 1 ? `mission` : `missions`})
                    </span>
                </>
            );
        });
        return tableData;
    };

    const searchReport = () => {
        setLoading(true);
        for (const key in formDetails) {
            if (
                Array.isArray(formDetails[key]) &&
                formDetails[key].length === 0
            ) {
                delete formDetails[key];
            }
        }

        ApiConsumer.post(API_ROUTES.REPORT.PROGRAMMES, formDetails)
            .then((res) => {
                res.data.sort((a, b) =>
                    compareDesc(new Date(a.updated), new Date(b.updated))
                );
                //Group by "id" to avoid duplicates
                res.data = res.data.filter(
                    (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
                );
                //Filter by status
                res.data.map((row, i) => {
                    if (
                        isAfter(new Date(), new Date(row.end_date)) &&
                        row.overall_progress < 100
                    )
                        res.data[i]["status"] = "OVERDUE".toLowerCase();
                    else
                        res.data[i]["status"] = SLIDER.filter(
                            (x) =>
                                x.MIN <= row.overall_progress &&
                                x.MAX >= row.overall_progress
                        )[0].NAME.toLowerCase();
                });
                if (
                    formDetails.programme_status !== undefined &&
                    formDetails.programme_status.length !== 0
                )
                    res.data = res.data.filter(
                        (report) =>
                            formDetails.programme_status.indexOf(
                                report.status
                            ) !== -1
                    );

                let normalisedProgrammeData = normaliseTableData(
                    PROGRAMME_HEADERS,
                    res.data
                );
                setProgrammesReport(
                    addProgrammeLinksToTableData(normalisedProgrammeData)
                );
            })
            .catch((err) => {})
            .finally(() => {
                setLoading(false);
            });
    };

    const onProgrammeDateChange = (dates) => {
        const [start, end] = dates;
        setFormDetails((prevState) => ({
            ...prevState,
            [`programme_start_date`]: start,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`programme_end_date`]: end,
        }));
    };

    useEffect(() => {
        setFormDetails((prevState) => ({
            ...prevState,
            [`programme_start_date`]: sub(new Date(), { years: 1 }),
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`programme_end_date`]: add(new Date(), { years: 1 }),
        }));
    }, []);

    const loadDropdown = (link, setMethod) => {
        ApiConsumer.get(link)
            .then((res) => {
                res.data.sort((a, b) => a.name.localeCompare(b.name));
                let options = res.data.map((option) => {
                    return {
                        value: option.id,
                        label: option.name,
                    };
                });
                setMethod(options);
            })
            .catch((err) => {});
    };

    useEffect(() => {
        loadDropdown(API_ROUTES.CONTENT.COUNTRY, setCountries);
        loadDropdown(API_ROUTES.PROGRAMME.CATEGORIES, setProgrammeCategories);
        loadDropdown(API_ROUTES.PROGRAMME.TAX_TYPES, setProgrammeTaxTypes);
        loadDropdown(API_ROUTES.PROGRAMME.INTERNAL_UNITS, setProgrammeUnits);
        loadDropdown(API_ROUTES.PROGRAMME.TYPES, setProgrammeTypes);
        loadDropdown(API_ROUTES.PROGRAMME.DEPARTMENTS, setProgrammeDepartments);

        ApiConsumer.get(API_ROUTES.USERS.USERS)
            .then((res) => {
                res.data.sort((a, b) =>
                    a.first_name.localeCompare(b.first_name)
                );
                let users = res.data.filter(
                    (type) =>
                        type.user_type.name === "Country Programmes Manager" ||
                        type.user_type.name === "Country Programmes Officer" ||
                        type.user_type.name === "Administrator"
                );
                let userOptions = users.map((user) => {
                    return {
                        value: user.id,
                        label: user.first_name + " " + user.last_name,
                    };
                });
                setProgrammeManagers(userOptions);

                users = res.data.filter(
                    (type) =>
                        type.user_type.name === "Internal Expert" ||
                        type.user_type.name === "External Expert"
                );
                userOptions = users.map((user) => {
                    return {
                        value: user.id,
                        label: user.first_name + " " + user.last_name,
                    };
                });
                setExpertUsers(userOptions);
            })
            .catch((err) => {});

        return () => {
            setProgrammeManagers([]);
            setProgrammeTypes([]);
            setProgrammeCategories([]);
            setProgrammeUnits([]);
            setProgrammeTaxTypes([]);
            setProgrammeDepartments([]);
            setExpertUsers([]);
            setSidebarOpen(false);
        };
    }, []);

    const clearSearch = () => {
        setFormDetails((prevState) => ({
            ...prevState,
            [`countries`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`programme_types`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`categories`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`tax_types`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`internal_units`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`departments`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`programme_users`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`expert_users`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`programme_status`]: [],
        }));
        setProgrammesReport([]);
    };

    return (
        <>
            <div className="min-h-full bg-gray-100">
                <Navigation
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                />

                <div className="lg:pl-64 flex flex-col flex-1">
                    <Header />

                    <main className="flex-1 pb-8">
                        <PageHeader pageHeaderName={`Programme Reports`} />

                        <div className="mx-full px-4 sm:px-6 lg:px-8 pt-2 my-2">
                            <div className="grid grid-cols-12 space-x-4">
                                <div className="col-span-3">
                                    <label
                                        className="text-sm"
                                        htmlFor="countries"
                                    >
                                        Countries:
                                    </label>
                                    <Select
                                        id="countries"
                                        name="countries"
                                        placeholder="All countries"
                                        isMulti
                                        options={countries}
                                        classNamePrefix="multi-select"
                                        className={`multi-select text-xs`}
                                        onChange={(event) => {
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`countries`]: [...event].map(
                                                    (option) => option.value
                                                ),
                                            }));
                                        }}
                                        defaultValue={0}
                                        value={
                                            Object.keys(countries).length !==
                                                0 &&
                                            formDetails.countries !== undefined
                                                ? formDetails?.countries?.map(
                                                      (selectedOption) => {
                                                          return countries[
                                                              countries.findIndex(
                                                                  (option) =>
                                                                      option.value ===
                                                                      selectedOption
                                                              )
                                                          ];
                                                      }
                                                  )
                                                : 0
                                        }
                                    />
                                </div>
                                <div className="col-span-3">
                                    <label
                                        className="text-sm"
                                        htmlFor="programme_types"
                                    >
                                        Programme types:
                                    </label>
                                    <Select
                                        id="programme_types"
                                        name="programme_types"
                                        placeholder="All types"
                                        isMulti
                                        options={programmeTypes}
                                        classNamePrefix="multi-select"
                                        className={`multi-select text-xs`}
                                        onChange={(event) => {
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`programme_types`]: [
                                                    ...event,
                                                ].map((option) => option.value),
                                            }));
                                        }}
                                        defaultValue={0}
                                        value={
                                            Object.keys(programmeTypes)
                                                .length !== 0 &&
                                            formDetails.programme_types !==
                                                undefined
                                                ? formDetails?.programme_types?.map(
                                                      (selectedOption) => {
                                                          return programmeTypes[
                                                              programmeTypes.findIndex(
                                                                  (option) =>
                                                                      option.value ===
                                                                      selectedOption
                                                              )
                                                          ];
                                                      }
                                                  )
                                                : 0
                                        }
                                    />
                                </div>
                                <div className="col-span-3">
                                    <label
                                        className="text-sm"
                                        htmlFor="categories"
                                    >
                                        Programme categories:
                                    </label>
                                    <Select
                                        id="categories"
                                        name="categories"
                                        placeholder="All categories"
                                        isMulti
                                        options={programmeCategories}
                                        classNamePrefix="multi-select"
                                        className={`multi-select text-xs`}
                                        onChange={(event) => {
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`categories`]: [...event].map(
                                                    (option) => option.value
                                                ),
                                            }));
                                        }}
                                        defaultValue={0}
                                        value={
                                            Object.keys(programmeCategories)
                                                .length !== 0 &&
                                            formDetails.categories !== undefined
                                                ? formDetails?.categories?.map(
                                                      (selectedOption) => {
                                                          return programmeCategories[
                                                              programmeCategories.findIndex(
                                                                  (option) =>
                                                                      option.value ===
                                                                      selectedOption
                                                              )
                                                          ];
                                                      }
                                                  )
                                                : 0
                                        }
                                    />
                                </div>
                                <div className="col-span-3">
                                    <label
                                        className="text-sm"
                                        htmlFor="tax_types"
                                    >
                                        Programme tax types:
                                    </label>
                                    <Select
                                        id="tax_types"
                                        name="tax_types"
                                        placeholder="All tax types"
                                        isMulti
                                        options={programmeTaxTypes}
                                        classNamePrefix="multi-select"
                                        className={`multi-select text-xs`}
                                        onChange={(event) => {
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`tax_types`]: [...event].map(
                                                    (option) => option.value
                                                ),
                                            }));
                                        }}
                                        defaultValue={0}
                                        value={
                                            Object.keys(programmeTaxTypes)
                                                .length !== 0 &&
                                            formDetails.tax_types !== undefined
                                                ? formDetails?.tax_types?.map(
                                                      (selectedOption) => {
                                                          return programmeTaxTypes[
                                                              programmeTaxTypes.findIndex(
                                                                  (option) =>
                                                                      option.value ===
                                                                      selectedOption
                                                              )
                                                          ];
                                                      }
                                                  )
                                                : 0
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mx-full px-4 sm:px-6 lg:px-8 pt-2 my-2">
                            <div className="grid grid-cols-12 space-x-4">
                                <div className="col-span-3">
                                    <label
                                        className="text-sm"
                                        htmlFor="internal_units"
                                    >
                                        Internal units:
                                    </label>
                                    <Select
                                        id="internal_units"
                                        name="internal_units"
                                        placeholder="All internal units"
                                        isMulti
                                        options={programmeUnits}
                                        classNamePrefix="multi-select"
                                        className={`multi-select text-xs`}
                                        onChange={(event) => {
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`internal_units`]: [
                                                    ...event,
                                                ].map((option) => option.value),
                                            }));
                                        }}
                                        defaultValue={0}
                                        value={
                                            Object.keys(programmeUnits)
                                                .length !== 0 &&
                                            formDetails.internal_units !==
                                                undefined
                                                ? formDetails?.internal_units?.map(
                                                      (selectedOption) => {
                                                          return programmeUnits[
                                                              programmeUnits.findIndex(
                                                                  (option) =>
                                                                      option.value ===
                                                                      selectedOption
                                                              )
                                                          ];
                                                      }
                                                  )
                                                : 0
                                        }
                                    />
                                </div>
                                <div className="col-span-3">
                                    <label
                                        className="text-sm"
                                        htmlFor="departments"
                                    >
                                        Specific subject matters:
                                    </label>
                                    <Select
                                        id="departments"
                                        name="departments"
                                        placeholder="All subject matters"
                                        isMulti
                                        options={programmeDepartments}
                                        classNamePrefix="multi-select"
                                        className={`multi-select text-xs`}
                                        onChange={(event) => {
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`departments`]: [...event].map(
                                                    (option) => option.value
                                                ),
                                            }));
                                        }}
                                        defaultValue={0}
                                        value={
                                            Object.keys(programmeDepartments)
                                                .length !== 0 &&
                                            formDetails.departments !==
                                                undefined
                                                ? formDetails?.departments?.map(
                                                      (selectedOption) => {
                                                          return programmeDepartments[
                                                              programmeDepartments.findIndex(
                                                                  (option) =>
                                                                      option.value ===
                                                                      selectedOption
                                                              )
                                                          ];
                                                      }
                                                  )
                                                : 0
                                        }
                                    />
                                </div>
                                <div className="col-span-3">
                                    <label
                                        className="text-sm"
                                        htmlFor="programme_users"
                                    >
                                        Programme managers/officers:
                                    </label>
                                    <Select
                                        id="programme_users"
                                        name="programme_users"
                                        placeholder="All users"
                                        isMulti
                                        options={programmeManagers}
                                        classNamePrefix="multi-select"
                                        className={`multi-select text-xs`}
                                        onChange={(event) => {
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`programme_users`]: [
                                                    ...event,
                                                ].map((option) => option.value),
                                            }));
                                        }}
                                        defaultValue={0}
                                        value={
                                            Object.keys(programmeManagers)
                                                .length !== 0 &&
                                            formDetails.programme_users !==
                                                undefined
                                                ? formDetails?.programme_users?.map(
                                                      (selectedOption) => {
                                                          return programmeManagers[
                                                              programmeManagers.findIndex(
                                                                  (option) =>
                                                                      option.value ===
                                                                      selectedOption
                                                              )
                                                          ];
                                                      }
                                                  )
                                                : 0
                                        }
                                    />
                                </div>
                                <div className="col-span-3">
                                    <label
                                        className="text-sm"
                                        htmlFor="expert_users"
                                    >
                                        Internal/external experts:
                                    </label>
                                    <Select
                                        id="expert_users"
                                        name="expert_users"
                                        placeholder="All users"
                                        isMulti
                                        options={expertUsers}
                                        classNamePrefix="multi-select"
                                        className={`multi-select text-xs`}
                                        onChange={(event) => {
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`expert_users`]: [
                                                    ...event,
                                                ].map((option) => option.value),
                                            }));
                                        }}
                                        defaultValue={0}
                                        value={
                                            Object.keys(expertUsers).length !==
                                                0 &&
                                            formDetails.expert_users !==
                                                undefined
                                                ? formDetails?.expert_users?.map(
                                                      (selectedOption) => {
                                                          return expertUsers[
                                                              expertUsers.findIndex(
                                                                  (option) =>
                                                                      option.value ===
                                                                      selectedOption
                                                              )
                                                          ];
                                                      }
                                                  )
                                                : 0
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="border-b border-gray-200 mx-full px-4 sm:px-6 lg:px-8 pb-3 my-3">
                            <div className="grid grid-cols-12 space-x-4">
                                <div className="col-span-3">
                                    <label
                                        className="text-sm"
                                        htmlFor="programme_status"
                                    >
                                        Programme status:
                                    </label>
                                    <Select
                                        id="programme_status"
                                        name="programme_status"
                                        placeholder="All statuses"
                                        isMulti
                                        options={STATUS}
                                        classNamePrefix="multi-select"
                                        className={`multi-select text-xs`}
                                        onChange={(event) => {
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`programme_status`]: [
                                                    ...event,
                                                ].map((option) => option.value),
                                            }));
                                        }}
                                        defaultValue={0}
                                        value={
                                            Object.keys(STATUS).length !== 0 &&
                                            formDetails.programme_status !==
                                                undefined
                                                ? formDetails?.programme_status?.map(
                                                      (selectedOption) => {
                                                          return STATUS[
                                                              STATUS.findIndex(
                                                                  (option) =>
                                                                      option.value ===
                                                                      selectedOption
                                                              )
                                                          ];
                                                      }
                                                  )
                                                : 0
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="relative py-2">
                            <AppLoader
                                pageContent={
                                    <h2 className="flex mx-full px-4 sm:px-6 lg:px-8 text-lg leading-6 text-gray-900 text-left items-center">
                                        <span className="min-w-0 flex-1 items-center font-medium">
                                            Programme Results (
                                            {programmesReport.length})
                                            <br />
                                            <span
                                                className="brand-link text-sm font-normal"
                                                onClick={() => clearSearch()}
                                            >
                                                Clear all search parameters
                                            </span>
                                        </span>

                                        <div className="col-span-3">
                                            <label
                                                className="text-sm float-left"
                                                htmlFor="date_range"
                                            >
                                                Date range:
                                            </label>
                                            <DatePicker
                                                fixedHeight
                                                onKeyDown={(e) => {
                                                    e.preventDefault();
                                                }}
                                                showYearDropdown
                                                dateFormat="dd MMMM yyyy"
                                                selected={
                                                    formDetails.programme_start_date
                                                }
                                                onChange={(event) =>
                                                    onProgrammeDateChange(event)
                                                }
                                                className="form-field"
                                                startDate={
                                                    formDetails.programme_start_date
                                                }
                                                endDate={
                                                    formDetails.programme_end_date
                                                }
                                                selectsRange
                                            />
                                        </div>
                                        <button
                                            type="button"
                                            className="button flex-shrink-0 ml-2 mt-4"
                                            onClick={() => searchReport()}
                                        >
                                            <div
                                                className="flex items-center pointer-events-none mr-1"
                                                aria-hidden="true"
                                            >
                                                <SearchIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            Search
                                        </button>
                                    </h2>
                                }
                            />
                        </div>
                        <Table
                            tableTitle={`Programme Report Results`}
                            tableHeaders={PROGRAMME_HEADERS}
                            tableData={programmesReport}
                            fontSize="text-xs"
                        />
                    </main>
                </div>
            </div>
        </>
    );
}
