const API_BASE = `https://taonline-api.ataftax.org/api/v1`;

export const API_ROUTES = {
    AUTHENTICATION: {
        LOGIN: `${API_BASE}/users/login`,
        PASSWORD_TOKEN: `${API_BASE}/users/password/token`,
        FORGOT_PASSWORD: `${API_BASE}/users/password/reset`,
    },
    PROGRAMME: {
        PROGRAMMES: `${API_BASE}/programmes`,
        PROGRAMME_DETAIL: function (programme_id) {
            return `${API_BASE}/programmes/${programme_id}`;
        },
        PROGRAMMES_BY_COUNTRY: function (country_id) {
            return `${API_BASE}/programmes/country/${country_id}`;
        },
        PROGRAMMES_BY_COUNTRYCATEGORY: function (country_id, category_id) {
            return `${API_BASE}/programmes/country/${country_id}/category/${category_id}`;
        },
        PROGRAMMES_BY_USER: function (user_id) {
            return `${API_BASE}/programmes/user/${user_id}`;
        },
        PROGRAMMES_BY_CATEGORY: function (category_id) {
            return `${API_BASE}/programmes/category/${category_id}`;
        },
        PROGRAMMES_BY_TYPE: function (type_id) {
            return `${API_BASE}/programmes/type/${type_id}`;
        },
        PROGRAMMES_BY_DEPARTMENT: function (department_id) {
            return `${API_BASE}/programmes/department/${department_id}`;
        },
        PROGRAMME_APPROVAL: function (programme_id) {
            return `${API_BASE}/programmes/approval/${programme_id}`;
        },
        PROGRAMME_EXTENTION: function (programme_id) {
            return `${API_BASE}/programmes/extension/${programme_id}`;
        },
        PROGRAMME_REOPEN: function (programme_id) {
            return `${API_BASE}/programmes/open/${programme_id}`;
        },
        CATEGORIES: `${API_BASE}/metadata/programme/categories`,
        CATEGORY_DETAIL: function (category_id) {
            return `${API_BASE}/metadata/programme/categories/${category_id}`;
        },
        TYPES: `${API_BASE}/metadata/programme/types`,
        TYPE_DETAIL: function (type_id) {
            return `${API_BASE}/metadata/programme/types/${type_id}`;
        },
        TAX_TYPES: `${API_BASE}/metadata/programme/tax_types`,
        TAX_TYPE_DETAIL: function (tax_type_id) {
            return `${API_BASE}/metadata/programme/tax_types/${tax_type_id}`;
        },
        DEPARTMENTS: `${API_BASE}/metadata/programme/departments`,
        DEPARTMENT_DETAIL: function (department_id) {
            return `${API_BASE}/metadata/programme/departments/${department_id}`;
        },
        INTERNAL_UNITS: `${API_BASE}/metadata/programme/internal_units`,
        INTERNAL_UNIT_DETAIL: function (internal_unit_id) {
            return `${API_BASE}/metadata/programme/internal_units/${internal_unit_id}`;
        },
        IMPACT: `${API_BASE}/metadata/programme/impact`,
        IMPACT_DETAIL: function (impact_id) {
            return `${API_BASE}/metadata/programme/impact/${impact_id}`;
        },
        GRAPHS: {
            COUNTRY: {
                DEPARTMENT: function (country_id) {
                    return `${API_BASE}/programmes/graphs/programme/department/${country_id}`;
                },
                CATEGORY: function (country_id) {
                    return `${API_BASE}/programmes/graphs/programme/category/${country_id}`;
                },
                TYPE: function (country_id) {
                    return `${API_BASE}/programmes/graphs/programme/type/${country_id}`;
                },
            },
            DEPARTMENT: `${API_BASE}/programmes/graphs/programme/department`,
            CATEGORY: `${API_BASE}/programmes/graphs/programme/category`,
            TYPE: `${API_BASE}/programmes/graphs/programme/type`,
            MISSION_TYPE: `${API_BASE}/programmes/graphs/mission/type`,
            MISSION_SUPPORT: `${API_BASE}/programmes/graphs/mission/area`,
            MISSION_BY_PARTNER: `${API_BASE}/missions/graphs/partners`,
            MISSION_BY_ACTIVITY: `${API_BASE}/missions/graphs/activity`,
        },
    },
    MISSION: {
        MISSIONS: `${API_BASE}/missions`,
        MISSION_DETAIL: function (mission_id) {
            return `${API_BASE}/missions/${mission_id}`;
        },
        MISSIONS_BY_PROGRAMME: function (programme_id) {
            return `${API_BASE}/missions/programme/${programme_id}`;
        },
        MISSIONS_BY_USER: function (user_id) {
            return `${API_BASE}/missions/user/${user_id}`;
        },
        TYPES: `${API_BASE}/metadata/mission/types`,
        TYPE_DETAIL: function (type_id) {
            return `${API_BASE}/metadata/mission/types/${type_id}`;
        },
        ACTIVITY: `${API_BASE}/metadata/mission/activity`,
        ACTIVITY_DETAIL: function (activity_id) {
            return `${API_BASE}/metadata/mission/activity/${activity_id}`;
        },
        AREAS_OF_SUPPORT: `${API_BASE}/metadata/mission/areas_of_support`,
        AREAS_OF_SUPPORT_DETAIL: function (areas_of_support_id) {
            return `${API_BASE}/metadata/mission/areas_of_support/${areas_of_support_id}`;
        },
        PARTNERS: `${API_BASE}/metadata/mission/partners`,
        PARTNER_DETAIL: function (partner_id) {
            return `${API_BASE}/metadata/mission/partners/${partner_id}`;
        },
        EXPERT_INSTITUTION: `${API_BASE}/metadata/mission/expert_institution`,
        EXPERT_INSTITUTION_DETAIL: function (expert_institution_id) {
            return `${API_BASE}/metadata/mission/expert_institution/${expert_institution_id}`;
        },
        IMPACT: `${API_BASE}/metadata/mission/impact`,
        IMPACT_DETAIL: function (impact_id) {
            return `${API_BASE}/metadata/mission/impact/${impact_id}`;
        },
    },
    TASKS: {
        TASKS: `${API_BASE}/tasks`,
        TASK_DETAIL: function (task_id) {
            return `${API_BASE}/tasks/${task_id}`;
        },
        TASKS_BY_MISSION: function (mission_id) {
            return `${API_BASE}/tasks/missions/${mission_id}`;
        },
    },
    COMMENTS: {
        COMMENTS: `${API_BASE}/comments`,
        COMMENT_DETAIL: function (comment_id) {
            return `${API_BASE}/comments/${comment_id}`;
        },
        TASKS: function (task_id) {
            return `${API_BASE}/comments/tasks/${task_id}`;
        },
        MISSIONS: function (mission_id) {
            return `${API_BASE}/comments/missions/${mission_id}`;
        },
    },
    ATTACHMENTS: {
        ATTACHMENTS: `${API_BASE}/attachments`,
        ATTACHMENT_DETAIL: function (attachment_id) {
            return `${API_BASE}/attachments/${attachment_id}`;
        },
        TASKS: function (task_id) {
            return `${API_BASE}/attachments/tasks/${task_id}`;
        },
        MISSIONS: function (mission_id) {
            return `${API_BASE}/attachments/missions/${mission_id}`;
        },
    },
    CONTENT: {
        COUNTRY: `${API_BASE}/content/country`,
        COUNTRY_DETAIL: function (country_id) {
            return `${API_BASE}/content/country/${country_id}`;
        },
        USER_COUNTRIES: `${API_BASE}/content/users/country`,
        REGION: `${API_BASE}/content/region`,
        PRIORITY: `${API_BASE}/content/priority`,
        STATUS: `${API_BASE}/content/status`,
        DOWNLOAD: `${API_BASE}/content/download`,
    },
    METADATA: {
        PROGRAMME: {
            CATEGORIES: `${API_BASE}/metadata/programme/categories`,
            CATEGORY_DETAIL: function (category_id) {
                return `${API_BASE}/metadata/programme/categories/${category_id}`;
            },
            TYPES: `${API_BASE}/metadata/programme/types`,
            TYPE_DETAIL: function (type_id) {
                return `${API_BASE}/metadata/programme/types/${type_id}`;
            },
            TAX_TYPES: `${API_BASE}/metadata/programme/tax_types`,
            TAX_TYPE_DETAIL: function (tax_type_id) {
                return `${API_BASE}/metadata/programme/tax_types/${tax_type_id}`;
            },
            DEPARTMENTS: `${API_BASE}/metadata/programme/departments`,
            DEPARTMENT_DETAIL: function (department_id) {
                return `${API_BASE}/metadata/programme/departments/${department_id}`;
            },
            INTERNAL_UNITS: `${API_BASE}/metadata/programme/internal_units`,
            INTERNAL_UNIT_DETAIL: function (internal_unit_id) {
                return `${API_BASE}/metadata/programme/internal_units/${internal_unit_id}`;
            },
            IMPACT: `${API_BASE}/metadata/programme/impact`,
            IMPACT_DETAIL: function (impact_id) {
                return `${API_BASE}/metadata/programme/impact/${impact_id}`;
            },
        },
        MISSION: {
            TYPES: `${API_BASE}/metadata/mission/types`,
            TYPE_DETAIL: function (type_id) {
                return `${API_BASE}/metadata/mission/types/${type_id}`;
            },
            ACTIVITY: `${API_BASE}/metadata/mission/activity`,
            ACTIVITY_DETAIL: function (activity_id) {
                return `${API_BASE}/metadata/mission/activity/${activity_id}`;
            },
            AREAS_OF_SUPPORT: `${API_BASE}/metadata/mission/areas_of_support`,
            AREAS_OF_SUPPORT_DETAIL: function (areas_of_support_id) {
                return `${API_BASE}/metadata/mission/areas_of_support/${areas_of_support_id}`;
            },
            PARTNERS: `${API_BASE}/metadata/mission/partners`,
            PARTNER_DETAIL: function (partner_id) {
                return `${API_BASE}/metadata/mission/partners/${partner_id}`;
            },
            EXPERT_INSTITUTION: `${API_BASE}/metadata/mission/expert_institution`,
            EXPERT_INSTITUTION_DETAIL: function (expert_institution_id) {
                return `${API_BASE}/metadata/mission/expert_institution/${expert_institution_id}`;
            },
            IMPACT: `${API_BASE}/metadata/mission/impact`,
            IMPACT_DETAIL: function (impact_id) {
                return `${API_BASE}/metadata/mission/impact/${impact_id}`;
            },
        },
    },
    USERS: {
        USERS: `${API_BASE}/users`,
        USER_DETAIL: function (user_id) {
            return `${API_BASE}/users/detail/${user_id}`;
        },
        TYPES: `${API_BASE}/users/types`,
        TYPE_DETAIL: function (type_id) {
            return `${API_BASE}/users/types/${type_id}`;
        },
        ACTIVITY: `${API_BASE}/users/activity`,
        ACTIVITY_BY_USER: function (user_id) {
            return `${API_BASE}/users/activity/user/${user_id}`;
        },
        ACTIVITY_BY_MISSION: function (mission_id) {
            return `${API_BASE}/users/activity/mission/${mission_id}`;
        },
        PERMISSIONS_BY_GROUP: `${API_BASE}/users/group`,
        PERMISSIONS: `${API_BASE}/users/permissions`,
        PERMISSIONS_BY_USER: function (user_id) {
            return `${API_BASE}/users/permissions/${user_id}`;
        },
        MISSION_PERMISSIONS_BY_USER: function (mission_id, user_id) {
            return `${API_BASE}/users/permissions/custom/mission/${mission_id}/${user_id}`;
        },
        PROGRAMME_PERMISSIONS_BY_USER: function (programme_id, user_id) {
            return `${API_BASE}/users/permissions/custom/mission/${programme_id}/${user_id}`;
        },
        CUSTOM_PERMISSIONS_BY_USER: function (user_id) {
            return `${API_BASE}/users/permissions/custom/user/${user_id}`;
        },
    },
    OUTCOMES: {
        OUTCOMES: `${API_BASE}/outcomes`,
        MISSION: `${API_BASE}/outcomes/mission`,
        MISSION_DETAIL: function (mission_id) {
            return `${API_BASE}/outcomes/mission/${mission_id}`;
        },
        PROGRAMME: `${API_BASE}/outcomes/programme`,
        PROGRAMME_DETAIL: function (programme_id) {
            return `${API_BASE}/outcomes/programme/${programme_id}`;
        },
    },
    REPORT: {
        REPORTS: `${API_BASE}/reports`,
        MISSIONS: `${API_BASE}/reports/missions`,
        PROGRAMMES: `${API_BASE}/reports/programmes`,
    },
};
