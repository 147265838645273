import { useState, useEffect } from "react";
import { PencilIcon } from "@heroicons/react/outline";
import { format } from "date-fns";

import { useAuthentication } from "hooks/AuthenticationHook";
import { PERMISSIONS } from "hooks/permissions";
import { API_ROUTES } from "api";
import ModalUserDetails from "components/modal-user-details";
import Modal from "components/modal";
import CloseMission from "components/close-mission";
import ViewMissionOutcome from "components/view-mission-outcome";
import Status from "components/status";
import Progress from "components/progress";
import ProgressBar from "components/progress-bar";
import DownloadAsPdf from "components/download-pdf";
import Delete from "components/delete";

export default function Card({
    row,
    editable,
    setEditableRow,
    reloadMissionData,
    tableHeaders,
}) {
    const [showMoreText, setShowMoreText] = useState(false);
    const [userDetailsRow, setUserDetailsRow] = useState({});
    const [openUserDetails, setOpenUserDetails] = useState(false);
    let { hasPermission } = useAuthentication();

    useEffect(() => {
        return () => {
            setShowMoreText(false);
        };
    }, []);

    return (
        <>
            <Modal
                modalHeaderTag={""}
                modalHeader={`User Details: ${row.created_by.first_name} ${row.created_by.last_name}`}
                modalContent={
                    <ModalUserDetails
                        setOpen={setOpenUserDetails}
                        userDetailsRow={userDetailsRow}
                    />
                }
                open={openUserDetails}
                setOpen={setOpenUserDetails}
            />
            <li
                key={row.id}
                className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200 flex flex-col"
            >
                <div className="w-full p-5 flex-1">
                    <div className="flex space-x-3">
                        <div className="min-w-0 flex-1">
                            <span className="header-tag-green">
                                {row.normalised.type}
                            </span>
                        </div>
                        <div className="flex-shrink-0 self-center flex">
                            {row.closed ? (
                                <ViewMissionOutcome mission={row} />
                            ) : (
                                <CloseMission
                                    mission={row}
                                    reloadMissionData={reloadMissionData}
                                />
                            )}
                        </div>
                    </div>

                    <div className="flex space-x-3">
                        <h2 className="h2 whitespace-normal text-xl mt-2">
                            {row.normalised.name}
                            {editable &&
                                !row.closed &&
                                hasPermission(
                                    PERMISSIONS.MISSIONS.CAN_EDIT_MISSION
                                ) && (
                                    <button
                                        type="button"
                                        className="bg-white p-1 mx-1 rounded-full text-gray-400 hover:text-gray-500"
                                    >
                                        <PencilIcon
                                            className="h-4 w-4 mr-1"
                                            aria-hidden="true"
                                            onClick={() => setEditableRow(row)}
                                        />
                                    </button>
                                )}
                        </h2>
                    </div>
                    <div>
                        <p className="text-gray-400 ">
                            {row.overview.length > 120 ? (
                                <>
                                    {showMoreText
                                        ? row.overview + ` `
                                        : row.overview.substr(0, 120) + ` ... `}
                                    <span
                                        className="brand-link"
                                        onClick={() =>
                                            setShowMoreText(!showMoreText)
                                        }
                                    >
                                        show {showMoreText ? `less` : `more`}
                                    </span>
                                </>
                            ) : (
                                row.overview
                            )}
                        </p>
                    </div>
                    <div className="items-center">
                        <p className="my-1 text-sm">
                            <strong>Date:</strong>{" "}
                            {row.normalised.start_date +
                                " - " +
                                row.normalised.end_date}
                        </p>
                        <p className="my-1 text-sm">
                            <strong>Duration:</strong> {row.normalised.duration}
                        </p>
                        <div className="pt-2">
                            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                                <ProgressBar
                                    progressValue={row.overall_progress}
                                />
                            </div>
                        </div>

                        <div className="pt-1 text-sm">
                            <div className="flex justify-between mb-1">
                                <Status progressValue={row.overall_progress} />
                                <Progress
                                    progressValue={row.overall_progress}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex border-t border-gray-200 p-5 rounded-bl-lg rounded-br-lg bg-gray-100 text-sm">
                    <div className="flex-1">
                        <span className="font-bold">Created:</span>{" "}
                        {format(new Date(row.created), "dd MMM yyyy")} by{" "}
                        <span
                            className="hover:underline cursor-pointer"
                            onClick={() => {
                                setUserDetailsRow(row.created_by);
                                setOpenUserDetails(true);
                            }}
                        >
                            {row.created_by.first_name +
                                " " +
                                row.created_by.last_name}
                        </span>{" "}
                        <br />
                        <span className="font-bold">Last updated:</span>{" "}
                        {row.normalised.updated}
                    </div>
                    <div className="float-right">
                        <DownloadAsPdf
                            tableTitle={`${row.programme.name}:`}
                            tableHeaders={tableHeaders}
                            tableData={[row]}
                            tableDataResult={row.name}
                            printType={`page`}
                            filename={`Mission: ${row.name}`}
                        />
                        {hasPermission(
                            PERMISSIONS.MISSIONS.CAN_DELETE_MISSION
                        ) && (
                            <Delete
                                apiRoute={API_ROUTES.MISSION.MISSION_DETAIL}
                                rowData={row}
                                loadData={reloadMissionData}
                                titleValue={"Mission"}
                            />
                        )}
                    </div>
                </div>
            </li>
        </>
    );
}
