export default function ProgressBar({ progressValue }) {
    const SLIDER = [
        {
            NAME: "NOT STARTED",
            MIN: 0,
            MAX: 1,
            STYLE: "bg-red-800",
        },
        {
            NAME: "POOR",
            MIN: 1,
            MAX: 33,
            STYLE: "bg-red-500",
        },
        {
            NAME: "PROGRESSIVE",
            MIN: 33,
            MAX: 66,
            STYLE: "bg-yellow-500",
        },
        {
            NAME: "GOOD",
            MIN: 66,
            MAX: 99,
            STYLE: "bg-green-500",
        },
        {
            NAME: "COMPLETE",
            MIN: 99,
            MAX: 100,
            STYLE: "bg-green-800",
        },
    ];
    return (
        <div
            className={`${
                SLIDER.filter(
                    (x) => x.MIN <= progressValue && x.MAX >= progressValue
                )[0].STYLE
            } h-2 rounded-full`}
            style={{ width: `${progressValue}%` }}
        ></div>
    );
}
