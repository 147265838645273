import { useState, useEffect, useContext } from "react";
import { format, isAfter } from "date-fns";
import { useFormValidation } from "hooks/FormValidationHook";
import { API_ROUTES } from "api";
import { ApiConsumer } from "api-consumer";

import {
    DocumentReportIcon,
    UserIcon,
    UserAddIcon,
} from "@heroicons/react/outline";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Alert from "components/alert";
import Select from "react-select";
import { AppLoaderContext } from "hooks/AppLoaderContext";
import ModalUserDetails from "components/modal-user-details";
import Modal from "components/modal";

export default function AddEditMission({
    programme,
    setOpen,
    editableRow,
    setRowUpdated,
}) {
    let { setLoading } = useContext(AppLoaderContext);
    let { validFormDetails } = useFormValidation();
    const [types, setTypes] = useState([]);
    const [activity, setActivity] = useState([]);
    const [areasOfSupport, setAreasOfSupport] = useState([]);
    const [partnerOrganizations, setPartnerOrganizations] = useState([]);
    const [internalExperts, setInternalExperts] = useState([]);
    const [externalExperts, setExternalExperts] = useState([]);
    const [reportUsers, setReportUsers] = useState([]);
    const [invalidFormDetails, setInvalidFormDetails] = useState({});
    const [formDetails, setFormDetails] = useState({});
    const [formError, setFormError] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [userDetailsRow, setUserDetailsRow] = useState({});
    const [openUserDetails, setOpenUserDetails] = useState(false);

    const formFields = [
        { name: "type", display: "mission type" },
        { name: "activity", display: "mission activity" },
        { name: "areas_of_support", display: "area of support" },
        { name: "name", display: "mission name" },
        { name: "overview", display: "overwiew" },
        { name: "start_date", display: "mission start date" },
        { name: "end_date", display: "mission end date" },

        {
            name: "report_users",
            display: "users allowed to view mission reports and outcomes",
        },
    ];

    const validateAddEditMission = (isEdit) => {
        if (
            validFormDetails(
                formFields,
                formDetails,
                setInvalidFormDetails,
                setFormError
            )
        ) {
            setLoading(true);
            if (isEdit) {
                ApiConsumer.put(
                    API_ROUTES.MISSION.MISSION_DETAIL(editableRow.id),
                    formDetails
                )
                    .then((res) => {
                        setRowUpdated(true);
                        setFormSubmitted(true);
                    })
                    .catch((err) => {
                        setFormError(err.toString());
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                ApiConsumer.post(API_ROUTES.MISSION.MISSIONS, formDetails)
                    .then((res) => {
                        setRowUpdated(true);
                        setFormSubmitted(true);
                    })
                    .catch((err) => {
                        setFormError(err.toString());
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }
    };

    const loadDropdown = (link, setMethod) => {
        ApiConsumer.get(link)
            .then((res) => {
                res.data.sort((a, b) => a.name.localeCompare(b.name));
                setMethod(res.data);
            })
            .catch((err) => {});
    };

    const loadMultiple = (link, setMethod) => {
        ApiConsumer.get(link)
            .then((res) => {
                res.data.sort((a, b) => a.name.localeCompare(b.name));
                let options = res.data.map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                    };
                });
                setMethod(options);
            })
            .catch((err) => {});
    };

    useEffect(() => {
        loadDropdown(API_ROUTES.MISSION.TYPES, setTypes);
        loadDropdown(API_ROUTES.MISSION.ACTIVITY, setActivity);
        loadMultiple(API_ROUTES.MISSION.AREAS_OF_SUPPORT, setAreasOfSupport);
        loadMultiple(API_ROUTES.MISSION.PARTNERS, setPartnerOrganizations);

        ApiConsumer.get(API_ROUTES.USERS.USERS)
            .then((res) => {
                res.data.sort((a, b) =>
                    a.first_name.localeCompare(b.first_name)
                );
                let users = res.data.filter(
                    (type) => type.user_type.name === "Internal Expert"
                );
                let userOptions = users.map((user) => {
                    return {
                        value: user.id,
                        label: user.first_name + " " + user.last_name,
                    };
                });
                setInternalExperts(userOptions);
                users = res.data.filter(
                    (type) => type.user_type.name === "External Expert"
                );
                userOptions = users.map((user) => {
                    return {
                        value: user.id,
                        label: user.first_name + " " + user.last_name,
                    };
                });
                setExternalExperts(userOptions);
                users = res.data; //Get all users
                userOptions = users.map((user) => {
                    return {
                        value: user.id,
                        label: user.first_name + " " + user.last_name,
                    };
                });
                setReportUsers(userOptions);
            })
            .catch((err) => {});

        /* Defaults */
        setFormDetails((prevState) => ({
            ...prevState,
            [`programme`]: programme.id,
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`type`]: "selected",
        }));

        setFormDetails((prevState) => ({
            ...prevState,
            [`activity`]: "selected",
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`start_date`]: new Date(programme.start_date),
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`end_date`]: new Date(programme.end_date),
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`areas_of_support`]: [],
        }));
        setFormDetails((prevState) => ({
            ...prevState,
            [`partners`]: [],
        }));
        let ta_officers = [];
        programme.ta_officers.forEach((ta_officer, i) => {
            ta_officers[i] = ta_officer.id;
        });
        setFormDetails((prevState) => ({
            ...prevState,
            [`ta_officers`]: ta_officers,
        }));
        let internal_experts = [];
        programme.internal_experts.forEach((internal_expert, i) => {
            internal_experts[i] = internal_expert.id;
        });
        setFormDetails((prevState) => ({
            ...prevState,
            [`internal_experts`]: internal_experts,
        }));
        let external_experts = [];
        programme.external_experts.forEach((external_expert, i) => {
            external_experts[i] = external_expert.id;
        });
        setFormDetails((prevState) => ({
            ...prevState,
            [`external_experts`]: external_experts,
        }));
        let report_users = [];
        programme.report_users.forEach((report_user, i) => {
            report_users[i] = report_user.id;
        });
        setFormDetails((prevState) => ({
            ...prevState,
            [`report_users`]: report_users,
        }));

        /* If editing */
        if (Object.keys(editableRow).length !== 0) {
            setFormDetails((prevState) => ({
                ...prevState,
                [`mission_id`]: editableRow.id,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`type`]: editableRow.type.id,
            }));

            setFormDetails((prevState) => ({
                ...prevState,
                [`activity`]: editableRow.activity.id,
            }));

            setFormDetails((prevState) => ({
                ...prevState,
                [`name`]: editableRow.name,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`overview`]: editableRow.overview,
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`start_date`]: new Date(editableRow.start_date),
            }));
            setFormDetails((prevState) => ({
                ...prevState,
                [`end_date`]: new Date(editableRow.end_date),
            }));
            let areas_of_support = [];
            editableRow.areas_of_support.forEach((area, i) => {
                areas_of_support[i] = area.id;
            });
            setFormDetails((prevState) => ({
                ...prevState,
                [`areas_of_support`]: areas_of_support,
            }));
            let partners = [];
            editableRow.partners.forEach((partner, i) => {
                partners[i] = partner.id;
            });
            setFormDetails((prevState) => ({
                ...prevState,
                [`partners`]: partners,
            }));
            ta_officers = [];
            editableRow.ta_officers.forEach((ta_officer, i) => {
                ta_officers[i] = ta_officer.id;
            });
            setFormDetails((prevState) => ({
                ...prevState,
                [`ta_officers`]: ta_officers,
            }));
            internal_experts = [];
            editableRow.internal_experts.forEach((internal_expert, i) => {
                internal_experts[i] = internal_expert.id;
            });
            setFormDetails((prevState) => ({
                ...prevState,
                [`internal_experts`]: internal_experts,
            }));
            external_experts = [];
            editableRow.external_experts.forEach((external_expert, i) => {
                external_experts[i] = external_expert.id;
            });
            setFormDetails((prevState) => ({
                ...prevState,
                [`external_experts`]: external_experts,
            }));
            report_users = [];
            editableRow.report_users.forEach((report_user, i) => {
                report_users[i] = report_user.id;
            });
            setFormDetails((prevState) => ({
                ...prevState,
                [`report_users`]: report_users,
            }));
        }

        return () => {
            setTypes([]);
            setActivity([]);
            setAreasOfSupport([]);
            setInternalExperts([]);
            setExternalExperts([]);

            setInvalidFormDetails({});
            setFormDetails({});
            setFormError("");
            setFormSubmitted(false);
        };
    }, [editableRow, programme]);

    return (
        <>
            {!formSubmitted ? (
                <form
                    className="space-y-4 mb-28"
                    onSubmit={(event) => {
                        event.preventDefault();
                        validateAddEditMission(Object.keys(editableRow).length);
                    }}
                    method="POST"
                >
                    {Object.keys(editableRow).length !== 0 && (
                        <>
                            <Modal
                                modalHeaderTag={""}
                                modalHeader={`User Details: ${editableRow.created_by.first_name} ${editableRow.created_by.last_name}`}
                                modalContent={
                                    <ModalUserDetails
                                        setOpen={setOpenUserDetails}
                                        userDetailsRow={userDetailsRow}
                                    />
                                }
                                open={openUserDetails}
                                setOpen={setOpenUserDetails}
                            />
                            <div className="text-sm border-gray-200 p-4 rounded-lg bg-gray-100">
                                <span className="font-bold">Created:</span>{" "}
                                {format(
                                    new Date(editableRow.created),
                                    "dd MMM yyyy"
                                )}{" "}
                                by{" "}
                                <span
                                    className="hover:underline cursor-pointer"
                                    onClick={() => {
                                        setUserDetailsRow(
                                            editableRow.created_by
                                        );
                                        setOpenUserDetails(true);
                                    }}
                                >
                                    {editableRow.created_by.first_name +
                                        " " +
                                        editableRow.created_by.last_name}
                                </span>{" "}
                                <span className="font-bold">Last updated:</span>{" "}
                                {editableRow.normalised.updated}
                            </div>
                        </>
                    )}

                    <div className="space-y-2">
                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="type">
                                    Mission type:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <select
                                    id="type"
                                    name="type"
                                    autoComplete="type"
                                    className={`${
                                        invalidFormDetails.type
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`type`]: event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.type
                                            ? formDetails.type
                                            : "selected"
                                    }
                                >
                                    <option value="selected">
                                        Select type
                                    </option>
                                    {types.map((type) => {
                                        return (
                                            <option
                                                key={type.id}
                                                value={type.id}
                                            >
                                                {type.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="activity">
                                    Mission activity:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <select
                                    id="activity"
                                    name="activity"
                                    autoComplete="activity"
                                    className={`${
                                        invalidFormDetails.activity
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`activity`]: event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.activity
                                            ? formDetails.activity
                                            : "selected"
                                    }
                                >
                                    <option value="selected">
                                        Select mission activity
                                    </option>
                                    {activity.map((activity) => {
                                        return (
                                            <option
                                                key={activity.id}
                                                value={activity.id}
                                            >
                                                {activity.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="areas_of_support"
                                >
                                    Area of support:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <Select
                                    id="areas_of_support"
                                    name="areas_of_support"
                                    isMulti
                                    options={areasOfSupport}
                                    classNamePrefix="multi-select"
                                    className={`multi-select ${
                                        invalidFormDetails.areas_of_support
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`areas_of_support`]: [
                                                ...event,
                                            ].map((option) => option.value),
                                        }));
                                    }}
                                    defaultValue={0}
                                    value={
                                        Object.keys(areasOfSupport).length !== 0
                                            ? formDetails?.areas_of_support?.map(
                                                  (selectedOption) => {
                                                      return areasOfSupport[
                                                          areasOfSupport.findIndex(
                                                              (option) =>
                                                                  option.value ===
                                                                  selectedOption
                                                          )
                                                      ];
                                                  }
                                              )
                                            : 0
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="name">
                                    Mission name:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <input
                                    id="name"
                                    name="name"
                                    type="text"
                                    autoComplete="name"
                                    className={`form-field ${
                                        invalidFormDetails.name
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`name`]: event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.name ? formDetails.name : ""
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="overview">
                                    Brief overview:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <textarea
                                    id="overview"
                                    name="overview"
                                    autoComplete="overview"
                                    className={`form-field ${
                                        invalidFormDetails.overview
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`overview`]: event.target.value,
                                        }));
                                    }}
                                    value={
                                        formDetails.overview
                                            ? formDetails.overview
                                            : ""
                                    }
                                ></textarea>
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="start_date">
                                    Mission start date:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <DatePicker
                                    fixedHeight
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    showYearDropdown
                                    minDate={new Date(programme.start_date)}
                                    maxDate={new Date(programme.end_date)}
                                    dateFormat="dd MMMM yyyy"
                                    selected={formDetails.start_date}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`start_date`]: event,
                                        }));
                                        if (
                                            isAfter(event, formDetails.end_date)
                                        )
                                            setFormDetails((prevState) => ({
                                                ...prevState,
                                                [`end_date`]: event,
                                            }));
                                    }}
                                    className="form-field"
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="end_date">
                                    Mission end date:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <DatePicker
                                    fixedHeight
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    showYearDropdown
                                    minDate={
                                        isAfter(
                                            new Date(programme.start_date),
                                            formDetails.start_date
                                        )
                                            ? new Date(programme.start_date)
                                            : new Date(formDetails.start_date)
                                    }
                                    maxDate={new Date(programme.end_date)}
                                    dateFormat="dd MMMM yyyy"
                                    selected={formDetails.end_date}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`end_date`]: event,
                                        }));
                                    }}
                                    className="form-field"
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="internal_experts"
                                >
                                    <UserIcon
                                        className="h-5 w-5 mr-0.5 inline-block"
                                        aria-hidden="true"
                                    />
                                    Internal expert(s) under this mission:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <Select
                                    id="internal_experts"
                                    name="internal_experts"
                                    isMulti
                                    options={internalExperts}
                                    classNamePrefix="multi-select"
                                    className={`multi-select ${
                                        invalidFormDetails.internal_experts
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`internal_experts`]: [
                                                ...event,
                                            ].map((option) => option.value),
                                        }));
                                    }}
                                    defaultValue={0}
                                    value={
                                        Object.keys(internalExperts).length !==
                                        0
                                            ? formDetails?.internal_experts?.map(
                                                  (selectedOption) => {
                                                      return internalExperts[
                                                          internalExperts.findIndex(
                                                              (option) =>
                                                                  option.value ===
                                                                  selectedOption
                                                          )
                                                      ];
                                                  }
                                              )
                                            : 0
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="external_experts"
                                >
                                    <UserAddIcon
                                        className="h-5 w-5 mr-0.5 inline-block"
                                        aria-hidden="true"
                                    />
                                    External expert(s) under this mission:
                                </label>
                            </div>
                            <div className="col-span-8">
                                <Select
                                    id="external_experts"
                                    name="external_experts"
                                    isMulti
                                    options={externalExperts}
                                    classNamePrefix="multi-select"
                                    className={`multi-select ${
                                        invalidFormDetails.external_experts
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`external_experts`]: [
                                                ...event,
                                            ].map((option) => option.value),
                                        }));
                                    }}
                                    defaultValue={0}
                                    value={
                                        Object.keys(externalExperts).length !==
                                        0
                                            ? formDetails?.external_experts?.map(
                                                  (selectedOption) => {
                                                      return externalExperts[
                                                          externalExperts.findIndex(
                                                              (option) =>
                                                                  option.value ===
                                                                  selectedOption
                                                          )
                                                      ];
                                                  }
                                              )
                                            : 0
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label
                                    className="text-sm"
                                    htmlFor="external_experts"
                                >
                                    <DocumentReportIcon
                                        className="h-5 w-5 mr-0.5 inline-block"
                                        aria-hidden="true"
                                    />
                                    Users allowed to view mission reports and
                                    outcomes:{" "}
                                    <span className="text-red-600">*</span>
                                </label>
                            </div>
                            <div className="col-span-8">
                                <Select
                                    id="report_users"
                                    name="report_users"
                                    isMulti
                                    options={reportUsers}
                                    classNamePrefix="multi-select"
                                    className={`multi-select ${
                                        invalidFormDetails.report_users
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`report_users`]: [...event].map(
                                                (option) => option.value
                                            ),
                                        }));
                                    }}
                                    defaultValue={0}
                                    value={
                                        Object.keys(reportUsers).length !== 0
                                            ? formDetails?.report_users?.map(
                                                  (selectedOption) => {
                                                      return reportUsers[
                                                          reportUsers.findIndex(
                                                              (option) =>
                                                                  option.value ===
                                                                  selectedOption
                                                          )
                                                      ];
                                                  }
                                              )
                                            : 0
                                    }
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-12">
                            <div className="col-span-4">
                                <label className="text-sm" htmlFor="partners">
                                    Partner organization(s):
                                </label>
                            </div>
                            <div className="col-span-8">
                                <Select
                                    id="partners"
                                    name="partners"
                                    isMulti
                                    options={partnerOrganizations}
                                    classNamePrefix="multi-select"
                                    className={`multi-select ${
                                        invalidFormDetails.partners
                                            ? `border-red-600`
                                            : `border-gray-300`
                                    }`}
                                    onChange={(event) => {
                                        setFormDetails((prevState) => ({
                                            ...prevState,
                                            [`partners`]: [...event].map(
                                                (option) => option.value
                                            ),
                                        }));
                                    }}
                                    defaultValue={0}
                                    value={
                                        Object.keys(partnerOrganizations)
                                            .length !== 0
                                            ? formDetails?.partners?.map(
                                                  (selectedOption) => {
                                                      return partnerOrganizations[
                                                          partnerOrganizations.findIndex(
                                                              (option) =>
                                                                  option.value ===
                                                                  selectedOption
                                                          )
                                                      ];
                                                  }
                                              )
                                            : 0
                                    }
                                />
                            </div>
                        </div>

                        {formError.length !== 0 && (
                            <div className="col-span-12 form-error text-center">
                                {formError}
                            </div>
                        )}

                        <div className="flex justify-center">
                            <button
                                type="button"
                                className="button-default mx-1 mt-4"
                                onClick={() => setOpen(false)}
                            >
                                Close Popup
                            </button>
                            <button type="submit" className="button mx-1 mt-4">
                                {Object.keys(editableRow).length === 0
                                    ? `Add Mission`
                                    : `Update`}
                            </button>
                        </div>
                    </div>
                </form>
            ) : (
                <>
                    <Alert
                        type={`success`}
                        message={`Successfully ${
                            Object.keys(editableRow).length === 0
                                ? `added`
                                : `updated`
                        } mission:<br /><strong>${formDetails.name}</strong>`}
                    />

                    <div className="flex justify-center">
                        <button
                            type="button"
                            className="button-default mx-1 mt-4"
                            onClick={() => setOpen(false)}
                        >
                            Close
                        </button>
                    </div>
                </>
            )}
        </>
    );
}
