import { useState, useEffect, useContext } from "react";
import { PencilIcon } from "@heroicons/react/outline";
import Alert from "components/alert";
import { format } from "date-fns";
import { ApiConsumer } from "api-consumer";
import { API_ROUTES } from "api";
import Status from "components/status";
import Progress from "components/progress";
import ProgressBar from "components/progress-bar";
import { useAuthentication } from "hooks/AuthenticationHook";
import { PERMISSIONS } from "hooks/permissions";
import ModalUserDetails from "components/modal-user-details";
import { AuthenticationContext } from "hooks/AuthenticationContext";
import Modal from "components/modal";
import Delete from "components/delete";

export default function Task({
    tableHeaders = [],
    tableData = [],
    editable,
    setEditableRow,
    loadData,
    reloadMissionData,
}) {
    let { currentUser } = useContext(AuthenticationContext);
    let { hasPermission } = useAuthentication();
    const [userDetailsRow, setUserDetailsRow] = useState({});
    const [openUserDetails, setOpenUserDetails] = useState(false);
    const [progressSubmitted, setProgressSubmitted] = useState(false);
    const [progress, setProgress] = useState([]);

    const updateTaskProgress = (task) => {
        let taskObj = {
            ...task,
            mission: task.mission.id,
            progress: progress[task.id].toString(),
        };
        delete taskObj.id;
        delete taskObj.created;
        delete taskObj.updated;
        delete taskObj.normalised;

        ApiConsumer.put(API_ROUTES.TASKS.TASK_DETAIL(task.id), taskObj).then(
            (res) => {
                reloadMissionData();
                setProgressSubmitted(true);
            }
        );
    };

    useEffect(() => {
        /* Defaults */
        setProgress((prevState) => ({
            ...prevState,
            [tableData.id]: tableData.progress,
        }));
    }, [tableData]);

    useEffect(() => {
        return () => {
            setProgressSubmitted(false);
            setProgress([]);
        };
    }, []);

    return (
        <>
            <Modal
                modalHeaderTag={""}
                modalHeader={`User Details: ${tableData.created_by.first_name} ${tableData.created_by.last_name}`}
                modalContent={
                    <ModalUserDetails
                        setOpen={setOpenUserDetails}
                        userDetailsRow={userDetailsRow}
                    />
                }
                open={openUserDetails}
                setOpen={setOpenUserDetails}
            />
            <div className="w-full p-5">
                <div className="mb-2">
                    {progressSubmitted && (
                        <Alert
                            type={`success`}
                            message={`${tableData.normalised.name} progress successfully updated.`}
                            hasClose={true}
                        />
                    )}
                </div>
                <div className="flex space-x-3">
                    <h2 className="flex-1 min-w-0 h2 whitespace-normal text-lg">
                        {tableData.normalised.name}
                        {editable &&
                            !tableData.mission.closed &&
                            hasPermission(PERMISSIONS.TASKS.CAN_EDIT_TASK) && (
                                <button
                                    type="button"
                                    className="bg-white p-1 mx-1 rounded-full text-gray-400 hover:text-gray-500"
                                >
                                    <PencilIcon
                                        className="h-4 w-4 mr-1"
                                        aria-hidden="true"
                                        onClick={() =>
                                            setEditableRow(tableData)
                                        }
                                    />
                                </button>
                            )}
                    </h2>

                    {!tableData.mission.closed &&
                        hasPermission(PERMISSIONS.TASKS.CAN_EDIT_TASK) && (
                            <div className=" font-medium">
                                <button
                                    type="button"
                                    onClick={() =>
                                        updateTaskProgress(tableData)
                                    }
                                    className="button-default float-right ml-1"
                                >
                                    Update
                                </button>

                                <select
                                    id="progress"
                                    name="progress"
                                    autoComplete="progress"
                                    className={`w-14 m-0 p-0 py-2 float-right`}
                                    onChange={(event) => {
                                        setProgress((prevState) => ({
                                            ...prevState,
                                            [tableData.id]: event.target.value,
                                        }));
                                        setProgressSubmitted(false);
                                    }}
                                    value={progress[tableData.id]}
                                >
                                    <option value="0">0%</option>
                                    <option value="10">10%</option>
                                    <option value="20">20%</option>
                                    <option value="30">30%</option>
                                    <option value="40">40%</option>
                                    <option value="50">50%</option>
                                    <option value="60">60%</option>
                                    <option value="70">70%</option>
                                    <option value="80">80%</option>
                                    <option value="90">90%</option>
                                    <option value="100">100%</option>
                                </select>
                            </div>
                        )}
                </div>
                <div className="items-center">
                    <p className="my-1 text-sm">
                        <strong>Duration:</strong>{" "}
                        {tableData.normalised.duration}
                        {" | "}
                        <strong>Date:</strong>{" "}
                        {tableData.normalised.start_date +
                            " - " +
                            tableData.normalised.end_date}
                    </p>
                    <p className=" my-1"></p>
                    <div className="pt-2">
                        <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                            <ProgressBar
                                progressValue={
                                    progress[tableData.id]
                                        ? progress[tableData.id]
                                        : tableData.progress
                                }
                            />
                        </div>
                    </div>

                    <div className="pt-1 text-sm">
                        <div className="flex justify-between mb-1">
                            <Status
                                progressValue={
                                    progress[tableData.id]
                                        ? progress[tableData.id]
                                        : tableData.progress
                                }
                            />
                            <Progress
                                progressValue={
                                    progress[tableData.id]
                                        ? progress[tableData.id]
                                        : tableData.progress
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="border-b border-gray-200 p-5 py-2 bg-gray-100 text-sm rounded-bl-lg rounded-br-lg">
                <div className="float-left pt-1">
                    <span className="font-bold">Created:</span>{" "}
                    {format(new Date(tableData.created), "dd MMM yyyy")} by{" "}
                    <span
                        className="hover:underline cursor-pointer"
                        onClick={() => {
                            setUserDetailsRow(tableData.created_by);
                            setOpenUserDetails(true);
                        }}
                    >
                        {tableData.created_by.first_name +
                            " " +
                            tableData.created_by.last_name}
                    </span>{" "}
                    <span className="font-bold">Last updated:</span>{" "}
                    {format(new Date(tableData.created), "dd MMM yyyy")}
                </div>
                <div className="float-right">
                    {tableData.created_by.id === currentUser.id &&
                        !tableData.mission.closed && (
                            <Delete
                                apiRoute={API_ROUTES.TASKS.TASK_DETAIL}
                                rowData={tableData}
                                loadData={loadData}
                                titleValue={"Task"}
                            />
                        )}
                </div>
                <div className="clear-both"></div>
            </div>
        </>
    );
}
