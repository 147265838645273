import { useEffect, useState } from "react";
import { PlusCircleIcon } from "@heroicons/react/outline";
import Navigation from "components/navigation";
import Header from "components/header";
import PageHeader from "components/page-header";
import Table from "components/table";
import Modal from "components/modal";
import Delete from "components/delete";
import AddEditDepartment from "components/add-edit-department";
import { useTextFormatHook } from "hooks/TextFormatHook";
import { ApiConsumer } from "api-consumer";
import { Redirect, NavLink } from "react-router-dom";
import { API_ROUTES } from "api";
import { PORTAL } from "portal";

export default function Departments() {
    let loggedInUser = JSON.parse(sessionStorage.getItem(`ATAFTAX`));
    let { normaliseTableData } = useTextFormatHook();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [departments, setDepartments] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [openAddEditDepartment, setOpenAddEditDepartment] = useState(false);
    const [editableRow, setEditableRow] = useState({});
    const [rowUpdated, setRowUpdated] = useState(false);

    const pageHeaderButtons = [
        <button
            type="button"
            onClick={() => setOpenAddEditDepartment(true)}
            className={`button`}
        >
            <span className="button-icon">
                <PlusCircleIcon className="h-5 w-5" aria-hidden="true" />
            </span>
            Add Specific Subject Matter
        </button>,
    ];

    const tableHeaders = [
        {
            key: "name",
            value: "Specific Subject Matters",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "main_correspondent",
            value: "Main Correspondent",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "designation",
            value: "Designation",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "email",
            value: "Email Address",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "contact_number",
            value: "Contact Number",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "created",
            value: "Created",
            showOnTable: false,
            showOnPrint: true,
        },
        {
            key: "updated",
            value: "Updated",
            showOnTable: false,
            showOnPrint: true,
        },
    ];

    const addLinksToTableData = (tableData) => {
        tableData.forEach((row) => {
            row.normalised.name = (
                <>
                    <NavLink
                        to={`${PORTAL.PROGRAMMES_BY_DEPARTMENT}/${row.id}`}
                        className={`brand-link`}
                    >
                        {row.normalised.name}
                    </NavLink>
                    <Delete
                        apiRoute={
                            API_ROUTES.METADATA.PROGRAMME.DEPARTMENT_DETAIL
                        }
                        rowData={row}
                        loadData={loadData}
                        titleValue={"Specific Subject Matter"}
                    />
                </>
            );
        });
        return tableData;
    };

    const loadData = () => {
        ApiConsumer.get(API_ROUTES.METADATA.PROGRAMME.DEPARTMENTS)
            .then((res) => {
                res.data.sort((a, b) => a.name.localeCompare(b.name));
                let normalisedData = normaliseTableData(tableHeaders, res.data);
                setDepartments(addLinksToTableData(normalisedData));
            })
            .catch((err) => {})
            .finally(() => {
                setLoaded(true);
            });
    };

    useEffect(() => {
        loadData();
        return () => {
            setDepartments({});
            setLoaded(false);
            setSidebarOpen(false);
            setOpenAddEditDepartment(false);
            setEditableRow({});
            setRowUpdated(false);
        };
    }, []);

    const onCloseAddEditDepartment = (modalStatus) => {
        //Modal closing
        if (modalStatus === false) {
            setEditableRow({});
            if (rowUpdated) loadData();
        }
        setOpenAddEditDepartment(modalStatus);
    };

    useEffect(() => {
        //Triggering table row Edit button
        Object.keys(editableRow).length === 0
            ? setOpenAddEditDepartment(false)
            : setOpenAddEditDepartment(true);
    }, [editableRow]);

    if (loggedInUser.user_type.name !== "Administrator")
        return <Redirect to={PORTAL.LOGIN} />;

    return (
        <>
            <Modal
                modalHeaderTag={""}
                modalHeader={
                    Object.keys(editableRow).length === 0
                        ? `Add New Specific Subject Matter`
                        : `Edit Specific Subject Matter: ${editableRow.name}`
                }
                modalContent={
                    <AddEditDepartment
                        setOpen={onCloseAddEditDepartment}
                        editableRow={editableRow}
                        setRowUpdated={setRowUpdated}
                    />
                }
                open={openAddEditDepartment}
                setOpen={onCloseAddEditDepartment}
            />

            <div className="min-h-full bg-gray-100">
                <Navigation
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                />

                <div className="lg:pl-64 flex flex-col flex-1">
                    <Header />

                    <main className="flex-1 pb-8">
                        <PageHeader
                            pageHeaderName={`Specific Subject Matters (${departments?.length})`}
                            pageHeaderButtons={pageHeaderButtons}
                        />

                        {loaded && (
                            <Table
                                tableTitle={`Departments/Sectors`}
                                tableHeaders={tableHeaders}
                                tableData={departments}
                                editable={true}
                                setEditableRow={setEditableRow}
                            />
                        )}
                    </main>
                </div>
            </div>
        </>
    );
}
