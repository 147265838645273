import { Fragment, useContext, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { MenuAlt1Icon } from "@heroicons/react/outline";
import { ChevronDownIcon, SearchIcon } from "@heroicons/react/solid";
import { AuthenticationContext } from "hooks/AuthenticationContext";
import { useAuthentication } from "hooks/AuthenticationHook";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import NotificationIcon from "components/notification-icon";
import { NotificationContext } from "hooks/NotificationContext";
import { PORTAL } from "portal";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function Header({ sidebarOpen, setSidebarOpen }) {
    let { currentUser, setCurrentUser } = useContext(AuthenticationContext);
    let { logout } = useAuthentication();
    let { history } = useHistory();
    let { showNotifications, setNotification } =
        useContext(NotificationContext);
    const [searchAction, setSearchAction] = useState(PORTAL.PROGRAMMES);

    return (
        <>
            <div className="relative z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200 lg:border-none">
                <button
                    type="button"
                    className="px-4 border-r border-gray-200 text-gray-400 focus:outline-none lg:hidden"
                    onClick={() => setSidebarOpen(true)}
                >
                    <span className="sr-only">Open sidebar</span>
                    <MenuAlt1Icon className="h-6 w-6" aria-hidden="true" />
                </button>
                {/* Search bar */}
                <div className="flex-1 flex justify-between mx-full px-4 sm:px-6 lg:px-8 my-5 ">
                    <div className="flex-1 flex">
                        <form
                            className="w-full flex md:ml-0"
                            action={searchAction}
                            method="GET"
                        >
                            <label htmlFor="search-field" className="sr-only">
                                Search
                            </label>
                            <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                                <div
                                    className="float-left p-1"
                                    aria-hidden="true"
                                >
                                    <SearchIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                    />
                                </div>
                                <label className="text-gray-500 float-left px-1 py-2 cursor-pointer">
                                    <input
                                        defaultChecked
                                        id="checkbox-programmes"
                                        name="type"
                                        className="border-transparent focus:outline-none focus:ring-0 focus:border-transparent sm:text-sm"
                                        type="radio"
                                        value="programmes"
                                        onClick={() =>
                                            setSearchAction(PORTAL.PROGRAMMES)
                                        }
                                    />{" "}
                                    Programmes
                                </label>
                                <label className="text-gray-500 float-left px-1 py-2 cursor-pointer">
                                    <input
                                        id="checkbox-missions"
                                        name="type"
                                        className="border-transparent focus:outline-none focus:ring-0 focus:border-transparent sm:text-sm"
                                        type="radio"
                                        value="missions"
                                        onClick={() =>
                                            setSearchAction(PORTAL.MISSIONS)
                                        }
                                    />{" "}
                                    Missions
                                </label>
                                <input
                                    id="search"
                                    name="search"
                                    className="float-left block ml-1 pl-2 pr-3 py-1.5 w-3/6 border-transparent text-gray-900 placeholder-gray-500 bg-gray-100 rounded-md focus:outline-none focus:ring-0 focus:border-transparent sm:text-sm"
                                    placeholder="Search for programme or mission..."
                                    type="search"
                                />
                            </div>
                        </form>
                    </div>
                    <div className="ml-4 flex items-center md:ml-6">
                        <NotificationIcon />
                        {/* Profile dropdown */}
                        <Menu as="div" className="relative">
                            <div>
                                <Menu.Button className="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none lg:p-2 lg:rounded-md lg:hover:bg-gray-100">
                                    <span className="icon-transparent p-2 bg-orange-500 text-orange-100 hover:text-orange-100">
                                        {currentUser.name
                                            .charAt(0)
                                            .toUpperCase() +
                                            currentUser.surname
                                                .charAt(0)
                                                .toUpperCase()}
                                    </span>
                                    <span className="hidden ml-1 text-gray-700 text-sm text-left leading-4 font-medium lg:block">
                                        <span className="sr-only">
                                            Open user menu for{" "}
                                        </span>
                                        {currentUser.name +
                                            " " +
                                            currentUser.surname}
                                        <br />
                                        <span className="text-xs text-gray-400">
                                            {currentUser.user_type.name}
                                        </span>
                                    </span>
                                    <ChevronDownIcon
                                        className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"
                                        aria-hidden="true"
                                    />
                                </Menu.Button>
                            </div>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <NavLink
                                                to={`${PORTAL.PROGRAMMES_BY_USER}/${currentUser.id}`}
                                                className={classNames(
                                                    active ? "bg-gray-100" : "",
                                                    "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                                                )}
                                            >
                                                My Programmes
                                            </NavLink>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <NavLink
                                                to={`${PORTAL.MISSIONS_BY_USER}/${currentUser.id}`}
                                                className={classNames(
                                                    active ? "bg-gray-100" : "",
                                                    "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                                                )}
                                            >
                                                My Missions
                                            </NavLink>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <div
                                                onClick={() => {
                                                    setNotification(
                                                        (prevState) => ({
                                                            ...prevState,
                                                            [`panel`]: true,
                                                        })
                                                    );
                                                }}
                                                className={classNames(
                                                    active ? "bg-gray-100" : "",
                                                    "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                                                )}
                                            >
                                                Notifications
                                            </div>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <div
                                                onClick={() => {
                                                    logout();
                                                    setCurrentUser({});
                                                    window.location.reload();
                                                    history.push("/login");
                                                    window.location.reload();
                                                }}
                                                className={classNames(
                                                    active ? "bg-gray-100" : "",
                                                    "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                                                )}
                                            >
                                                Logout
                                            </div>
                                        )}
                                    </Menu.Item>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                </div>
            </div>
        </>
    );
}
