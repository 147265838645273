import { useEffect, useRef, useState } from "react";
import { ApiConsumer } from "api-consumer";
import ReactEcharts from "echarts-for-react";

export function Bar({ url, title = "", height = "1500" }) {
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState({ programmes: 0, missions: 0 });
  const [chartHeight, setChartHeight] = useState(1500);
  const [errorMsg, setErrorMsg] = useState('loading ..');
  const fontSize = useRef(16);

  const colours = ["#5fa83f", "#f72585", "#118ab2", "#ff9770", "#d35400"];

  const setDivisions = () => {
    if (data.length > 0) {
      let values = [];
      data.map((item, index) => {
        if (index !== 0) values.push(item[1],item[2]);
      });
      const maxValue = Math.max.apply(Math, values);
      const roundedValue = Math.ceil(maxValue / 10) * 10;
      return roundedValue;
    } 
  };

  const returnData = (dataType) => {
    if (data.length > 0) {
      const input = [...data];
      input.shift();
      const resultData = input.map((item) => item[dataType]);
      return resultData;  
    }
  };

  const setFontWidth = () => {
    const width = window.innerWidth;
    if (width < 750) fontSize.current = 6;
    else if (width > 750 && width < 1100) fontSize.current = 8;
    else if (width > 1100 && width < 1250) fontSize.current = 10;
    else if (width > 1250 && width < 1500) fontSize.current = 11;
    else fontSize.current = 16;
  };

  useEffect(() => {
    setFontWidth();
    window.addEventListener('resize', setFontWidth);

    return () => {
      window.removeEventListener('resize', setFontWidth);
    };
  }, []); 

  const getOptions = () => ({
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      data: ['Programmes', 'Missions'],
      textStyle: {
        fontSize: 18
      }
    },
    yAxis: {
      type: 'category',
      data: returnData(0),
      axisLabel: {
        fontSize: fontSize.current,
        fontFamily: "Arial",
        color: '#000'
      },
    },
    xAxis: {
      type: 'value',
      interval: 10,
      max: setDivisions()
    },
    series: [
      {
        name: 'Programmes',
        type: 'bar',
        data: returnData(1)
      },
      {
        name: 'Missions',
        type: 'bar',
        data: returnData(2),
        color: colours[4]
      }
    ],
  });

  const totalProgrammesAndMissions = (countryData) => {
    if (countryData.length > 0) {
      let totalProgrammes = 0;
      let totalMissions = 0;
      const inputData = [...countryData];
      inputData.shift();
      inputData.map((item) => {
        totalProgrammes = item[1] + totalProgrammes;
        totalMissions = item[2] + totalMissions;
      });
      setTotalCount({ programmes: totalProgrammes, missions: totalMissions });
    }
  };

  const sortAndRemove = (data) => {
    const input = [...data];
    const header = input[0];
    let validItems = [];
    input.shift();
    input.map((item, i) => {
      if (item[1] > 0 || item[2] > 0) validItems.push(item);
  });
  let sortedArray = validItems.sort().reverse();
  sortedArray.unshift(header);
  return sortedArray;
  }

  const adjustChartHeight = () => {
    const length = data.length;
    if (length < 3) setChartHeight(300);
    else if (length <= 11) setChartHeight(600);
    else if (length > 11 && length <= 15) setChartHeight(800);
    else if (length > 15 && length <= 20) setChartHeight(1100);
    else setChartHeight(1500);
  };

  useEffect(() => {
    if (data.length > 0) adjustChartHeight();
  }, [data, chartHeight]);

  useEffect(() => {
    ApiConsumer.get(url)
    .then((res) => {
      function sort(a, b) {
        return b.total_programmes - a.total_programmes;
      }

      let temp = res.data.sort(sort);

      let result = [];
      result.push(["Country", "Programmes", "Missions"]);
      temp.map((t) => {
        result.push([t.name, t.total_programmes, t.total_missions]);
      });
      const finalResult = sortAndRemove(result);
      setData(finalResult);
      if (finalResult.length === 1) {
        setErrorMsg('No data');
      }
      totalProgrammesAndMissions(result);
    })
    .catch((err) => console.error(err));
  }, []);

  return (
    <>
      <div className="mx-full px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col mt-2">
          <div className="bg-white align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
            <h3 className="bg-white p-2 text-center">{title}</h3>
            {!data || data.length === 1 ? (
                <div style={{textAlign: 'center', marginTop: '10%', width: '100%', height: '300px'}}>{errorMsg}</div>
            ) : (
              <>
              <div className="bg-white mt-4 flex space-x-3">
              <h6 className="min-w-0 flex-1 text-center text-sm">
                TOTAL COUNTRIES: {data.length - 1}
              </h6>
              <h6 className="min-w-0 flex-1 text-center text-sm">
                TOTAL PROGRAMMES: {totalCount.programmes}
              </h6>
              <h6 className="min-w-0 flex-1 text-center text-sm">
                TOTAL MISSIONS: {totalCount.missions}
              </h6>
            </div>
            
            <ReactEcharts option={getOptions()} style={{ height: chartHeight, marginTop: '2%', marginLeft: '2%' }} />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
