import { PORTAL } from "portal";

const axios = require("axios");
export const ApiConsumer = axios.create();

export const isAuthenticated = () => {
    return !!sessionStorage.getItem(`ATAFTAX`);
};

export const UserId = () => {
    return JSON.parse(sessionStorage.getItem(`ATAFTAX`)).id;
};

ApiConsumer.interceptors.request.use(
    (request) => {
        if (isAuthenticated()) {
            request.headers.Authorization = `Api-Key ${
                JSON.parse(sessionStorage.getItem(`ATAFTAX`))?.token
            }`;
            request.headers["User-Id"] = UserId();
        } else {
            window.location.replace(PORTAL.LOGIN);
        }
        return request;
    },
    (error) => {
        return Promise.reject(error);
    }
);
