import { useEffect, useState } from "react";
import { ApiConsumer } from "api-consumer";
import { API_ROUTES } from "api";
import { compareDesc, format } from "date-fns";
import Navigation from "components/navigation";
import Header from "components/header";
import PageHeader from "components/page-header";
import Table from "components/table";
import { useTextFormatHook } from "hooks/TextFormatHook";
import { PORTAL } from "portal";

import { Redirect } from "react-router-dom";
import ModalUserDetails from "components/modal-user-details";
import Modal from "components/modal";

export default function Activity() {
    let loggedInUser = JSON.parse(sessionStorage.getItem(`ATAFTAX`));
    let { normaliseTableData } = useTextFormatHook();
    const [activity, setActivity] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [userDetailsRow, setUserDetailsRow] = useState({});
    const [openUserDetails, setOpenUserDetails] = useState(false);
    const [editableRow, setEditableRow] = useState({});
    const pageHeaderButtons = [];

    const tableHeaders = [
        {
            key: "name",
            value: "User",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "activity",
            value: "Activity",
            showOnTable: true,
            showOnPrint: true,
        },
        {
            key: "created",
            value: "Date",
            showOnTable: true,
            showOnPrint: true,
        },
    ];

    const addLinksToTableData = (tableData) => {
        tableData.forEach((row) => {
            row.normalised.name = (
                <span
                    className={`brand-link`}
                    onClick={() => {
                        setUserDetailsRow(row.user);
                        setOpenUserDetails(true);
                    }}
                >
                    {row.user.first_name + " " + row.user.last_name}
                </span>
            );
            row.name = row.user.first_name + " " + row.user.last_name;
            row.normalised.created = format(
                new Date(row.created),
                "dd MMM yyyy HH:ii:ss"
            );
        });
        return tableData;
    };

    const loadData = () => {
        ApiConsumer.get(API_ROUTES.USERS.ACTIVITY)
            .then((res) => {
                res.data.sort((a, b) =>
                    compareDesc(new Date(a.created), new Date(b.created))
                );
                let normalisedData = normaliseTableData(tableHeaders, res.data);
                setActivity(addLinksToTableData(normalisedData));
            })
            .catch((err) => {})
            .finally(() => {
                setLoaded(true);
            });
    };

    useEffect(() => {
        loadData();
        return () => {
            setActivity({});
            setLoaded(false);
        };
    }, []);

    if (loggedInUser.user_type.name !== "Administrator")
        return <Redirect to={PORTAL.LOGIN} />;

    return (
        <>
            <Modal
                modalHeaderTag={""}
                modalHeader={`User Details: ${userDetailsRow?.first_name} ${userDetailsRow?.last_name}`}
                modalContent={
                    <ModalUserDetails
                        setOpen={setOpenUserDetails}
                        userDetailsRow={userDetailsRow}
                    />
                }
                open={openUserDetails}
                setOpen={setOpenUserDetails}
            />
            <div className="min-h-full bg-gray-100">
                <Navigation
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                />

                <div className="lg:pl-64 flex flex-col flex-1">
                    <Header />

                    <main className="flex-1 pb-8">
                        <PageHeader
                            pageHeaderName={`System Activity`}
                            pageHeaderButtons={pageHeaderButtons}
                        />
                        {loaded && (
                            <Table
                                tableTitle={`System Activity`}
                                tableHeaders={tableHeaders}
                                tableData={activity}
                                editable={false}
                                setEditableRow={setEditableRow}
                            />
                        )}
                    </main>
                </div>
            </div>
        </>
    );
}
